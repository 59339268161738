<section [formGroup]="stepForm">
  <header class="mb-4">
    <h4>{{ 'wizards:advanced_filter_title' | i18next: { format: 'title' } }}</h4>
  </header>

  <mbs-card [size]="'sm'"
            [border]="false">
    <mbs-card-group>
      <mbs-form-group class="mb-0">
        <mbs-radio formControlName="backupAllFiles"
                   name="backupAllFiles"
                   (change)="backupAllFilesChangeHandler($event)"
                   [label]="'wizards:backup_in_selected_label' | i18next"
                   [id]="'backup-all-files-radio-0'"
                   [value]="0"></mbs-radio>
        <mbs-radio formControlName="backupAllFiles"
                   name="backupAllFiles"
                   (change)="backupAllFilesChangeHandler($event)"
                   [label]="'wizards:backup_types_label' | i18next"
                   [descriptionOutside]="false"
                   [id]="'backup-all-files-radio-1'"
                   [value]="1">
          <ng-container appendLabel>
            <span class="cursor-help fa fa-info-circle text-info ml-2"
                  [ngbTooltip]="'wizards:backup_types_tooltip' | i18next"></span>
          </ng-container>
          <ng-template radioDescription
                       [hide]="stepForm.get('backupAllFiles').value !== 1">
            <mbs-input formControlName="includeMask"
                       name="includeMask"
                       [id]="'include-mask-input'"
                       [validationWhitespace]="true"></mbs-input>
          </ng-template>
        </mbs-radio>
        <mbs-radio formControlName="backupAllFiles"
                   name="backupAllFiles"
                   (change)="backupAllFilesChangeHandler($event)"
                   [label]="'wizards:not_backup_types_label' | i18next"
                   [descriptionOutside]="false"
                   [id]="'backup-all-files-radio-2'"
                   [value]="2">
          <ng-container appendLabel>
            <span class="cursor-help fa fa-info-circle text-info ml-2"
                  [ngbTooltip]="'wizards:not_backup_types_tooltip' | i18next"></span>
          </ng-container>
          <ng-template radioDescription
                       [hide]="stepForm.get('backupAllFiles').value !== 2">
            <mbs-input formControlName="excludeMask"
                       name="excludeMask"
                       [id]="'exclude-mask-input'"
                       [validationWhitespace]="true"></mbs-input>
          </ng-template>
        </mbs-radio>
      </mbs-form-group>
    </mbs-card-group>
    <mbs-card-group>
      <mbs-form-group [class.mb-0]="isLinux">
        <div class="form-row">
          <div class="col-3">
            <mbs-checkbox formControlName="skipFolders"
                          (change)="skipFoldersChangeHandler($event)"
                          [label]="'wizards:skip_folders_label' | i18next"
                          [id]="'skip-folders-checkbox'">
              <ng-container appendLabel>
                <span class="cursor-help fa fa-info-circle text-info ml-2"
                      container="body"
                      tooltipClass="tooltip-lg"
                      [ngbTooltip]="'wizards:skip_folders_tooltip' | i18next"></span>
              </ng-container>
            </mbs-checkbox>
          </div>
          <div *ngIf="canBackupEmptyFolders"
               class="col-9">
            <mbs-checkbox formControlName="BackupEmptyFolders"
                          [label]="'wizards:backup_empty_label' | i18next"
                          [id]="'backup-empty-checkbox'"></mbs-checkbox>
          </div>
        </div>
        <div *ngIf="stepForm.get('skipFolders').value"
             class="mbs-check_description -inside mt-3">
          <mbs-input formControlName="skipFolderName"
                     name="skipFolderName"
                     [id]="'skip-folder-name'"
                     [validationWhitespace]="true"></mbs-input>
        </div>
      </mbs-form-group>

      <ng-container *ngIf="!isLinux">
        <mbs-form-group>
          <div class="form-row align-items-center">
            <div class="col-4">
              <mbs-checkbox formControlName="backupFilesModified"
                            (change)="backupFilesModifiedChangeHandler($event)"
                            [label]="'wizards:backup_modified_label' | i18next"
                            [id]="'backup-files-modified-checkbox'"></mbs-checkbox>
            </div>
            <div class="col-2">
              <mbs-number formControlName="daysAgo"
                          min="1"
                          [id]="'days-ago-input-number'"></mbs-number>
            </div>
            <div class="col-3">
              <label class="mbs-form_label mb-0"
                     for="days-ago-input-number"
                     [class.-disabled]="!stepForm.get('backupFilesModified').value">{{'wizards:days_ago_label' | i18next}}</label>
              <span class="cursor-help fa fa-info-circle text-info ml-2"
                    [ngbTooltip]="'wizards:days_ago_tooltip' | i18next"></span>
            </div>
          </div>
        </mbs-form-group>

        <mbs-form-group>
          <div class="form-row align-items-center">
            <div class="col-5">
              <mbs-checkbox formControlName="backupFilesModifiedSince"
                            (change)="backupFilesModifiedSinceChangeHandler($event)"
                            [label]="'wizards:backup_modified_since_label' | i18next"
                            [id]="'backup-files-modified-since-checkbox'"></mbs-checkbox>
            </div>
            <div class="col-3">
              <mbs-datepicker formControlName="date"
                              [needSetCurrent]="true"
                              [id]="'backup-files-modified-since-date-picker'"></mbs-datepicker>
            </div>
            <div class="col-4">
              <div class="d-flex align-items-center">
                <label class="mbs-form_label mb-0 mr-2"
                       for="backup-files-modified-since-time-picker"
                       [class.-disabled]="!stepForm.get('backupFilesModifiedSince').value">{{'wizards:time_label' | i18next}}</label>
                <mbs-timepicker formControlName="time"
                                [id]="'backup-files-modified-since-time-picker'"
                                [meridian]="true"
                                [needChangeInvalid]="true"></mbs-timepicker>
                <span class="cursor-help fa fa-info-circle text-info ml-2"
                      [placement]="['top-right', 'left', 'auto']"
                      [ngbTooltip]="'wizards:backup_modified_since_tooltip' | i18next"></span>
              </div>
            </div>
          </div>
        </mbs-form-group>

        <mbs-form-group class="mb-0">
          <div class="form-row align-items-center">
            <div class="col-5">
              <mbs-checkbox formControlName="doNotBackup"
                            class="mr-2"
                            (change)="doNotBackupChangeHandler($event)"
                            [label]="'wizards:not_backup_large_than_label' | i18next"
                            [id]="'do-not-backup-files-checkbox'"></mbs-checkbox>
            </div>
            <div class="col-3">
              <mbs-number formControlName="fileSize"
                          min="1"
                          [id]="'file-size-input-number'"></mbs-number>
            </div>
            <div class="col-1">
              <label class="mbs-form_label mb-0"
                     for="file-size-input-number"
                     [class.-disabled]="!stepForm.get('doNotBackup').value">MB</label>
            </div>
          </div>
        </mbs-form-group>
      </ng-container>
    </mbs-card-group>

    <mbs-card-group>
      <mbs-form-group class="mb-0">
        <mbs-checkbox formControlName="ignoreSystemAndHidden"
                      [label]="'wizards:not_backup_system_hidden_label' | i18next"
                      [id]="'ignore-system-hidden-checkbox'"></mbs-checkbox>

        <ng-container *ngIf="!linuxLessThat4_2">
          <mbs-checkbox formControlName="SkipInUseFiles"
                        [label]="'wizards:not_backup_locked_label' | i18next"
                        [id]="'ignore-locked-checkbox'"></mbs-checkbox>
        </ng-container>
        <ng-container *ngIf="!isLinux">
          <mbs-checkbox formControlName="ignoreOnDemand"
                        [label]="'wizards:not_backup_demand_label' | i18next"
                        [id]="'ignore-on-demand-checkbox'">
            <ng-container appendLabel>
              <span class="cursor-help fa fa-info-circle text-info ml-2"
                    container="body"
                    tooltipClass="tooltip-lg"
                    [ngbTooltip]="ignoreOnDemandTooltip"></span>
              <ng-template #ignoreOnDemandTooltip>
                <div class="text-left">
                  <span *ngFor="let item of notBackupDemandTooltipTexts;let last = last"
                        [class]="!last ? 'd-block mb-2' : ''">
                    {{item}}
                  </span>
                </div>
              </ng-template>
            </ng-container>
          </mbs-checkbox>
        </ng-container>
      </mbs-form-group>
    </mbs-card-group>
  </mbs-card>
</section>
