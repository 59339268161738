import { Injectable } from '@angular/core';
import { TFAService } from '@components/tfa/services/tfa.service';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbilityService } from 'ability';
import { MbsSize, ModalService, ModalSettings } from 'mbs-ui-kit';
import { Observable, catchError, from, iif, of, switchMap, take } from 'rxjs';
import { AuthService } from '.';
import { TFARequireModalComponent } from '../components/tfa/components/tfa-require-modal/tfa-require-modal.component';

const TFAErrorStatus = 420;

@UntilDestroy()
@Injectable()
export class TFARMMPermissionHelperService {
  constructor(
    private modalService: ModalService,
    private TFAService: TFAService,
    private ability: AbilityService,
    private commandService: CommandService,
    private authService: AuthService
  ) {}

  public is2FAPassedStream(hids, twoFaModalContaner: string, alwaysRequired = false, tfaTitle?: string): Observable<boolean> {
    if (!hids.length) return of(false);

    if (this.commandService.canHidAccess(hids[0]) && !alwaysRequired) return of(true);

    return this.initTwoFAModal(hids, twoFaModalContaner, tfaTitle);
  }

  private initTwoFAModal(hids, container: string, tfaTitle?: string): Observable<boolean> {
    if (!this.ability.can('read', '2FAEnabled')) {
      return from(
        this.modalService.openCustom(TFARequireModalComponent, {
          size: MbsSize.sm,
          container: container
        })
      ).pipe(
        catchError((err) => of(false)),
        switchMap((value) => of(false))
      );
    }
    return this.requestPermission(hids, container, tfaTitle);
  }

  private requestPermission(hids, container: string, tfaTitle?: string): Observable<any> {
    return this.authService
      .requestPermissions({
        hids
      })
      .pipe(
        take(1),
        catchError((error) => {
          if (error?.status !== TFAErrorStatus) {
            return of(false);
          }
          return from(
            this.show2FAConfirm(
              tfaTitle
                ? {
                    title: tfaTitle,
                    ...error.error
                  }
                : error.error,
              container
            )
          ).pipe(switchMap((result) => iif(() => Boolean(result), this.requestPermission(hids, container, tfaTitle), of(false))));
        }),
        untilDestroyed(this)
      );
  }

  private show2FAConfirm(data: ModalSettings, container: string): Promise<unknown> {
    return this.TFAService.openApproveModal(data, container);
  }
}
