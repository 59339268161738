<section [formGroup]="stepForm"
         class="mbs-wizard_form">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{ (mainService.allowPrePostActions | async) ?  ('wizards:pre_post_title' | i18next: { format: 'title' }) : ('wizards:pre_post_title_backup_chain' | i18next: { format: 'title' }) }}
    </h4>
  </header>

  <mbs-alert *ngIf="isRDMode"
             [attr.data-test-element]="elementsSelector.name.isRDModeAlert"
             type="warning"
             [icon]="true">
    {{'wizards:compression_encryption_pre_post_rd_warning' | i18next}}
  </mbs-alert>

  <div class="mbs-wizard_form-content">
    <mbs-form-group *ngIf="(mainService.allowPrePostActions | async)"
                    [attr.data-test-element]="elementsSelector.name.allowPrePostActionsBlock">
      <mbs-checkbox formControlName="executeCommandBeforeBackup"
                    (change)="beforeBackupChangeHandler($event)"
                    [label]="isRestore ?
                    ('wizards:restore_pre_action_label' | i18next) :
                    ('wizards:command_before_backup_label' | i18next)"
                    [descriptionOutside]="false"
                    [id]="'execute-command-before-backup-checkbox'">
        <ng-template checkboxDescription>
          <mbs-form-group>
            <mbs-input formControlName="preAction"
                       [validationWhitespace]="true"></mbs-input>
          </mbs-form-group>

          <mbs-form-group class="mb-0">
            <mbs-radio formControlName="exitOnPreActionFail"
                       name="exitOnPreActionFail"
                       [label]="isRestore ?
                       ('wizards:restore_exit_pre_action_failed_label' | i18next) :
                       ('wizards:exit_pre_backup_failed_label' | i18next)"
                       [id]="'exit-on-pre-action-fail-radio-1'"
                       [value]="true"></mbs-radio>
            <mbs-radio formControlName="exitOnPreActionFail"
                       name="exitOnPreActionFail"
                       [label]="isRestore ?
                       ('wizards:restore_continue_pre_action_failed_label'| i18next) :
                       ('wizards:continue_pre_backup_failed_label' | i18next)"
                       [id]="'exit-on-pre-action-fail-radio-2'"
                       [value]="false"></mbs-radio>
          </mbs-form-group>
        </ng-template>
      </mbs-checkbox>
    </mbs-form-group>

    <mbs-form-group *ngIf="(mainService.allowPrePostActions | async)"
                    [attr.data-test-element]="elementsSelector.name.allowPrePostActionsSecBlock">
      <mbs-checkbox formControlName="executeCommandAfterBackupCompletion"
                    (change)="afterBackupChangeHandler($event)"
                    [label]="isRestore ?
                    ('wizards:restore_post_action_label' | i18next):
                    ('wizards:execute_after_complete_label' | i18next)"
                    [descriptionOutside]="false"
                    [id]="'execute-command-after-backup-completion-checkbox'">
        <ng-template checkboxDescription>
          <mbs-form-group>
            <mbs-input formControlName="postAction"
                       [validationWhitespace]="true"></mbs-input>
          </mbs-form-group>

          <mbs-form-group class="mb-0">
            <mbs-radio formControlName="executePostActionOnSuccess"
                       name="executePostActionOnSuccess"
                       [label]="isRestore ?
                       ('wizards:restore_post_success_label'| i18next) :
                       ('wizards:execute_post_success_label' | i18next)"
                       [id]="'execute-post-action-on-success-radio-1'"
                       [value]="true"></mbs-radio>
            <mbs-radio formControlName="executePostActionOnSuccess"
                       name="executePostActionOnSuccess"
                       [label]="isRestore ?
                       ('wizards:restore_post_regardless_label'| i18next) :
                       ('wizards:execute_post_regardless_label' | i18next)"
                       [id]="'execute-post-action-on-success-radio-2'"
                       [value]="false"></mbs-radio>
          </mbs-form-group>
        </ng-template>
      </mbs-checkbox>
    </mbs-form-group>

    <mbs-form-group *ngIf="!isLinux && !isRestore"
                    [attr.data-test-element]="elementsSelector.name.notLinuxNotRestore">
      <mbs-checkbox formControlName="enableBackupChain"
                    [label]="'wizards:execute_follow_label' | i18next"
                    (change)="backupChainChangeHandler($event)"
                    [descriptionOutside]="false"
                    [id]="'enable-backup-chain-checkbox'">
        <ng-template checkboxDescription>
          <mbs-form-group>
            <mbs-select formControlName="backupChainNextPlan"
                        class="w-50"
                        [appendTo]="'.modal-content'"
                        [bindValue]="'value'"
                        [bindLabel]="'label'"
                        [clearable]="false"
                        [hideSelected]="true"
                        [items]="ChainPlans"
                        [id]="'overdue-after-success-period-select'"
                        [searchable]="true"></mbs-select>
          </mbs-form-group>

          <mbs-form-group>
            <mbs-radio formControlName="executeChainPlanOnSuccess"
                       name="executeChainPlanOnSuccess"
                       [label]="'wizards:execute_specified_label' | i18next"
                       [id]="'execute-chain-plan-on-success-radio-1'"
                       [value]="true"></mbs-radio>
            <mbs-radio formControlName="executeChainPlanOnSuccess"
                       name="executeChainPlanOnSuccess"
                       [label]="'wizards:execute_specified_regardless_label' | i18next"
                       [id]="'execute-chain-plan-on-success-radio-2'"
                       [value]="false"></mbs-radio>
          </mbs-form-group>

          <mbs-form-group class="mb-0">
            <label
              [class.-disabled]="stepForm.get('ForceFullNextPlan').disabled">
              {{'wizards:force_full_backup_text' | i18next}}
            </label>
            <mbs-radio formControlName="ForceFullNextPlan"
                       name="ForceFullNextPlan"
                       [class]="'ml-4'"
                       [label]="'wizards:force_full_backup_label' | i18next"
                       [id]="'force-full-radio-1'"
                       [value]="true"></mbs-radio>
            <mbs-radio formControlName="ForceFullNextPlan"
                       name="ForceFullNextPlan"
                       [class]="'ml-4'"
                       [label]="'wizards:not_force_full_backup_label' | i18next"
                       [id]="'force-full-radio-2'"
                       [value]="false">
              <ng-container appendLabel>
                <span class="fa fa-info-circle ml-2 cursor-help text-info"
                      [ngbTooltip]="'wizards:force_full_backup_tooltip' | i18next"
                      container="body"></span>
              </ng-container>
            </mbs-radio>
          </mbs-form-group>
        </ng-template>
      </mbs-checkbox>
    </mbs-form-group>

  </div>
</section>
