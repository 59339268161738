<section [formGroup]="stepForm"
         class="mbs-wizard_form">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{ 'wizards:retention_policy_title' | i18next: { format: 'title' } }}
    </h4>
  </header>

  <div class="mbs-wizard_form-content">
    <mbs-alert *ngIf="needShowMinPeriodAlert"
               [attr.data-test-element]="elementsSelector.name.minPeriodAlert"
               [type]="mbsPopupType.warning"
               [icon]="true">
      <span *ngFor="let item of retentionPolicyMinPeriodAlert">
        {{item}}
      </span>
    </mbs-alert>

    <mbs-alert *ngIf="isRDMode && importedPlanChanged"
               [attr.data-test-element]="elementsSelector.name.notSupportedAlert"
               [type]="mbsPopupType.warning"
               [icon]="true">
      {{'wizards:where_backup_config_not_supported' | i18next}}
    </mbs-alert>

    <mbs-alert *ngIf="isNBF && !isFullBackup && !isFFIMode"
               [attr.data-test-element]="elementsSelector.name.notFullAlert"
               [type]="mbsPopupType.warning"
               [icon]="true">
      {{'wizards:retention_policy_not_full_alert' | i18next}}
    </mbs-alert>

    <!--is archive start-->
    <div *ngIf="isNBF"
         [attr.data-test-element]="elementsSelector.name.nbfBlock">
      <div class="mbs-form_section">
        <mbs-form-group>
          <mbs-radio formControlName="RetentionUseDefaultSettings"
                     name="RetentionUseDefaultSettings"
                     [disabled]="isFFIMode"
                     [label]="'wizards:do_not_purge_label' | i18next"
                     (change)="doNotPurgeChangeHandler($event)"
                     [value]="true"
                     [id]="'selection-use-defaults-1'"></mbs-radio>
          <mbs-radio formControlName="RetentionUseDefaultSettings"
                     name="RetentionUseDefaultSettings"
                     [label]="'wizards:keep_backup_label' | i18next"
                     (change)="doNotPurgeChangeHandler($event)"
                     [value]="false"
                     [descriptionOutside]="false"
                     [id]="'selection-use-defaults-2'">
            <ng-template radioDescription>
              <mbs-form-group>
                <div class="form-row align-items-center">
                  <div class="col-2">
                    <mbs-number formControlName="deleteVersionsOlderThanCount"
                                min="1"
                                [id]="'ibb-deleteVersionsOlderThanCount'"></mbs-number>
                  </div>
                  <div class="col-3">
                    <mbs-select formControlName="deleteVersionsOlderThanPeriod"
                                [appendTo]="'.modal-content'"
                                [bindValue]="'value'"
                                [bindLabel]="'label'"
                                [clearable]="false"
                                [items]="periodEnumSelectArray"
                                [id]="'PeriodEnumSelect'"
                                [searchable]="false"
                                (change)="keepBackupForPeriodChangeHandler()"></mbs-select>
                  </div>
                  <div class="col-1">
                    <span class="fa fa-info-circle ml-2 cursor-help text-info"
                          tooltipClass="tooltip-xl"
                          [container]="'body'"
                          [placement]="['bottom-left', 'bottom']"
                          [ngbTooltip]="isFFIMode ? ('wizards:delete_version_tooltip_ffi' | i18next) : ('wizards:delete_version_tooltip' | i18next)"></span>
                  </div>
                </div>
                <mbs-input-errors *ngIf="isFFIMode" [errors]="[{ message: keepBackupFFIAlert }]"></mbs-input-errors>
              </mbs-form-group>
            </ng-template>
          </mbs-radio>
        </mbs-form-group>

        <mbs-form-group *ngIf="!isLinux && showGFS"
                        [attr.data-test-element]="elementsSelector.name.showGFSBlock"
                        formGroupName="GFSPolicySettings"
                        class="mb-0">
          <mbs-checkbox formControlName="enabled"
                        (change)="enableGFSChangeHandler($event)"
                        [label]="'wizards:gfs_label' | i18next"
                        [descriptionOutside]="false">
            <ng-container appendLabel>
              <span class="fa fa-info-circle ml-2 cursor-help text-info"
                    [ngbTooltip]="'wizards:gfs_label_tooltip' | i18next"></span>
            </ng-container>
            <ng-template checkboxDescription>
              <div class="text-xs mb-3">
                <span *ngFor="let item of gfsLabelDesc;let last = last">
                  {{item}}<br *ngIf="!last">
                </span>
              </div>

              <mbs-form-group>
                <div formGroupName="weekly"
                     class="row align-items-center"
                     [class.mb-2]="!GFSPolicySettingsCtrl?.controls?.weekly?.controls?.storePeriod?.invalid"
                     [class.mb-0]="GFSPolicySettingsCtrl?.controls?.weekly?.controls?.storePeriod?.invalid">
                  <div class="col-6">
                    <mbs-checkbox formControlName="enabled"
                                  [label]="'wizards:period_weekly_label' | i18next"
                                  (change)="fullBackupsChangeHandler($event, 'weekly')"></mbs-checkbox>
                  </div>
                  <div class="col-2">
                    <mbs-number formControlName="storePeriod"
                                min="1"
                                max="100000"
                                [id]="'archive-weekly-store-period-number'"></mbs-number>
                  </div>
                  <div class="col-2">
                    <label class="mbs-form_label mb-0"
                           for="archive-weekly-store-period-number"
                           [class.-disabled]="!GFSPolicySettingsCtrl?.controls?.weekly?.controls?.storePeriod?.enabled">
                      {{'wizards:weeks_label' | i18next}}
                    </label>
                  </div>
                </div>
                <div *ngIf="GFSPolicySettingsCtrl?.controls?.weekly?.controls?.storePeriod?.invalid"
                     class="row mb-2">
                  <div class="col-6 offset-6">
                    <mbs-input-errors [errors]="[
                                        { message: GFSPolicySettingsCtrl?.controls?.weekly?.controls?.storePeriod?.errors?.moreThanMonthly ?
                                          'This period cannot be longer than monthly one' :
                                          GFSPolicySettingsCtrl?.controls?.weekly?.controls?.storePeriod?.errors?.moreThanYearly ?
                                            'This period cannot be longer than yearly one' :
                                            ''}
                                       ]"></mbs-input-errors>
                  </div>
                </div>

                <div formGroupName="monthly"
                     class="row align-items-center"
                     [class.mb-2]="!GFSPolicySettingsCtrl?.controls?.monthly?.controls?.storePeriod?.invalid"
                     [class.mb-0]="GFSPolicySettingsCtrl?.controls?.monthly?.controls?.storePeriod?.invalid">
                  <div class="col-6">
                    <mbs-checkbox formControlName="enabled"
                                  [label]="'wizards:period_monthly_label' | i18next"
                                  (change)="fullBackupsChangeHandler($event, 'monthly')"></mbs-checkbox>
                  </div>
                  <div class="col-2">
                    <mbs-number formControlName="storePeriod"
                                min="1"
                                max="10000"
                                (change)="monthlyStorePeriodChangeHandler()"
                                [id]="'archive-monthly-store-period-number'"></mbs-number>
                  </div>
                  <div class="col-2">
                    <label class="mbs-form_label mb-0"
                           for="archive-monthly-store-period-number"
                           [class.-disabled]="!GFSPolicySettingsCtrl?.controls?.monthly?.controls?.storePeriod?.enabled">
                      {{'wizards:months_label' | i18next}}
                    </label>
                  </div>
                </div>
                <div *ngIf="GFSPolicySettingsCtrl?.controls?.monthly?.controls?.storePeriod?.invalid"
                     class="row mb-2">
                  <div class="col-6 offset-6">
                    <mbs-input-errors [errors]="[{ message: GFSPolicySettingsCtrl?.controls?.monthly?.controls?.storePeriod?.errors?.moreThanYearly ? 'This period cannot be longer than yearly one' : '' }]"></mbs-input-errors>
                  </div>
                </div>

                <ng-container formGroupName="yearly">
                  <div class="row align-items-center mb-3">
                    <div class="col-6">
                      <mbs-checkbox formControlName="enabled"
                                    [label]="'wizards:period_yearly_label' | i18next"
                                    (change)="fullBackupsChangeHandler($event, 'yearly')"></mbs-checkbox>
                    </div>
                    <div class="col-2">
                      <mbs-number formControlName="storePeriod"
                                  min="1"
                                  max="1000"
                                  (change)="yearlyStorePeriodChangeHandler()"
                                  [id]="'archive-yearly-store-period-number'"></mbs-number>
                    </div>
                    <div class="col-2">
                      <label class="mbs-form_label mb-0"
                             for="archive-yearly-store-period-number"
                             [class.-disabled]="!GFSPolicySettingsCtrl?.controls?.yearly?.controls?.storePeriod?.enabled">
                        {{'wizards:years_label' | i18next}}
                      </label>
                    </div>
                  </div>
                  <div class="row align-items-center mb-3">
                    <div class="col-6">
                      <label class="mbs-form_label mb-0"
                             for="archive-take-backup-from-month-select"
                             [class.-disabled]="!GFSPolicySettingsCtrl?.controls?.yearly?.controls?.takeBackupFromMonth?.enabled">
                        {{'wizards:first_successful_label' | i18next}}
                      </label>
                    </div>
                    <div class="col-3">
                      <mbs-select formControlName="takeBackupFromMonth"
                                  [dropdownPosition]="'auto'"
                                  [appendTo]="'.modal-content'"
                                  [bindValue]="'value'"
                                  [bindLabel]="'label'"
                                  [items]="monthNames"
                                  [id]="'archive-take-backup-from-month-select'"
                                  [clearable]="false"
                                  [searchable]="false"></mbs-select>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="correctlyVersionForImmutability">
                  <ng-template #labelImmutability>
                    {{'wizards:retention_enable_immutability' | i18next}}
                    <a *ngIf="ability.can('read', 'HelpMarketing'); else onlyText"
                       routerLink="/AP/Help/backup-and-restore/about-backup/gfs/immutability" target="_blank">
                      {{'wizards:retention_immutability' | i18next}}
                    </a>
                    <ng-template #onlyText>{{'wizards:retention_immutability' | i18next}}</ng-template>
                  </ng-template>
                  <mbs-form-group>
                    <mbs-checkbox [label]="labelImmutability"
                                  formControlName="enabledImmutability"
                                  (change)="enableImmutabilityChangeHandler($event)">
                      <ng-container appendLabel>
                        <span class="fa fa-info-circle ml-2 cursor-help text-info"
                              [ngbTooltip]="'wizards:retention_immutability_tooltip' | i18next"></span>
                      </ng-container>
                    </mbs-checkbox>
                  </mbs-form-group>
                </ng-container>

                <mbs-alert *ngIf="!showImmutability"
                           [type]="mbsPopupType.warning"
                           [icon]="true"
                           [class.mb-0]="!isShowAlert">
                  {{'wizards:retention_immutability_alert' | i18next}}
                  <a *ngIf="ability.can('read', 'HelpMarketing')"
                     routerLink="/AP/Help/backup/about/gfs/immutability" target="_blank">
                    {{'wizards:learn_more_link' | i18next}}
                  </a>
                </mbs-alert>

                <mbs-alert *ngIf="isShowAlert"
                           [type]="mbsPopupType.warning"
                           [icon]="true"
                           class="mb-0">
                  {{
                  'wizards:enable_gfs_alert'
                    | i18next
                    : (GFSPolicySettingsCtrl?.controls?.weekly?.controls?.enabled.value
                      ? { firstPeriod: 'weekly', secondPeriod: 'week' }
                      : GFSPolicySettingsCtrl?.controls?.monthly?.controls?.enabled.value
                        ? { firstPeriod: 'monthly', secondPeriod: 'month' }
                        : GFSPolicySettingsCtrl?.controls?.yearly?.controls?.enabled.value
                          ? { firstPeriod: 'yearly', secondPeriod: 'month' }
                          : {
                            firstPeriod: '',
                            secondPeriod: ''
                          })
                  }}
                  <mbs-button [isCtrl]="true"
                              [size]="MbsSize.md"
                              (click)="changeBackupScheduleClickHandler()"
                              [type]="'primary'">
                    {{'wizards:enable_gfs_alert_change_schedule' | i18next}}
                  </mbs-button><br>
                  <a *ngIf="ability.can('read', 'HelpMarketing')"
                     href="/AP/Help/backup-and-restore/about-backup/gfs/gfs" target="_blank">
                    {{'buttons:learnMore' | i18next}}
                  </a>
                </mbs-alert>
              </mbs-form-group>
            </ng-template>
          </mbs-checkbox>
        </mbs-form-group>

        <mbs-form-group *ngIf="isFFIMode && showIntelligentRetention">
          <mbs-checkbox formControlName="intelligentRetention"
                        [disabledSelf]="disableIntelligentRetention"
                        [label]="'wizards:intelligent_retention_label' | i18next"
                        (change)="intelligentRetentionChangeHandler($event)">
            <ng-container appendLabel>
              <span class="fa fa-info-circle ml-2 cursor-help text-info"
                    tooltipClass="tooltip-xl"
                    [container]="'body'"
                    [ngbTooltip]="'To disable \'Intelligent Retention\', please request assistance from the Support Team'"></span>
            </ng-container>
          </mbs-checkbox>
          <div class="mt-4">
            <p class="text-md mb-1">
              <strong>{{ 'wizards:intelligent_retention_about_label' | i18next }}</strong>
            </p>
            <p class="text-md mb-1">
              {{ 'wizards:intelligent_retention_about' | i18next }}
              <a *ngIf="ability.can('read', 'HelpMarketing')"
                 href="/AP/Help/backup/about/ir"
                 target="_blank">
                {{ 'wizards:intelligent_retention_about_link' | i18next }}
              </a>
            </p>
            <p class="text-md mb-1">
              <strong>{{ 'wizards:intelligent_retention_FAQ_label' | i18next }}</strong>
            </p>
            <p class="text-md mb-4">
              {{ 'wizards:intelligent_retention_FAQ' | i18next }}
            </p>
            <p class="text-md mb-4">
              <a *ngIf="ability.can('read', 'HelpMarketing')"
                 href="/AP/Help/backup/about/backup-format/ffi"
                 target="_blank">
                {{ 'wizards:learn_more_link' | i18next }}
                <span class="ml-1 fa fa-external-link"></span>
              </a>
            </p>
          </div>
        </mbs-form-group>
      </div>
    </div>
    <!--is archive end-->

    <!--is not archive start-->
    <div *ngIf="!isNBF"
         [attr.data-test-element]="elementsSelector.name.notNBFBlock">
      <div class="mbs-form_section">
        <mbs-form-group class="mb-0">
          <mbs-radio formControlName="RetentionUseDefaultSettings"
                     name="RetentionUseDefaultSettings"
                     [label]="'wizards:use_default_label' | i18next"
                     (change)="retentionUseDefaultSettingsChangeHandler($event)"
                     [value]="true"
                     [id]="'selection-use-defaults-1'">
            <ng-container appendLabel>
              <span class="fa fa-info-circle ml-2 cursor-help text-info"
                    container="body"
                    placement="right-top bottom"
                    [ngbTooltip]="isIBBPlan ? ('wizards:use_default_label_append_ibb' | i18next) : ('wizards:use_default_label_append' | i18next)"></span>
            </ng-container>
          </mbs-radio>

          <mbs-radio formControlName="RetentionUseDefaultSettings"
                     name="RetentionUseDefaultSettings"
                     [label]="'wizards:not_use_default_label' | i18next"
                     (change)="retentionUseDefaultSettingsChangeHandler($event)"
                     [value]="false"
                     [descriptionOutside]="false"
                     [id]="'selection-use-defaults-2'">

            <ng-template radioDescription>
              <div *ngIf="!isBackupPlan && !isSQLPlan"
                   [attr.data-test-element]="elementsSelector.name.notSQLNotFileBlock">
                <mbs-form-group>
                  <mbs-checkbox formControlName="deleteVersionsOlderThan"
                                [label]="'wizards:delete_older_then_label' | i18next"
                                (change)="deleteVersionsOlderThanChangeHandler($event)"
                                [descriptionOutside]="false">

                    <ng-template checkboxDescription>
                      <mbs-form-group>
                        <div class="form-row align-items-center">
                          <div class="col-2">
                            <mbs-number formControlName="deleteVersionsOlderThanCount"
                                        min="1"
                                        [id]="'ibb-deleteVersionsOlderThanCount'"></mbs-number>
                          </div>
                          <div class="col-3">
                            <mbs-select formControlName="deleteVersionsOlderThanPeriod"
                                        [bindValue]="'value'"
                                        [bindLabel]="'label'"
                                        [items]="periodEnumSelectArray"
                                        [id]="'PeriodEnumSelect'"
                                        [clearable]="false"
                                        [searchable]="false"></mbs-select>
                          </div>
                        </div>
                      </mbs-form-group>
                    </ng-template>
                  </mbs-checkbox>
                </mbs-form-group>

                <mbs-form-group>
                  <div class="form-row align-items-center">
                    <div class="col-auto">
                      <mbs-checkbox formControlName="keepNumberOfVersions"
                                    [label]="'wizards:keep_versions_label' | i18next"
                                    (change)="keepNumberOfVersionsChangeHandler($event)"
                                    [descriptionOutside]="false"></mbs-checkbox>
                    </div>
                    <div class="col-2">
                      <mbs-number formControlName="RetentionNumberOfVersions"
                                  min="1"
                                  max="32767"
                                  [id]="'ibb-retention-number-of-versions-number'"></mbs-number>
                    </div>
                    <div class="col-auto">
                      <label class="mbs-form_label mb-0"
                             for="ibb-retention-number-of-versions-number"
                             [class.-disabled]="stepForm.get('RetentionNumberOfVersions').disabled">
                        {{'wizards:for_each_file_label' | i18next}}
                      </label>
                    </div>
                  </div>
                </mbs-form-group>
              </div>

              <div *ngIf="isBackupPlan || isSQLPlan"
                   [attr.data-test-element]="elementsSelector.name.isSQLorFileBlock">
                <mbs-form-group>
                  <mbs-checkbox formControlName="deleteVersionsOlderThan"
                                [label]="'wizards:delete_older_then_label' | i18next"
                                (change)="deleteVersionsOlderThanChangeHandler($event)"
                                [descriptionOutside]="false"
                                [id]="'main-delete-versions-older-than-checkbox'">
                    <ng-template checkboxDescription>
                      <mbs-form-group>
                        <div class="row">
                          <div class="col-11">
                            <div class="form-row align-items-center">
                              <div class="col-2">
                                <mbs-number formControlName="deleteVersionsOlderThanCount"
                                            min="1"
                                            max="10000"
                                            [id]="'main-delete-versions-older-than-count-number'"></mbs-number>
                              </div>
                              <div class="col-3">
                                <mbs-select formControlName="deleteVersionsOlderThanPeriod"
                                            [bindValue]="'value'"
                                            [bindLabel]="'label'"
                                            [items]="periodEnumSelectArray"
                                            [id]="'main-delete-versions-older-than-period-select'"
                                            [clearable]="false"
                                            [searchable]="false"></mbs-select>
                              </div>
                              <div class="col-auto">
                                <label class="mbs-form_label mb-0"
                                       for="main-from-time-select"
                                       [class.-disabled]="stepForm.get('fromTimeSelected').disabled">
                                  {{'wizards:from_label' | i18next}}
                                </label>
                              </div>
                              <div [attr.data-test-element]="elementsSelector.name.fromTimeSelectedBlock"
                                   class="col-4">
                                <mbs-select formControlName="fromTimeSelected"
                                            (change)="fromTimeSelectedChangeHandler($event)"
                                            [bindValue]="'value'"
                                            [bindLabel]="'label'"
                                            [items]="fromTimeSelectedItems"
                                            [id]="'main-from-time-select'"
                                            [clearable]="false"
                                            [searchable]="false"></mbs-select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </mbs-form-group>

                      <mbs-form-group *ngIf="!isSQLPlan"
                                      [attr.data-test-element]="elementsSelector.name.alwaysKeepLastVersionBlock">
                        <div class="form-row align-items-center">
                          <div class="col-auto">
                            <mbs-checkbox formControlName="alwaysKeepLastVersion"
                                          [label]="'wizards:always_keep_label' | i18next"
                                          [id]="'main-always-keep-last-version-checkbox'"></mbs-checkbox>
                          </div>
                          <div class="col"
                               *ngIf="isShowWarning"
                               [attr.data-test-element]="elementsSelector.name.daysAgoAlert">
                            <mbs-alert class="mb-0"
                                       [type]="mbsPopupType.warning"
                                       [icon]="false">
                              {{ 'wizards:days_ago_alert' | i18next: {count: daysCount} }}
                            </mbs-alert>
                          </div>
                        </div>
                      </mbs-form-group>
                    </ng-template>
                  </mbs-checkbox>
                </mbs-form-group>

                <mbs-form-group>
                  <div class="row">
                    <div class="col-10">
                      <div class="form-row align-items-center">
                        <div class="col-auto">
                          <mbs-checkbox formControlName="keepNumberOfVersions"
                                        [label]="'wizards:keep_versions_label' | i18next"
                                        (change)="keepNumberOfVersionsChangeHandler($event)"
                                        [descriptionOutside]="false"
                                        [id]="'main-keep-number-of-versions-checkbox'">
                          </mbs-checkbox>
                        </div>
                        <div class="col-2">
                          <mbs-number formControlName="RetentionNumberOfVersions"
                                      min="1"
                                      max="32767"
                                      [id]="'main-retention-number-of-versions-number'"></mbs-number>
                        </div>
                        <div class="col-auto">
                          <label class="mbs-form_label mb-0"
                                 for="main-retention-number-of-versions-number"
                                 [class.-disabled]="stepForm.get('RetentionNumberOfVersions').disabled">
                            {{'wizards:for_each_file_label' | i18next}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </mbs-form-group>

                <mbs-form-group *ngIf="!isLinux && !isSQLPlan"
                                [attr.data-test-element]="elementsSelector.name.notLinuxBlock"
                                class="mb-0">
                  <div class="row">
                    <div class="col-10">
                      <div class="form-row align-items-center">
                        <div class="col-auto">
                          <mbs-checkbox formControlName="delayPurgeForEnabled"
                                        (change)="delayPurgeForEnabledChangeHandler($event)"
                                        [label]="'wizards:delay_purge_label' | i18next"
                                        [id]="'main-delay-purge-for-enabled-checkbox'"></mbs-checkbox>
                        </div>
                        <div class="col-2">
                          <mbs-number formControlName="delayPurgeFor"
                                      min="1"
                                      max="10000"
                                      [id]="'main-delay-purge-for-number'"></mbs-number>
                        </div>
                        <div class="col-3">
                          <mbs-select formControlName="delayPurgeForSelect"
                                      [bindValue]="'value'"
                                      [bindLabel]="'label'"
                                      [items]="periodEnumSelectArray"
                                      [id]="'main-delay-purge-for-select'"
                                      [clearable]="false"
                                      [searchable]="false"></mbs-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </mbs-form-group>
              </div>
            </ng-template>
          </mbs-radio>
        </mbs-form-group>
      </div>

      <div *ngIf="isBackupPlan && !isHybrid"
           [attr.data-test-element]="elementsSelector.name.fileTypeNotHybrid">
        <div class="mbs-form_section">
          <mbs-form-group class="mb-0">
            <mbs-checkbox formControlName="deleteIfLocallyDeleted"
                          (change)="deleteIfLocallyDeletedChangeHandler($event)"
                          [label]="'wizards:delete_files_label' | i18next"
                          [descriptionOutside]="false">
              <ng-template checkboxDescription>
                <div class="form-row align-items-center flex-nowrap">
                  <div class="col-auto">
                    <label class="mbs-form_label mb-0"
                           for="deleteAfter"
                           [class.-disabled]="stepForm.get('deleteAfter').disabled">
                      {{'wizards:delete_after_label' | i18next}}
                    </label>
                  </div>
                  <div class="col-2">
                    <mbs-number formControlName="deleteAfter"
                                min="1"
                                max="10000"></mbs-number>
                  </div>
                  <div class="col-auto">
                    <label class="mbs-form_label mb-0"
                           for="deleteAfter"
                           [class.-disabled]="stepForm.get('deleteAfter').disabled">
                      {{'wizards:days_label' | i18next}}
                    </label>
                  </div>
                  <div *ngIf="!isLinux"
                       [attr.data-test-element]="elementsSelector.name.fileTypeNotHybridNotLinux"
                       class="col">
                    <mbs-checkbox formControlName="doNotShowWarning"
                                  [label]="'wizards:not_show_warning_label' | i18next"></mbs-checkbox>
                  </div>
                </div>
              </ng-template>
            </mbs-checkbox>
          </mbs-form-group>

          <mbs-alert *ngIf="stepForm.get('deleteIfLocallyDeleted')?.value"
                     [attr.data-test-element]="elementsSelector.name.deleteIfLocallyDeletedAlert"
                     class="mb-0 mt-3"
                     [type]="mbsPopupType.info"
                     [icon]="true">
            <span *ngFor="let item of deleteFilesLabelAlert;let last = last">
              {{item}}<br *ngIf="!last">
            </span>
          </mbs-alert>
        </div>
      </div>

      <div *ngIf="isHybrid"
           [attr.data-test-element]="elementsSelector.name.cbfHybridBlock"
           class="mbs-form_section"
           formGroupName="HybridRetentionPolicy">
        <mbs-form-group>
          <mbs-checkbox formControlName="RetentionUseDefaultSettings"
                        [label]="'wizards:use_default_hybrid_label' | i18next"
                        [descriptionOutside]="false"
                        (change)="retentionUseDefaultSettingsChangeHandler($event, true)">
            <ng-template checkboxDescription
                         [hide]="!stepForm.get('HybridRetentionPolicy').value.RetentionUseDefaultSettings">
              <div *ngIf="!isBackupPlan && !isSQLPlan"
                   [attr.data-test-element]="elementsSelector.name.hybridNotFileBlock">
                <mbs-form-group>
                  <mbs-checkbox formControlName="deleteVersionsOlderThan"
                                [label]="'wizards:delete_older_then_label' | i18next"
                                (change)="deleteVersionsOlderThanChangeHandler($event, true)"
                                [descriptionOutside]="false">
                    <ng-template checkboxDescription>
                      <mbs-form-group>
                        <div class="form-row align-items-center">
                          <div class="col-2">
                            <mbs-number formControlName="deleteVersionsOlderThanCount"
                                        min="1"
                                        max="10000"
                                        [id]="'hybrid-ibb-deleteVersionsOlderThanCount'"></mbs-number>
                          </div>
                          <div class="col-3">
                            <mbs-select formControlName="deleteVersionsOlderThanPeriod"
                                        [appendTo]="'.modal-content'"
                                        [bindValue]="'value'"
                                        [bindLabel]="'label'"
                                        [clearable]="false"
                                        [items]="periodEnumSelectArray"
                                        [id]="'hybrid-PeriodEnumSelect'"
                                        [searchable]="false"></mbs-select>
                          </div>
                        </div>
                      </mbs-form-group>
                    </ng-template>
                  </mbs-checkbox>
                </mbs-form-group>

                <mbs-form-group>
                  <div class="form-row align-items-center">
                    <div class="col-auto">
                      <mbs-checkbox formControlName="keepNumberOfVersions"
                                    (change)="keepNumberOfVersionsChangeHandler($event, true)"
                                    [label]="'wizards:keep_versions_label' | i18next"
                                    [descriptionOutside]="false"></mbs-checkbox>
                    </div>
                    <div class="col-2">
                      <mbs-number formControlName="RetentionNumberOfVersions"
                                  min="1"
                                  max="32767"
                                  [id]="'hybrid-ibb-retention-number-of-versions-number'"></mbs-number>
                    </div>
                    <div class="col-auto">
                      <label class="mbs-form_label mb-0"
                             for="hybrid-ibb-retention-number-of-versions-number"
                             [class.-disabled]="stepForm.get('HybridRetentionPolicy')?.controls?.RetentionNumberOfVersions.disabled">
                        {{'wizards:for_each_file_label' | i18next}}
                      </label>
                    </div>
                  </div>
                </mbs-form-group>
              </div>

              <div *ngIf="isBackupPlan || isSQLPlan"
                   [attr.data-test-element]="elementsSelector.name.hybridFileOrSQLBlock">
                <mbs-form-group>
                  <mbs-checkbox formControlName="deleteVersionsOlderThan"
                                (change)="deleteVersionsOlderThanChangeHandler($event, true)"
                                [label]="'wizards:delete_older_then_label' | i18next"
                                [descriptionOutside]="false"
                                [id]="'hybrid-delete-versions-older-than-checkbox'">
                    <ng-template checkboxDescription>
                      <mbs-form-group>
                        <div class="form-row align-items-center">
                          <div class="col-2">
                            <mbs-number formControlName="deleteVersionsOlderThanCount"
                                        min="1"
                                        max="10000"
                                        [id]="'hybrid-delete-versions-older-than-count-number'"></mbs-number>
                          </div>
                          <div class="col-3">
                            <mbs-select formControlName="deleteVersionsOlderThanPeriod"
                                        [bindValue]="'value'"
                                        [bindLabel]="'label'"
                                        [items]="periodEnumSelectArray"
                                        [id]="'hybrid-delete-versions-older-than-period-select'"
                                        [clearable]="false"
                                        [searchable]="false"></mbs-select>
                          </div>
                          <div *ngIf="!isSQLPlan"
                               [attr.data-test-element]="elementsSelector.name.hybridFromTimeSelectedBlockLabel"
                               class="col-auto">
                            <label class="mbs-form_label mb-0"
                                   for="hybrid-from-time-select"
                                   [class.-disabled]="stepForm.get('fromTimeSelected').disabled">
                              {{'wizards:from_label' | i18next}}
                            </label>
                          </div>
                          <div *ngIf="!isSQLPlan"
                               [attr.data-test-element]="elementsSelector.name.hybridFromTimeSelectedBlock"
                               class="col-4">
                            <mbs-select formControlName="fromTimeSelected"
                                        (change)="fromTimeSelectedChangeHandler($event, true)"
                                        [bindValue]="'value'"
                                        [bindLabel]="'label'"
                                        [items]="fromTimeSelectedItems"
                                        [id]="'hybrid-from-time-select'"
                                        [clearable]="false"
                                        [searchable]="false"></mbs-select>
                          </div>
                        </div>
                      </mbs-form-group>

                      <mbs-form-group *ngIf="!isSQLPlan"
                                      [attr.data-test-element]="elementsSelector.name.hybridAlwaysKeepLastVersionBlock">
                        <mbs-checkbox formControlName="alwaysKeepLastVersion"
                                      [label]="'wizards:always_keep_label' | i18next"
                                      [id]="'hybrid-always-keep-last-version-checkbox'"></mbs-checkbox>
                      </mbs-form-group>
                    </ng-template>
                  </mbs-checkbox>
                </mbs-form-group>

                <mbs-form-group>
                  <div class="row">
                    <div class="col-10">
                      <div class="form-row align-items-center">
                        <div class="col-auto">
                          <mbs-checkbox formControlName="keepNumberOfVersions"
                                        (change)="keepNumberOfVersionsChangeHandler($event, true)"
                                        [label]="'wizards:keep_versions_label' | i18next"
                                        [descriptionOutside]="false"
                                        [id]="'hybrid-keep-number-of-versions-checkbox'"></mbs-checkbox>
                        </div>
                        <div class="col-2">
                          <mbs-number formControlName="RetentionNumberOfVersions"
                                      min="1"
                                      max="32767"
                                      [id]="'hybrid-retention-number-of-versions-number'"></mbs-number>
                        </div>
                        <div class="col-auto">
                          <label class="mbs-form_label mb-0"
                                 for="hybrid-retention-number-of-versions-number"
                                 [class.-disabled]="stepForm.get('HybridRetentionPolicy')?.controls?.RetentionNumberOfVersions.disabled">
                            {{'wizards:for_each_file_label' | i18next}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </mbs-form-group>

                <mbs-form-group *ngIf="!isLinux && !isSQLPlan"
                                [attr.data-test-element]="elementsSelector.name.hybridNotLinuxBlock">
                  <div class="row">
                    <div class="col-10">
                      <div class="form-row align-items-center">
                        <div class="col-auto">
                          <mbs-checkbox formControlName="delayPurgeForEnabled"
                                        (change)="delayPurgeForEnabledChangeHandler($event, true)"
                                        [label]="'wizards:delay_purge_label' | i18next"
                                        [id]="'hybrid-delay-purge-for-enabled-checkbox'"></mbs-checkbox>
                        </div>
                        <div class="col-2">
                          <mbs-number formControlName="delayPurgeFor"
                                      min="1"
                                      max="10000"
                                      [id]="'hybrid-delay-purge-for-number'"></mbs-number>
                        </div>
                        <div class="col-3">
                          <mbs-select formControlName="delayPurgeForSelect"
                                      [dropdownPosition]="'top'"
                                      [bindValue]="'value'"
                                      [bindLabel]="'label'"
                                      [items]="periodEnumSelectArray"
                                      [id]="'hybrid-delay-purge-for-select'"
                                      [clearable]="false"
                                      [searchable]="false"></mbs-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </mbs-form-group>
              </div>
            </ng-template>
          </mbs-checkbox>
        </mbs-form-group>
      </div>
    </div>

  </div>
</section>

<ng-template #IntelligentRetentionPayAttention>
  <mbs-alert [type]="mbsPopupType.warning"
             [icon]="true">
    {{'wizards:intelligent_retention_alert_prefix'|i18next}}
    <strong>{{'wizards:intelligent_retention_label'|i18next }}</strong>
    {{'wizards:intelligent_retention_alert_postfix'|i18next}}
  </mbs-alert>
</ng-template>

<ng-template #confirmNotFullModal>
  <p>{{'wizards:not_full_confirm_text'|i18next}}</p>
  <mbs-alert class="mb-0"
             [type]="mbsPopupType.warning"
             [icon]="true">
    {{'wizards:not_full_confirm_alert_text'|i18next}}
  </mbs-alert>
</ng-template>
