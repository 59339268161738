<mbs-sidepanel id="connect-computer-settings"
               side="right"
               [isCreate]="false"
               [showDelete]="false"
               [loading]="isSaveInProgress"
               [actionNames]="{
                close: 'buttons:cancel' | i18next
               }"
               [loadingData]="isLoading()">
  <div header-title class="mbs-sidepanel_title">
    <div>
      <span [ngClass]="[(computer | computerOsIcon), computer?.online ? 'text-primary' : 'text-muted']"></span>
    </div>
    <div class="mbs-sidepanel_title-name ml-3">
      {{ getComputerName() }}
    </div>
    <div class="ml-2 text-xs text-gray">
      ({{ getComputerStatus() }})
    </div>
  </div>
  <div body>
    <div class="mbs-from_group mb-4">
      <p class="mb-2">{{ 'computers.module:connectOptions.header' | i18next }}</p>
      <div class="mbs-form-group">
        <mbs-button-group [toggle]="true"
                          class="w-100"
                          customClasses="w-100">
          <mbs-button [type]="'secondary'"
                      class="flex-basis-50"
                      customClasses="w-100 justify-content-center"
                      [active]="useGlobal.value"
                      (click)="useGlobal.patchValue(true)">
           {{ hasCompanySettings() ? ('computers.module:connectOptions.useCompany' | i18next) : ('computers.module:connectOptions.useGlobal' | i18next) }}
          </mbs-button>
          <mbs-button [type]="'secondary'"
                      class="flex-basis-50"
                      customClasses="w-100 justify-content-center"
                      [active]="!useGlobal.value"
                      (click)="useGlobal.patchValue(false)">
            {{ 'computers.module:connectOptions.useCustom' | i18next }}
          </mbs-button>
        </mbs-button-group>
      </div>

      <mbs-alert *ngIf="isGlobalSettingsVisible() && ('AccessToAllCompanies' | can: 'read') && ('ManageCompanies' | can: 'read')"
                   [type]="alertType.info"
                   class="my-3">
          {{ hasCompanySettings() ? ('computers.module:connectOptions.toEditCompanyOptions' | i18next) : ('computers.module:connectOptions.toEditOptions' | i18next) }},
          <a [href]="getGlobalOptionsEditUrl()">
            {{ 'buttons:clickHere' | i18next }}
            <span class="fa fa-link text-info"></span>
          </a>
      </mbs-alert>
    </div>

    <mbs-connect-settings-form [form]="form"
                               [formState]="useGlobal?.value ? parentAgentSettingsFormState : formState"
                               [mode]="'panel'"></mbs-connect-settings-form>
  </div>
</mbs-sidepanel>
