<ng-container *ngIf="wizardForm.value.os !== os.Windows; else restProducts">
  <ng-container [ngTemplateOutlet]="builds"></ng-container>
</ng-container>

<ng-template #restProducts>
  <mbs-input-label [label]="'computers.module:addComputerWizard.downloadStep.selectModeTitle' | i18next"
                   [labelClasses]="'font-weight-bold'"></mbs-input-label>

  <div class="row">
    <div *ngFor="let data of tilesConfig; let index = index" class="col-6">
      <button (click)="wizardForm.get('mode').patchValue(data.id)"
              [class.-selected]="data.id === wizardForm.value.mode"
              class="app-add-computer-wizard_tile">
        <div class="mx-3 my-3 w-100">
          <div class="d-flex justify-content-center">
            <div class="app-add-computer-wizard_tile-icon-wrapper">
              <span [class]="data.icon"
                    [class.mt-1]="index === 0"
                    class="app-add-computer-wizard_tile-icon"></span>
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <div class="text-lg font-weight-bold"
                 [class.w-75]="index === 0">{{ data.title }}</div>
          </div>
        </div>

        <span *ngIf="data.isBeta"
              class="bg-dark-100 text-white add-computer-wizard-download-and-install_betta-label">
            {{'app:beta' | i18next}}</span>
      </button>
    </div>
  </div>

  <ng-container *ngIf="wizardForm.value.mode === installationModeEnum.Manual">
    <ng-container [ngTemplateOutlet]="builds"></ng-container>
  </ng-container>

  <ng-container *ngIf="wizardForm.value.mode === installationModeEnum.Bulk">
    <h5>{{ 'app:products.networkDiscovery' | i18next }}</h5>

    <p>
      {{ 'computers.module:addComputerWizard.downloadStep.ndDescription' | i18next }}
      <a *ngIf="('HelpMarketing' | can: 'read')"
         href="/AP/Help/downloads/discover-deploy-tool"
         target="_blank"> {{ 'buttons:learnMore' | i18next }} </a>
    </p>

    <mbs-download-build [build]="networkDiscoveryBuild"
                        [align]="'left'"
                        [showDeleteButton]="false"
                        [companyId]="wizardForm.value.companyId"
                        [beta]="true"></mbs-download-build>
  </ng-container>
</ng-template>

<ng-template #builds>
  <h5>
    {{ 'computers.module:addComputerWizard.downloadStep.agents.' + wizardForm.value.agentType?.toLowerCase() |
    i18next }}
  </h5>

  <ng-container *ngFor="let build of displayedBuilds; let i = index">
    <section class="pb-4"
             [ngClass]="{'border-bottom': i !== displayedBuilds.length - 1, 'pt-4': i !== 0}">
      <mbs-download-build
        [isSandboxEnabled]="(buildsSettings$ | async).isSandBoxAvailable"
        [build]="build"
        [companyId]="wizardForm.value.companyId"
        [licensedBrandList]="licensedBrandList"
        [buildsInProgress]="buildsInProgress"
        (buildPanelClick)="onBuildPanelClick($event)"
        [index]="i"
        [showDeleteButton]="false"
        [align]="'left'"
        [openedPanelIds]="openedPanelIds"
      ></mbs-download-build>
    </section>
  </ng-container>
</ng-template>
