<section [formGroup]="stepForm"
         class="mbs-wizard_form">
  <header class="mbs-wizard_form-header"
          [class.mb-4]="view === viewMode.Default"
          [class.mb-0]="view === viewMode.Onboarding">
    <ng-container *ngIf="view === viewMode.Default">
      <h4 class="mbs-wizard_form-title"
          [attr.data-test-element]="elementSelectors.name.defaultTitle">
        {{ 'wizards:what_backup_tree_title' | i18next: { format: 'title' } }}
      </h4>
    </ng-container>

    <div *ngIf="view === viewMode.Onboarding"
         class="d-flex align-items-center justify-content-between border-bottom border-2 p-3">
      <h4 class="mbs-wizard_form-title font-weight-bold mb-0"
          [attr.data-test-element]="elementSelectors.name.onboardingTitle">
        {{ 'wizards:select_files_and_folders' | i18next: { format: 'title' } }}
      </h4>

      <mbs-button type="primary"
                  [isCtrl]="true"
                  (click)="showLegend()">
        {{'wizards:show_legend_text' | i18next }}
      </mbs-button>
    </div>

    <mbs-alert *ngIf="isOffline"
               [attr.data-test-element]="elementSelectors.name.isOfflineAlert"
               [type]="mbsPopupType.warning"
               [icon]="true">
      {{'wizards:what_backup_tree_offline_alert_text' | i18next}}
    </mbs-alert>

    <ng-container *ngIf="view === viewMode.Default">
      <div class="row">
        <div class="col-9">
          <div *ngIf="showPlainText"
               class="exampleTextBox text-xs"
               [attr.data-test-element]="elementSelectors.name.exampleTextBox">
            <div class="form-row">
              <div class="col-auto">
                {{isLinux ? 'Example: /home/user' : 'Example:'}}
              </div>
              <div *ngIf="!isLinux"
                   class="col">
                D:\folder\subfolder <br>
                %userprofile%\folder
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </header>

  <div *ngIf="!showPlainText"
       [class.pt-3]="view === viewMode.Onboarding"
       [class.px-3]="view === viewMode.Onboarding">
    <mbs-card class="mbs-wizard-tree-card"
              [class.-invalid]="!stepForm.valid && !foldersForm.get('includeString').valid"
              [ngClass]="'-' + view">
      <mbs-loader *ngIf="loading"
                  size="small"></mbs-loader>
      <mbs-alert *ngIf="!treeData.length || loading"
                 [attr.data-test-element]="elementSelectors.name.isLoadingOrNoSectionAlert"
                 [type]="mbsPopupType.warning"
                 [icon]="true">
        {{loading ? 'Loading data...' : 'No sections available'}}
      </mbs-alert>
      <mbs-tree *ngIf="!loading"
                mode="file"
                [attr.data-test-element]="elementSelectors.name.treeElement"
                [classesForRoot]="'treeElement mbs-wizard-tree mx-0 p-3 overflow-auto -' + view"
                [height]="view === viewMode.Onboarding ? '322px': '440px'"
                (mbsChange)="changeTreeHandler($event)"
                [disableIfLoadingSubtree]="true"
                [childrenManualControl]="true"
                [data]="treeData"
                [disableChildren]="false"
                [dontSelectChildren]="false"
                [loadMore]="getSubtree.bind(this)"
                [getItems]="getSubtree.bind(this)"
                [itemsCanBeHidden]="true"
                [lazy]="true">
        <ng-template treeElement
                     let-item>
          <span class="form-row align-items-center flex-nowrap mbs-wizard-tree-label-row">
            <span class="col-auto d-flex">
              <img [src]="item.icon"
                   class="mbs-wizard-tree-label-img"
                   alt="">
            </span>
            <span class="col overflow-hidden">
              <pre class="mbs-wizard-tree-label-pre-wrap mb-0">{{ item.label }}</pre>
            </span>
          </span>
        </ng-template>
      </mbs-tree>
    </mbs-card>
  </div>

  <ng-container *ngIf="showPlainText">
    <mbs-form-group [attr.data-test-element]="elementSelectors.name.plainTextFirstBlock">
      <div class="form-row align-items-center">
        <div class="col-auto">
          <div class="mbs-form_label mb-0">
            {{'wizards:include_text' | i18next}}
            <span class="mbs-form_label-required">*</span>
          </div>
        </div>
        <div class="col text-right">
          <div class="d-inline-block"
               container="body"
               placement="left-top bottom"
               [ngbTooltip]="'buttons:pasteFromClipboardNotSupported' | i18next"
               [disableTooltip]="!pastFromClipboardNotSupported">
            <mbs-button container="body"
                        placement="left-top bottom"
                        type="primary"
                        size="sm"
                        icon="fa fa-paste"
                        [ngbTooltip]="pasteTooltip"
                        [disabled]="!!editedString || loading || pastFromClipboardNotSupported"
                        [isCtrl]="true"
                        (click)="pasteFromClipboardHandler(error)">
              <span class="font-weight-semibold">{{'buttons:paste_from_clipboard' | i18next}}</span>
            </mbs-button>
          </div>
        </div>
      </div>

      <div class="mt-2 pt-1"
           [formGroup]="foldersForm">
        <mbs-input formControlName="includeString"
                   maxLength="32767"
                   [appendButtons]="[
                     {
                       icon: editedString && editedString.isInclude ? 'fa fa-check-circle text-success' : 'fa fa-plus-circle',
                       id: 'newIncludeFolderButton',
                       disabled: foldersForm.get('includeString').disabled,
                       type: 'secondary'
                     },
                     {
                       icon: 'fa fa-close',
                       id: 'closeIncludeFolderButton',
                       disabled: false,
                       hide: !editedString || !editedString.isInclude,
                       type: 'secondary'
                     }
                   ]"
                   [buttonClickOnEnter]="!!foldersForm.get('includeString')?.value"
                   [validationWhitespace]="true"
                   (blur)="includeExcludeStringBlur($event, 'includeString')"
                   (buttonClick)="addIncludeExcludeStringHandler($event)"
                   (input)="includeExcludeInputHandler($event)"></mbs-input>
      </div>

      <div class="mt-2 pt-1">
        <mbs-card [size]="'xs'"
                  [staticHeight]="128">
          <div class="form-row mb-2 align-items-center flex-nowrap _hover-parent"
               *ngFor="let str of stepForm.get('includeFolders')?.value; let i = index">
            <div class="col-auto text-xs flex-shrink-1 text-overflow"
                 [class.text-muted]="editedString && editedString.value !== str">
            <span ngbTooltip="{{ str }}"
                  container="body"
                  class="pre-wrap"
                  tooltipClass="tooltip-lg text-left">{{str}}</span>
            </div>
            <div class="col-auto _hover-target"
                 *ngIf="!foldersForm.get('includeString')?.disabled && !editedString">
              <div class="row align-items-center no-gutters lh-1">
                <div class="col-auto">
                  <mbs-button [isCtrl]="true"
                              type="primary"
                              icon="fa fa-pencil px-1"
                              (click)="editHandler(i)"></mbs-button>
                </div>
                <div class="col-auto">
                  <mbs-button [isCtrl]="true"
                              type="primary"
                              icon="fa fa-times-circle px-1"
                              (click)="delIncludeExcludeStringHandler(i)"></mbs-button>
                </div>
              </div>
            </div>
          </div>
        </mbs-card>
      </div>
    </mbs-form-group>

    <mbs-form-group [attr.data-test-element]="elementSelectors.name.plainTextSecondBlock">
      <div class="form-row align-items-center">
        <div class="col-auto">{{'wizards:exclude_text' | i18next}}</div>
        <div class="col text-right">
          <div class="d-inline-block"
               container="body"
               placement="left-top bottom"
               [ngbTooltip]="'buttons:pasteFromClipboardNotSupported' | i18next"
               [disableTooltip]="!pastFromClipboardNotSupported">
            <mbs-button container="body"
                        placement="left-top bottom"
                        type="primary"
                        size="sm"
                        icon="fa fa-paste"
                        [ngbTooltip]="pasteTooltip"
                        [disabled]="!!editedString || loading || pastFromClipboardNotSupported"
                        [isCtrl]="true"
                        (click)="pasteFromClipboardHandler(error, false)">
              <span class="font-weight-semibold">{{'buttons:paste_from_clipboard' | i18next}}</span>
            </mbs-button>
          </div>
        </div>
      </div>
      <div class="mt-2 pt-1"
           [formGroup]="foldersForm">
        <mbs-input formControlName="excludeString"
                   maxLength="32767"
                   [appendButtons]="[
                   {
                     icon: editedString && !editedString.isInclude ? 'fa fa-check-circle text-success' : 'fa fa-plus-circle',
                     id: 'newExcludeFolderButton',
                     disabled: foldersForm.get('excludeString').disabled,
                     type: 'secondary'
                   },
                   {
                     icon: 'fa fa-close',
                     id: 'closeExcludeFolderButton',
                     disabled: false,
                     hide: !editedString || editedString.isInclude,
                     type: 'secondary'
                   }
                 ]"
                   [buttonClickOnEnter]="!!foldersForm.get('excludeString')?.value"
                   [validationWhitespace]="true"
                   (blur)="includeExcludeStringBlur($event, 'excludeString')"
                   (buttonClick)="addIncludeExcludeStringHandler($event, false)"
                   (input)="includeExcludeInputHandler($event, false)"></mbs-input>
      </div>

      <div class="mt-2 pt-1">
        <mbs-card [size]="'xs'"
                  [staticHeight]="128">
          <div class="form-row mb-2 align-items-center flex-nowrap _hover-parent"
               *ngFor="let str of stepForm.get('excludeFolders').value; let i = index">
            <div class="col-auto text-xs flex-shrink-1 text-overflow"
                 [class.text-muted]="editedString && editedString.value !== str">
            <span ngbTooltip="{{str}}"
                  container="body"
                  class="pre-wrap"
                  tooltipClass="tooltip-lg text-left">{{str}}</span>
            </div>
            <div class="col-auto _hover-target"
                 *ngIf="!foldersForm.get('excludeString')?.disabled && !editedString">
              <div class="row align-items-center no-gutters lh-1">
                <div class="col-auto">
                  <mbs-button [isCtrl]="true"
                              type="primary"
                              icon="fa fa-pencil px-1"
                              (click)="editHandler(i, false)"></mbs-button>
                </div>
                <div class="col-auto">
                  <mbs-button [isCtrl]="true"
                              type="primary"
                              icon="fa fa-times-circle px-1"
                              (click)="delIncludeExcludeStringHandler(i, false)"></mbs-button>
                </div>
              </div>
            </div>
          </div>
        </mbs-card>
      </div>
    </mbs-form-group>
  </ng-container>

  <mbs-button *ngIf="!isRDMode && view === viewMode.Default"
              [attr.data-test-element]="elementSelectors.name.advancedOptionsBtn"
              [class.mt-3]="!showPlainText"
              [isCtrl]="true"
              [hideDropdownIcon]="true">
    {{'wizards:advancedOptionsBtn' | i18next}}
    <span class="fa fa-caret-down"></span>

    <ng-template mbsButtonDropdown>
      <button *ngIf="!isOffline && !isLinux"
              ngbDropdownItem
              [attr.data-test-element]="elementSelectors.name.advancedOptionsBtnShare"
              (click)="addNetworkShare()">{{'wizards:addNetworkShare' | i18next}}</button>
      <button ngbDropdownItem
              (click)="showLegend()">{{'wizards:show_legend_text' | i18next}}</button>
      <button ngbDropdownItem
              [disabled]="!!editedString || loading"
              (click)="showHidePlaneTextHandler()">
        {{showPlainText ? ('wizards:hide_plain_text' | i18next) : ('wizards:show_plain_text' | i18next)}}
      </button>
    </ng-template>
  </mbs-button>
</section>


<ng-template #pasteTooltip>
  <div class="tooltip_content text-left">
    {{'wizards:past_tooltip_text' | i18next}}<br>
    <ng-container *ngIf="!isLinux">
      D:\Folder 1<br>
      D:\Folder 2<br>
      D:\Folder 3
    </ng-container>
    <ng-container *ngIf="isLinux">
      /home/folder 1<br>
      /home/folder 2<br>
      /home/folder 3
    </ng-container>
  </div>
</ng-template>

<ng-template #error>
  <mbs-form-group>
    <img src="assets/clipboard.png"
         alt="No Backup Plans" />
  </mbs-form-group>
  {{'validation:read_permission_error' | i18next}}
</ng-template>
