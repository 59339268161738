<ng-content select="[prependLabel]"></ng-content>
<ng-content select="input"></ng-content>
<label [for]="id"
       [class.font-weight-bold]="boldLabel"
       [ngClass]="labelClassesInner">

  <span *ngIf="label"
        [ngClass]="labelContentClassesInner">
    <ng-template *ngTemplateOutlet="isString(label) ? labelDefault : label; context: {$implicit: context}"></ng-template>
  </span>

  <span class="mbs-form_label-required"
        *ngIf="required">*</span>
  <span *ngIf="info"
        class="fa fa-info-circle tooltip-btn mb-1 ml-1 text-lg text-cyan"
        [placement]="infoPlacement"
        [triggers]="infoTriggers"
        [container]="infoContainer"
        [ngbTooltip]="info"></span>
</label>
<ng-content select="[appendLabel]"></ng-content>

<ng-template #labelDefault>
  {{ label || ''}}
</ng-template>
