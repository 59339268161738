export enum WindowsKeyCombinationMode {
  ALWAYS = 'Always',
  NEVER = 'Never',
  ONLY_IN_FULL_SCREEN = 'OnlyInFullscreenMode'
}

export enum PasswordInputType {
  PASSWORD = 'password',
  TEXT = 'text'
}
