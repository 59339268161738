<section [formGroup]="stepForm">
  <header class="mb-4">
    <h4>{{ 'wizards:restore_point_title' | i18next: { format: 'title' } }}</h4>
  </header>

  <mbs-form-group>
    <mbs-radio formControlName="restoreType"
               name="restoreType"
               [label]="'wizards:latest_version_label' | i18next"
               [descriptionClasses]="'mt-1'"
               [descriptionOutside]="false"
               [value]="0"
               [id]="'restore-type-0'">
      <ng-template radioDescription>
        <span class="text-sm">{{ 'wizards:latest_version_subtitle' | i18next }}</span>
      </ng-template>
    </mbs-radio>
    <ng-container *ngIf="!isNBF">
      <mbs-radio formControlName="restoreType"
                 name="restoreType"
                 [label]="'wizards:point_of_time_label' | i18next"
                 [descriptionClasses]="'mt-1'"
                 [descriptionOutside]="false"
                 [value]="1"
                 [id]="'restore-type-1'">
        <ng-container appendLabel>
          <span class="fa fa-info-circle ml-2 cursor-help text-info"
                [ngbTooltip]="'wizards:provider_timezone_utc_text' | i18next : { offset: providerOffset }"></span>
        </ng-container>
        <ng-template radioDescription>
          <span class="text-sm">{{ 'wizards:point_of_time_subtitle' | i18next }}</span>
          <div *ngIf="stepForm.get('restoreType').value === 1"
               class="form-row align-items-center my-3"
               [formGroup]="dateTimeForm">
            <div class="col-3">
              <mbs-datepicker formControlName="pointOfTimeData"
                              [needSetCurrent]="true"
                              [id]="'from-date-picker-0'"></mbs-datepicker>
            </div>
            <div class="col-1">
              <label [class.-disabled]="stepForm.get('restoreType').value !== 1"
                     class="mbs-form_label mb-0"
                     [for]="'from-time-picker-0'">
                {{ 'wizards:time_label' | i18next }}
              </label>
            </div>
            <div class="col-3">
              <mbs-timepicker formControlName="pointOfTimeTime"
                              [id]="'from-time-picker-0'"
                              [meridian]="true"></mbs-timepicker>
            </div>
          </div>
        </ng-template>
      </mbs-radio>
    </ng-container>

    <mbs-radio *ngIf="isNBF"
               formControlName="restoreType"
               name="restoreType"
               [label]="'wizards:select_restore_point_label' | i18next"
               [descriptionClasses]="'mt-1'"
               [descriptionOutside]="false"
               [value]="1"
               [id]="'restore-type-1-1'">
      <ng-template radioDescription>
        <span class="text-sm d-block mb-3">{{ 'wizards:select_restore_point_subtitle' | i18next }}</span>
        <mbs-select [(ngModel)]="selectedRestorePoint"
                    (change)="selectedRestorePointChangeHandler($event)"
                    [dropdownPosition]="'auto'"
                    [appendTo]="'.modal-content'"
                    [textOverflow]="false"
                    [bindValue]="'value'"
                    [bindLabel]="'label'"
                    [clearable]="false"
                    [disabled]="stepForm?.get('restoreType')?.value !== 1"
                    [id]="'selected-restore-point-select'"
                    [items]="restorePoints"
                    [ngModelOptions]="{ standalone: true }"
                    [searchable]="false"></mbs-select>
        <mbs-alert *ngIf="isNBF && (!restorePoints || !restorePoints.length)"
                   class="mb-0 mt-3"
                   [type]="mbsPopupType.info"
                   [icon]="true">
          {{'wizards:no_restore_points_text' | i18next}}
        </mbs-alert>
      </ng-template>
    </mbs-radio>

    <mbs-alert *ngIf="isNBF && stepForm.get('resultPoint').value?.needDeepSync && !stepService?.deepSyncSuccess$?.value"
               class="mb-0 mt-3"
               [type]="mbsPopupType.warning"
               [icon]="true">
      {{'wizards:need_deep_sync_alert_text' | i18next}}
    </mbs-alert>

    <ng-container *ngIf="!isNBF && isRestorePlan && !isLinux">
      <mbs-radio formControlName="restoreType"
                 name="restoreType"
                 [label]="'wizards:modified_period_label' | i18next"
                 [descriptionOutside]="false"
                 [value]="3"
                 [id]="'restore-type-2'">
        <ng-container appendLabel>
          <span class="fa fa-info-circle ml-2 cursor-help text-info"
                [ngbTooltip]="'wizards:provider_timezone_utc_text' | i18next : { offset: providerOffset }"></span>
        </ng-container>
        <ng-template radioDescription>
          <div class="form-row align-items-center mb-2"
               [formGroup]="dateTimeForm">
            <div class="col-1">
              <label [class.-disabled]="stepForm.get('restoreType').value !== 3"
                     class="mbs-form_label mb-0"
                     [for]="'from-date-picker-1'">
                {{ 'wizards:from_label' | i18next }}
              </label>
            </div>
            <div class="col-3">
              <mbs-datepicker formControlName="modifiedFromData"
                              (change)="updateToState()"
                              [needSetCurrent]="true"
                              [id]="'from-date-picker-1'"></mbs-datepicker>
            </div>
            <div class="col-1">
              <label [class.-disabled]="stepForm.get('restoreType').value !== 3"
                     class="mbs-form_label mb-0"
                     [for]="'from-time-picker-1'">
                {{ 'wizards:time_label' | i18next }}
              </label>
            </div>
            <div class="col-3">
              <mbs-timepicker formControlName="modifiedFromTime"
                              (change)="updateToState()"
                              [id]="'from-time-picker-1'"
                              [meridian]="true"></mbs-timepicker>
            </div>
          </div>
          <div class="form-row align-items-center"
               [formGroup]="dateTimeForm">
            <div class="col-1">
              <label [class.-disabled]="stepForm.get('restoreType').value !== 3"
                     class="mbs-form_label mb-0"
                     [for]="'from-date-picker-2'">
                {{ 'wizards:small_to_label' | i18next }}
              </label>
            </div>
            <div class="col-3">
              <mbs-datepicker formControlName="modifiedToData"
                              (change)="updateToState()"
                              [isValidated]="true"
                              [needSetCurrent]="true"
                              [id]="'from-date-picker-2'"></mbs-datepicker>
            </div>
            <div class="col-1">
              <label [class.-disabled]="stepForm.get('restoreType').value !== 3"
                     class="mbs-form_label mb-0"
                     [for]="'from-time-picker-2'">
                {{ 'wizards:time_label' | i18next }}
              </label>
            </div>
            <div class="col-3">
              <mbs-timepicker formControlName="modifiedToTime"
                              (change)="updateToState()"
                              [id]="'from-time-picker-2'"
                              [meridian]="true"></mbs-timepicker>
            </div>
          </div>
          <div *ngIf="!dateTimeForm.get('modifiedToTime')?.valid && dateTimeForm.get('modifiedToData').enabled"
               class="form-row">
            <div class="col-5 offset-1">
              <mbs-input-errors
                                [errors]="[{ message: 'wizards:validate_from_to_controls' | i18next }]"></mbs-input-errors>
            </div>
          </div>
        </ng-template>
      </mbs-radio>

      <mbs-radio formControlName="restoreType"
                 name="restoreType"
                 [label]="'wizards:backup_period_label' | i18next"
                 [descriptionOutside]="false"
                 [value]="4"
                 [id]="'restore-type-3'">
        <ng-container appendLabel>
          <span class="fa fa-info-circle ml-2 cursor-help text-info"
                [ngbTooltip]="'wizards:provider_timezone_utc_text' | i18next : { offset: providerOffset }"></span>
        </ng-container>
        <ng-template radioDescription>
          <div class="form-row align-items-center mb-2"
               [formGroup]="dateTimeForm">
            <div class="col-1">
              <label [class.-disabled]="stepForm.get('restoreType').value !== 4"
                     class="mbs-form_label mb-0"
                     [for]="'from-date-picker-3'">
                {{ 'wizards:from_label' | i18next }}
              </label>
            </div>
            <div class="col-3">
              <mbs-datepicker formControlName="backupFromData"
                              (change)="updateToState(true)"
                              [needSetCurrent]="true"
                              [id]="'from-date-picker-3'"></mbs-datepicker>
            </div>
            <div class="col-1">
              <label [class.-disabled]="stepForm.get('restoreType').value !== 4"
                     class="mbs-form_label mb-0"
                     [for]="'from-time-picker-3'">
                {{ 'wizards:time_label' | i18next }}
              </label>
            </div>
            <div class="col-3">
              <mbs-timepicker formControlName="backupFromTime"
                              (change)="updateToState(true)"
                              [id]="'from-time-picker-3'"
                              [meridian]="true"></mbs-timepicker>
            </div>
          </div>
          <div class="form-row align-items-center"
               [formGroup]="dateTimeForm">
            <div class="col-1">
              <label [class.-disabled]="stepForm.get('restoreType').value !== 4"
                     class="mbs-form_label mb-0"
                     [for]="'from-date-picker-4'">
                {{ 'wizards:small_to_label' | i18next }}
              </label>
            </div>
            <div class="col-3">
              <mbs-datepicker formControlName="backupToData"
                              [isValidated]="true"
                              (change)="updateToState(true)"
                              [needSetCurrent]="true"
                              [id]="'from-date-picker-4'"></mbs-datepicker>
            </div>
            <div class="col-1">
              <label [class.-disabled]="stepForm.get('restoreType').value !== 4"
                     class="mbs-form_label mb-0"
                     [for]="'from-time-picker-4'">
                {{ 'wizards:time_label' | i18next }}
              </label>
            </div>
            <div class="col-3">
              <mbs-timepicker formControlName="backupToTime"
                              (change)="updateToState(true)"
                              [id]="'from-time-picker-4'"
                              [meridian]="true"></mbs-timepicker>
            </div>
          </div>
          <div *ngIf="!dateTimeForm.get('backupToTime')?.valid && dateTimeForm.get('backupToData').enabled"
               class="form-row">
            <div class="col-5 offset-1">
              <mbs-input-errors
                                [errors]="[{ message: 'wizards:validate_from_to_controls' | i18next }]"></mbs-input-errors>
            </div>
          </div>
        </ng-template>
      </mbs-radio>
    </ng-container>

    <mbs-radio *ngIf="!isNBF && (!isRestore || !isLinux)"
               formControlName="restoreType"
               name="restoreType"
               [label]="'wizards:manually_label' | i18next"
               [descriptionClasses]="'mt-1'"
               [descriptionOutside]="false"
               [value]="2"
               [id]="'restore-type-4'">
      <ng-template radioDescription>
        <span class="text-sm d-block mb-2">{{ 'wizards:manually_subtitle' | i18next }}</span>
        <mbs-list-select *ngIf="isRestoreIbb && stepForm.get('restoreType').value === 2"
                         id="partition-step-table"
                         listType="table"
                         [changeSortState]="orderBy"
                         (sort)="handleSort($event)"
                         [bindSelected]="'restorePointId'"
                         [invalid]="stepForm.touched && !value?.valid"
                         [keepState]="true"
                         [maxHeight]="'240px'"
                         [multipleSelect]="false"
                         [headers]="headers"
                         [isNeedSelectRow]="false"
                         [loadSource]="false"
                         [showCheckboxes]="true"
                         [showHeaders]="true"
                         [showSearch]="false"
                         [selectedData]="selectedRestorePoint ? [selectedRestorePoint] : []"
                         [data]="loadedRestorePoints || []"
                         (changeSelected)="restorePointCheckedHandler($event, true)">
          <ng-template mbsTableCell
                       let-part>
            {{part.isFull ? 'Full' : 'Block-level'}}
          </ng-template>
          <ng-template mbsTableCell
                       let-part>
            <mbs-rmm-date-time-format [time]="part.date"></mbs-rmm-date-time-format>
          </ng-template>
          <ng-template mbsTableCell
                       let-part>
            {{part.sizeOnStorage ? getDiskSizeFromMB(part.sizeOnStorage) : '-'}}
          </ng-template>
          <ng-template mbsTableCell
                       let-part>
            {{part.isCompressed}}
          </ng-template>
          <ng-template mbsTableCell
                       let-part>
            {{part.isEncrypted}}
          </ng-template>
        </mbs-list-select>
      </ng-template>
    </mbs-radio>
  </mbs-form-group>

  <mbs-form-group *ngIf="isRestorePlan && isLinux && !isNBF">
    <mbs-checkbox formControlName="syncBeforeRun"
                  [label]="'wizards:restores_sync_local_with_storage_linux_label' | i18next"
                  [id]="'sync-before-run-checkbox'"></mbs-checkbox>
  </mbs-form-group>
</section>
