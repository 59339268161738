<mbs-modal [title]="'computers.module:modals.allowConnectTitle' | i18next : { computerName: getComputerName() }"
           [bodyClasses]="'remote-connection-allow-modal-component_body'">
  <ng-container modal-body>
    <ng-container *ngIf="!loading; else loader">
      <mbs-alert [type]="mbsPopupType.danger"
                 [icon]="true">
        <ng-container *ngIf="hasPerComputerSettings; else noComputerSettings">
          <div>{{ 'computers.module:connect.remoteAccessNotAllowed' | i18next }}</div>
          <div>
            <span>{{ 'computers.module:connect.allowRemoteAccessIn' | i18next }}</span>
            <mbs-button [isCtrl]="true"
                        (click)="onPerComputerButtonClick()">{{ 'computers.module:connect.connectAgentOptions' | i18next }}</mbs-button>
          </div>
        </ng-container>

        <ng-template #noComputerSettings>
          <span [innerHTML]="errorMessage"></span>
        </ng-template>
      </mbs-alert>
    </ng-container>

    <ng-template #loader>
      <mbs-loader [size]="'small'"></mbs-loader>
    </ng-template>
  </ng-container>
  <ng-container modalFooter>
    <mbs-button (click)="baseModal.close()">{{'buttons:close' | i18next}}</mbs-button>
  </ng-container>
</mbs-modal>
