import { Component, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MbsPopupType } from 'mbs-ui-kit';
import { ModalComponent } from 'mbs-ui-kit/modal/modal.component';

@UntilDestroy()
@Component({
  selector: 'authorization-license-error-modal',
  templateUrl: './authorization-license-error-modal.component.html'
})
export class AuthorizationLicenseErrorModalComponent {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public alertType = MbsPopupType.warning;
}
