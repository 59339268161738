<section [formGroup]="stepForm"
         class="mbs-wizard_form">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{ 'wizards:notification_title' | i18next: { format: 'title' } }}
    </h4>
  </header>

  <ng-container *ngIf="(ability.can('read', 'Notification') || isProvider) && !isRDMode">
    <mbs-form-group [attr.data-test-element]="elementsSelector.name.mainNotificationRadio">
      <div class="row">
        <div class="col-4">
          <mbs-radio [label]="'wizards:notification_company_label' | i18next"
                     name="notificationEnabled"
                     [id]="'notification-enabled-radio-1'"
                     [value]="false"
                     [(ngModel)]="notification.Enabled"
                     [ngModelOptions]="{standalone: true}"></mbs-radio>
        </div>
        <div class="col-6">
          <mbs-radio [label]="'wizards:notification_specify_label' | i18next"
                     name="notificationEnabled"
                     [id]="'notification-enabled-radio-2'"
                     [value]="true"
                     [(ngModel)]="notification.Enabled"
                     [ngModelOptions]="{standalone: true}"></mbs-radio>
        </div>
      </div>
    </mbs-form-group>

    <ng-template #checkboxes
                 let-type="type">
      <mbs-form-group>
        <div class="h5 align-items-center"
             [class.text-success]="type === 'Success'"
             [class.text-warning]="type === 'Warning'"
             [class.text-danger]="type === 'Failure'">
          {{ type }}
          <span class="fa fa-info-circle ml-2 cursor-help text-info"
                container="body"
                placement="right auto"
                [ngbTooltip]="getTooltipByType(type)"></span>
        </div>
        <div class="mb-3">
          <mbs-checkbox [label]="'wizards:notification_provider_label' | i18next"
                        [disabled]="!notification.Enabled"
                        [checked]="getPermission(type, NotificationPlanRule.Provider)"
                        (change)="setPermission(type, NotificationPlanRule.Provider)">
            <ng-container appendLabel><span class="text-sm text-muted ml-3 align-self-center">({{ providerEmail }})</span></ng-container>
          </mbs-checkbox>

        </div>
        <div class="mb-3">
          <mbs-checkbox [label]="'wizards:notification_specify_emails_label' | i18next"
                        [disabled]="!notification.Enabled"
                        [checked]="getPermission(type, NotificationPlanRule.SpecifiedEmails)"
                        (change)="setPermission(type, NotificationPlanRule.SpecifiedEmails)">
            <ng-container appendLabel>
            <span class="text-info ml-3"
                  [class.text-muted]="!notification.Enabled"
                  [class.cursor-pointer]="notification.Enabled"
                  (click)="addClickHandler(type, emailsChanger)">
              {{ getEmailsCount(type) > 0 ? '(' + getEmailsCount(type) + ') Edit' : 'Add' }}
            </span>
            </ng-container>
          </mbs-checkbox>
        </div>
      </mbs-form-group>
    </ng-template>

    <ng-container *ngTemplateOutlet="checkboxes;context: { type: 'Success'}"></ng-container>
    <ng-container *ngTemplateOutlet="checkboxes;context: { type: 'Warning'}"></ng-container>
    <ng-container *ngTemplateOutlet="checkboxes;context: { type: 'Failure'}"></ng-container>

    <hr>
  </ng-container>

  <mbs-form-group formGroupName="receiveNotificationOnCompleteGroup">
    <div class="row">
      <div class="col-12">
        <mbs-checkbox formControlName="flag"
                      [label]="'wizards:notification_email_label' | i18next"
                      (change)="flagChangeHandler($event)"
                      [id]="'receiveNotificationOnCompleteGroup-add-flag-checkbox'"
                      [descriptionOutside]="false">
          <ng-template checkboxDescription>
            <div class="add-case-radio-box">
              <mbs-radio formControlName="case"
                         name="receiveNotificationOnCompleteGroupAddCaseRadio"
                         value="whenFails"
                         [label]="isRestore ?
                         ('wizards:restore_notification_when_fails' | i18next) :
                         ('wizards:notification_email_when_fails_label') | i18next"
                         [id]="'receiveNotificationOnCompleteGroup-add-flag-radio-1'"></mbs-radio>
              <mbs-radio formControlName="case"
                         name="receiveNotificationOnCompleteGroupAddCaseRadio"
                         value="allCases"
                         [label]="'wizards:all_cases_label' | i18next"
                         [id]="'receiveNotificationOnCompleteGroup-add-flag-radio-2'"></mbs-radio>
            </div>
          </ng-template>
        </mbs-checkbox>
      </div>
    </div>
  </mbs-form-group>

  <mbs-form-group *ngIf="!isLinux"
                  formGroupName="addEntryToEventLogOnCompleteGroup"
                  [attr.data-test-element]="elementsSelector.name.notLinuxBlock">
    <div class="row">
      <div class="col-12">
        <mbs-checkbox formControlName="flag"
                      [label]="'wizards:add_entry_to_event_log_label' | i18next"
                      (change)="flagChangeHandler($event, false)"
                      [id]="'addEntryToEventLogOnCompleteGroup-add-flag-checkbox'"
                      [descriptionOutside]="false">
          <ng-template checkboxDescription>
            <div class="add-case-radio-box">
              <mbs-radio formControlName="case"
                         name="addEntryToEventLogOnCompleteGroupAddCaseRadio"
                         value="whenFails"
                         [label]="isRestore ?
                         ('wizards:restore_notification_when_fails' | i18next) :
                         ('wizards:notification_email_when_fails_label') | i18next"
                         [id]="'addEntryToEventLogOnCompleteGroup-add-flag-radio-1'"></mbs-radio>
              <mbs-radio formControlName="case"
                         name="addEntryToEventLogOnCompleteGroupAddCaseRadio"
                         value="allCases"
                         [label]="'wizards:all_cases_label' | i18next"
                         [id]="'addEntryToEventLogOnCompleteGroup-add-flag-radio-2'"></mbs-radio>
            </div>
          </ng-template>
        </mbs-checkbox>
      </div>
    </div>
  </mbs-form-group>
</section>

<ng-template #emailsChanger>
  <mbs-form-group>
    <div class="form-row"
         [formGroup]="newEmailForm">
      <div class="col-10">
        <mbs-input [autocomplete]="'off'"
                   [validationWhitespace]="true"
                   [placeholder]="'wizards:insert_email_placeholder' | i18next"
                   type="email"
                   formControlName="newEmail"></mbs-input>
      </div>
      <div class="col-2 text-right">
        <mbs-button type="primary"
                    [block]="true"
                    [disabled]="!newEmailForm.get('newEmail').value || newEmailForm.get('newEmail').invalid"
                    (click)="addEmailHandler()">Add</mbs-button>
      </div>

    </div>
  </mbs-form-group>

  <mbs-table [showHeaders]="true"
             [staticHeightIfVoidData]="true"
             [headers]="newEmailsHeaders"
             [data]="editEmailsNotification.emails"
             [height]="240"
             [showCheckboxes]="false">
    <ng-template mbsTableCell
                 let-rowData>
      {{rowData.email}}
    </ng-template>
    <ng-template mbsTableCell
                 let-index="rowIndex">
      <span class="fa fa-close text-danger cursor-pointer" (click)="delEmailHandler(index)"></span>
    </ng-template>
  </mbs-table>
</ng-template>
