import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import Computer, { AgentType, OsType } from '@models/Computer';
import { PermissionsEnum } from '@models/PermissionsEnum';
import { GenerationGFSType, GenerationGFSTypeIcons, ItemTypeEnum, MyTreeElements, i18KeysByType } from '@models/backup/storages-type';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isWindows } from '@utils/is-windows';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { isNil } from 'lodash';
import {
  IntMaxValue,
  MbsSize,
  ModalService,
  ModalSettings,
  SharedPersistentStateEnum,
  StatusIconType,
  TableHeader,
  TreeElement
} from 'mbs-ui-kit';
import { AlertType } from 'mbs-ui-kit/alert/alert.model';
import { Subject, noop } from 'rxjs';

const OriginalSizeKey = 'originalSize';
const PointKey = 'backup-storages:browseStorage.restorePoints';

@UntilDestroy()
@Component({
  selector: 'mbs-browse-storage-tables',
  templateUrl: './browse-storage-tables.component.html',
  styleUrls: ['./browse-storage-tables.component.scss']
})
export class BrowseStorageTablesComponent implements OnChanges, OnInit {
  @Input() rightTableData: MyTreeElements[];
  @Input() selectedItemsForQR: MyTreeElements[] = [];
  @Input() selectedTreeItem: MyTreeElements;
  @Input() computerData: Computer;
  @Input() isOnline = false;
  @Input() isOnlineAccess = false;
  @Input() deleteProcess = false;

  @Output() itemDblClick = new EventEmitter<{ event: MouseEvent; item: TreeElement }>();
  @Output() selectedItemsChange = new EventEmitter<MyTreeElements[]>();
  @Output() deleteClick = new EventEmitter<MyTreeElements>();
  @Output() restoreToLocal = new EventEmitter<{ isLocal: boolean; item: MyTreeElements }>();

  public readonly minimalAgentVersion = 793;
  public readonly alertType = AlertType;
  public readonly MbsSize = MbsSize;
  public readonly itemTypeEnum = ItemTypeEnum;
  public readonly StatusIconType = StatusIconType;
  public readonly generationGFSType = GenerationGFSType;
  public readonly generationGFSTypeIcons = GenerationGFSTypeIcons;
  public readonly viewMode: SharedPersistentStateEnum = SharedPersistentStateEnum.table;
  public readonly backupLevelText = this.getTextFromI18Pipe('backup');
  public readonly generationLevelText = this.getTextFromI18Pipe('generation');
  public readonly pointLevelText = this.getTextFromI18Pipe('restorePoint');
  public readonly alignRightTableClass = 'd-flex justify-content-start';
  public readonly statusText = {
    success: this.getTextFromI18Pipe('success'),
    warning: this.getTextFromI18Pipe('warning'),
    danger: this.getTextFromI18Pipe('failed'),
    fail: this.getTextFromI18Pipe('failed'),
    unknown: this.getTextFromI18Pipe('consistencyCheckScheduleLabel')
  };
  public readonly rightTableDataHeaders: TableHeader[] = [
    {
      name: this.getTextFromI18Pipe('name'),
      headerName: 'label',
      gridColSize: '4.5fr',
      class: this.alignRightTableClass,
      overflow: true
    },
    { name: this.getTextFromI18Pipe('modifyDate'), headerName: 'modifyDateUTC', gridColSize: '3.5fr', class: this.alignRightTableClass },
    {
      name: this.getTextFromI18Pipe(OriginalSizeKey),
      headerName: 'originalSize',
      gridColSize: '3fr',
      class: this.alignRightTableClass,
      overflow: true
    }
  ];
  public readonly rightTableBunchFullHeaders: TableHeader[] = [
    {
      name: this.getTextFromI18Pipe('planName'),
      headerName: 'planName',
      gridColMin: '140px',
      gridColSize: '2fr',
      class: this.alignRightTableClass,
      overflow: true
    },
    {
      name: this.getTextFromI18Pipe('firstRestorePoint'),
      headerName: 'firstRestorePoint',
      gridColMin: '160px',
      gridColSize: '1fr',
      class: this.alignRightTableClass
    },
    {
      name: this.getTextFromI18Pipe('lastRestorePoint'),
      headerName: 'lastRestorePoint',
      gridColMin: '160px',
      gridColSize: '1fr',
      class: this.alignRightTableClass
    },
    { name: 'Icons', headerName: 'Icons', gridColMin: '96px', gridColSize: '96px' },
    {
      name: this.getTextFromI18Pipe('keepBackupFor'),
      headerName: 'keepBackupFor',
      gridColMin: '110px',
      gridColSize: '110px',
      class: this.alignRightTableClass
    },
    { name: this.getTextFromI18Pipe(OriginalSizeKey), gridColMin: '88px', gridColSize: '88px', class: this.alignRightTableClass },
    { name: this.getTextFromI18Pipe('sizeOnStorage'), gridColMin: '104px', gridColSize: '104px', class: this.alignRightTableClass },
    {
      name: this.getTextFromI18Pipe('totalSavings'),
      headerName: 'totalSavings',
      gridColMin: '126px',
      gridColSize: '126px',
      class: this.alignRightTableClass
    },
    { name: '', headerName: 'Actions', gridColMin: '86px', gridColSize: '86px', isGridColumn: false }
  ];
  public readonly rightTableBunchSmallHeaders: TableHeader[] = [
    {
      name: this.getTextFromI18Pipe('planName'),
      headerName: 'planName',
      gridColMin: '140px',
      gridColSize: '1fr',
      class: this.alignRightTableClass,
      overflow: true
    },
    { name: '', headerName: 'Actions', gridColMin: '86px', gridColSize: '86px', isGridColumn: false }
  ];
  public readonly rightTableGenerationFullHeaders: TableHeader[] = [
    { name: '', gridColMin: '37px', gridColSize: '37px' },
    {
      name: this.getTextFromI18Pipe('firstRestorePoint'),
      headerName: 'firstRestorePoint',
      gridColMin: '160px',
      gridColSize: '1fr',
      class: this.alignRightTableClass
    },
    {
      name: this.getTextFromI18Pipe('lastRestorePoint'),
      headerName: 'lastRestorePoint',
      gridColMin: '160px',
      gridColSize: '1fr',
      class: this.alignRightTableClass
    },
    { name: 'Icons', headerName: 'Icons', gridColMin: '96px', gridColSize: '96px' },
    { name: this.getTextFromI18Pipe(OriginalSizeKey), gridColMin: '88px', gridColSize: '88px', class: this.alignRightTableClass },
    { name: this.getTextFromI18Pipe('sizeOnStorage'), gridColMin: '104px', gridColSize: '104px', class: this.alignRightTableClass },
    {
      name: this.getTextFromI18Pipe('daysToPurge'),
      headerName: 'daysToPurge',
      gridColMin: '110px',
      gridColSize: '110px',
      class: this.alignRightTableClass
    },
    {
      name: this.getTextFromI18Pipe('totalSavings'),
      headerName: 'totalSavings',
      gridColMin: '126px',
      gridColSize: '126px',
      class: this.alignRightTableClass
    },
    { name: '', headerName: 'Actions', gridColMin: '86px', gridColSize: '86px', isGridColumn: false }
  ];
  public readonly rightTableGenerationSmallHeaders: TableHeader[] = [
    { name: '', gridColMin: '37px', gridColSize: '37px' },
    {
      name: this.getTextFromI18Pipe('firstRestorePoint'),
      headerName: 'firstRestorePoint',
      gridColMin: '160px',
      gridColSize: '1fr',
      class: this.alignRightTableClass
    },
    {
      name: this.getTextFromI18Pipe('lastRestorePoint'),
      headerName: 'lastRestorePoint',
      gridColMin: '160px',
      gridColSize: '1fr',
      class: this.alignRightTableClass
    },
    { name: '', headerName: 'Actions', gridColMin: '86px', gridColSize: '86px', isGridColumn: false }
  ];
  public readonly rightTablePointFullHeaders: TableHeader[] = [
    { name: '', gridColMin: '37px', gridColSize: '37px' },
    { name: this.getTextFromI18Pipe('date'), gridColMin: '154px', gridColSize: '1fr', class: this.alignRightTableClass },
    { name: this.getTextFromI18Pipe('backupStatus'), gridColMin: '116px', gridColSize: '116px' },
    { name: this.getTextFromI18Pipe('consistencyCheck'), headerName: 'consistencyCheck', gridColMin: '140px', gridColSize: '140px' },
    { name: this.getTextFromI18Pipe('restoreVerification'), headerName: 'restoreVerification', gridColMin: '148px', gridColSize: '148px' },
    { name: this.getTextFromI18Pipe('duration'), gridColMin: '80px', gridColSize: '80px', class: this.alignRightTableClass },
    { name: this.getTextFromI18Pipe(OriginalSizeKey), gridColMin: '95px', gridColSize: '95px', class: this.alignRightTableClass },
    { name: this.getTextFromI18Pipe('sizeOnStorage'), gridColMin: '104px', gridColSize: '104px', class: this.alignRightTableClass },
    {
      name: this.getTextFromI18Pipe('totalSavings'),
      headerName: 'totalSavings',
      gridColMin: '126px',
      gridColSize: '126px',
      class: this.alignRightTableClass
    },
    { name: '', headerName: 'Actions', gridColMin: '46px', gridColSize: '46px', isGridColumn: false }
  ];
  public readonly rightTablePointSmallHeaders: TableHeader[] = [
    { name: '', gridColMin: '37px', gridColSize: '37px' },
    { name: this.getTextFromI18Pipe('date'), gridColMin: '154px', gridColSize: '1fr', class: this.alignRightTableClass },
    { name: '', headerName: 'Actions', gridColMin: '46px', gridColSize: '46px', isGridColumn: false }
  ];
  public readonly textForWindowsAlertMap = {
    [ItemTypeEnum.RestorePointIncremental]: this.getTextFromI18Pipe(i18KeysByType[ItemTypeEnum.RestorePoint] + 's'),
    [ItemTypeEnum.RestorePointTLog]: this.getTextFromI18Pipe(i18KeysByType[ItemTypeEnum.RestorePoint] + 's'),
    [ItemTypeEnum.RestorePoint]: this.getTextFromI18Pipe(i18KeysByType[ItemTypeEnum.RestorePoint] + 's'),
    [ItemTypeEnum.Generation]: this.getTextFromI18Pipe(i18KeysByType[ItemTypeEnum.Generation] + 's')
  };
  public readonly elementsSelector = {
    name: {
      rightTableDefault: 'right-table-default',
      rightTableBunches: 'right-table-bunches',
      rightTableGeneration: 'right-table-generation',
      rightTablePoints: 'right-table-points',
      modifyDateUTCTableRow: 'modify-date-utc-table-row',
      originalSizeTableRow: 'original-size-table-row',
      firstRestoreDateTableRow: 'first-restore-date-table-row',
      lastRestoreDateTableRow: 'last-restore-date-table-row',
      sizeOnStorageTableRow: 'size-on-storage-table-row',
      daysToPurgeTableRow: 'days-to-purge-table-row',
      totalSavingsTableRow: 'total-savings-table-row',
      dateTableRow: 'date-table-row',
      backupResultTableRow: 'backup-result-table-row',
      consistencyCheckResultTableRow: 'consistency-check-result-table-row',
      restoreVerificationResultTableRow: 'restore-verification-result-table-row',
      durationTableRow: 'duration-result-table-row'
    }
  };

  private readonly titleMap: { [key: number]: string } = {
    [ItemTypeEnum.Bunch]: this.i18nPipe.transform('backup-storages:browseStorage.backupPlans'),
    [ItemTypeEnum.Generation]: this.i18nPipe.transform('backup-storages:browseStorage.backupGenerations'),
    [ItemTypeEnum.RestorePointTLog]: this.i18nPipe.transform(PointKey),
    [ItemTypeEnum.RestorePointIncremental]: this.i18nPipe.transform(PointKey),
    [ItemTypeEnum.RestorePoint]: this.i18nPipe.transform(PointKey)
  };

  private isDisableRestore: Subject<boolean> = new Subject();
  private readonly downloadSettings: ModalSettings = {
    header: { title: this.i18nPipe.transform('backup-storages:browseStorage.dataRestore', { format: 'title' }) },
    footer: { okButton: { text: this.i18nPipe.transform('buttons:restore'), type: 'primary', disabled$: this.isDisableRestore } }
  };

  public headers: TableHeader[] = [];

  public isLocal = false;
  public isGenerations = false;
  public isPoints = false;
  public isBackups = false;

  public existColumns: { label: boolean; modifyDateUTC: boolean; originalSize: boolean } = {
    label: true,
    modifyDateUTC: true,
    originalSize: true
  };

  rightTableBunchHeaders: TableHeader[] = [];
  rightTableGenerationHeaders: TableHeader[] = [];

  private topTypes: ItemTypeEnum[] = [
    ItemTypeEnum.Bunch,
    ItemTypeEnum.Generation,
    ItemTypeEnum.RestorePoint,
    ItemTypeEnum.RestorePointIncremental,
    ItemTypeEnum.RestorePointTLog
  ];

  get isWindows(): boolean {
    return isWindows();
  }

  get isTopLevel(): boolean {
    return this.isBackups || this.isGenerations || this.isPoints;
  }

  get isWindowsComputer(): boolean {
    return this.computerData?.os === OsType.windows;
  }

  get isOldAgent(): boolean {
    return !Computer.IsSupportedAgentVersion(this.computerData, AgentType.Backup, this.minimalAgentVersion);
  }

  get isNotWindowsOrOldAgent(): boolean {
    return !this.isWindowsComputer || this.isOldAgent;
  }

  get headerText(): string {
    return (this.isBackups || this.isGenerations || this.isPoints) && this.rightTableData?.length
      ? this.titleMap[this.rightTableData[0].itemType]
      : this.i18nPipe.transform('backup-storages:browseStorage.filesFolder');
  }

  get disabledQRButton(): boolean {
    return !this.selectedTreeItem?.checked || (!this.selectedTreeItem?.isCanBeQuickRestored && !this.selectedItemsForQR?.length);
  }

  get disabledDeleteButton(): boolean {
    return (
      !this.selectedTreeItem?.isCanBeDeleted ||
      this.deleteProcess ||
      (this.selectedTreeItem?.itemType !== ItemTypeEnum.Bunch && this.selectedTreeItem?.itemType !== ItemTypeEnum.Generation)
    );
  }

  get showCheckboxes(): boolean {
    const isTop = this.topTypes.includes(this.selectedTreeItem?.itemType);
    return (
      (this.selectedTreeItem?.isCanBeQuickRestored && this.selectedTreeItem?.itemType !== ItemTypeEnum.File && !isTop) ||
      this.rightTableData.some((item) => item.isCanBeQuickRestored)
    );
  }

  get rightTablePointHeaders(): TableHeader[] {
    return this.isNotWindowsOrOldAgent
      ? this.rightTablePointSmallHeaders
      : this.rightTablePointFullHeaders.filter(
          (header) => this.selectedTreeItem?.needShowRV || header.headerName !== 'restoreVerification'
        );
  }

  @ViewChild('downloadModal', { static: true, read: TemplateRef }) downloadModal: TemplateRef<any>;

  constructor(public ability: AbilityService, private i18nPipe: I18NextPipe, private modalService: ModalService) {}

  ngOnInit(): void {
    this.applyNewTableData(this.rightTableData);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.applyNewTableData(changes.rightTableData?.currentValue);

    if (changes?.isOnlineAccess && this.rightTableData?.length && (this.isBackups || this.isGenerations)) {
      this.setBackupsOrGenerationHeaders();
    }
  }

  selectedItemsForQRChangeHandler(elements: MyTreeElements[]): void {
    this.selectedItemsChange.emit(elements);
  }

  itemDblClickHandler(event: MouseEvent, item: TreeElement): void {
    this.itemDblClick.emit({ event, item });
  }

  disableRestoreBtn(item: TreeElement): boolean {
    return (
      this.deleteProcess ||
      !(item as MyTreeElements).isCanBeQuickRestored ||
      (this.isTopLevel && (!this.isWindows || !this.ability.can('read', PermissionsEnum.QuickRestore)))
    );
  }

  getDiskSizeFromKB(bytes: number): string {
    if (bytes < 1024) return bytes + ' B';

    const kb = +(bytes / 1024).toFixed(1);

    if (kb < 1024) return kb + ' KB';

    const mb = +(kb / 1024).toFixed(1);

    if (mb < 1024) return mb + ' MB';

    return +(mb / 1024).toFixed(1) + ' GB';
  }

  daysToPurgeToString(days: number): string {
    if (!days || days === IntMaxValue) return this.i18nPipe.transform('backup-storages:browseStorage.tables.forever');

    if (days < 30) {
      return days === 1
        ? this.i18nPipe.transform('backup-storages:browseStorage.tables.day')
        : this.i18nPipe.transform('backup-storages:browseStorage.tables.days', { days });
    }

    if (days < 365) {
      const months = Math.floor(days / 30);
      return months === 1
        ? this.i18nPipe.transform('backup-storages:browseStorage.tables.month')
        : this.i18nPipe.transform('backup-storages:browseStorage.tables.months', { months });
    }

    const years = Math.floor(days / 365);

    return years === 1
      ? this.i18nPipe.transform('backup-storages:browseStorage.tables.year')
      : this.i18nPipe.transform('backup-storages:browseStorage.tables.years', { years });
  }

  existPointsHeaderColumn(headerName: string): boolean {
    return this.rightTablePointHeaders.some((h: TableHeader) => h.headerName === headerName);
  }

  getDeleteTooltipText(item: MyTreeElements): string {
    if (!this.isOnline) return this.getTextFromI18Pipe('cantDeleteOffline');

    if (this.deleteProcess || !item.isCanBeDeleted) {
      return this.getTextFromI18Pipe('cantDelete');
    }

    const level = this.getTextFromI18Pipe(i18KeysByType[item?.itemType]);

    return this.i18nPipe.transform('backup-storages:browseStorage.tables.delete', { level });
  }

  deleteSelectedClickHandler(item: MyTreeElements = null): void {
    this.deleteClick.emit(item);
  }

  downloadClickHandler(item: MyTreeElements = null): void {
    this.isLocal = !this.isWindows && !this.isTopLevel;

    if (this.isWindows && this.isTopLevel) return this.restoreToLocal.emit({ isLocal: this.isLocal, item });

    if (this.isWindows && !this.isTopLevel && !this.ability.can('read', PermissionsEnum.QuickRestore)) {
      this.isLocal = true;
    }

    this.modalService
      .open(this.downloadSettings, this.downloadModal)
      .then((confirm) => {
        if (!confirm) return;

        return this.restoreToLocal.emit({ isLocal: this.isLocal, item });
      })
      .catch(noop);
  }

  isLocalChangeHandler(state: boolean) {
    this.isDisableRestore.next(state && !this.isOnline);
  }

  private applyNewTableData(tableData: MyTreeElements[] = []): void {
    if (tableData?.length) {
      this.setViewTypeFromItem(tableData[0]);
      this.setHeaders();
    }
  }

  private setViewTypeFromItem(item: MyTreeElements): void {
    this.isGenerations = item.itemType === ItemTypeEnum.Generation;
    this.isPoints =
      item.itemType === ItemTypeEnum.RestorePoint ||
      item.itemType === ItemTypeEnum.RestorePointIncremental ||
      item.itemType === ItemTypeEnum.RestorePointTLog;
    this.isBackups = item.itemType === ItemTypeEnum.Bunch;
  }

  private setBackupsOrGenerationHeaders(): void {
    const fullHeaders = this.isBackups
      ? this[this.isNotWindowsOrOldAgent ? 'rightTableBunchSmallHeaders' : 'rightTableBunchFullHeaders']
      : this[this.isNotWindowsOrOldAgent ? 'rightTableGenerationSmallHeaders' : 'rightTableGenerationFullHeaders'];

    this[this.isBackups ? 'rightTableBunchHeaders' : 'rightTableGenerationHeaders'] = fullHeaders.map((header) => {
      const newHeader = { ...header };

      if (this.isOnlineAccess && newHeader.headerName === 'Actions') {
        newHeader.gridColMin = '46px';
        newHeader.gridColSize = '46px';
      }

      return newHeader;
    });
  }

  private setHeaders(): void {
    this.existColumns = { label: true, modifyDateUTC: false, originalSize: false };

    this.rightTableData?.forEach((item: any) => {
      if (!isNil(item.modifyDateUTC)) this.existColumns.modifyDateUTC = true;
      if (!isNil(item.originalSize)) this.existColumns.originalSize = true;
    });

    this.headers = this.rightTableDataHeaders.filter((item) => this.existColumns[item.headerName]);
  }

  private getTextFromI18Pipe(name: string): string {
    return this.i18nPipe.transform(`backup-storages:browseStorage.tables.${name}`);
  }
}
