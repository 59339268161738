import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChange,
  TemplateRef,
  ViewChildren
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual, isNil } from 'lodash';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BootstrapTheme, MbsSize, cancelSelectionTextDefault, isTemplate, noDataMessageDefault } from '../../utils';
import { TableHeaderNameDirective } from '../directives/headerFor.directive';
import { TableCellDirective } from '../directives/table-cell.directive';
import { SortDirection, SortEvent, rotate } from '../models/sort-event';

export interface TableColumn {
  name: string;
  overflow?: boolean;
  gridColSize?: string;
  gridColMin?: string;
  sort?: string;
  class?: string | string[];
  headerClass?: string;
  headerTemplate?: TemplateRef<any>;
  headerName?: string;
  colSpan?: string[]; // array of widths any valid CSS value except FRs
}

export interface OnChange {
  [propKey: string]: SimpleChange;
}

export interface ClassRowObject {
  [name: string]: string;

  class: string;
  childRowsClass?: string;
}

export enum MultipleSelectType {
  Mouse = 'mouse',
  Keyboard = 'keyboard'
}

export interface ExtendedTableRow {
  item: any;
  indeterminate: boolean;
  loadingChildren: boolean;
  loadedChildren: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-table,mbs-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements OnInit, OnChanges {
  /**
   * The flat data is original copy from data in 1 level
   * @private
   */
  #flatData: any[];
  #selectedItems: any[] = [];
  #extendedData: ExtendedTableRow[];
  #selectable: boolean;
  #defaultHighlightClass = 'bg-blue-10';

  /**
   * Index of the last clicked row if MultipleSelectType.Keyboard is enabled and SHIFT is pressed
   * @private
   */
  private lastShiftSelectedIndex = 0;
  private openedCollapsibleItems: any[] = [];
  private requestedChildren: any[] = [];
  private myHeaderTemplates: TableHeaderNameDirective[] = [];

  public classTableGridObject: { [prefix: string]: boolean };
  public noDataMessageDefault = noDataMessageDefault;
  public cancelSelectionTextDefault = cancelSelectionTextDefault;
  public isTemplate = isTemplate;
  public parentScrollMode: 'basic' | 'virtual' | 'infinite' = 'basic';
  public myTemplates: TemplateRef<any>[] = [];
  public myChildrenTemplates: TemplateRef<any>[] = [];
  public rowStyles: {
    gridHeaderColumns: SafeStyle;
    gridTemplateColumns: SafeStyle;
  };
  public subtitleStyles: {
    gridTemplateColumns: SafeStyle;
  };
  public actualTableColumnsForStyles: TableColumn[] = [];

  @Input() headers: TableColumn[] = [];
  @Input() subtitleHeaders: TableColumn[] = this.headers;
  @Input() showHeaders = true;
  @Input() keepState = false;
  @Input() bordered = true;
  /**
   * predicate. If it returns `true` - table will not execute getItems for this root.
   *
   * If this root has children - arrow still will be shown
   */
  @Input() loadedChildren: (item: any) => boolean;
  @Input() bindChildren: string;
  @Input() showCheckboxes: boolean;
  @Input() showCheckboxesForChildren = true;
  @Input() noDataMessage: string | TemplateRef<any>;
  @Input() selectedCountText: string | TemplateRef<any>;
  @Input() selectAllButtonText: string;
  @Input() cancelSelectionText: string;
  @Input() collapsibleMode = false;
  @Input() needChildrenPaddingLeft = true;
  @Input() maxHeight: string;
  @Input() minHeight: string;
  @Input() stickyHeader = false;
  @Input() rotateSequence: { [key: string]: SortDirection } = rotate;

  /**
   * If true - select row only if click on checkbox
   */
  @Input() selectOnlyOnCheckboxClick: boolean;

  /** Infinite Scroll */
  @Input() infiniteScroll: boolean;
  @Input() infiniteScrollDistance: number;
  @Input() infiniteScrollThrottle: number;
  @Input() scrollWindow = false;

  @Input() infiniteScrollLoading: boolean;

  /**
   * Classes that will be added to opened row (parent, children and children headers).
   * if it's `true` - default class (`bg-blue-10`) will be used.
   */
  @Input() highlightOpened: boolean | string = false;

  /**
   * If true - getItems function will be called after click on row arrow in collapsible mode;
   */
  @Input() lazy = false;

  /**
   * Function that runs to load array of child elements. Only for `lazy` mode;
   * @argument parent - parent element for new children array.
   */
  @Input() getItems: (parent: any) => Observable<any[]>;

  /**
   * Key that allows to distinguish one parent from the other, like id, GUID and so on.
   * uses to save collapsing status on data update.
   */
  @Input() public bindParentKey: string;

  @Input() set templates(value: TemplateRef<any>[]) {
    this.myTemplates = value;
  }

  @Input() set childrenTemplates(value: TemplateRef<any>[]) {
    this.myChildrenTemplates = value;
  }

  @ViewChildren(TableCellDirective, { read: TableCellDirective })
  contentTemplates12;

  // will assign anyway
  @ContentChildren(TableCellDirective, {
    read: TableCellDirective,
    descendants: true
  })
  set contentTemplates(value: QueryList<TableCellDirective>) {
    const cells = value.toArray();
    const childTemplates = cells.filter((c) => c.group == 'child');
    this.myChildrenTemplates = childTemplates.length > 0 ? childTemplates.map((c) => c.template) : this.myChildrenTemplates;
    const templates = cells.filter((c) => isNil(c.group));
    this.myTemplates = templates.length > 0 ? templates.map((c) => c.template) : this.myTemplates;
  }

  @Input() set headerTemplates(obj: TableHeaderNameDirective[]) {
    this.myHeaderTemplates = obj || [];
  }

  @ContentChildren(TableHeaderNameDirective) set contentHeaderTemplates(value: QueryList<TableHeaderNameDirective>) {
    // don't overwrite if push from `@Input`
    if (value.length > 0) {
      this.myHeaderTemplates = value.toArray();
    }
  }

  @Input() changeSortState: SortEvent;
  @Input() striped = false;
  @Input() hover = true;
  @Input() loading = false;
  @Input() loaderType: BootstrapTheme = 'light';

  /**
   * Table size
   */
  @Input() size: MbsSize.sm | MbsSize.lg = null;
  /**
   * Custom class applied to mbs-table-grid
   */
  @Input() classesTable: string;

  /**
   * Key of `data` element for compare and select in `showTableCheckboxes` mode
   */
  @Input() bindSelected = 'id';

  /**
   *  * Key of `data` element for compare and select in `showTableCheckboxes` mode
   */
  @Input() bindSelectedChildren = 'id';

  /**
   * Table's height. By default, it has an auto height. If its value is a number, the height is measured in pixels; if its value is a string, the value will be assigned to element's style.height, the height is affected by external styles
   * @type {string | number}
   */
  @Input() height: string | number;
  @Input() staticHeightIfVoidData = false;

  @Input() getCustomRowClasses: (row: any) => string;
  @Input() rowClasses = '';
  @Input() checkboxCellClass: string;
  @Input() checkboxCellWidth = '50px';
  @Input() toggleCellWidth = '50px';
  @Input() needToggleOnEndRow = false;
  @Input() contentClass: string;
  /**
   * @Deprecated since shared 1.1.369
   * Need to use this property `selectable` instead of `isNeedSelectRow` since shared 1.1.369
   */
  @Input() isNeedSelectRow: boolean;

  @Input() set selectable(value: boolean) {
    this.#selectable = this.isNeedSelectRow || value;
  }

  get selectable(): boolean {
    return this.#selectable || this.isNeedSelectRow;
  }

  @Input() multipleSelect = false;
  @Input() showSelectAllCheckbox = true;
  @Input() multipleSelectType = MultipleSelectType.Mouse;
  /**
   * if `false` - ignore selecting parent rows. Collapsible mode only;
   */
  @Input() canSelectParent = true;

  @Input() selectRowClass = '-selected';
  @Input() selectAllIgnoreDisabled = false;
  @Input() arrayRowsWithClasses: ClassRowObject[] = [];
  @Input() bindKeyForRowClass = 'id';
  @Input() childHeaderClasses: string;
  @Input() childRowsClasses: string;

  /**
   * if true - shows headers in collapsed content. Collapsible mode only;
   */
  @Input() showChildrenHeaders = true;

  /**
   *  function used in every rows "for in".
   */
  @Input() myTrackBy: (index: number, item: any) => any;

  @Input() bindDisabledValues: { key: string; value: any };

  @Input() virtualScrolling = false;
  @Input() virtualItemSize = 50;
  @Input() virtualItemsNumber = 20;
  @Input() minVirtualScrollItemSize = 10;
  @Input() maxVirtualScrollItemSize = 40;
  @Input() virtualScrollItemSizeDivider = 10;
  @Input() minBufferPx = 1200;
  @Input() maxBufferPx = 1200;

  @Input() dependenceChildren = false;
  @Input() disableChildren = false;

  @Input() showSelectAllHint = false;
  @Input() totalItems: number;
  @Input() totalSelectedItems: number;
  @Input() entityName: string;

  @Output() sort: EventEmitter<SortEvent> = new EventEmitter<SortEvent>();
  @Output() clickTableElement: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectAllOnAllPages: EventEmitter<number> = new EventEmitter<number>();
  @Output() scrolled: EventEmitter<null> = new EventEmitter<null>();
  @Output() scroll: EventEmitter<Event> = new EventEmitter<Event>();

  /**
   * Set selected items. Main array (only flat) for selected parent/child items
   * @param {any[]} items
   */
  @Input() set selectedItems(items: any[]) {
    if (this.dependenceChildren && this.multipleSelect && !this.keepState) {
      this.setDependenceChildren(items);
    } else {
      this.#selectedItems = items ? Array.from(items) : [];
    }

    if (items?.length && this.isBindChildrenInMultipleSelect) {
      queueMicrotask(() => this.updateCheckboxesState());
    }
  }

  get isBindChildrenInMultipleSelect(): boolean {
    return Boolean(this.bindChildren && this.bindSelectedChildren && this.multipleSelect);
  }

  /**
   * Return all selected items
   */
  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get selectedItems(): any[] {
    return this.#selectedItems;
  }

  @Input() set data(data: any[]) {
    this.#extendedData = data.map((itemData: any) => {
      const tmp = {
        item: itemData,
        indeterminate: false,
        loadingChildren: false,
        loadedChildren: this.loadedChildren
          ? this.loadedChildren(itemData)
          : this.keepState
          ? this.requestedChildren.includes(itemData[this.bindParentKey])
          : false
      };

      if (this.lazy && this.keepState && this.requestedChildren.includes(itemData[this.bindParentKey])) {
        tmp.item[this.bindChildren] = this.#extendedData.find((el) => el.item[this.bindParentKey] === itemData[this.bindParentKey]).item[
          this.bindChildren
        ];
      }

      return tmp;
    });

    this.updateFlatData(this.#extendedData);
  }

  get data(): any[] {
    return this.#extendedData;
  }

  get isDisableHeaderCheckbox(): boolean {
    return (this.selectAllIgnoreDisabled && this.selectAllIsNotDisabled().length === 0) || this.data.length === 0;
  }

  @HostBinding('style.height') get getHeight() {
    if (this.data.length <= 0 && !this.staticHeightIfVoidData) {
      return 'auto';
    } else if (typeof this.height === 'number') {
      return `${this.height}px`;
    } else {
      return this.height;
    }
  }

  public get indeterminateMainCheckbox(): boolean {
    return (
      this.selectedItems.length > 0 &&
      this.selectedItems.length <
        this.#flatData.length -
          (this.bindDisabledValues && this.selectAllIgnoreDisabled
            ? this.#flatData.filter(
                (dataItem) => dataItem.item.disabled || dataItem.item[this.bindDisabledValues.key] === this.bindDisabledValues.value
              ).length
            : 0)
    );
  }

  constructor(private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (!(this.collapsibleMode || this.bindChildren) && this.virtualScrolling) {
      this.parentScrollMode = 'virtual';
    }

    if (!(this.collapsibleMode || this.bindChildren) && this.infiniteScroll) {
      this.parentScrollMode = 'infinite';
    }

    if (this.disableChildren) {
      this.data.forEach((dataItem) => {
        const disabled = !this.isSelectedRow(dataItem.item, true);

        dataItem[this.bindChildren].forEach((child) => (child.disabled = disabled));
      });
    }

    // init flatData
    this.updateFlatData(this.#extendedData);

    if (this.highlightOpened && this.collapsibleMode) {
      if (typeof this.highlightOpened === 'boolean') {
        this.childHeaderClasses += ' ' + this.#defaultHighlightClass;
      } else {
        this.childHeaderClasses += ' ' + this.highlightOpened;
      }
    }

    this.updateClassTableGridObject();
  }

  ngOnChanges(changes: OnChange): void {
    if (changes.data || changes.showCheckboxes || changes.headers) {
      this.generateActualTableColumnsForStyles();
      this.updateStylesAndHeaders();
    }

    if (changes.data || changes.showCheckboxes) {
      this.updateDataDependentOptions();

      if (this.isBindChildrenInMultipleSelect) {
        this.updateCheckboxesState();
      }
    }

    if (changes.striped || changes.hover || changes.bordered || changes.loading || changes.size) {
      this.updateClassTableGridObject();
    }
  }

  /**
   * Check disabling state for parent/child checkbox
   * @param {any} row
   * @return {boolean}
   */
  disabledStateByRow(row: any): boolean {
    if (this.bindDisabledValues) {
      return row[this.bindDisabledValues.key] === this.bindDisabledValues.value || row.disabled;
    }

    return row.disabled;
  }

  /**
   * Check indeterminate state for parent checkbox
   * @param {any} row
   * @return {boolean}
   */
  indeterminateState(row): boolean {
    if (this.dependenceChildren && row.item[this.bindChildren]) {
      return row.indeterminate;
    }

    return false;
  }

  updateDataDependentOptions(): void {
    if (this.keepState) {
      const bind = !isNil(this.bindParentKey) ? this.bindParentKey : this.bindSelected;

      if (this.dependenceChildren && this.multipleSelect) {
        this.setDependenceChildren(this.#selectedItems);
      }

      this.requestedChildren = this.requestedChildren.filter((requested) => !!this.data.find((item) => item.item[bind] === requested));
      this.openedCollapsibleItems = this.openedCollapsibleItems.filter((opened) => !!this.data.find((item) => item.item[bind] === opened));
    } else {
      this.#selectedItems = [];
      this.requestedChildren = [];
      this.openedCollapsibleItems = [];
    }

    this.changeSelected.emit(this.selectedItems);
  }

  updateCheckboxesState(): void {
    this.#extendedData = this.#extendedData.map((element: ExtendedTableRow) => {
      if (element.item[this.bindChildren]?.length) {
        const someSelected = element.item[this.bindChildren]?.some((child) => this.isSelectedRow(child, false));
        const someNoteSelected = element.item[this.bindChildren]?.some((child) => !this.isSelectedRow(child, false));

        element.indeterminate = someSelected && someNoteSelected;
      }

      return element;
    });

    this.cdr.detectChanges();
  }

  /**
   * Mark parent children by adding them to selectedItems array
   * @param {any[]} items
   * @private
   */
  private setDependenceChildren(items: any[]): void {
    const tmp = [];

    items.forEach((item) => {
      tmp.push(item);

      if (!isNil(item[this.bindChildren])) {
        const childItems = item[this.bindChildren];

        childItems.forEach((child) => {
          const some = items.some((childItem) => childItem[this.bindSelectedChildren] === child[this.bindSelectedChildren]);

          if (!some) {
            tmp.push(child);
          }
        });
      }
    });

    this.#selectedItems = Array.from(tmp);
  }

  updateStylesAndHeaders(): void {
    let styles: string[] = [];
    let headerStyles: string[] = [];

    if (this.showCheckboxes) {
      styles = [this.checkboxCellWidth];
      headerStyles = [this.checkboxCellWidth];
    }

    if (this.collapsibleMode && !this.needToggleOnEndRow) {
      styles = [...styles, this.toggleCellWidth];
      headerStyles = [...headerStyles, this.toggleCellWidth];
    }

    let style = styles.concat(this.getStyles(this.headers || [])).join(' ');
    let headerStyle = headerStyles.concat(this.getHeaderStyles(this.headers || [])).join(' ');

    if (this.collapsibleMode && this.needToggleOnEndRow) {
      style = `${style} ${this.toggleCellWidth}`;
      headerStyle = `${headerStyles} ${this.toggleCellWidth}`;
    }

    if (this.subtitleHeaders) {
      let subtitleStyle = this.subtitleHeaders.map((h) => `minmax(${h.gridColMin || '1px'}, ${h.gridColSize || '15fr'})`).join(' ');

      if (this.showCheckboxes && this.showCheckboxesForChildren) {
        const checkboxStyles = `minmax(${this.checkboxCellWidth},${this.checkboxCellWidth}) `;

        subtitleStyle = checkboxStyles.concat(' ', subtitleStyle);
      }

      this.subtitleStyles = {
        gridTemplateColumns: this.sanitizer.bypassSecurityTrustStyle(subtitleStyle)
      };
    }

    this.rowStyles = {
      gridTemplateColumns: this.sanitizer.bypassSecurityTrustStyle(style),
      gridHeaderColumns: this.sanitizer.bypassSecurityTrustStyle(headerStyle)
    };
  }

  /**
   * Get all list classes for row
   * @param {any} row
   * @param {boolean} isParent
   * @return {string}
   */
  getClassListForRow(row, isParent: boolean): string {
    let rowClass = this.getRowClass(row, isParent);

    if (this.highlightOpened && this.collapsibleMode && !this.isCollapsed(row)) {
      if (typeof this.highlightOpened === 'boolean') {
        rowClass += ' ' + this.#defaultHighlightClass;
      } else {
        rowClass += ' ' + this.highlightOpened;
      }
    }

    if (this.selectable && this.isSelectedRow(row, isParent)) {
      rowClass += ' ' + this.selectRowClass;
    }

    return rowClass;
  }

  /**
   * Check if there is a parent / child row in the selectedItems array
   * @param {any} row
   * @param {boolean} isParent
   * @return {boolean}
   */
  private isSelectedRow(row: any, isParent: boolean): boolean {
    if (this.selectedItems.length === 0 || isNil(row)) return false;

    const compareKey = isParent ? this.bindParentKey || this.bindSelected : this.bindSelectedChildren;

    return this.selectedItems.some((item) => item[compareKey] === row[compareKey]);
  }

  /**
   * Get classes for row
   * @param {any} row
   * @param {boolean} isParent
   * @return {string}
   */
  getRowClass(row, isParent: boolean): string {
    let rowClass = isParent ? this.rowClasses : '';

    if (this.arrayRowsWithClasses.length) {
      const index = this.arrayRowsWithClasses.findIndex(
        (item) => item[this.bindKeyForRowClass] === row[this.bindKeyForRowClass].toString()
      );

      if (!isParent && this.childRowsClasses) rowClass += ` ${this.childRowsClasses}`;

      if (index >= 0) {
        if (isParent) rowClass += ` ${this.arrayRowsWithClasses[index].class}`;
        else if (this.arrayRowsWithClasses[index].childRowsClass) rowClass += ` ${this.arrayRowsWithClasses[index].childRowsClass}`;
      }
    }

    if (this.disabledStateByRow(row)) {
      rowClass += ' -disabled';
    }

    return rowClass + ' ' + (typeof this.getCustomRowClasses === 'function' && this.getCustomRowClasses(row));
  }

  /**
   * Updating the main array of selected rows
   * @param {row} row to add/remove from the array `selectedItems`
   * @param {state} state for selected/unselected row
   * @param {boolean} isParent click target is parent if true
   * @private
   */
  private updateSelectedItems(row: any, state: boolean, isParent: boolean): void {
    let clone = [...this.selectedItems];

    if (!this.multipleSelect) clone = []; // for Single Selected Mode

    const selectedKey = isParent ? this.bindParentKey || this.bindSelected : this.bindSelectedChildren || this.bindSelected;
    const index = clone.findIndex((item) => item[selectedKey] === row[selectedKey]);

    if (state) {
      if (index === -1) clone.push(row);
    } else {
      if (index >= 0) clone.splice(index, 1);
    }

    this.#selectedItems = clone;
    this.selectAllOnAllPages.emit(this.#selectedItems.length);
  }

  /**
   * Do some actions if click/check on row/checkbox
   * @param {ExtendedTableRow} row Click was on this element
   * @param {boolean} isParent Click was on parent/child row
   * @param {MouseEvent} event Mouse click event. If event is `null`, then click on checkbox, else it's click on row
   */
  handleRowClick(row: ExtendedTableRow, isParent: boolean, event: MouseEvent): void {
    const _row = isParent ? row.item : row;
    const isCheckboxCell =
      (event?.target as HTMLElement)?.classList.contains('mbs-table-grid_checkbox') ||
      (event?.target as HTMLElement)?.classList.contains('mbs-table-grid_checkbox-cell');

    this.clickTableElement.emit(_row);

    if (this.showCheckboxes && !isCheckboxCell) return;

    if (this.disabledStateByRow(_row) || (this.collapsibleMode && !this.showCheckboxes) || (isParent && !this.canSelectParent)) {
      return;
    }

    const buttonParent = (event.target as Element).closest('button');
    const linkParent = isNil(buttonParent) && (event.target as Element).closest('a');
    const switcherParent = isNil(linkParent) && isNil(buttonParent) && (event.target as Element).closest('.mbs-switcher');

    if (!isNil(buttonParent) || !isNil(linkParent) || !isNil(switcherParent)) {
      event.stopPropagation();
      return;
    }

    if ((this.showCheckboxes || this.selectable) && !this.disabledStateByRow(_row)) {
      const oldState = this.isSelectedRow(_row, isParent);

      if (this.multipleSelectType == MultipleSelectType.Keyboard) {
        this.updateShiftSelectedRows(_row, event, oldState, isParent);
      }

      if (this.multipleSelectType == MultipleSelectType.Mouse) {
        this.updateSelectedItems(_row, !oldState, isParent);

        // nothing any do if `multipleSelect` is false
        if (!this.multipleSelect) {
          this.changeSelected.emit(this.selectedItems);
          return;
        }

        // click on parent row
        if (isParent) {
          // dependent children
          if (this.dependenceChildren) {
            if (!oldState) row.indeterminate = false;
            _row[this.bindChildren] && _row[this.bindChildren].forEach((child) => this.updateSelectedItems(child, !oldState, false));
            row.indeterminate = _row[this.bindChildren] && !this.isSelectedRow(_row, isParent) && !oldState;
          }
          // independent children
          else {
            this.updateSelectedItems(_row, !oldState, isParent);
          }
        }
        // click on child row
        else {
          const parent = this.getParent(_row);

          // dependent children
          if (this.dependenceChildren) {
            const firstState = this.isSelectedRow(parent.item[this.bindChildren][0], false);
            const sameState = !parent.item[this.bindChildren].some((child) => this.isSelectedRow(child, false) !== firstState);

            if (sameState) {
              parent.indeterminate = false;
              this.updateSelectedItems(parent.item, firstState, true);
            } else {
              parent.indeterminate = true;
            }
          }
        }
      }

      // disabled children
      if (this.disableChildren) {
        const parent = this.getParent(_row);

        parent[this.bindChildren] && parent[this.bindChildren].forEach((child) => (child.disabled = !this.isSelectedRow(parent, true)));
      }
    }

    this.changeSelected.emit(this.selectedItems);
  }

  /**
   * Update selectable rows if use MultipleSelectType.Keyboard mode
   * @param row
   * @param event
   * @param oldState
   * @private
   */

  private updateShiftSelectedRows(row, event: MouseEvent, oldState: boolean, isParent = false): void {
    const isMac = navigator.platform.startsWith('Mac');
    const ctrlOrCmd = event.ctrlKey || (isMac && event.metaKey);

    if (!ctrlOrCmd && !event.shiftKey) {
      this.#selectedItems = [];
      this.updateSelectedItems(row, !oldState, isParent);
    } else if (event.shiftKey && this.selectedItems.length !== 0) {
      const currentClickIndex = this.#flatData.findIndex((dataItem) => dataItem.item[this.bindSelected] === row[this.bindSelected]);
      let start: number = null;
      let end: number = null;

      if (currentClickIndex > this.lastShiftSelectedIndex) {
        start = this.lastShiftSelectedIndex;
        end = currentClickIndex;
      } else if (currentClickIndex <= this.lastShiftSelectedIndex) {
        start = currentClickIndex;
        end = this.lastShiftSelectedIndex;
      }

      this.#selectedItems = [];
      this.#selectedItems = Array.from(this.getOriginalFlatData(this.#flatData).slice(start, end + 1));
    } else {
      this.updateSelectedItems(row, !oldState, isParent);
    }

    // must be after all updateSelectedItems()
    if (this.selectedItems.length === 1) {
      this.lastShiftSelectedIndex = this.#flatData.findIndex((dataItem) => dataItem.item[this.bindSelected] === row[this.bindSelected]);
    }
  }

  /**
   * Get parent elements of child
   * @param {any} row
   * @return {any}
   * @private
   */
  private getParent(row: any) {
    const isParent = this.data.some((dataItem) => dataItem.item[this.bindSelected] === row[this.bindSelected]);

    if (isParent) {
      return row;
    } else {
      return this.data.find((dataItem) => {
        return (
          !isNil(dataItem.item[this.bindChildren]) &&
          dataItem.item[this.bindChildren].some((child) => child[this.bindSelectedChildren] === row[this.bindSelectedChildren])
        );
      });
    }
  }

  generateActualTableColumnsForStyles(): void {
    this.actualTableColumnsForStyles = this.actualTableColumnsForStyles.length > 0 ? [] : this.actualTableColumnsForStyles;
    this.headers.forEach((header: TableColumn) => {
      if (header.colSpan) {
        header.colSpan.forEach((item: string, idx: number) => {
          this.actualTableColumnsForStyles.push({
            name: header.name,
            class: Array.isArray(header.class) ? header.class[idx] : header.class,
            overflow: header.overflow
          });
        });
      } else {
        this.actualTableColumnsForStyles.push({
          name: header.name,
          class: header.class,
          overflow: header.overflow
        });
      }
    });
  }

  updateClassTableGridObject(): void {
    const result = {
      '-striped': this.striped,
      '-hover': this.hover,
      '-bordered': this.bordered,
      '-loading': this.loading,
      '-sm': this.size === 'sm',
      '-lg': this.size === 'lg'
    };

    if (!isEqual(this.classTableGridObject, result)) {
      this.classTableGridObject = result;
    }
  }

  getStyles(h: TableColumn[]): string[] {
    const result = [];

    for (let i = 0; i < h.length; i++) {
      let colspan = h[i].colSpan ? h[i].colSpan.length : 0;

      if (colspan) {
        let j = i;

        while (colspan > 0) {
          const size = h[i].colSpan ? h[i].colSpan[j - i] : '15fr';

          result.push(`minmax(1px, ${size})`);
          colspan--;
          j++;
        }
      } else {
        result.push(`minmax(${h[i].gridColMin || '1px'}, ${h[i].gridColSize || '15fr'})`);
      }
    }

    return result;
  }

  getHeaderStyles(h: TableColumn[]): string[] {
    const result = [];

    if (h.some((col) => col.colSpan && col.colSpan.some((size) => size.includes('fr')))) {
      console.error('do not use FRs in colSpan. It is not working properly');
      return [];
    }

    for (let i = 0; i < h.length; i++) {
      const sizes = h[i].colSpan;

      if (sizes && sizes.length) {
        let colspan = sizes.length;
        let j = i;
        let tmp = `minmax(${h[i].gridColMin || '1px'}, calc( `;

        while (colspan > 0) {
          tmp += sizes[j - i];
          colspan--;
          tmp = colspan ? `${tmp} + ` : `${tmp} ))`;
          j++;
        }
        result.push(tmp);
      } else {
        result.push(`minmax(${h[i].gridColMin || '1px'}, ${h[i].gridColSize || '15fr'})`);
      }
    }

    return result;
  }

  handleSort(event: SortEvent): void {
    this.sort.emit(event);
  }

  private classToObject(column: TableColumn): any {
    return column ? { 'text-overflow': column.overflow } : {};
  }

  isTextMuted(columns: TableColumn[], i: number): boolean {
    const obj = this.columnClassToObject(columns, i);

    return obj && !isNil(obj['text-muted']) && obj['text-muted'];
  }

  columnClassToObject(columns: TableColumn[], i: number): any {
    if (columns && columns[i]) {
      const column = columns[i];
      const classes = this.classToObject(column);

      if (column && column.class) {
        const rawClass = Array.isArray(column.class) ? column.class[0] : column.class;

        rawClass.split(' ').forEach((item) => {
          classes[item] = true;
        });
      }

      return classes;
    }
  }

  headerClassToObject(column: TableColumn): any {
    if (column && column.headerClass) {
      const headerClass = this.classToObject(column);

      headerClass[column.headerClass] = true;

      return headerClass;
    } else {
      return this.columnClassToObject([column], 0);
    }
  }

  findHeaderTemplate(column: TableColumn): TemplateRef<any> {
    const content = this.myHeaderTemplates.find((i) => i.value === column.headerName || i.value === column.name);

    if (content) {
      return content.template;
    }

    return null;
  }

  get allChecked(): boolean {
    if (this.selectAllIgnoreDisabled) {
      const notDisabledElements = this.selectAllIsNotDisabled();

      return this.selectedItems.length > 0 && notDisabledElements.length === this.selectedItems.length;
    }

    return this.selectedItems.length > 0 && this.#flatData.length === this.selectedItems.length;
  }

  set allChecked(isChecked: boolean) {
    this.selectAll(isChecked);
    this.changeSelected.emit(this.selectedItems);
  }

  /**
   * Select/unselect all rows
   * @param {boolean} isChecked
   */
  selectAll(isChecked: boolean): void {
    if (this.selectAllIgnoreDisabled) {
      this.#selectedItems = isChecked ? this.getOriginalFlatData(this.selectAllIsNotDisabled()) : [];
    } else {
      this.#selectedItems = isChecked ? Array.from(this.getOriginalFlatData(this.#flatData)) : [];
      this.selectAllOnAllPages.emit(this.#selectedItems.length);
    }
  }

  public clearSelection() {
    this.changeSelected.emit([]);
    this.selectAllOnAllPages.emit(0);
  }

  public selectAllOnAllPagesHandle() {
    this.selectAll(true);
    this.selectAllOnAllPages.emit(this.totalItems);
  }

  public getSelectAllButtonTextDefault(): string {
    return this.entityName ? `Select all ${this.entityName}: ${this.totalItems}` : `Select all: ${this.totalItems}`;
  }

  public getSelectedCountTextDefault(): string {
    return `Selected ${this.totalSelectedItems || this.selectedItems?.length} ${this.entityName || ''} ${
      this.isSelectedAllOnAllPages() ? ' on all pages.' : ' on this page.'
    }`;
  }

  private isSelectedAllOnAllPages(): boolean {
    return this.totalSelectedItems === this.totalItems;
  }

  private getOriginalFlatData(flatData: any): any[] {
    return flatData.map((item) => (item.item ? item.item : item));
  }

  selectAllIsNotDisabled(): any[] {
    return this.bindDisabledValues
      ? this.#flatData.filter(
          (dataItem) => dataItem.item[this.bindDisabledValues.key] !== this.bindDisabledValues.value && !dataItem.item.disabled
        )
      : this.#flatData.filter((dataItem) => !dataItem.item.disabled);
  }

  /**
   * Checking status checkbox relative to whether item is in main selectedItems array (used in view)
   * @param {any} row
   * @param {boolean} isParent
   * @return {boolean}
   */
  isChecked(row, isParent: boolean): boolean {
    if (this.selectedItems.length === 0 || !row) return false;

    return this.isSelectedRow(isParent ? row.item : row, isParent);
  }

  noDataIsTemplate(): boolean {
    return isTemplate(this.noDataMessage);
  }

  handleToggleCollapse(event, row): void {
    event && event.stopPropagation();
    const openedItemIndex = this.openedCollapsibleItems.indexOf(row.item[this.bindParentKey]);

    if (openedItemIndex >= 0) {
      this.openedCollapsibleItems.splice(openedItemIndex, 1);
    } else {
      if (this.lazy && !row.loadedChildren) {
        row.loadingChildren = true;

        this.getItems(row.item)
          .pipe(
            filter((children) => !isNil(children)),
            untilDestroyed(this)
          )
          .subscribe((children) => {
            row.item[this.bindChildren] = children;
            row.loadingChildren = false;
            row.loadedChildren = true;

            if (row.item[this.bindChildren] && row.item[this.bindChildren].length) {
              this.openedCollapsibleItems.push(row.item[this.bindParentKey]);
            }

            this.requestedChildren.push(row.item[this.bindParentKey]);
            this.cdr.detectChanges();
          });
      } else {
        this.openedCollapsibleItems.push(row.item[this.bindParentKey]);
      }
    }
  }

  /**
   * Checking is collapsed children elements
   * @param {any} row
   * @return {boolean}
   */
  isCollapsed(row): boolean {
    const parent = this.getParent(row);
    const _parent = parent.item ? parent.item : parent;

    return !this.openedCollapsibleItems.includes(_parent[this.bindParentKey]);
  }

  hasData(): boolean {
    return this.data && this.data.length > 0;
  }

  // Custom dynamic item size for cdk-virtual-scroll-viewport component for virual scroll mode
  get virtualScrollItemSize() {
    const value = this.data.length / this.virtualScrollItemSizeDivider;

    if (value < this.minVirtualScrollItemSize) return this.minVirtualScrollItemSize;

    if (value > this.maxVirtualScrollItemSize) return this.maxVirtualScrollItemSize;

    return value;
  }

  get virtualScrollViewportSize(): string {
    const height = this.maxHeight ?? this.height;

    return height ? `calc(${height} - ${this.virtualItemSize}px` : `${this.virtualItemSize * this.virtualItemsNumber}px`;
  }

  /**
   * Generate flat data from data
   * @param {any[]} data
   */
  updateFlatData(data: any[]): void {
    this.#flatData = [];

    data.forEach((dataItem) => {
      this.#flatData.push(dataItem);

      if (dataItem.item && dataItem.item[this.bindChildren]) {
        dataItem.item[this.bindChildren].forEach((child) => this.#flatData.push(child));
      }
    });
  }

  getCollapsableModeVSContainerHeight(rowCount) {
    return (rowCount >= this.virtualItemsNumber ? this.virtualItemsNumber * this.virtualItemSize : rowCount * this.virtualItemSize) + 'px';
  }
}
