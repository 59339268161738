<div class="mbs-wizard_form">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{ 'wizards:appAwareTitle' | i18next: { format: 'title', type: planTypeText } }}
      <mbs-tag [clickable]="false"
               [closable]="false"
               [active]="true"
               [noBorder]="true"
               [class]="'background-primary mt-n1'">
        {{'app:notifications:beta' | i18next: { format: 'title', type: planTypeText } }}
      </mbs-tag>
    </h4>
  </header>

  <div class="mbs-wizard_form-content">
    <div class="pb-3">
      <div *ngIf="isVMWare"
           [id]="elementSelectors.id.formDescriptionVMWare"
           class="mbs-wizard_form-description">
        {{ 'wizards:appAwareSubtitleVMWare' | i18next }}
      </div>
      <div *ngIf="isHyperV"
           [id]="elementSelectors.id.formDescriptionHyperV"
           class="mbs-wizard_form-description">
        {{ 'wizards:appAwareSubtitleHyperV' | i18next }}
      </div>
    </div>

    <mbs-alert class="mt-3 text-sm"
               [type]="mbsPopupType.info"
               [icon]="true">
      {{'wizards:appAwareInfo' | i18next}}
      <a *ngIf="canReadHelpMarketing"
         [id]="elementSelectors.id.infoPanel"
         href="/AP/Help/backup/about/application-processing"
         target="_blank">
        {{'wizards:learn_more_link' | i18next}}...
      </a>
    </mbs-alert>

    <mbs-advanced-search [id]="elementSelectors.id.searchString"
                         [(ngModel)]="searchModel"
                         [data]="[]"
                         [placeholder]="'wizards:appAwareSearchPlaceholder' | i18next"
                         [accessUrlQuery]="false"
                         (ngModelChange)="searchModelChange($event)">
    </mbs-advanced-search>

    <mbs-form-group>
      <mbs-table-grid class="mt-3"
                      [id]="elementSelectors.id.tableGrid"
                      [headers]="headers"
                      [data]="tableData"
                      [bindSelected]="'id'"
                      [isNeedSelectRow]="false"
                      [keepState]="true">
        <ng-template mbsTableCell let-machine>
          <div class="form-row h-100 align-items-center {{elementSelectors.css.tableGridCell(elementSelectors.css.tableGridNameCell, machine.virtualMachineId)}}">
            <div class="col overflow-hidden">
              <mbs-text-ellipsis [tooltip]="machine.VMName">
                {{ machine.VMName }}
              </mbs-text-ellipsis>
            </div>
            <div *ngIf="machine.edited" class="col-auto">
              <div class="fa fa-pencil text-info" [class]="elementSelectors.css.editedIcon"></div>
            </div>
          </div>
        </ng-template>
        <ng-template mbsTableCell let-machine>
          <div class="{{elementSelectors.css.tableGridCell(elementSelectors.css.tableGridPMCell, machine.virtualMachineId)}}">
            <mbs-button type="link"
                        [class]="elementSelectors.css.processingModeButton(null)"
                        [isCtrl]="true"
                        [customClasses]="'text-dark'">
              {{ processingValues[machine.processingMode] }}
              <ng-template mbsButtonDropdown>
                <button *ngFor="let value of processingValues; let i = index"
                        [class]="elementSelectors.css.processingModeButton(i)"
                        ngbDropdownItem
                        (click)="setProcessingMode(machine, i)">
                  {{ value }}
                </button>
              </ng-template>
            </mbs-button>
          </div>
        </ng-template>
        <ng-template mbsTableHeader="ProcessingMode" let-column>
          <div class="d-flex align-items-center flex-nowrap">
            <div>{{'wizards:appAwareTableViewHeaderPM' | i18next}}</div>
            <div class="cursor-help fa fa-info-circle text-info ml-2 mr-3"
                 tooltipClass="tooltip-lg"
                 [container]="'body'"
                 [ngbTooltip]="processingModeInfoTooltip"></div>
          </div>
        </ng-template>
      </mbs-table-grid>
    </mbs-form-group>
  </div>
</div>

<ng-template #processingModeInfoTooltip>
  <div class="text-left">
    <span *ngFor="let item of processingModeInfo;let last = last; let i = index" class="text-sm">
      <strong *ngIf="i % 2 === 0">{{item}}&nbsp;</strong>
      <span *ngIf="i % 2 === 1">{{item}}</span>
      <br *ngIf="i === 5">
      <br *ngIf="i % 2 === 1 && !last">
  </span>
  </div>
</ng-template>
