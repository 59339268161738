import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PermanentTooltipDirective, PermanentTooltipInternalComponent } from './directives/permanent-tooltip.directive';
import { ButtonModule } from '../button';

@NgModule({
  imports: [CommonModule, NgbModule, NgbTooltipModule, ButtonModule],
  exports: [PermanentTooltipDirective],
  declarations: [PermanentTooltipDirective, PermanentTooltipInternalComponent]
})
export class MbsDirectivesModule {}
