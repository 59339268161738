import { RoutingPath } from '@mbs-ui/app/app-routing-path.enum';
import { PagedResponse, QueryPagingParams, QuerySortParams } from '@models/Paging';
import { getOfflineDuration } from '@utils/computer-utils';
import {
  UnixAgentVersionSupportsOfflineLicenseManagement,
  WindowsAgentVersionSupportsOfflineLicenseManagement
} from '@utils/constants/agent-versions';
import { I18NextPipe } from 'angular-i18next';
import { BuildType, getBuildTypeKeysByValues } from './BuildType.enum';
import Company from './Company';
import { ComputerLicenseStatus } from './License';
import { LicenseType } from './LicenseType.enum';
import { ComputerPlanInfo } from './backup/plan-info-model';
import { SidepanelRouteType } from './backup/sidepanel-route-type';
import { MBSEntities } from './entity-decorator';
import { BackupLicense } from './licenses/BackupLicense';
import HostInfo from './rmm/HostInfo';

export enum OsType {
  desktop = 'desktop',
  apple = 'apple',
  linux = 'linux',
  dropbox = 'dropbox',
  gear = 'gear',
  server = 'server',
  android = 'android',
  hdd_o = 'hdd-o',
  windows = 'windows'
}

export enum Platform {
  Desktop,
  Server
}

export enum DisplayOsType {
  apple = 'macOS',
  linux = 'linux',
  windows = 'windows'
}

export const OSTypeAdapter: { os: OsType; displayOS: DisplayOsType }[] = [
  { os: OsType.windows, displayOS: DisplayOsType.windows },
  { os: OsType.linux, displayOS: DisplayOsType.linux },
  { os: OsType.apple, displayOS: DisplayOsType.apple }
];

export enum AuthStatus {
  All = 'all',
  Pending = 'pending',
  Authorized = 'authorized'
}

export type ComputerPlanStatistic = {
  totalBackupError: number;
  totalBackupOverdue: number;
  totalBackupPending: number;
  totalBackupSuccess: number;
  totalBackupWarning: number;
  totalPlans: number;
  totalRestoreError: number;
  totalRestoreOverdue: number;
  totalRestorePending: number;
  totalRestoreSuccess: number;
  totalRestoreWarning: number;
  totalUndefinedPlanError: number;
  totalUndefinedPlanOverdue: number;
  totalUndefinedPlanPending: number;
  totalUndefinedPlanWarning: number;
};

export const minimalWindowsVersionForOfflineEdit = 793000;

@MBSEntities.register()
export default class Computer {
  id?: string;
  hid?: string;
  name?: string;
  displayName?: string;
  company?: Company;
  displayVersion?: string;
  offlineDuration?: number;
  hidden?: boolean;
  /**
   * @deprecated
   */
  profileName?: string;

  userAccount?: {
    id?: string;
    accountName?: string;
    firstName?: string;
    lastName?: string;
  };

  os?: OsType;
  osType?: Platform;
  instanceType?: VMDetectorInstance;

  apps?: Agent[];
  ComputerName?: Computer['name'];
  IsOffline?: boolean | 'True' | 'False';
  /**
   * @deprecated use version from apps
   */
  Version?: string;

  plans?: ComputerPlanInfo[];
  hostInfo?: HostInfo;

  backupLicense?: BackupLicense;

  online?: boolean;

  lostAuth?: boolean;
  ipAddress?: string;

  healthState?: string;
  healthStateTime?: string; // dateTime
  tags?: ComputerTagDto[];
  computerTag?: string;
  computerName?: string;
  authStatus?: AuthStatus;
  socketsNumber?: number;
  timeZoneOffset?: number;
  planStatistic?: ComputerPlanStatistic;

  constructor(init?: Partial<Computer>) {
    Object.assign(this, {}, init);
  }

  static isDisplayNameExist(computer: Computer): boolean {
    return !!computer?.displayName || false;
  }

  static getComputerName(computer: Computer) {
    return Computer.isDisplayNameExist(computer) ? `${computer.displayName} [${computer.name}]` : computer?.name || '';
  }

  static getComputerStatusText(online: boolean, i18nPipe: I18NextPipe): string {
    return i18nPipe.transform(`computers.module:${online ? 'online' : 'offline'}`, { format: 'title' });
  }

  static getComputerStatusDurationText(computer: Computer, i18nPipe: I18NextPipe, delimiter = ':'): string {
    const status = Computer.getComputerStatusText(computer?.online, i18nPipe);
    const duration = computer?.offlineDuration ? `${delimiter} ${getOfflineDuration(computer.offlineDuration)}` : '';
    return `${status}${duration}`;
  }

  static getAgent(computer: Computer, agent: AgentType): Agent {
    return computer?.apps?.find((app) => app.applicationId === agent) ?? null;
  }
  static hasAgent(computer: Computer, agent: AgentType): boolean {
    return Boolean(Computer.getAgent(computer, agent));
  }

  static hasAgents(computer: Computer, agents: AgentType[]): boolean {
    return agents.every((agent) => Computer.hasAgent(computer, agent));
  }

  static isAgentOnline(computer: Computer, agent: AgentType): boolean {
    return computer?.apps?.find((app) => app.applicationId === agent)?.online ?? false;
  }

  static isUltimateOrSQLServer(computer: Computer): boolean {
    return (computer?.apps || [])
      .map((app: Agent) => app.buildType)
      .some((appBuildType) => getBuildTypeKeysByValues([BuildType.SqlServer, BuildType.Ultimate]).includes(appBuildType));
  }

  static isUltimateOrAnySQL(computer: Computer, agent: AgentType = AgentType.Backup): boolean {
    return getBuildTypeKeysByValues([BuildType.SqlServer, BuildType.SqlExchange, BuildType.Ultimate]).includes(
      Computer.getAgent(computer, agent)?.buildType
    );
  }

  static hasOsType(computer: Computer, osTypes: OsType[]): boolean {
    return osTypes.includes(computer?.os);
  }

  static isWindows(computer: Computer): boolean {
    return Computer.hasOsType(computer, [OsType.windows]);
  }

  static isLinux(computer: Computer): boolean {
    return Computer.hasOsType(computer, [OsType.linux]);
  }

  static isServer(computer: Computer): boolean {
    return computer.osType === Platform.Server;
  }

  static isMac(computer: Computer): boolean {
    return Computer.hasOsType(computer, [OsType.apple]);
  }

  static isMacOrLinux(computer: Computer): boolean {
    return Computer.hasOsType(computer, [OsType.linux, OsType.apple]);
  }

  static getAgentVersion(computer: Computer, agentType: AgentType, needFull = false): number {
    const versionString = Computer.getAgent(computer, agentType)?.version?.split('.').join('');
    let version = versionString?.substring(0, 3);

    if (needFull && versionString) {
      let floatSlice = versionString.substring(3, 6);

      if (floatSlice.length === 1) floatSlice = `00${floatSlice}`;
      if (floatSlice.length === 2) floatSlice = `0${floatSlice}`;

      version += `${floatSlice}`;
    }

    return +version || 0;
  }

  static getAgentVersionStatus(computer: Computer, agentType: AgentType): AgentVersionStatus {
    return Computer.getAgent(computer, agentType)?.versionStatus;
  }

  static getFirstBuildTypeValue(computer: Computer, agentTypes: AgentType[]): BuildType {
    const buildTypeName = computer?.apps?.filter((app) => agentTypes.includes(app.applicationId))?.find(Boolean)?.buildType;
    return buildTypeName ? BuildType[buildTypeName] : null;
  }

  static IsSupportedAgentVersion(computer: Computer, agentType: AgentType, requiredVersion: number, needFull = false): boolean {
    return Computer.getAgentVersion(computer, agentType, needFull) >= requiredVersion;
  }

  static IsUnsupportedAgentVersion(computer: Computer, agentType: AgentType, requiredVersion: number, needFull = false): boolean {
    return Computer.hasAgent(computer, agentType) && Computer.getAgentVersion(computer, agentType, needFull) <= requiredVersion;
  }

  static getLicenseName(computer: Computer): string {
    return computer?.backupLicense
      ? Object.keys(LicenseType)[Object.values(LicenseType).indexOf(computer.backupLicense?.licenseType)]
      : null;
  }

  static isExpiredLicense(computer: Computer): boolean {
    const expiredDate = computer?.backupLicense?.dateExpired;

    return expiredDate && new Date().getTime() > new Date(expiredDate).getTime();
  }

  static getLicenseStatus(computer: Computer, isIgnoredExpiredStatus = false): ComputerLicenseStatus {
    const license = computer?.backupLicense;

    if (!license) {
      return null;
    }

    if (Computer.isExpiredLicense(computer) && !isIgnoredExpiredStatus) {
      return ComputerLicenseStatus.EXPIRED;
    }

    return license.isTrial ? ComputerLicenseStatus.TRIAL : ComputerLicenseStatus.PAID;
  }

  static getLicenseStatusTitle(data: Computer | ComputerLicenseStatus, isIgnoredExpiredStatus = false): string {
    const status = typeof data === 'object' ? Computer.getLicenseStatus(data, isIgnoredExpiredStatus) : data;

    const titles = {
      [ComputerLicenseStatus.TRIAL]: 'computers.module:license.status.trial',
      [ComputerLicenseStatus.PAID]: 'computers.module:license.status.paid',
      [ComputerLicenseStatus.EXPIRED]: 'computers.module:license.status.expired'
    };

    return titles[status] || 'computers.module:license.status.noLicense';
  }

  static getBackupInfo(computer: Computer, i18nPipe: I18NextPipe): string {
    const version = Computer.getBackupVersion(computer);

    if (!version) {
      return null;
    }

    return `${i18nPipe.transform(Computer.getBackupDestination(computer))}, v${version}`;
  }

  static getLicenseInfo(computer: Computer, i18nPipe: I18NextPipe, licenseName?: string, isIgnoredExpiredStatus = false) {
    const licenseStatusTitle = i18nPipe.transform(Computer.getLicenseStatusTitle(computer, isIgnoredExpiredStatus));

    return `${
      computer.backupLicense ? (licenseName || Computer.getLicenseName(computer)) + ' license. ' + licenseStatusTitle : licenseStatusTitle
    }.`;
  }

  static isBackupOnline(computer: Computer): boolean {
    return Computer.isAgentOnline(computer, AgentType.Backup);
  }

  static getBackupVersion(computer: Computer): string {
    return Computer.getAgent(computer, AgentType.Backup)?.version;
  }

  static getBackupShortenedVersion(computer: Computer): string {
    const backupVersion = Computer.getBackupVersion(computer);

    return backupVersion ? `v${backupVersion.split('.', 2).join('.')}` : null;
  }

  static getBackupDestination(computer: Computer): string {
    if (Computer.isLinux(computer)) return 'backup:backups:forLinux';
    if (Computer.isWindows(computer)) return 'backup:backups:forWindows';
    if (Computer.isMac(computer)) return 'backup:backups:forMac';
    return 'backup:backups:forVM';
  }

  static isPending(computer: Computer): boolean {
    return computer?.authStatus === AuthStatus.Pending;
  }

  static isDeepInstComputer(computer: Computer): boolean {
    return computer?.hid.startsWith('DeepInstID-') ?? false;
  }

  static isAgentSupportOfflineLicenseManagement(computer: Computer): boolean {
    return Computer.IsSupportedAgentVersion(
      computer,
      AgentType.Backup,
      Computer.isWindows(computer) ? WindowsAgentVersionSupportsOfflineLicenseManagement : UnixAgentVersionSupportsOfflineLicenseManagement
    );
  }
}

export class ComputersResponse extends PagedResponse<Computer> {}

export class ComputerTagDto {
  id: number;
  name: string;
}

export class ComputerWithStatus extends Computer {
  lastStatus?: string;
  lastStatusDate?: string;
}

export enum AgentType {
  RA = 'ra',
  Backup = 'backup',
  RMM = 'rmm',
  DeepInst = 'deepinst',
  NetworkDiscovery = 'nd'
}

export const AgentTypeAny = '@any';

export type BulkAgentType = AgentType | typeof AgentTypeAny;

export enum AgentState {
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  NotRegistered = 'NotRegistered'
}

export interface Agent {
  applicationId: AgentType;
  applicationState: AgentState;
  buildType: keyof typeof BuildType;
  MBSBuildType: number;
  displayVersion: string;
  online: boolean;
  version: string;
  versionStatus?: AgentVersionStatus;
  stateChangedBy?: string;
  stateChangedDate?: string;
}

export enum AgentVersionStatus {
  Latest = 'Latest',
  Outdated = 'Outdated',
  Unsupported = 'Unsupported'
}

export interface Edition {
  buildName: string;
  buildValue: number;
  isCurrentBuild: boolean;
}

export function convertOsTypeToNeededCase(osType: OsType): string {
  switch (osType) {
    case OsType.desktop:
      return 'Desktop';
    case OsType.apple:
      return 'Apple';
    case OsType.linux:
      return 'Linux';
    case OsType.dropbox:
      return 'Dropbox';
    case OsType.gear:
      return 'Gear';
    case OsType.server:
      return 'Server';
    case OsType.android:
      return 'Android';
    case OsType.hdd_o:
      return 'HDD_O';
    case OsType.windows:
      return 'Windows';
    default:
      return 'Unknown';
  }
}

export enum DisplayAgentType {
  Backup = 'Backup',
  RMM = 'RMM',
  RA = 'Connect',
  DeepInst = 'Deep Instinct'
}

export const agentTypeAdapter = [
  { agentType: AgentType.Backup, displayAgentType: DisplayAgentType.Backup },
  { agentType: AgentType.RMM, displayAgentType: DisplayAgentType.RMM },
  { agentType: AgentType.RA, displayAgentType: DisplayAgentType.RA },
  { agentType: AgentType.DeepInst, displayAgentType: DisplayAgentType.DeepInst }
];

export function convertAgentTypeToNeededCase(value: AgentType): string {
  return agentTypeAdapter.find((agentType) => agentType.agentType === value)?.displayAgentType;
}

export enum ComputersHealthFilterType {
  All = 'all',
  BackupWarning = 'backup_warning',
  BackupFailed = 'backup_failed',
  BackupOverdue = 'backup_overdue',
  RMMProblem = 'rmm_problem',
  RMMWarning = 'rmm_warning',
  Pending = 'pending',
  Unsupported = 'unsupported'
}

export enum ComputerBackupRestoreStatus {
  Success = 'success',
  Warning = 'warning',
  Overdue = 'overdue',
  Error = 'error'
}

export enum ComputerStatus {
  Online = 'online',
  Offline = 'offline'
}

export interface UpdateAgentSettings {
  mbsBuildType: number;
  userId: string;
}

export enum VMDetectorInstance {
  Unknown = 0,
  PhysicalMachine = 1,
  VirtualMachine = 2,
  PhysicalMachineHyperVhost = 3,
  VMWare = 4,
  HyperV = 5,
  Kvm = 6,
  KvmQemu = 7,
  Xen = 8,
  Lxc = 9,
  Uml = 10,
  Virtuozzo = 11,
  AmazonV1 = 12,
  AmazonV2 = 13,
  Azure = 14,
  Google = 15,
  OpenStack = 16
}

export enum ComputersMode {
  Computers,
  Backup,
  RMM
}

export const ComputerModeAdapter = [
  { mode: ComputersMode.Backup, url: RoutingPath.ApBackupComputers, pageCaptionI18Key: 'modeBackup', agentType: AgentType.Backup },
  { mode: ComputersMode.RMM, url: RoutingPath.ApRMMComputers, pageCaptionI18Key: 'modeRMM', agentType: AgentType.RMM },
  { mode: ComputersMode.Computers, url: RoutingPath.ApComputers, pageCaptionI18Key: 'modeComputers', agentType: undefined }
].map((data) => ({
  ...data,
  displayAgentType: agentTypeAdapter.find((value) => value.agentType === data.agentType)?.displayAgentType
}));

export interface StorageConnection {
  id: string;
  displayName: string;
  isLocal: boolean;
  path: string;
  prefix: string;
}

export type ComputersFilters = {
  hid?: string;
  companyIds?: string[];
  hidden?: boolean;
  online?: boolean;
  offline?: boolean;
  healthy?: boolean;
  healthWarning?: boolean;
  healthProblem?: boolean;
  type?: ComputersFiltersType;
  ipAddresses?: string[];
  appIds?: AgentType[];
  appIdsNotInstalled?: AgentType[];
  os?: OsType[];
  backupPlanStatuses?: ComputerBackupRestoreStatus[];
  restorePlanStatuses?: ComputerBackupRestoreStatus[];
  tags?: number[];
  search?: string;
  healthScopeAppId?: AgentType.Backup | AgentType.RMM;
  authStatus?: AuthStatus;
};

/** Include fields from Computer entity model */
export type ComputersModelPartialParams = {
  includes?: string | string[];
  withOnlineInfo?: boolean;
};

export enum ComputersFiltersType {
  None = 0,
  Unsupported
}

export type PlanSettingsReportParams = {
  email: string;
  hids?: string[];
  filter?: Partial<QuerySortParams> & Partial<QueryPagingParams> & ComputersFilters;
};

export type GetComputersParams = Partial<QuerySortParams> & Partial<QueryPagingParams> & ComputersFilters & ComputersModelPartialParams;

export type SendLogsExtraOptions = {
  error?: string;
  lastRun?: string;
  plan?: string;
  ticket: string;
  description: string;
};

export class ComputersApplications {
  backup?: number;
  rmm?: number;
  ra?: number;
  deepInst?: number;
}

export enum ComputersAPIFilterKeys {
  Search = 'search',
  BackupPlanStatuses = 'backupPlanStatuses',
  RestorePlanStatuses = 'restorePlanStatuses',
  AppIds = 'appIds',
  AppIdsNotInstalled = 'appIdsNotInstalled',
  OS = 'os',
  Online = 'online',
  Offline = 'offline',
  IpAddresses = 'ipAddresses',
  Tags = 'tags'
}

export type ComputerSidePanelUriParams = { hid?: string; activeTab?: string; sidepanel?: SidepanelRouteType };
