<div class="browse-storage-table">
  <div class="d-flex align-items-center justify-content-between mb-2">
    <h4 class="mb-0">{{ headerText }}</h4>

    <div *ngIf="selectedTreeItem?.itemType !== itemTypeEnum.Prefix && !isBackups && !isGenerations && !isPoints && showCheckboxes"
         class="d-flex">
      <mbs-button type="secondary"
                  [icon]="'fa fa-rotate-right'"
                  (click)="downloadClickHandler()"
                  [disabled]="disabledQRButton"
                  [loading]="deleteProcess">
        {{ 'backup-storages:restore' | i18next }}
      </mbs-button>
    </div>
  </div>

  <mbs-alert *ngIf="isWindowsComputer && isOldAgent"
             [type]="alertType.warning"
             [icon]="true"
             class="mb-2">
    {{'backup-storages:browseStorage.tables.windowsVersionAlert' | i18next}}
  </mbs-alert>

  <mbs-alert *ngIf="!isWindowsComputer"
             [type]="alertType.warning"
             [icon]="true"
             class="mb-2">
    {{
      isGenerations || isPoints ?
      ('backup-storages:browseStorage.tables.windowsAlert' | i18next: { level: textForWindowsAlertMap[rightTableData?.[0]?.itemType] }) :
      ('backup-storages:browseStorage.tables.windowsBunchAlert' | i18next)
    }}
  </mbs-alert>

  <mbs-table-grid *ngIf="!isBackups && !isPoints && !isGenerations"
                  (changeSelected)="selectedItemsForQRChangeHandler($event)"
                  [data]="rightTableData"
                  [headers]="headers"
                  [multipleSelect]="true"
                  [selectable]="false"
                  [classesTable]="'mb-0 -bordered -vertical'"
                  [showRefreshButton]="false"
                  [disableViewSwitch]="true"
                  [isNeedSelectRow]="true"
                  [selectedItems]="selectedItemsForQR"
                  [selectOnlyOnCheckboxClick]="true"
                  [sizeTable]="MbsSize.sm"
                  [showTableCheckboxes]="showCheckboxes"
                  [switcherView]="false"
                  [viewMode]="viewMode"
                  [keepState]="true"
                  [attr.data-test-element]="elementsSelector.name.rightTableDefault"
                  bindSelected="id"
                  id="selected-items-for-qr-list">
    <ng-template *ngIf="existColumns.label"
                 let-item
                 mbsTableCell>
      <span (dblclick)="itemDblClickHandler($event, item)"
            class="d-flex align-items-center justify-content-start position-relative browse-storage-table_img">
        <img [src]="item.iconCustom"
             class="browse-storage-table_img-main mr-2"
             alt="Table main icon">
        <mbs-text-ellipsis [tooltip]="item.label">{{ item.label }}</mbs-text-ellipsis>
      </span>
    </ng-template>
    <ng-template *ngIf="existColumns.modifyDateUTC"
                 let-item
                 mbsTableCell>
      <mbs-backup-date-time-format *ngIf="item?.modifyDateUTC; else notData"
                                   [attr.data-test-element]="elementsSelector.name.modifyDateUTCTableRow"
                                   [placement]="'auto'"
                                   [time]="item?.modifyDateUTC"
                                   [showIcon]="true"
                                   container="body"
                                   defaultText="Unknown"></mbs-backup-date-time-format>
    </ng-template>
    <ng-template *ngIf="existColumns.originalSize"
                 let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.originalSize || item.originalSize === 0; else notData"
                         [attr.data-test-element]="elementsSelector.name.originalSizeTableRow"
                         [tooltip]="getDiskSizeFromKB(item.originalSize)">
        {{getDiskSizeFromKB(item.originalSize)}}
      </mbs-text-ellipsis>
    </ng-template>
  </mbs-table-grid>

  <mbs-table-grid *ngIf="isBackups"
                  (changeSelected)="selectedItemsForQRChangeHandler($event)"
                  [data]="rightTableData"
                  [headers]="rightTableBunchHeaders"
                  [multipleSelect]="true"
                  [selectable]="false"
                  [classesTable]="'mbs-browse-table mb-0 -bordered -vertical'"
                  [showRefreshButton]="false"
                  [disableViewSwitch]="true"
                  [sizeTable]="MbsSize.sm"
                  [switcherView]="false"
                  [viewMode]="viewMode"
                  [keepState]="true"
                  [attr.data-test-element]="elementsSelector.name.rightTableBunches">
    <ng-container *ngFor="let header of rightTableBunchHeaders">
      <ng-template [mbsTableHeader]="header.name"
                   let-header>
        <span *ngIf="header.headerName !== 'Icons'; else iconsHeader"
              class="text-xs w-100 text-left">
          {{header.name}}
          <span *ngIf="header.headerName === 'totalSavings'"
                class="ico ico-InfoCircle ml-2 cursor-help text-info"
                tooltipClass="text-left"
                [placement]="'top'"
                [container]="'body'"
                [ngbTooltip]="'backup-storages:browseStorage.tables.totalSavingsLabel' | i18next"></span>
        </span>
      </ng-template>
    </ng-container>

    <ng-template let-item
                 mbsTableCell>
      <span (dblclick)="itemDblClickHandler($event, item)"
            class="d-flex align-items-center justify-content-start position-relative browse-storage-table_img">
        <span class="d-flex align-items-center justify-content-start position-relative browse-storage-table_img mr-1">
          <img [src]="item.iconCustom"
               class="browse-storage-table_img-main"
               alt="Table main icon">
          <img *ngIf="item.rightBottomIcon"
               [src]="item.rightBottomIcon"
               class="browse-storage-table_img-bottom"
               alt="Table icon right bottom">
          <img *ngIf="item.leftTopIcon"
               [src]="item.leftTopIcon"
               class="browse-storage-table_img-top"
               alt="Table icon left top">
        </span>
        <mbs-text-ellipsis [tooltip]="item.label">{{ item.label }}</mbs-text-ellipsis>
      </span>
    </ng-template>

    <ng-template *ngIf="!isNotWindowsOrOldAgent"
                 let-item
                 mbsTableCell>
      <span (dblclick)="itemDblClickHandler($event, item)">
        <mbs-backup-date-time-format *ngIf="item?.firstRestoreDate; else notData"
                                     [attr.data-test-element]="elementsSelector.name.firstRestoreDateTableRow"
                                     [placement]="'auto'"
                                     [time]="item?.firstRestoreDate"
                                     [showIcon]="true"
                                     container="body"
                                     defaultText="Unknown">
        </mbs-backup-date-time-format>
      </span>
    </ng-template>
    <ng-template *ngIf="!isNotWindowsOrOldAgent"
                 let-item
                 mbsTableCell>
      <span (dblclick)="itemDblClickHandler($event, item)">
        <mbs-backup-date-time-format *ngIf="item?.lastRestoreDate; else notData"
                                     [attr.data-test-element]="elementsSelector.name.lastRestoreDateTableRow"
                                     [placement]="'auto'"
                                     [time]="item?.lastRestoreDate"
                                     [showIcon]="true"
                                     container="body"
                                     defaultText="Unknown">
        </mbs-backup-date-time-format>
      </span>
    </ng-template>
    <ng-template *ngIf="!isNotWindowsOrOldAgent"
                 let-item
                 mbsTableCell>
      <div class="d-flex justify-content-between w-100">
        <span class="browse-storage-table-col-4 text-left">
          {{item.countOfSuccessfulStarts}}
        </span>
        <span class="browse-storage-table-col-4 text-center">
          {{item.countOfWarningStarts}}
        </span>
        <span class="browse-storage-table-col-4 text-right">
          {{item.countOfErrorStarts}}
        </span>
      </div>

    </ng-template>
    <ng-template *ngIf="!isNotWindowsOrOldAgent"
                 let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="!item.isLegacy; else notData"
                         [attr.data-test-element]="elementsSelector.name.daysToPurgeTableRow"
                         [tooltip]="daysToPurgeToString(item.retentionKeepBackupFor)">
        {{daysToPurgeToString(item.retentionKeepBackupFor)}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template *ngIf="!isNotWindowsOrOldAgent"
                 let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.originalSize || item.originalSize === 0; else notData"
                         [attr.data-test-element]="elementsSelector.name.originalSizeTableRow"
                         [tooltip]="getDiskSizeFromKB(item.originalSize)">
        {{getDiskSizeFromKB(item.originalSize)}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template *ngIf="!isNotWindowsOrOldAgent"
                 let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.sizeOnStorage || item.sizeOnStorage === 0; else notData"
                         [attr.data-test-element]="elementsSelector.name.sizeOnStorageTableRow"
                         [tooltip]="getDiskSizeFromKB(item.sizeOnStorage)">
        {{getDiskSizeFromKB(item.sizeOnStorage)}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template *ngIf="!isNotWindowsOrOldAgent"
                 let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.totalSavings || item.totalSavings === 0; else notData"
                         [attr.data-test-element]="elementsSelector.name.totalSavingsTableRow"
                         [tooltip]="item.totalSavings + '%'">
        {{item.totalSavings + '%'}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <div class="cursor-help"
           [placement]="'top-right'"
           [container]="'body'"
           [disableTooltip]="disableRestoreBtn(item)"
           [ngbTooltip]="'backup-storages:browseStorage.tables.restore' | i18next: { level: backupLevelText }">
        <mbs-button type="secondary"
                    [icon]="'fa fa-rotate-right'"
                    (click)="downloadClickHandler(item)"
                    [disabled]="disableRestoreBtn(item)"></mbs-button>
      </div>
      <div *ngIf="!isOnlineAccess"
           class="cursor-help ml-1"
           [placement]="'top-right'"
           [container]="'body'"
           [disableTooltip]="deleteProcess"
           [ngbTooltip]="getDeleteTooltipText(item)">
        <mbs-button type="secondary"
                    [icon]="'fa fa-trash-o'"
                    (click)="deleteSelectedClickHandler(item)"
                    [disabled]="!isOnline || deleteProcess || !item.isCanBeDeleted"></mbs-button>
      </div>
    </ng-template>
  </mbs-table-grid>

  <mbs-table-grid *ngIf="isGenerations"
                  (changeSelected)="selectedItemsForQRChangeHandler($event)"
                  [data]="rightTableData"
                  [headers]="rightTableGenerationHeaders"
                  [multipleSelect]="true"
                  [selectable]="false"
                  [classesTable]="'mbs-browse-table mb-0 -bordered -vertical'"
                  [showRefreshButton]="false"
                  [disableViewSwitch]="true"
                  [sizeTable]="MbsSize.sm"
                  [switcherView]="false"
                  [viewMode]="viewMode"
                  [keepState]="true"
                  [attr.data-test-element]="elementsSelector.name.rightTableGeneration">
    <ng-container *ngFor="let header of rightTableGenerationHeaders">
      <ng-template [mbsTableHeader]="header.name"
                   let-header>
        <span *ngIf="header.headerName !== 'Icons'; else iconsHeader"
              class="text-xs w-100 text-left">
          {{header.name}}
          <span *ngIf="header.headerName === 'totalSavings'"
                class="ico ico-InfoCircle ml-2 cursor-help text-info"
                tooltipClass="text-left"
                [placement]="'top'"
                [container]="'body'"
                [ngbTooltip]="'backup-storages:browseStorage.tables.totalSavingsLabel' | i18next"></span>
        </span>
      </ng-template>
    </ng-container>

    <ng-template let-item
                 mbsTableCell>
      <span (dblclick)="itemDblClickHandler($event, item)"
            class="d-flex align-items-center justify-content-start position-relative browse-storage-table_img">
        <img [src]="item.iconCustom"
             class="browse-storage-table_img-main"
             alt="Table main icon">
        <img *ngIf="item.rightBottomIcon"
             [src]="item.rightBottomIcon"
             class="browse-storage-table_img-bottom"
             alt="Table icon right bottom">
        <img *ngIf="item.leftTopIcon"
             [src]="item.leftTopIcon"
             class="browse-storage-table_img-top"
             alt="Table icon left top">
      </span>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <span (dblclick)="itemDblClickHandler($event, item)">
        <mbs-backup-date-time-format *ngIf="item?.firstRestoreDate"
                                     [attr.data-test-element]="elementsSelector.name.firstRestoreDateTableRow"
                                     [placement]="'auto'"
                                     [time]="item?.firstRestoreDate"
                                     [showIcon]="true"
                                     container="body"
                                     defaultText="Unknown">
        </mbs-backup-date-time-format>
      </span>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <span (dblclick)="itemDblClickHandler($event, item)">
        <mbs-backup-date-time-format *ngIf="item?.lastRestoreDate"
                                     [attr.data-test-element]="elementsSelector.name.lastRestoreDateTableRow"
                                     [placement]="'auto'"
                                     [time]="item?.lastRestoreDate"
                                     [showIcon]="true"
                                     container="body"
                                     defaultText="Unknown">
        </mbs-backup-date-time-format>
      </span>
    </ng-template>
    <ng-template *ngIf="!isNotWindowsOrOldAgent"
                 let-item
                 mbsTableCell>
      <div class="d-flex justify-content-between w-100">
        <span class="browse-storage-table-col-4 text-left">
          {{item.countOfSuccessfulStarts}}
        </span>
        <span class="browse-storage-table-col-4 text-center">
          {{item.countOfWarningStarts}}
        </span>
        <span class="browse-storage-table-col-4 text-right">
          {{item.countOfErrorStarts}}
        </span>
      </div>

    </ng-template>
    <ng-template *ngIf="!isNotWindowsOrOldAgent"
                 let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.originalSize || item.originalSize === 0; else notData"
                         [attr.data-test-element]="elementsSelector.name.originalSizeTableRow"
                         [tooltip]="getDiskSizeFromKB(item.originalSize)">
        {{getDiskSizeFromKB(item.originalSize)}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template *ngIf="!isNotWindowsOrOldAgent"
                 let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.sizeOnStorage || item.sizeOnStorage === 0; else notData"
                         [attr.data-test-element]="elementsSelector.name.sizeOnStorageTableRow"
                         [tooltip]="getDiskSizeFromKB(item.sizeOnStorage)">
        {{getDiskSizeFromKB(item.sizeOnStorage)}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template *ngIf="!isNotWindowsOrOldAgent"
                 let-item
                 mbsTableCell>
      <img *ngIf="item.GFSSettings && item.GFSSettings.type !== generationGFSType.NotAssigned"
           [src]="generationGFSTypeIcons[item.GFSSettings.type]"
           [ngbTooltip]="item.purgeSummary"
           [disableTooltip]="!item.purgeSummary"
           [container]="'body'"
           class="backup-storage-tree_img-gfs mr-1 text-info"
           alt="Tree icon for gfs settings type - {{ item.GFSSettings.type }}" />
      <mbs-text-ellipsis *ngIf="!item.isLegacy; else notData"
                         [attr.data-test-element]="elementsSelector.name.daysToPurgeTableRow"
                         [tooltip]="daysToPurgeToString(item.daysToPurge)">{{daysToPurgeToString(item.daysToPurge)}}</mbs-text-ellipsis>
    </ng-template>
    <ng-template *ngIf="!isNotWindowsOrOldAgent"
                 let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.totalSavings || item.totalSavings === 0; else notData"
                         [attr.data-test-element]="elementsSelector.name.totalSavingsTableRow"
                         [tooltip]="item.totalSavings + '%'">
        {{item.totalSavings + '%'}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <div class="cursor-help"
           [placement]="'top-right'"
           [container]="'body'"
           [disableTooltip]="disableRestoreBtn(item)"
           [ngbTooltip]="'backup-storages:browseStorage.tables.restore' | i18next: { level: generationLevelText }">
        <mbs-button type="secondary"
                    [icon]="'fa fa-rotate-right'"
                    (click)="downloadClickHandler(item)"
                    [disabled]="disableRestoreBtn(item)"></mbs-button>
      </div>
      <div *ngIf="!isOnlineAccess"
           class="cursor-help ml-1"
           [placement]="'top-right'"
           [container]="'body'"
           [disableTooltip]="deleteProcess"
           [ngbTooltip]="getDeleteTooltipText(item)">
        <mbs-button type="secondary"
                    [icon]="'fa fa-trash-o'"
                    (click)="deleteSelectedClickHandler(item)"
                    [disabled]="!isOnline || deleteProcess || !item.isCanBeDeleted"></mbs-button>
      </div>
    </ng-template>
  </mbs-table-grid>

  <mbs-table-grid *ngIf="isPoints"
                  (changeSelected)="selectedItemsForQRChangeHandler($event)"
                  [data]="rightTableData"
                  [headers]="rightTablePointHeaders"
                  [multipleSelect]="true"
                  [selectable]="false"
                  [classesTable]="'mbs-browse-table mb-0 -bordered -vertical'"
                  [showRefreshButton]="false"
                  [disableViewSwitch]="true"
                  [sizeTable]="MbsSize.sm"
                  [switcherView]="false"
                  [viewMode]="viewMode"
                  [keepState]="true"
                  [attr.data-test-element]="elementsSelector.name.rightTablePoints">
    <ng-container *ngFor="let header of rightTablePointHeaders">
      <ng-template [mbsTableHeader]="header.name"
                   let-header>
        <span class="text-xs w-100 text-left">
          {{header.name}}
          <span *ngIf="header.headerName === 'consistencyCheck'"
                class="ico ico-InfoCircle ml-2 cursor-help text-info"
                tooltipClass="tooltip-lg text-left"
                [placement]="'auto'"
                [container]="'body'"
                [ngbTooltip]="'backup-storages:browseStorage.tables.consistencyCheckLabel' | i18next"></span>
          <span *ngIf="header.headerName === 'restoreVerification'"
                class="ico ico-InfoCircle ml-2 cursor-help text-info"
                tooltipClass="tooltip-lg text-left"
                [placement]="'auto'"
                [container]="'body'"
                [ngbTooltip]="'backup-storages:browseStorage.tables.restoreVerificationLabel' | i18next"></span>
          <span *ngIf="header.headerName === 'totalSavings'"
                class="ico ico-InfoCircle ml-2 cursor-help text-info"
                tooltipClass="text-left"
                [placement]="'top'"
                [container]="'body'"
                [ngbTooltip]="'backup-storages:browseStorage.tables.totalSavingsLabel' | i18next"></span>
        </span>
      </ng-template>
    </ng-container>

    <ng-template let-item
                 mbsTableCell>
      <span (dblclick)="itemDblClickHandler($event, item)"
            class="d-flex align-items-center justify-content-start position-relative browse-storage-table_img">
        <img [src]="item.iconCustom"
             class="browse-storage-table_img-main"
             alt="Table main icon">
        <img *ngIf="item.rightBottomIcon"
             [src]="item.rightBottomIcon"
             class="browse-storage-table_img-bottom"
             alt="Table icon right bottom">
        <img *ngIf="item.leftTopIcon"
             [src]="item.leftTopIcon"
             class="browse-storage-table_img-top"
             alt="Table icon left top">
      </span>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <span (dblclick)="itemDblClickHandler($event, item)">
        <mbs-backup-date-time-format *ngIf="item?.date; else notData"
                                     [attr.data-test-element]="elementsSelector.name.dateTableRow"
                                     [placement]="'auto'"
                                     [time]="item?.date"
                                     [showIcon]="true"
                                     container="body"
                                     defaultText="Unknown">
        </mbs-backup-date-time-format>
      </span>
    </ng-template>
    <ng-template *ngIf="!isNotWindowsOrOldAgent"
                 let-item
                 mbsTableCell>
      <span *ngIf="item.backupResult && item.backupResult !== 'unknown'; else notDataWithText"
            class="text-md"
            [attr.data-test-element]="elementsSelector.name.backupResultTableRow"
            [ngbTooltip]="statusText[item.backupResult]"
            [placement]="'auto'"
            [container]="'body'"
            [ngClass]="StatusIconType[item.backupResult] + ' text-' + item.backupResult"></span>
    </ng-template>
    <ng-template *ngIf="!isNotWindowsOrOldAgent"
                 let-item
                 mbsTableCell>
      <span *ngIf="item.consistencyCheckResult; else notDataWithText"
            class="text-md"
            [attr.data-test-element]="elementsSelector.name.consistencyCheckResultTableRow"
            [ngbTooltip]="statusText[item.consistencyCheckResult]"
            [placement]="'auto'"
            [container]="'body'"
            [ngClass]="item.consistencyCheckResult === 'unknown' ? 'fa fa-clock-o text-gray' : StatusIconType[item.consistencyCheckResult] + ' text-' + item.consistencyCheckResult"></span>
    </ng-template>
    <ng-template *ngIf="existPointsHeaderColumn('restoreVerification')"
                 let-item
                 mbsTableCell>
      <span *ngIf="item.restoreVerificationResult && item.restoreVerificationResult !== 'unknown'; else notDataWithText"
            class="text-md"
            [attr.data-test-element]="elementsSelector.name.restoreVerificationResultTableRow"
            [ngbTooltip]="statusText[item.restoreVerificationResult]"
            [placement]="'auto'"
            [container]="'body'"
            [ngClass]="StatusIconType[item.restoreVerificationResult] + ' text-' + item.restoreVerificationResult"></span>
      <span *ngIf="!item.restoreVerificationResult || item.restoreVerificationResult === 'unknown';"
            class="ico ico-InfoCircle ml-2 cursor-help text-info"
            tooltipClass="tooltip-lg text-left"
            [placement]="'auto'"
            [container]="'body'"
            [ngbTooltip]="'backup-storages:browseStorage.tables.rvNALabel' | i18next"></span>
    </ng-template>
    <ng-template *ngIf="!isNotWindowsOrOldAgent"
                 let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.duration; else notDataWithText"
                         [attr.data-test-element]="elementsSelector.name.durationTableRow"
                         [tooltip]="item.duration">
        {{item.duration}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template *ngIf="!isNotWindowsOrOldAgent"
                 let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.originalSize || item.originalSize === 0; else notData"
                         [attr.data-test-element]="elementsSelector.name.originalSizeTableRow"
                         [tooltip]="getDiskSizeFromKB(item.originalSize)">
        {{getDiskSizeFromKB(item.originalSize)}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template *ngIf="!isNotWindowsOrOldAgent"
                 let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.sizeOnStorage || item.sizeOnStorage === 0; else notData"
                         [attr.data-test-element]="elementsSelector.name.sizeOnStorageTableRow"
                         [tooltip]="getDiskSizeFromKB(item.sizeOnStorage)">
        {{getDiskSizeFromKB(item.sizeOnStorage)}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template *ngIf="!isNotWindowsOrOldAgent"
                 let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.totalSavings || item.totalSavings === 0; else notData"
                         [attr.data-test-element]="elementsSelector.name.totalSavingsTableRow"
                         [tooltip]="item.totalSavings + '%'">
        {{item.totalSavings + '%'}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <div class="cursor-help"
           [placement]="'top-right'"
           [container]="'body'"
           [disableTooltip]="disableRestoreBtn(item)"
           [ngbTooltip]="'backup-storages:browseStorage.tables.restore' | i18next: { level: pointLevelText }">
        <mbs-button type="secondary"
                    [icon]="'fa fa-rotate-right'"
                    (click)="downloadClickHandler(item)"
                    [disabled]="disableRestoreBtn(item)"></mbs-button>
      </div>
    </ng-template>
  </mbs-table-grid>

  <ng-template #iconsHeader>
    <span class="d-flex justify-content-between w-100">
      <mbs-status [textSize]="MbsSize.lg"
                  [ngbTooltip]="'backup-storages:browseStorage.tables.iconSuccess' | i18next"
                  [placement]="'auto'"
                  [container]="'body'"
                  [icon]="StatusIconType.success"
                  [type]="'success'"></mbs-status>
      <mbs-status [textSize]="MbsSize.lg"
                  [ngbTooltip]="'backup-storages:browseStorage.tables.iconWarning' | i18next"
                  [placement]="'auto'"
                  [container]="'body'"
                  [icon]="StatusIconType.warning"
                  [type]="'warning'"></mbs-status>
      <mbs-status [textSize]="MbsSize.lg"
                  [ngbTooltip]="'backup-storages:browseStorage.tables.iconFail' | i18next"
                  [placement]="'auto'"
                  [container]="'body'"
                  [icon]="StatusIconType.danger"
                  [type]="'danger'"></mbs-status>
    </span>
  </ng-template>

  <ng-template #notData>—</ng-template>
  <ng-template #notDataWithText>N/A</ng-template>
</div>

<ng-template #downloadModal>
  <ng-container *ngIf="!isWindows || !ability.can('read', 'QuickRestore')">
    <span *ngIf="isOnline">
      {{ 'backup-storages:browseStorage.confirmComputerForRestore' | i18next: { computerName: computerData?.name } }}
    </span>
    <span *ngIf="!isOnline">
      {{ 'backup-storages:browseStorage.confirmComputerForRestoreOffline' | i18next: { computerName: computerData?.name } }}
    </span>
  </ng-container>

  <ng-container *ngIf="isWindows && !isTopLevel && ability.can('read', 'QuickRestore')">
    <span class="d-block mb-3 font-weight-semibold">{{ 'backup-storages:browseStorage.computerForRestore' | i18next }}</span>

    <mbs-radio name="isLocal"
               (change)="isLocalChangeHandler($event)"
               [(ngModel)]="isLocal"
               [label]="'backup-storages:browseStorage.localComputer' | i18next"
               [value]="false"
               [id]="'is-local-restore-1'"></mbs-radio>
    <mbs-radio name="isLocal"
               (change)="isLocalChangeHandler($event)"
               [(ngModel)]="isLocal"
               [descriptionOutside]="false"
               [id]="'is-local-restore-2'"
               [label]="'backup-storages:browseStorage.sourceComputer' | i18next: { computerName: computerData?.name }"
               [value]="true">
      <ng-template radioDescription
                   [hide]="isOnline">
        <div class="invalid-feedback">{{'backup-storages:browseStorage.sourceComputerOffline' | i18next}}</div>
      </ng-template>
    </mbs-radio>
  </ng-container>
</ng-template>
