<mbs-modal [isCustomModal]="true"
           [showFooter]="true"
           [title]="'app:sendLogs.title.reportProblem' | i18next: { name: computerName }"
           #modal>

  <ng-container modal-body>

    <form [formGroup]="formGroup"
          class="mbs-form">

      <fieldset class="mbs-form_content">

        <ng-container formGroupName="params">

          <mbs-form-group>

            <mbs-checkbox *ngIf="!('Readonly' | can: 'read')"
                          formControlName="sendToSupport"
                          [label]="'app:sendLogs.label.toSupport' | i18next: { format: 'capitalize' }"
                          [descriptionClasses]="'mt-2'"
                          (change)="handleChangeToSupport($event)">

              <ng-template checkboxDescription
                           [hide]="!formGroup.get('params.sendToSupport').value">

                <mbs-alert [type]="MbsPopupType.warning"
                           [icon]="true"
                           *ngIf="('SuperAdmin' | can: 'read')">
                  {{ 'app:sendLogs.alert.sendLogsForSuperAdmin' | i18next }}
                </mbs-alert>

                <mbs-alert [type]="MbsPopupType.info"
                           [icon]="true"
                           class="mb-0"
                           #alertElementRef>

                  <ng-container *ngIf="('SendTicketsOnlyToSupportEmail' | can: 'read') || (('SubAdmin' | can: 'read') &&
                                       !('HelpMarketing' | can:  'read')); else helpMarketing">
                    {{ 'app:sendLogs.alert.noHelpMarketing' | i18next: { format: 'capitalize' } }}
                  </ng-container>

                  <ng-template #helpMarketing>

                    {{ 'app:sendLogs.alert.text_start' | i18next: { format: 'capitalize' } }}
                    <mbs-button [isCtrl]="true"
                                size="md"
                                (click)="handleRedirectToSP()">
                      {{ 'app:sendLogs.alert.supportPortal' | i18next: { format: 'title' } }}
                    </mbs-button>.
                    {{ 'app:sendLogs.alert.text_end' | i18next: { format: 'capitalize' } }}

                    <a href="https://www.msp360.com/download/MSP360-customer-support-reference-guide.pdf"
                       target="_blank">
                      {{ 'app:sendLogs.alert.link' | i18next: { format: 'title' } }}
                    </a>

                  </ng-template>

                </mbs-alert>

              </ng-template>

            </mbs-checkbox>

            <mbs-checkbox *ngIf="!('Readonly' | can: 'read')"
                          [checked]="false"
                          formControlName="sendToCustomEmail"
                          [label]="'app:sendLogs.label.toEmail' | i18next: { format: 'capitalize' }">

              <ng-template checkboxDescription>
                <mbs-input formControlName="customEmails"
                           class="mt-2 d-block"
                           [placeholder]="placeholderText"
                           [ngbTooltip]="placeholderText"
                           [container]="'body'"
                           [placement]="'auto'"
                           [tooltipClass]="'tooltip-lg'"
                           [disabled]="!formGroup.get('params.sendToCustomEmail').value"></mbs-input>
              </ng-template>

            </mbs-checkbox>

          </mbs-form-group>

        </ng-container>

        <mbs-form-group *ngIf="!isLastRunInfo"
                        [title]="'app:sendLogs.title.selectAgent' | i18next ">

          <div class="row">

            <ng-container formGroupName="agentCommands">

              <div class="col-3">
                <mbs-checkbox formControlName="backup"
                              [disabled]="!isAgentTypeByApplicationID(AgentType.Backup)"
                              (change)="handleChangeIncludeEventLogs($event)"
                              [label]="'app:products.backup' | i18next"></mbs-checkbox>
              </div>

              <div class="col-3">
                <mbs-checkbox formControlName="rmm"
                              [disabled]="!isAgentTypeByApplicationID(AgentType.RMM)"
                              [label]="'app:products.rmm' | i18next"></mbs-checkbox>
              </div>

              <div class="col-4">
                <mbs-checkbox formControlName="ra"
                              [disabled]="!isAgentTypeByApplicationID(AgentType.RA)"
                              [label]="'app:products.remote_desktop' | i18next"></mbs-checkbox>
              </div>

            </ng-container>

            <div *ngIf="isBackupAgentVersionValid"
                 class="col-12 mt-2">
              <mbs-checkbox formControlName="includeEventLogs"
                            [disabled]="!formGroup.get('agentCommands.backup').value"
                            [label]="'app:sendLogs.label.includeEvent' | i18next: { format: 'capitalize' }"></mbs-checkbox>
            </div>

          </div>

        </mbs-form-group>

        <mbs-form-group>
          <mbs-input [trim]="false"
                     formControlName="message"
                     [label]="'app:sendLogs.label.problemDescription' | i18next: { format: 'capitalize' }"
                     [placeholder]="'app:sendLogs.placeholder.problemDescription' | i18next: { format: 'capitalize' }"
                     rows="4"
                     type="textarea"></mbs-input>
        </mbs-form-group>

        <mbs-form-group [class.mb-0]="!('SuperAdmin' | can: 'read')">
          <mbs-input formControlName="ticket"
                     [validationWhitespace]="true"
                     [label]="'app:sendLogs.label.ticket' | i18next"
                     [placeholder]="'app:sendLogs.placeholder.ticket' | i18next: { format: 'capitalize' }"></mbs-input>
        </mbs-form-group>

        <mbs-form-group *ngIf="('SuperAdmin' | can: 'read')"
                        class="mb-0">
          <mbs-checkbox formControlName="sendToSuperAdmin"
                        [label]="'app:sendLogs.label.toDev' | i18next: { format: 'capitalize' }"></mbs-checkbox>
        </mbs-form-group>

      </fieldset>

    </form>

  </ng-container>

  <ng-container modalFooter>

    <mbs-button type="primary"
                [loading]="loading$ | async"
                [disabled]="!(formGroup.get('agentCommands').valid && isSelectedParams)"
                (click)="handleResolve()">
      {{ 'buttons:report' | i18next: { format: 'title' } }}
    </mbs-button>

    <mbs-button type="secondary"
                data-dismiss="modal"
                (click)="modal.close()">
      {{ 'buttons:cancel' | i18next: { format: 'title' } }}
    </mbs-button>

  </ng-container>

</mbs-modal>

<ng-template #errorTemplate
             let-context>

  <mbs-table-grid [multipleSelect]="false"
                  classesTable="-accordion"
                  rowClasses="background-light"
                  childHeaderClasses="background-white"
                  bindParentKey="hid"
                  bindChildren="childs"
                  [collapsibleMode]="true"
                  [viewMode]="sharedPersistentStateEnum.table"
                  [stripedTable]="false"
                  [showNav]="false"
                  [showHeaders]="false"
                  [headers]="headers"
                  [minHeight]="'200px'"
                  [height]="'calc(100vh - 8rem - 139px)'"
                  [showChildrenHeaders]="false"
                  [subtitleHeaders]="subHeaders"
                  [data]="context.resultList">

    <ng-template mbsTableCell
                 let-error>

      <div class="row align-items-center">

        <div class="col-auto text-center">
          <span class="mbs-table-grid_ico text-primary">
            <i class="fa fa-desktop text-primary"></i>
          </span>
        </div>

        <div class="col">
          <div class="mbs-table-grid_accordion-title">
            {{ error.name || error.displayName }}
          </div>
        </div>

      </div>

    </ng-template>

    <ng-template mbsTableCell
                 let-error>

      <div class="row justify-content-end">

        <div class="col-auto lh-1"
             *ngFor="let child of error.childs">

          <mbs-status class="force-update-status-bar"
                      [icon]="child.ok ? 'ico ico-check-circle' : 'ico ico-exclamation-circle'"
                      [type]="child.ok ? 'success' : 'danger'">
            {{ child.agentType }}
          </mbs-status>

        </div>

      </div>

    </ng-template>

    <ng-template mbsTableCell
                 group="child"
                 let-child>
      <div class="force-update-details">

        <div class="font-weight-semibold mb-1">{{ child.agentType }}</div>

        <div class="form-row">

          <div class="col-auto lh-1">
            <span class="mbs-status">
              <span class="mbs-status_ico -sm fa fa-circle text-{{ child.ok ? 'success' : 'danger' }} mr-0"></span>
            </span>
          </div>

          <div class="col">{{ child.message }}</div>

        </div>

      </div>

    </ng-template>

  </mbs-table-grid>

</ng-template>
