<mbs-wizard [(ngModel)]="currentStep"
            [buttonLoadingMode]="true"
            [canClosed]="!loadingPlan && wizardFinish"
            [isOpenSteps]="!loadingPlan && isEdit"
            [loading]="loadingPlan"
            [ignoreSteps]="[advancedTitle]"
            [stepsOrientation]="orientations"
            [cancelButton]="{ hide: fromGettingStarted, text: 'buttons:cancel' | i18next: { format: 'title' } }"
            [saveButton]="{ text: fromGettingStarted ? ('buttons:saveAndRun' | i18next: { format: 'title' }) :
            'buttons:save' | i18next: { format: 'title' } }"
            [stepQueue]="stepQueue"
            [stepsContainerClass]="mainService.isOffline ? 'fit-content' : ''"
            (save)="handleSave()"
            [title]="wizardTitle + ' ' + mainService.compName">
  <div *ngIf="mainService.isOffline"
       prependStep
       class="pl-4 py-2 background-secondary">
    <span class="fa fa-power-off"></span> {{ prependStepText }}
  </div>
  <mbs-wizard-step [title]="'wizards:welcome' | i18next: { format: 'title' }"
                   [valid]="FormModel?.planNameStep?.valid"
                   (next)="planNameStep?.forceValid()">
    <mbs-plan-name-step #planNameStep
                        [(ngModel)]="FormModel.planNameStep"
                        [planTypeText]="planTypeText"></mbs-plan-name-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="'wizards:where_backup_step_title' | i18next: { format: 'title' }"
                   [valid]="FormModel?.whereBackupStep?.valid"
                   (next)="whereBackupStep?.forceValid()">
    <mbs-where-backup-step #whereBackupStep
                           [(ngModel)]="FormModel.whereBackupStep"
                           (changeStorage)="changeStorageHandler($event)"
                           [Storages]="FormModel?.Storages"></mbs-where-backup-step>
  </mbs-wizard-step>

  <mbs-wizard-step *ngIf="advancedOptionsNeedShow"
                   [title]="advancedTitle"
                   [valid]="FormModel?.advancedOptionsStep?.valid"
                   (next)="advancedOptionsStep?.forceValid(currentStep)">
    <mbs-advanced-options-step #advancedOptionsStep
                               [(ngModel)]="FormModel.advancedOptionsStep"
                               (changeStorageType)="changeAdvancedStorageHandler($event)"
                               (nextStep)="forceNextStepWizard($event, 'advancedOptionsStep', advancedTitle)"
                               [stepFocused]="currentStep?.title === advancedTitle"
                               [advancedOptionsFlags]="FormModel?.advancedOptionsFlags"></mbs-advanced-options-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="'wizards:what_backup_step_title' | i18next: { format: 'title' }"
                   [valid]="FormModel.whatBackupTreeStep?.valid"
                   (next)="whatBackupTreeStep?.forceValid()">
    <mbs-what-backup-tree-step #whatBackupTreeStep
                               [(ngModel)]="FormModel.whatBackupTreeStep"></mbs-what-backup-tree-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="'wizards:advanced_filter_title' | i18next: { format: 'title' }"
                   [valid]="FormModel?.advancedFilterStep?.valid"
                   (next)="advancedFilterStep?.forceValid()">
    <mbs-advanced-filter-step #advancedFilterStep
                              [canBackupEmptyFolders]="isNBF || canBackupEmptyFolders"
                              [(ngModel)]="FormModel.advancedFilterStep"></mbs-advanced-filter-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="compressionEncryptionTitle"
                   [valid]="FormModel?.compressionEncryptionStep?.valid"
                   (next)="compressionEncryptionStep?.forceValid(currentStep)">
    <mbs-compression-encryption-step #compressionEncryptionStep
                                     [isS3Selected]="FormModel?.advancedOptionsFlags?.UseS3AccelerationPossible"
                                     [stepFocused]="currentStep?.title === compressionEncryptionTitle"
                                     [SaveDeletedDataInCloud]="FormModel?.advancedOptionsStep?.SaveDeletedDataInCloud"
                                     (nextStep)="forceNextStepWizard($event, 'compressionEncryptionStep', 'Compression & Encryption')"
                                     [(ngModel)]="FormModel.compressionEncryptionStep"></mbs-compression-encryption-step>
  </mbs-wizard-step>

  <mbs-wizard-step *ngIf="isNBF"
                   [title]="'wizards:archive_consistency_backup_title' | i18next: { format: 'title' }"
                   [valid]="FormModel?.archiveConsistencyStep?.valid">
    <mbs-consistency-step [(ngModel)]="FormModel.archiveConsistencyStep"></mbs-consistency-step>
  </mbs-wizard-step>

  <mbs-wizard-step *ngIf="!isNBF"
                   [title]="retentionTitle"
                   [valid]="FormModel?.retentionPolicyStep?.valid"
                   (next)="retentionPolicyStep?.forceValid()">
    <mbs-retention-policy-step #retentionPolicyStep
                               [minPeriod]="minPeriod"
                               [(ngModel)]="FormModel.retentionPolicyStep"
                               [showImmutability]="showImmutability"
                               [showGFS]="showGFS"
                               [isFFIMode]="isFFIMode"
                               [incrementalScheduleData]="incrementalData"
                               [isHybrid]="FormModel.whereBackupStep.IsHybridBackup"></mbs-retention-policy-step>
  </mbs-wizard-step>

  <mbs-wizard-step [title]="scheduleTitle"
                   [valid]="FormModel?.scheduleStep?.valid"
                   (next)="scheduleStep?.forceValid(currentStep)">
    <mbs-schedule-step #scheduleStep
                       [(ngModel)]="FormModel.scheduleStep"
                       [fastNTFS]="FormModel?.advancedOptionsStep?.UseFastNtfsScan"
                       [stepFocused]="currentStep?.title === scheduleTitle"
                       [invalidFFIStorage]="invalidFFIStorage"
                       [isGFSMode]="isGFSMode"
                       [retentionTitle]="retentionTitle"
                       [retentionUseDefault]="FormModel.retentionPolicyStep.RetentionUseDefaultSettings"
                       (nextStep)="forceNextStepWizard($event, 'scheduleStep', scheduleTitle)"
                       (registerRequiredStep)="stepQueue.registerRequiredStep($event)"
                       (unregisterRequiredStep)="stepQueue.unregisterRequiredStep($event)"
                       (offFastNTFS)="offFastNTFSHandler()"
                       (incrementalData)="setIncrementalData($event)"
                       (changeType)="scheduleTypeChangeHandler($event)"></mbs-schedule-step>
  </mbs-wizard-step>
  <mbs-wizard-step *ngIf="isNBF"
                   [title]="retentionTitle"
                   [valid]="FormModel?.retentionPolicyStep?.valid"
                   (next)="retentionPolicyStep?.forceValid(currentStep)">
    <mbs-retention-policy-step #retentionPolicyStep
                               [minPeriod]="minPeriod"
                               [isHybrid]="FormModel.whereBackupStep.IsHybridBackup"
                               [isFullBackup]="FormModel?.scheduleStep?.fullBackupData?.enabled"
                               [isMonthly]="FormModel?.scheduleStep?.fullBackupData?.recurringPeriod === 'Monthly'"
                               [showImmutability]="showImmutability"
                               [showIntelligentRetention]="!invalidIntelligentRetentionStorage"
                               [showGFS]="showGFS"
                               [isFFIMode]="isFFIMode"
                               [incrementalScheduleData]="incrementalData"
                               [stepFocused]="currentStep?.title === retentionTitle"
                               (backStep)="forceBackStepWizard('retentionPolicyStep', retentionTitle)"
                               (nextStep)="forceNextStepWizard($event, 'retentionPolicyStep', retentionTitle)"
                               [(ngModel)]="FormModel.retentionPolicyStep"></mbs-retention-policy-step>
  </mbs-wizard-step>
  <mbs-wizard-step *ngIf="!isNBF && FormModel?.scheduleStep?.ScheduleType === 'recurring'"
                   [title]="'wizards:advanced_schedule_step_title' | i18next: { format: 'title' }"
                   [valid]="FormModel?.scheduleAdvancedStep?.valid"
                   (next)="scheduleAdvancedStep?.forceValid()">
    <mbs-schedule-advanced-step #scheduleAdvancedStep
                                [(ngModel)]="FormModel.scheduleAdvancedStep"></mbs-schedule-advanced-step>
  </mbs-wizard-step>
  <mbs-wizard-step *ngIf="!isNBF && FormModel?.scheduleStep?.ScheduleType === 'predefined'"
                   [title]="'wizards:simple_schedule_title' | i18next: { format: 'title' }"
                   [valid]="FormModel?.simpleScheduleStep?.valid"
                   (next)="simpleScheduleStep?.forceValid()">
    <mbs-simple-schedule-step #simpleScheduleStep
                              [(ngModel)]="FormModel.simpleScheduleStep"></mbs-simple-schedule-step>
  </mbs-wizard-step>
  <mbs-wizard-step *ngIf="FormModel?.scheduleStep?.ScheduleType !== 'instantly' && (!isLinux || (mainService.allowPrePostActions | async))"
                   [title]="(mainService.allowPrePostActions | async) ? ('wizards:pre_post_title' | i18next: { format: 'title' }) :
                            ('wizards:pre_post_title_backup_chain' | i18next: { format: 'title' })"
                   [valid]="FormModel?.prePostActionsStep?.valid"
                   (next)="prePostActionsStep?.forceValid()">
    <mbs-pre-post-actions-step #prePostActionsStep
                               [(ngModel)]="FormModel.prePostActionsStep"
                               [ChainPlans]="FormModel?.AvailableMachinePlans || []"></mbs-pre-post-actions-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="'wizards:notification_step_title' | i18next: { format: 'title' }"
                   [valid]="FormModel?.notificationStep?.valid">
    <mbs-notifications-step [(ngModel)]="FormModel.notificationStep"
                            (emailsNotificationChange)="emailsNotificationChangeHandler($event)"
                            [notification]="mainService.planNotification"></mbs-notifications-step>
  </mbs-wizard-step>
</mbs-wizard>
