import { Component, ContentChild, HostBinding, Input } from '@angular/core';
import { CardGroupComponent } from './card-group.component';

@Component({
  selector: 'mbs-card',
  templateUrl: './card.component.html'
})
export class CardComponent {
  @Input() invalid = false;
  @Input() innerBordered = false;
  @Input() border = true;
  @Input() hasPadding = true;
  @Input() size: 'xs' | 'sm' | 'lg' | '' = '';
  @Input() customClasses = '';
  @Input() staticHeight: string | number;
  @ContentChild(CardGroupComponent, { static: false }) cardGroups: CardGroupComponent;

  get cardSize(): string {
    return this.size ? `-${this.size}` : '';
  }

  get cardMainClasses(): string {
    return this.cardSize + ' ' + this.customClasses;
  }

  @HostBinding('style.height') get getHeight(): string {
    if (this.staticHeight) {
      if (typeof this.staticHeight === 'number') {
        return String(this.staticHeight) + 'px';
      } else {
        return this.staticHeight;
      }
    }
    return '';
  }
}
