import BrandLicense from '@models/BrandLicense';
import { AdministratorLicense } from '@models/licenses/administrator-license';
import { License } from '../../models/licenses/License';
import {Params} from "@angular/router";
import {LicenseType} from "@models/LicenseType.enum";

export class LicensesHelper {
  public static IsLicenseExpired(license: License): boolean {
    const now = new Date();
    return now > new Date(license.dateExpired);
  }
  public static isLicenseExpiresAfterManyYears(license: License | BrandLicense | AdministratorLicense): boolean {
    const manyYears = 9000;
    const expiredDate = new Date(license.dateExpired);

    return expiredDate.getFullYear() > manyYears;
  }

  public static getQueryParamsForCalculator(license: LicenseType, hid:string): Params{
    const licenseTypes = {
      1: 'sql',
      2: 'exchage',
      7: 'baremetal',
      9: 'desktop',
      10: 'vm',
      11: 'vmsocket',
      18: 'ultimate',
      29: 'deepinst'
    };

    return {
      buy: 'true', licenses: licenseTypes[license] || '', assign: `${hid}:${license}`
    }
  }


}
