<section [formGroup]="stepForm">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{ 'wizards:sql:sqlDatabasesTitle' | i18next: { format: 'title' } }}
    </h4>
  </header>

  <mbs-form-group>
    <mbs-radio formControlName="databaseBackupType"
               name="databaseBackupType"
               (change)="databaseBackupTypeChangeHandler($event)"
               [label]="'wizards:sql:sqlDatabasesAllLabel' | i18next"
               [subtitle]="'wizards:sql:sqlDatabasesAllDescription' | i18next"
               [subtitleOutside]="false"
               [value]="databaseBackupType.All"
               [id]="elementsSelector.id.databaseBackupTypeAll"></mbs-radio>
    <mbs-radio formControlName="databaseBackupType"
               name="databaseBackupType"
               (change)="databaseBackupTypeChangeHandler($event)"
               [label]="'wizards:sql:sqlDatabasesAllUserLabel' | i18next"
               [subtitle]="'wizards:sql:sqlDatabasesAllUserDescription' | i18next"
               [subtitleOutside]="false"
               [value]="databaseBackupType.User"
               [id]="elementsSelector.id.databaseBackupTypeUser"></mbs-radio>
    <mbs-radio *ngIf="!isRDMode"
               formControlName="databaseBackupType"
               name="databaseBackupType"
               (change)="databaseBackupTypeChangeHandler($event)"
               [attr.data-test-element]="elementsSelector.name.databaseBackupTypeSelected"
               [label]="'wizards:sql:sqlDatabasesSelectedLabel' | i18next"
               [value]="databaseBackupType.Selected"
               [id]="elementsSelector.id.databaseBackupTypeSelected"></mbs-radio>
  </mbs-form-group>

  <mbs-form-group *ngIf="!isRDMode"
                  [attr.data-test-element]="elementsSelector.name.databaseListSelect">
    <mbs-list-select id="what-backup-partition-table"
                     listType="table"
                     [bindSelected]="'Name'"
                     [bindDisabledValues]="disabledRowBy"
                     [selectAllIgnoreDisabled]="true"
                     [invalid]="showValidStatusListSelect && !value?.valid"
                     [keepState]="true"
                     [maxHeight]="'286px'"
                     [headers]="tableHeaders"
                     [isNeedSelectRow]="false"
                     [loadSource]="false"
                     [showCheckboxes]="value?.databaseBackupType === databaseBackupType.Selected"
                     [showHeaders]="true"
                     [showSearch]="false"
                     [selectedData]="selectedDatabases"
                     [data]="databases"
                     [noDataMessage]="'wizards:where_backup_no_data' | i18next"
                     (changeSelected)="databasesCheckedHandler($event)">
      <ng-template mbsTableCell
                   let-database>
        <mbs-text-ellipsis [tooltip]="database.Name">{{database.Name}}</mbs-text-ellipsis>
      </ng-template>
    </mbs-list-select>
  </mbs-form-group>

  <mbs-checkbox formControlName="copyOnly"
                (change)="copyOnlyChangeHandler($event)"
                [label]="'wizards:sql:sqlDatabasesCopyOnlyLabel' | i18next"
                [id]="elementsSelector.id.copyOnlyCheckbox">
    <ng-container appendLabel>
        <span class="fa fa-info-circle ml-2 cursor-help text-info"
              container="body"
              placement="right-bottom auto"
              tooltipClass="tooltip-lg text-left"
              [ngbTooltip]="tooltipText"></span>
      <ng-template #tooltipText>
        {{'wizards:sql:sqlDatabasesCopyOnlyTooltipFirst' | i18next}} <br>
        {{'wizards:sql:sqlDatabasesCopyOnlyTooltipSecond' | i18next}}
      </ng-template>
    </ng-container>
  </mbs-checkbox>
</section>

<ng-template #copyOnlyModal>
  <mbs-alert [type]="mbsPopupType.warning"
             [icon]="true">
    {{'wizards:sql:sqlDatabasesCopyOnlyModalAlert' | i18next}}
  </mbs-alert>

  {{'wizards:sql:sqlDatabasesCopyOnlyModalText' | i18next}}
</ng-template>
