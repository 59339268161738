<div (click)="handleClick($event)"
     [ngClass]="getTileClasses()"
     class="mbs-tile">
  <ng-container *ngTemplateOutlet="tileTemplate ? tileTemplate : defaultTile; context: {$implicit: data}">
  </ng-container>
</div>

<ng-template #defaultTile
             let-item>
  <header class="mbs-tile_header">
    <div class="form-row mbs-tile_header-row">
      <ng-container *ngTemplateOutlet="headerTemplate ? headerTemplate : tileHeaderDefault; context: {$implicit: item}">
      </ng-container>
    </div>
  </header>
  <div *ngIf="showBody"
       class="mbs-tile_body">
    <div class="mbs-tile_data">
      <ng-container *ngFor="let header of headers">
        <div class="form-row mbs-tile_data-row">
          <div class="col-6 text-muted mbs-tile_data-option">
            {{ header.name }}:
          </div>
          <div [ngClass]="{'text-overflow': header.overflow}"
               class="col-6 mbs-tile_data-value">
            <ng-template #value
                         [ngTemplateOutletContext]="{$implicit: item, header: header}"
                         [ngTemplateOutlet]="templates ? templates[header.id] : defaultValue"></ng-template>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #defaultValue
             let-data
             let-header="header">
  {{ data[header.headerName] | json }}
</ng-template>


<ng-template #tileHeaderDefault>
  <div *ngIf="icon"
       class="mbs-tile_ico">
    <span [class]="icon"></span>
  </div>
  <div class="mbs-tile_name"
       ngbTooltip="{{ (hoverAble ? title : '') | titlecase }}">
    {{ title }}
  </div>
</ng-template>
