<mbs-alert [type]="MbsPopupType.info"
           [icon]="true"
           *ngIf="isTrialVMLicense">
  {{ 'backup:VMServerTrialLicense' | i18next }}
</mbs-alert>

<div class="border rounded p-3 license-tile">
  <ng-container *ngTemplateOutlet="licenseInfo; context: { license: this.license }"></ng-container>
  <ng-container *ngTemplateOutlet="buttons"></ng-container>
</div>

<ng-template #licenseInfo
             let-license="license">
  <div *ngIf="licenseStatus === null">{{ 'backup:licenseNotAssigned' | i18next }}</div>
  <div class="container pr-0"
       *ngIf="licenseStatus !== null">
    <div class="d-flex justify-content-between">
      <div>
        <b>{{ licenseName }}</b>
        <b *ngIf="isPaidVMLicense"> ({{'backup:socketsIncluded' | i18next : { count:license.socketsCount } }})</b>
        <b *ngIf="isVMSocketLicense"> ({{'backup:sockets' | i18next : { count:license.socketsCount } }})</b>
      </div>
      <div class="license-type px-3"
           [ngClass]="licenseStatusClass">{{ hint | i18next }}</div>
    </div>
    <div class="row mt-2">
      <div class="col-3">{{ 'backup:status' | i18next }}</div>
      <div class="col-9">
        {{ (license?.isExpired ? 'computers.module:license.status.expired' : 'computers.module:license.status.active') | i18next }}
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3">{{ 'backup:type' | i18next }}</div>
      <div class="col-9">
        {{ (license?.isTrial ? 'computers.module:license.status.trial' : 'computers.module:license.status.paid') | i18next }}
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3">{{ 'backup:activated' | i18next }}</div>
      <div class="col-9">
        <mbs-date-time-format     [time]="license?.activated"
                                  [dateTimeFormat]="mediumDateWithoutTimeMoment"></mbs-date-time-format>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3">{{ 'backup:validUntil' | i18next }}</div>
      <div class="col-9">
        <mbs-date-time-format     [time]="license?.validUntil"
                                  [dateTimeFormat]="mediumDateWithoutTimeMoment"></mbs-date-time-format>
      </div>
    </div>
    <div class="row mt-2"
         *ngIf="licenseStatus === ComputerLicenseStatus.PAID">
      <div class="col-3">{{ 'backup:autoRenew' | i18next }}</div>
      <div class="col-9">
        <mbs-auto-renewal-switcher (autoRenewChange)="loadComputer()"
                                   [license]="license"
                                   [isAutoRenew]="license?.autoRenew"
                                   [request]="getAutoRenewRequest(!license?.autoRenew, isPostPayment | async)"></mbs-auto-renewal-switcher>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #buttons>
  <div class="buttons d-flex justify-content-end mt-3">
    <mbs-button [type]="'primary'"
                [disabled]="('Readonly' | can:'read')"
                *ngIf="licenseStatus === null"
                (click)="selectLicense()">{{
      'backup:assign' | i18next
      }}</mbs-button>
    <mbs-button [type]="'success'"
                class="ml-2"
                *ngIf="isBuyBtnShown(isPostPayment | async)"
                (click)="(isShareIT || !isAgentSupportOfflineLicenseManagement) ? buyLicense() : proceed()">{{ 'backup:buyLicense' | i18next }}</mbs-button>
    <mbs-button [type]="'outline-danger'"
                [disabled]="('Readonly' | can:'read')"
                class="ml-2"
                *ngIf="isReleaseBtnShown(isPostPayment | async)"
                (click)="releaseLicense()">{{
      'backup:releaseLicense' | i18next
      }}</mbs-button>
    <mbs-button [type]="'secondary'"
                [disabled]="('Readonly' | can:'read')"
                class="ml-2"
                *ngIf="isSelectBtnShown(isPostPayment | async)"
                (click)="selectLicense()">{{ 'backup:changeLicense' | i18next }}</mbs-button>
  </div>
</ng-template>
