import { Component, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CompaniesFacade } from '@facades/companies.facade';
import Company from '@models/Company';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormsUtil, ModalComponent } from 'mbs-ui-kit';
import { filter, map, switchMap, take } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'mbs-add-company-modal',
  templateUrl: './add-company-modal.component.html'
})
export class AddCompanyModalComponent {
  public requestInProgress = false;
  public modalForm = this.fb.group({
    companyName: [null, [Validators.required]]
  });

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(private fb: FormBuilder, private companiesFacade: CompaniesFacade) {
    this.companiesFacade.loadData();
  }

  public onCreateCompanyButtonClick() {
    if (!this.modalForm.valid) {
      FormsUtil.triggerValidation(this.modalForm);
      return;
    }

    this.requestInProgress = true;

    this.companiesFacade
      .create({ name: this.modalForm.value.companyName } as Company)
      .pipe(
        switchMap((company) =>
          this.companiesFacade.getData$().pipe(
            filter((companies) => companies.some((comp) => comp.id === company.id)),
            take(1),
            map(() => company)
          )
        ),
        untilDestroyed(this)
      )
      .subscribe({
        next: (company) => this.baseModal.save(company),
        error: () => (this.requestInProgress = false)
      });
  }
}
