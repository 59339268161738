<label class="mbs-switcher"
       [ngClass]="labelClasses"
       [class.font-weight-bold]="boldLabel"
       [class.-reverse]="labelPosition === 'left'"
       [class.-disabled]="disabledState"
       [class.-readonly]="readonlyState"
       [class.w-100]="expandLabel">

  <input #inputElement
         type="checkbox"
         [id]="id"
         class="mbs-switcher_input"
         [disabled]="disabledState"
         [readonly]="readonlyState"
         [checked]="selected"
         (change)="handleChange($event)"
         (click)="handleClick($event)"
         [name]="name"/>

  <span class="mbs-switcher_track">
    <span class="mbs-switcher_toggler"></span>
  </span>

  <span *ngIf="loading$ | async" class="-loader loader-sm loader-primary my-1"></span>

  <span *ngIf="showStatus || label"
        class="mbs-switcher_content"
        [class.-reverse]="statusPosition === 'right'"
        [class.overflow-hidden]="textOverflow"
        [class.w-100]="expandLabel && expandedLabelPosition === 'left'">

    <mbs-status *ngIf="showStatus"
                [class.justify-content-between]="statusPosition !== 'right'"
                [iconPosition]="statusPosition !== 'right' ? 'right' : 'left'"
                [disabled]="disabledState"
                [type]="selected ? 'success' : 'danger'"
                [size]="MbsSize.md"
                [style.width]="statusWidth">
      {{ selected ? Switch.on : Switch.off }}
    </mbs-status>

    <span *ngIf="label"
          class="mbs-switcher_label"
          [class.-disabled]="disabledState"
          [class.text-overflow]="textOverflow">
      <span class="mbs-switcher_label-content"
            [ngClass]="labelSizeClass">
        <ng-template *ngTemplateOutlet="isTemplate(label) ? label: labelDefault; context: { $implicit: labelContext }"></ng-template>
      </span>
    </span>
  </span>
</label>

<ng-content select="[appendLabel]"
            ngProjectAs="[appendLabel]"></ng-content>

<div *ngIf="subtitle && !subtitleHide"
     class="mbs-switcher_subtitle"
     [class.-inside]="!subtitleOutside"
     [class.-disabled]="disabledState"
     [class.-reverse]="labelPosition === 'left'"
     [ngClass]="subtitleClasses">
  {{ subtitle }}
</div>

<div *ngIf="switcherDescription && !switcherDescription.hide"
     class="mbs-switcher_description"
     [class.-inside]="!descriptionOutside"
     [class.-reverse]="labelPosition === 'left'"
     [ngClass]="descriptionClasses">
  <ng-template [ngTemplateOutlet]="switcherDescription.template"></ng-template>
</div>

<ng-template #labelDefault>
  {{ label }}
</ng-template>
