import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { QuickRestoreSchemaModalComponent } from '@components/quick-restore-schema-modal/quick-restore-schema-modal.component';
import { DownloadToSelectedComputerComponent } from '@components/sidepanel-backup/tabs/download-to-selected-computer/download-to-selected-computer.component';
import { TFARequireModalComponent } from '@components/tfa/components/tfa-require-modal/tfa-require-modal.component';
import { TFAService } from '@components/tfa/services/tfa.service';
import { ComputerBackupFacade } from '@facades/computer.backup.facade';
import Administrator from '@models/Administrator';
import Computer, { AgentType, OsType } from '@models/Computer';
import { PermissionsEnum } from '@models/PermissionsEnum';
import { TempTokenData } from '@models/auth-models';
import { ShortPlanInfo } from '@models/backup/plan-info-model';
import { PlanRunInfo } from '@models/backup/plan-run-info-model';
import { PlanRunSimpleStatus } from '@models/backup/plan-run-simple-status';
import {
  ComputerIcon,
  DeepSyncEnum,
  GenerationGFSType,
  GenerationGFSTypeIcons,
  GetBackupContentBunchType,
  GetBackupContentType,
  HomeComputerIcon,
  ItemTypeEnum,
  MyTreeElements,
  StorageEncryptionState,
  TreeBunchesIcons,
  TreeRestorePointsIconPath,
  i18KeysByType
} from '@models/backup/storages-type';
import { Build } from '@models/build';
import { RemoteCommandType } from '@models/rm/remote-command-model';
import { StorageConnection } from '@models/storge-connections';
import { PasswordModalComponent, PasswordModalParams } from '@modules/password-modal/password-modal.component';
import { StepsHelpers } from '@modules/wizards/helpers/steps-helpers';
import {
  ArchiveRestorePointSummaryStatus,
  BunchItem,
  ComputerBackupStorageItem,
  IdsForCurrentFormats
} from '@modules/wizards/models/backup-to-restore-models';
import { RestorePointItem } from '@modules/wizards/models/restore-point-models';
import { TreeIconPath } from '@modules/wizards/models/what-backup-tree-model';
import {
  BitLockerPasswordType,
  GetBackupContentParams,
  ParamsForDeepSync,
  ParamsForDeleteBackupObject,
  ParamsForRCRestoreMethods,
  WizardStepsService
} from '@modules/wizards/services/wizard-steps.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services';
import { unidentifiedErrorText } from '@shared/interceptors/error-handler.interceptor';
import { getRestorePointObjectPath } from '@utils/backup-storages.utils';
import { mediumDateWithTimeMoment } from '@utils/date';
import fileToBase64 from '@utils/fileToBase64';
import { isWindows } from '@utils/is-windows';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep, get, isNil } from 'lodash';
import { IntMaxValue, MbsPopupType, MbsSize, ModalService, ModalSettings, ToastService, TreeComponent, TreeElement } from 'mbs-ui-kit';
import { AlertType } from 'mbs-ui-kit/alert/alert.model';
import * as moment from 'moment';
import { BehaviorSubject, EMPTY, Observable, defer, from, noop, of, throwError } from 'rxjs';
import { catchError, debounceTime, filter, first, map, skip, startWith, switchMap, take, tap } from 'rxjs/operators';

const TFAErrorStatus = 420;

type DataFromItemType = { src: string; label: string; rightBottomIcon: string; leftTopIcon: string; restorePoint?: string };

@UntilDestroy()
@Component({
  selector: 'mbs-browse-storage',
  templateUrl: './browse-storage.component.html',
  styleUrls: ['./browse-storage.component.scss']
})
export class BrowseStorageComponent implements OnChanges, OnDestroy, OnInit {
  @Input() joinForInput = '\\';
  @Input() isOnline: boolean;
  @Input() isOnlineAccess: boolean;
  @Input() computerData: Computer;
  @Input() downloadLinkData: Build;
  @Input() currentPlanDestination: ShortPlanInfo;
  @Input() storages: StorageConnection[] = [];
  @Input() set selectedStorage(storage: StorageConnection) {
    this.mySelectedStorage = storage;
  }

  @Output() changedSelectedStorage = new EventEmitter<StorageConnection>();
  @Output() fullScreenChange = new EventEmitter<boolean>();

  // Readonly block start
  public readonly modalContainer = '.mbs-tabset_content';
  public readonly IntMaxValue = IntMaxValue;
  public readonly AlertType = AlertType;
  public readonly MbsSize = MbsSize;
  public readonly itemTypeEnum = ItemTypeEnum;
  public readonly textForDeleteMap = {
    [ItemTypeEnum.RestorePoint]: this.i18nPipe.transform(
      `backup-storages:browseStorage.tables.${i18KeysByType[ItemTypeEnum.RestorePoint]}`
    ),
    [ItemTypeEnum.RestorePointIncremental]: this.i18nPipe.transform(
      `backup-storages:browseStorage.tables.${i18KeysByType[ItemTypeEnum.RestorePoint]}`
    ),
    [ItemTypeEnum.RestorePointTLog]: this.i18nPipe.transform(
      `backup-storages:browseStorage.tables.${i18KeysByType[ItemTypeEnum.RestorePoint]}`
    ),
    [ItemTypeEnum.Generation]: this.i18nPipe.transform(`backup-storages:browseStorage.tables.${i18KeysByType[ItemTypeEnum.Generation]}`)
  };
  public readonly deepSyncEnum = DeepSyncEnum;
  public readonly generationGFSType = GenerationGFSType;
  public readonly generationGFSTypeIcons = GenerationGFSTypeIcons;
  public readonly elementsSelector = {
    name: {
      selectedStorage: 'selected-storage',
      searchInput: 'search-input',
      treeLoader: 'tree-loader',
      treeBlock: 'tree-block',
      treeRightBottomIcon: 'tree-right-bottom-icon',
      treeLeftTopIcon: 'tree-left-top-icon',
      treeDeepSyncProcessLoader: 'tree-deep-sync-process-loader',
      treeGFSImage: 'tree-gfs-image',
      treeLoadMoreForComputers: 'tree-load-more-for-computers',
      treeNoDataNoLoadMoreBlock: 'tree-no-data-no-load-more-block',
      treeNoDataForTree: 'tree-no-data-for-tree',
      treeNoDataForRightTable: 'tree-no-data-for-right-table',
      loadingRightTableData: 'loading-right-table-data',
      rightTable: 'right-table'
    }
  };

  private readonly FIRST_BACKUP_AGENT_SUPPORTED_BITLOCKER_FILE = 783;
  private readonly quickRestoreModalSettings: ModalSettings = {
    footer: {
      okButton: { show: false },
      cancelButton: { text: this.i18nPipe.transform('buttons:close', { format: 'title' }) }
    }
  };
  private readonly deleteModalSettings: ModalSettings = {
    header: { title: this.i18nPipe.transform('backup-storages:browseStorage.confirmBuildDeletionTitle', { format: 'title' }) },
    footer: { okButton: { text: this.i18nPipe.transform('buttons:delete'), type: 'danger' } }
  };
  private readonly openQuickRestoreModalSettings = {
    header: { title: this.i18nPipe.transform(`computers.module:backupSidePanel.restoreAppRequired`, { format: 'title' }) },
    footer: {
      okButton: {
        text: this.i18nPipe.transform(`computers.module:backupSidePanel.openQuickRestoreModalButton`, { format: 'title' })
      },
      cancelButton: { text: this.i18nPipe.transform('buttons:cancel', { format: 'title' }) }
    }
  };
  private readonly quickRestoreTypes = [
    ItemTypeEnum.Bunch,
    ItemTypeEnum.Generation,
    ItemTypeEnum.RestorePoint,
    ItemTypeEnum.RestorePointIncremental,
    ItemTypeEnum.RestorePointTLog
  ];
  private readonly SQLTypes: GetBackupContentBunchType[] = [
    GetBackupContentBunchType.MsSql,
    GetBackupContentBunchType.Database,
    GetBackupContentBunchType.Exchange
  ];
  // Readonly block end

  // Public block start
  public noBackup = false;
  public showComputers = true;
  public mySelectedStorage: StorageConnection = null;
  public searchText = '';
  public bitlockerPasswordForm = new UntypedFormGroup({
    passwordType: new FormControl('1'),
    password: new FormControl(''),
    recoveryPassword: new FormControl(''),
    keyFile: new FormControl('')
  });
  public encryptionPasswordType = false;
  public recoveryPasswordInputType = false;
  public bitLockerFileName: string;
  public storageHashTable: {
    [key: string]: { prefix?: string; generationId?: string; isLoading?: boolean; isIbb?: boolean; data?: MyTreeElements[] };
  } = {};
  public rightTableData: MyTreeElements[] = null;
  public selectedItemsForQR: MyTreeElements[] = [];
  public selectedItemsForQRHash: { [key: string]: MyTreeElements } = {};
  public treeItemForActions: MyTreeElements = null;
  public selectedTreeItem: MyTreeElements = null;
  public loadingRightTableData = false;
  public deleteProcess = false;
  // Public block end

  // Private block start
  private ownerId = '';
  private allowedRecovery = false;
  private providerTimezone = 0;
  private backupVersionUpdated: string;
  private bunchesHashTable: { [key: string]: { [key: string]: string } } = {};
  private rootNodes: { [key: string]: TreeElement } = {};

  private passwordGroup = new UntypedFormGroup({ password: new FormControl('', [Validators.required]) });
  private passwordRecoveryRoot: MyTreeElements;
  private currentEncryptedRestorePoint = '';
  private lastPasswordModalPath: string;
  private passwordModalIsOpen = false;
  private bitLockerFileContent: string | ArrayBuffer;
  // Private block end

  get isWindowsComputer(): boolean {
    return this.computerData?.os === OsType.windows;
  }

  get isNBF(): boolean {
    const bunchId = this.passwordRecoveryRoot.bunchId;

    return bunchId !== IdsForCurrentFormats.File && bunchId !== IdsForCurrentFormats.Ibb;
  }

  get selectedItemForQR(): MyTreeElements {
    if (this.treeItemForActions?.isCanBeQuickRestored && (!this.treeItemForActions?.isLegacy || !this.selectedItemsForQR?.length)) {
      return this.treeItemForActions;
    }

    return this.selectedTreeItem.isCanBeQuickRestored && (!this.selectedTreeItem?.isLegacy || !this.selectedItemsForQR?.length)
      ? this.selectedTreeItem
      : this.selectedItemsForQR[0];
  }

  get isBitLockedFileSupported(): boolean {
    return Computer.IsSupportedAgentVersion(this.computerData, AgentType.Backup, this.FIRST_BACKUP_AGENT_SUPPORTED_BITLOCKER_FILE);
  }

  get bitlockerPasswordTypeControl(): FormControl {
    return <FormControl>this.bitlockerPasswordForm?.get('passwordType');
  }

  get bitlockerPasswordControl(): FormControl {
    return <FormControl>this.bitlockerPasswordForm?.get('password');
  }

  get bitlockerRecoveryPasswordControl(): FormControl {
    return <FormControl>this.bitlockerPasswordForm?.get('recoveryPassword');
  }

  get bitlockerKeyFileControl(): FormControl {
    return <FormControl>this.bitlockerPasswordForm?.get('keyFile');
  }

  get bitlockerFileNotAvailable(): string {
    return this.i18nPipe.transform('computers.module:modals.bitlockerFileNotAvailable');
  }

  private get getBitlockerPasswordControls(): Array<{ value: string; control: FormControl }> {
    return [
      { value: '1', control: this.bitlockerPasswordControl },
      { value: '2', control: this.bitlockerRecoveryPasswordControl },
      { value: '3', control: this.bitlockerKeyFileControl }
    ];
  }

  @ViewChild('deleteConfirmTemplate', { static: true, read: TemplateRef }) deleteConfirmTemplate: TemplateRef<any>;
  @ViewChild(TreeComponent, { static: false }) treeComponent: TreeComponent;
  @ViewChild('bitLockerPasswordModal', { static: true, read: TemplateRef }) bitLockerPasswordModal: TemplateRef<any>;

  constructor(
    public ability: AbilityService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private facade: ComputerBackupFacade,
    private stepService: WizardStepsService,
    private i18nPipe: I18NextPipe,
    private modalService: ModalService,
    private toastService: ToastService,
    private TFAService: TFAService
  ) {
    this.authService.currentUser.pipe(untilDestroyed(this)).subscribe((user: Administrator) => {
      if (user) {
        this.ownerId = (user.ProviderInfo && user.ProviderInfo?.Id) || '';
        this.allowedRecovery = user.ProviderInfo?.PasswordRecoveryEnabled && user.IsProvider;
        this.providerTimezone = user.ProviderInfo?.TimeZoneOffset;
      }
    });
  }

  ngOnInit(): void {
    this.baseInitData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.computerData?.currentValue) {
      this.backupVersionUpdated = this.computerData.apps
        ?.find((app) => app.applicationId === AgentType.Backup)
        ?.version?.replaceAll('.', '');
    }

    if (changes.selectedStorage?.previousValue && changes.selectedStorage?.currentValue) {
      this.resetRightTable();
      this.baseInitData();
    }
  }

  ngOnDestroy(): void {
    delete this.storageHashTable;
  }

  backToStoragesClickHandler(): void {
    this.fullScreenChange.emit(false);
  }

  expandedLazyNodeHandler(data: { event; item: MyTreeElements }): void {
    if (!data?.item?.shown) return;

    const notChildren = isNil(data?.item?.children);

    if (notChildren && data.item.gotChildren) return;

    const closeAction = !notChildren && !data.item?.expanded;

    if (closeAction && !this.isCurrentBrunchClicked(data.item, this.selectedTreeItem)) return;

    if (!this.loadingRightTableData) this.loadingRightTableData = notChildren;

    this.selectedTreeItem = !closeAction && !notChildren ? data.item : null;

    this.treeItemSelectHandler(this.selectedTreeItem);
  }

  needShowSeparator(item: MyTreeElements): boolean {
    return (
      item.isLegacy ||
      item.isObjectLock ||
      !!item.purgeSummary ||
      !!item.keepBackupFor ||
      (!!item.daysToPurge && this.isWindowsComputer) ||
      (item.GFSSettings && item.GFSSettings.type !== GenerationGFSType.NotAssigned) ||
      (item?.label === this.computerData?.name && item?.itemType === ItemTypeEnum.File)
    );
  }

  treeItemSelectHandler(treeItem: MyTreeElements): void {
    if (!treeItem) return void this.resetRightTable();

    this.storageHashTable[treeItem.storageId].prefix = treeItem.prefix;

    if (this.selectedTreeItem.children?.length) this.selectedTreeItem.children.forEach((ch) => this.closeAllChildrenInTree(ch));

    this.updateRightTable(this.selectedTreeItem.children);
  }

  mySelectedStorageChangeHandler(storage: StorageConnection): void {
    this.noBackup = false;
    this.resetRightTable();
    this.selectedTreeItem = null;
    this.changedSelectedStorage.emit(storage);
  }

  showComputersChangeHandler(): void {
    this.resetRightTable();
    this.baseInitData();
  }

  selectedItemsForQRChangeHandler(elements: MyTreeElements[]): void {
    this.selectedItemsForQR = elements;

    this.selectedTreeItem.checked = !!elements.length;
    this.selectedTreeItem.indeterminate = !!elements.length && elements.length !== this.rightTableData.length;

    if (!elements.length) this.selectedTreeItem.children?.forEach((child) => (child.checked = false));

    this.cdr.detectChanges();
  }

  itemDblClickHandler(event: { event: MouseEvent; item: TreeElement }): void {
    if (this.treeComponent) {
      this.treeComponent.handleClickItem(this.treeComponent.getFlatByNested(event.item), event.event, true);
    }
  }

  deleteSelectedClickHandler(item: MyTreeElements): void {
    this.deleteProcess = true;

    this.treeItemForActions = item || this.selectedTreeItem;

    this.modalService
      .open(this.deleteModalSettings, this.deleteConfirmTemplate)
      .then((confirm) => {
        if (!confirm) return;

        this.deleteSelectedItem();
      })
      .catch(() => {
        this.treeItemForActions = null;
        this.deleteProcess = false;
      });
  }

  openModalWithQuickRestore(usePath = false): void {
    const modal = this.modalService.openRef(QuickRestoreSchemaModalComponent, this.quickRestoreModalSettings);

    modal.componentInstance.needPreparingTextShow = true;
    modal.componentInstance.downloadLink = this.downloadLinkData?.public?.downloadLink;

    modal.result.then(noop).catch(noop);

    if (
      isWindows() &&
      (this.ability.can('read', PermissionsEnum.RemoteManagement) || !this.authService.isMBSMode) &&
      this.ability.can('read', PermissionsEnum.QuickRestore)
    ) {
      queueMicrotask(() => this.createQuickRestoreSchema(usePath, modal));
    }
  }

  restoreToLocalClickHandler(data: { isLocal: boolean; item: MyTreeElements }): void {
    this.treeItemForActions = data?.item || this.selectedTreeItem;

    if (data?.isLocal) return this.downloadToSelectedComputer();

    this.openModalWithQuickRestore();
  }

  downloadToSelectedComputer(invalidPass = false, selectedPath = ''): void {
    this.modalService
      .openCustom(DownloadToSelectedComputerComponent, this.getSettingsForQRToComputer(invalidPass, selectedPath))
      .then((result: any) => {
        if (!result) return;

        const itemForQR = this.selectedItemForQR;

        const params = {
          agentType: 'backup',
          commandType: 'StartQuickRestore',
          params: {
            RestorePointObjectPath: itemForQR?.restorePointObjectPath,
            UseCustomPrefix: false,
            ItemsToRestore: this.getItemsToRestore(result.restorePath, true),
            Password: result.password || '',
            StartAfterCreate: true,
            Destination: result.selectedPath
          }
        };

        this.runQuickRestoreOnSelectedComputer(params, itemForQR, result.selectedPath);
      })
      .catch(noop);
  }

  getRoot(storageId: string): MyTreeElements {
    if (!this.rootNodes[storageId]) {
      this.rootNodes[storageId] = { id: `rootNode-${storageId}`, label: '', storageId } as MyTreeElements;
    }

    return this.rootNodes[storageId];
  }

  getSubtree(root: MyTreeElements): Observable<MyTreeElements[]> {
    const subtree$ = new BehaviorSubject<MyTreeElements[]>(null);

    this.selectedItemsForQR = [];
    this.storageHashTable[root.storageId].isIbb = this.bunchesHashTable[root.storageId][root.bunchId] === 'DiskImage';

    this.newSubtreeByParams(this.getParams(root, false, true), subtree$, root);

    return subtree$ as Observable<MyTreeElements[]>;
  }

  loadMoreComputers(): void {
    this.showComputers = true;
    this.noBackup = false;
    this.showComputersChangeHandler();
  }

  changeBitLockerPasswordType(): void {
    const requiredValidators: ValidatorFn[] = [Validators.required];

    this.getBitlockerPasswordControls.forEach((element) => {
      if (this.bitlockerPasswordTypeControl.value === element.value) {
        element.control.enable();
        element.control.addValidators(requiredValidators);
        element.control.updateValueAndValidity();

        return;
      }

      element.control.disable();
      element.control.removeValidators(requiredValidators);
    });
  }

  async onChangeBLPassFile(event: Event & HTMLInputElement): Promise<void> {
    const eventTarget = event.target as HTMLInputElement;

    this.bitLockerFileContent = '';
    this.bitLockerFileName = '';

    if (this.bitlockerKeyFileControl.valid && eventTarget && eventTarget.files && eventTarget.files.length) {
      const file = eventTarget.files[0];

      this.bitLockerFileContent = '';
      this.bitLockerFileName = '';

      from(fileToBase64(file))
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (fileContent) => {
            this.bitLockerFileContent = fileContent.split(',')[1];
            this.bitLockerFileName = file.name;
          },
          error: (err) => console.log('error while reading file', err)
        });
    }
  }

  // Private methods section

  // Initial methods Start
  private baseInitData(): void {
    if (!this.mySelectedStorage?.ID) return;

    let planDestination: ShortPlanInfo = null;

    if (this.currentPlanDestination && this.currentPlanDestination.destinationId === this.mySelectedStorage.ID) {
      planDestination = this.currentPlanDestination;
      this.currentPlanDestination = null;
    }

    this.loadDestination(this.mySelectedStorage.ID, !!planDestination)
      .pipe(
        switchMap(() => (planDestination ? this.loadPlanDestination(planDestination) : EMPTY)),
        untilDestroyed(this)
      )
      .subscribe();

    this.facade.refreshCurrentComputer();
  }

  private loadDestination(storageId: string, skipStopLoading = false): Observable<any> {
    if (!this.computerData) return EMPTY;

    this.storageHashTable[storageId] = { isLoading: true };
    this.bunchesHashTable[storageId] = {};

    return this.facade.computer$.pipe(
      skip(1),
      take(1),
      switchMap((computer) => this.loadDataAfterDestinations(computer, storageId, skipStopLoading))
    );
  }

  private loadDataAfterDestinations(computer: Computer, storageId: string, skipStopLoading: boolean): Observable<any> {
    if (!computer) return EMPTY;

    const dataForParams = { storageId: storageId, label: '', id: '' };
    const params: ParamsForRCRestoreMethods | GetBackupContentParams = this.showComputers
      ? {
          agentType: 'backup',
          commandType: 'GetRestoreSourcePrefix',
          params: { ConnectionId: storageId }
        }
      : this.getParams(dataForParams, true);

    return this.stepService.getRemoteCommandData(params, this.computerData.hid).pipe(
      tap((results) => {
        this.storageHashTable[storageId].isLoading = skipStopLoading;

        !this.showComputers ? this.prepareBunches(results, storageId) : this.prepareComputers(results, storageId, this.computerData.name);
      }),
      catchError(() => {
        this.storageHashTable[storageId].isLoading = skipStopLoading;

        return of();
      }),
      untilDestroyed(this)
    );
  }

  private loadPlanDestination(planDestination: ShortPlanInfo): Observable<MyTreeElements[]> {
    if (!this.storageHashTable[planDestination.destinationId]) return of([]);

    const planElement = this.storageHashTable[planDestination.destinationId].data?.find((el) => el.id === planDestination.id);

    if (!planElement) return this.offLoadingAndReturnVoidTreeItemsList(planDestination.destinationId);

    return this.getSubTreeByParams(this.getParams(planElement), planElement).pipe(
      tap((results) => {
        if (results && results.length) {
          planElement.children = results;
          planElement.gotChildren = true;
          planElement.expanded = true;
          results.forEach((plan) => (plan.parent = planElement));
          this.storageHashTable[planDestination.destinationId].isLoading = false;
        }
      }),
      catchError(() => this.offLoadingAndReturnVoidTreeItemsList(planDestination.destinationId)),
      untilDestroyed(this)
    );
  }

  private offLoadingAndReturnVoidTreeItemsList(id: string): Observable<MyTreeElements[]> {
    this.storageHashTable[id] = { isLoading: false };

    return of([]);
  }

  private prepareBunches(bunches, storageId: string): void {
    if (!bunches?.data?.items) return;

    if (bunches.data.items.length) {
      const myPrefixPath: string[] = bunches.data.items[0]?.path?.split(this.joinForInput) || [];
      const idx = myPrefixPath.findIndex((str) => str === storageId) + 1;

      if (idx && myPrefixPath[idx]) this.storageHashTable[storageId].prefix = myPrefixPath[idx];
    }

    this.storageHashTable[storageId].data = bunches.data.items.map((item: BunchItem) => {
      const id = item.bunchId || item.bunchName || item.displayName;

      this.bunchesHashTable[storageId][id] = item.type;

      return {
        label: item.bunchName || item.displayName,
        id,
        path: item.path,
        hideCheckbox: true,
        expanded: false,
        rightBottomIcon:
          item.bunchName === IdsForCurrentFormats.VMWare ? TreeBunchesIcons.VmWare : this.getBunchLastStatusIcon(item.lastBackupStatus),
        iconCustom: this.getBunchItemIcon(item),
        needShowRV: item.isNBF && item.type === 'DiskImage',
        shown: true,
        itemType: item.itemType,
        isLegacy: !item.isNBF,
        isDeleted: !item.isPlanExists,
        isCanBeDeleted: item.isCanBeDeleted,
        isCanBeQuickRestored: true,
        sizeOnStorage: item.sizeOnStorage,
        totalSavings: item.totalSavings ? +item.totalSavings.toFixed(2) : item.totalSavings,
        purgeSummary: item.purgeSummary,
        gotChildren: item.isCanBeExpand === false,
        keepBackupFor: this.getKeepBackupFor(item.retentionKeepBackupFor),
        storageId: storageId,
        bunchId: id,
        prefix: this.storageHashTable[storageId].prefix,
        countOfErrorStarts: item.countOfErrorRestorePoints || 0,
        countOfSuccessfulStarts: item.countOfSuccessfulRestorePoints || 0,
        countOfWarningStarts: item.countOfWarningRestorePoints || 0
      } as MyTreeElements;
    });

    if (!this.storageHashTable[storageId].data?.length) this.noBackup = true;
  }

  private prepareComputers(computers, storageId: string, currentComputerName: string): void {
    if (!computers?.data) return;

    const allComputers: TreeElement[] = computers.data.map((item: ComputerBackupStorageItem) => {
      const newId = item.id || storageId + item.name;
      const name = item.name || item.displayName;

      return {
        label: name,
        prefix: item.name,
        isCurrent: item.isCurrent,
        id: newId,
        hideCheckbox: true,
        gotChildren: item.IsCanBeExpand === false,
        path: `${storageId}${this.joinForInput}${item.name}`,
        expanded: false,
        rightBottomIcon: '',
        iconCustom: item.isCurrent ? HomeComputerIcon : ComputerIcon,
        shown: true,
        itemType: ItemTypeEnum.Prefix,
        storageId: storageId
      } as TreeElement;
    });

    const lowName = currentComputerName.toLowerCase();
    const prefixEqualCurrentName: TreeElement = allComputers.find(
      (computer: TreeElement) => computer.label.toString().toLowerCase() === lowName
    );

    if (!prefixEqualCurrentName) {
      allComputers.push({
        label: currentComputerName,
        id: this.computerData.hid,
        hideCheckbox: true,
        expanded: false,
        gotChildren: true,
        children: [],
        iconCustom: ComputerIcon,
        shown: true,
        itemType: ItemTypeEnum.File,
        storageId: storageId
      } as TreeElement);
    }

    this.storageHashTable[storageId].data = allComputers;
  }
  // Initial methods End

  // Right Table methods Start
  private updateRightTable(data: MyTreeElements[]): void {
    if (data) {
      this.rightTableData = cloneDeep(data);

      this.updateSelectedItemsForQR();

      this.selectedItemsForQR.forEach((el) => (el.parent = this.selectedTreeItem));
    }

    this.loadingRightTableData = false;
  }

  private resetRightTable(): void {
    this.rightTableData = null;
    this.selectedItemsForQRHash = {};
    this.selectedItemsForQR = [];
  }

  private updateSelectedItemsForQR(): void {
    if (!this.selectedTreeItem?.isCanBeQuickRestored) {
      this.selectedItemsForQR = [];

      return;
    }

    if (this.selectedItemsForQR.length && this.selectedItemsForQR?.[0]?.parent?.id === this.rightTableData?.[0]?.parent?.id) {
      this.selectedItemsForQR.forEach((el) => (this.selectedItemsForQRHash[el.id] = el));
      this.selectedItemsForQR = this.rightTableData.filter((el) => this.selectedItemsForQRHash[el.id]);

      return;
    }

    this.selectedItemsForQR = this.selectedTreeItem.checked ? this.rightTableData : [];
  }
  // Right Table methods End

  // Quick Restore methods Start
  private openQuickRestoreModal(): void {
    this.modalService
      .open(this.openQuickRestoreModalSettings, this.i18nPipe.transform(`computers.module:backupSidePanel.restoreAppRequiredDescription`))
      .then((result) => result && this.openModalWithQuickRestore(true))
      .catch(noop);
  }

  private createQuickRestoreSchema(usePath = false, modal: NgbModalRef): void {
    modal.componentInstance.preparing = true;

    this.authService
      .getTempToken(this.computerData.userAccount.id, this.computerData.hid, 'OneTimeQuickRestore')
      .pipe(
        catchError(() => of(null)),
        untilDestroyed(this)
      )
      .pipe(
        map((tokenData: TempTokenData) => {
          const itemForQR = this.selectedItemForQR;
          const jsonObj: any = {
            UserToken: tokenData?.token,
            RestorePointObjectPath: getRestorePointObjectPath(
              {
                computer: this.computerData,
                storage: !itemForQR ? this.mySelectedStorage : null,
                item: itemForQR,
                storageHash: this.storageHashTable
              },
              !!itemForQR && usePath
            ),
            ownerId: this.ownerId
          };

          if (!usePath && (this.isOnline || itemForQR.restorePath)) {
            jsonObj.ItemsToRestore = this.getItemsToRestore();
          }

          return jsonObj;
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: (jsonIbj: { [key: string]: string }) => {
          modal.componentInstance.preparing = false;
          modal.componentInstance.createSchemaParams = jsonIbj;
        },
        error: () => (modal.componentInstance.preparing = false)
      });
  }

  private getItemsToRestore(alternativePath = '', isSelected = false): string[] {
    if (this.treeItemForActions?.isLegacy && this.selectedItemsForQR.length) {
      return [this.getItemsToRestorePath(this.selectedItemsForQR[0], alternativePath, isSelected)];
    }

    if (this.treeItemForActions?.checked && !this.treeItemForActions.indeterminate && this.treeItemForActions.isCanBeQuickRestored) {
      return [this.getItemsToRestorePath(this.treeItemForActions, alternativePath, isSelected)];
    }

    if (this.treeItemForActions.indeterminate || !this.treeItemForActions.isCanBeQuickRestored) {
      return this.selectedItemsForQR.map((item: MyTreeElements) => this.getItemsToRestorePath(item, alternativePath, isSelected));
    }

    return [];
  }

  private getItemsToRestorePath(item: MyTreeElements, alternativePath = '', isSelected = false): string {
    if (item.restorePath) {
      let path = item.restorePath;

      if (!isSelected && path && isWindows() && path.includes('/')) path = path.replaceAll('/', '\\');

      return path;
    }

    const excludeStrings = [this.computerData.name, this.storageHashTable[item.storageId].prefix, item.bunchId, item.restorePoint];
    const joinStr = isSelected ? this.joinForInput : isWindows() ? '\\' : '/';

    return (alternativePath ? alternativePath : item.path)
      .split(this.joinForInput)
      .filter((str) => !excludeStrings.includes(str))
      .join(joinStr);
  }

  private runQuickRestoreOnSelectedComputer(params, item: MyTreeElements, selectedPath: string): void {
    const status$: BehaviorSubject<PlanRunInfo | null> = new BehaviorSubject(null);
    let planId = '';

    this.stepService
      .getRemoteCommandData(params, this.computerData.hid)
      .pipe(
        filter((result) => result?.data?.planId),
        tap(() => this.toastService.toast({ header: 'Started', content: 'Restore started...', type: MbsPopupType.info, delay: 5000 })),
        switchMap((result) => {
          planId = result.data.planId;

          return status$.pipe(debounceTime(1000));
        }),
        switchMap((result) => {
          if (!result?.status || result.status === PlanRunSimpleStatus.Running) {
            const newParams = { agentType: AgentType.Backup, commandType: RemoteCommandType.GetPlanInfo, params: { planId } };

            this.stepService
              .getRemoteCommandData(newParams, this.computerData.hid)
              .pipe(first())
              .subscribe((data) => {
                if (get(data, 'data.planInfo.lastRunInfo')) status$.next(data.data.planInfo.lastRunInfo);
              });
          }

          return of(result);
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: (result) => {
          const toast = { header: PlanRunSimpleStatus[0], content: 'Unknown status...', type: MbsPopupType.info, delay: 5000 };

          if (!result?.status) {
            this.treeItemForActions = null;
            return;
          }

          toast.header = PlanRunSimpleStatus[result.status];

          if (result.status === PlanRunSimpleStatus.Running) {
            toast.content = 'In the process of restoration...';
            this.treeItemForActions = null;
            return void this.toastService.toast(toast);
          }

          toast.content = 'Files restored';
          toast.type = MbsPopupType.success;

          if (result.status === PlanRunSimpleStatus.Failed) {
            toast.type = MbsPopupType.danger;
            toast.content = result.errorDetails[0]?.title;
          }

          this.treeItemForActions = null;
          this.toastService.toast(toast);
          status$.unsubscribe();
        },
        error: (e) => {
          // TODO MBS-19405 The agent should add errorCode 2000, but while clients in most cases have installed agents of version BEFORE 7.9.0.275, we do not remove the error text check.
          if (e?.error?.errorCode === 2000 || e?.error?.detail === 'Encryption password missing or incorrect') {
            this.downloadToSelectedComputer(true, selectedPath);
            return status$.unsubscribe();
          }

          this.treeItemForActions = null;
          status$.unsubscribe();
        }
      });
  }

  private getSettingsForQRToComputer(invalidPass: boolean, selectedPath: string): ModalSettings {
    return {
      data: {
        invalidPass,
        selectedPath,
        computerData: this.computerData,
        path: this.treeItemForActions.path,
        restorePoint: this.treeItemForActions.restorePoint,
        currentPath: this.getPathFromMyTreeElement(this.treeItemForActions),
        isEncrypted: this.treeItemForActions.isEncrypted,
        encryptionPasswordHint: this.treeItemForActions.encryptionPasswordHint,
        bunchId: this.treeItemForActions.bunchId,
        backupVersionUpdated: this.backupVersionUpdated
      },
      collapsing: true
    };
  }
  // Quick Restore methods End

  // Tree methods Start
  private newSubtreeByParams(params: any, subtree$: BehaviorSubject<MyTreeElements[]>, root: MyTreeElements = null): void {
    this.getSubTreeByParams(params, root).subscribe({
      next: (result) => subtree$.next(result),
      error: (e) => {
        if (!e?.error?.errorCode) return void subtree$.next([]);

        if (e.error.errorCode === 4999) {
          this.resetRootAndSelectedItemAfterSomeErrors(root);

          if (!this.isOnline && root.isEncrypted && !params.params.Password) {
            return void this.showPasswordModal(root, subtree$);
          }

          if (!this.someParentIsSql(root) && (!this.quickRestoreTypes.includes(root.itemType) || isWindows())) this.openQuickRestoreModal();

          return void subtree$.next([]);
        }

        if ([2000, 2046].includes(+e.error.errorCode) && this.lastPasswordModalPath === root?.path) this.toastService.error(e.error.title);

        if (+e.error.errorCode === 2000) {
          this.resetRootAndSelectedItemAfterSomeErrors(root);
          return void this.showPasswordModal(root, subtree$);
        }

        if (+e.error.errorCode === 2046) {
          this.resetRootAndSelectedItemAfterSomeErrors(root);
          return void this.showBitLockerPasswordModal(root, subtree$);
        }

        if (+e.error.errorCode !== 2526) return void subtree$.next([]);

        return void this[this.computerData.os === OsType.windows ? 'getDeepSyncStatus' : 'runDeepSync'](params, subtree$, root);
      }
    });
  }

  private resetRootAndSelectedItemAfterSomeErrors(root: MyTreeElements): void {
    root.checked = true;
    this.selectedTreeItem = root;

    this.treeItemSelectHandler(root);
  }

  private getSubTreeByParams(params: any, root: MyTreeElements): Observable<MyTreeElements[]> {
    const storageId = root.storageId;

    return this.stepService.getRemoteCommandData(params, this.computerData.hid).pipe(
      map((result) => {
        if (!result?.data?.items) return [];

        const newTreeItems = this.getNewTreeItemsFromBackupContent(result.data, root, storageId);

        root.totalChildren = StepsHelpers.getTotalChildren(result?.data, newTreeItems.length);

        return newTreeItems;
      }),
      untilDestroyed(this)
    );
  }

  private someParentIsSql(root: MyTreeElements): boolean {
    return this.SQLTypes.includes(root.type as GetBackupContentBunchType) || (!!root.parent && this.someParentIsSql(root.parent));
  }

  private getNewTreeItemsFromBackupContent(
    data: { items: any[]; isCanBeQuickRestored: boolean; restorePointObjectPath: string },
    root: MyTreeElements,
    storageId: string
  ): MyTreeElements[] {
    if (root.prefix) this.storageHashTable[storageId].prefix = root.prefix;

    if (!data?.items?.length || !this.storageHashTable[storageId]) return [];

    const someParentIsSQL: boolean = this.someParentIsSql(root);

    return data.items.map((item: any, idx: number) => {
      const itemData = this.getDataFromItemType(item);
      const treeElement = {
        prefix: root.prefix,
        label: itemData.label,
        retentionKeepBackupFor: item.retentionKeepBackupFor,
        id: item.path + idx,
        gotChildren: item.isCanBeExpand === false || item.itemType === ItemTypeEnum.Version,
        shown: item.isCanBeExpand || (item.itemType !== ItemTypeEnum.File && item.itemType !== ItemTypeEnum.Version),
        expanded: false,
        isCanBeQuickRestored: data.isCanBeQuickRestored || (!someParentIsSQL && this.quickRestoreTypes.includes(item.itemType)),
        restorePointObjectPath: data.restorePointObjectPath,
        path: item.path,
        itemType: item.itemType,
        originalSize: item.originalSize,
        modifyDateUTC: item.modifyDateUTC,
        restorePath: item.restorePath,
        isLegacy: item.isNBF === false && item.itemType === ItemTypeEnum.Bunch,
        isDeleted: !item.isPlanExists,
        isCanBeDeleted: item.isCanBeDeleted && !someParentIsSQL,
        needShowRV: root.needShowRV || (item.isNBF && item.type === 'DiskImage'),
        generationId: root.generationId || item.generationId || '',
        restorePoint: root.restorePoint || itemData.restorePoint || '',
        isEncrypted: this.getStorageEncryptionStateByParent(item.isEncrypted, root.isEncrypted),
        isObjectLock: item.isImmutable,
        encryptionPasswordHint: item.encryptionPasswordHint || root.encryptionPasswordHint || '',
        bunchId: root.bunchId || item.bunchId,
        storageId
      } as MyTreeElements;

      const additionalData: Partial<MyTreeElements> = {
        iconCustom: item.itemType === ItemTypeEnum.Bunch ? this.getBunchItemIcon(item) : itemData.src,
        leftTopIcon: itemData.leftTopIcon,
        rightBottomIcon: itemData.rightBottomIcon
      };

      if (item.gfsType) additionalData.GFSSettings = { type: item.gfsType };

      if (item.itemType === ItemTypeEnum.Bunch) {
        this.updateBunchesTreeItem(additionalData, item, root);
      }

      if (item.itemType === ItemTypeEnum.Generation) {
        this.updateGenerationTreeItem(additionalData, item, root);
      }

      if (
        item.itemType === ItemTypeEnum.RestorePoint ||
        item.itemType === ItemTypeEnum.RestorePointIncremental ||
        item.itemType === ItemTypeEnum.RestorePointTLog
      ) {
        this.updateRestorePointTreeItem(additionalData, item);
      }

      return { ...treeElement, ...additionalData };
    });
  }

  daysToPurgeToString(days: number): string {
    if (!days || days === IntMaxValue) return this.i18nPipe.transform('backup-storages:browseStorage.keepBackupForever');
    if (days < 30) return this.i18nPipe.transform('backup-storages:browseStorage.purgeDays', { days });
    if (days < 365) return this.i18nPipe.transform('backup-storages:browseStorage.purgeMonth', { months: Math.floor(days / 30) });

    return this.i18nPipe.transform('backup-storages:browseStorage.purgeYears', { years: Math.floor(days / 365) });
  }

  getKeepBackupFor(days: number): string {
    if (!days) return '';
    if (days < 7) return `Keep Backup for ${Math.floor(days)} days`;
    if (days < 30) return `Keep Backup for ${Math.floor(days / 7)} weeks`;
    if (days < 365) return `Keep Backup for ${Math.floor(days / 30)} months`;

    return `Keep Backup for ${Math.floor(days / 365)} years`;
  }

  private updateBaseTreeItem(additionalData: Partial<MyTreeElements>, item: any, root?: MyTreeElements): void {
    additionalData.sizeOnStorage = item.sizeOnStorage;
    additionalData.daysToPurge = item.daysToPurge;
    additionalData.totalSavings = item.totalSavings ? +item.totalSavings.toFixed(2) : item.totalSavings;
    additionalData.purgeSummary = item.purgeSummary;
  }

  private updateBunchesTreeItem(additionalData: Partial<MyTreeElements>, item: any, root?: MyTreeElements): void {
    additionalData.firstRestoreDate = item.firstRestoreDate;
    additionalData.lastRestoreDate = item.lastRestoreDate;
    additionalData.keepBackupFor = this.getKeepBackupFor(item.retentionKeepBackupFor);
    this.updateBaseTreeItem(additionalData, item, root);
    additionalData.countOfErrorStarts = item.countOfErrorRestorePoints || 0;
    additionalData.countOfSuccessfulStarts = item.countOfSuccessfulRestorePoints || 0;
    additionalData.countOfWarningStarts = item.countOfWarningRestorePoints || 0;
  }

  private updateGenerationTreeItem(additionalData: Partial<MyTreeElements>, item: any, root?: MyTreeElements): void {
    additionalData.firstRestoreDate = item.firstRestoreDate;
    additionalData.lastRestoreDate = item.lastRestoreDate;
    this.updateBaseTreeItem(additionalData, item, root);
    additionalData.countOfErrorStarts = item.countOfErrorStarts || 0;
    additionalData.countOfSuccessfulStarts = item.countOfSuccessfulStarts || 0;
    additionalData.countOfWarningStarts = item.countOfWarningStarts || 0;
  }

  getStatusText(status: string): string {
    return status === 'fail' || status === 'Fail' ? 'danger' : status?.toLowerCase() || '';
  }

  private updateRestorePointTreeItem(additionalData: Partial<MyTreeElements>, item: any): void {
    additionalData.date = item.date;
    additionalData.backupResult = this.getStatusText(item?.backupResult);
    additionalData.consistencyCheckResult = this.getStatusText(item?.consistencyCheckResult);
    additionalData.restoreVerificationResult = this.getStatusText(item?.restoreVerificationResult);
    additionalData.sizeOnStorage = item.sizeOnStorage;
    additionalData.totalSavings = item.totalSavings ? +item.totalSavings.toFixed(2) : item.totalSavings;
    additionalData.compressionRatio = item.compressionRatio;
    additionalData.deduplicationRatio = item.deduplicationRatio;
    additionalData.duration = item.duration;
  }

  private getDataFromItemType(item: any): DataFromItemType {
    const data: DataFromItemType = {
      src: TreeRestorePointsIconPath.File,
      label:
        item.displayName && item.displayName.length === 2 && item.displayName[item.displayName.length - 1] === ':'
          ? item.displayName + this.joinForInput
          : item.displayName || 'Unnamed',
      leftTopIcon: '',
      rightBottomIcon: ''
    };
    const displayNameArr = item.type === GetBackupContentType.Volume ? item.displayName.split(' ') : [];

    switch (item.itemType) {
      case ItemTypeEnum.DatabaseExchange:
        data.rightBottomIcon = TreeRestorePointsIconPath.DBPlan;
        break;
      case ItemTypeEnum.Disk:
        data.src = TreeRestorePointsIconPath.ParentDisk;
        break;
      case ItemTypeEnum.Volume:
        data.label = displayNameArr.length > 5 ? displayNameArr.slice(displayNameArr.length - 5).join(' ') : item.displayName;
        data.src = TreeRestorePointsIconPath.Disk;
        break;
      case ItemTypeEnum.Host:
        data.src = TreeRestorePointsIconPath.ServerGroup;
        break;
      case ItemTypeEnum.Machine:
        data.src = TreeRestorePointsIconPath.Server;
        break;
      case ItemTypeEnum.RestorePoint:
      case ItemTypeEnum.RestorePointIncremental:
      case ItemTypeEnum.RestorePointTLog:
        data.label = moment(item.date).utcOffset(this.providerTimezone).format(mediumDateWithTimeMoment);
        data.restorePoint = StepsHelpers.getNormalDate(item.date);
        data.src = item.isFull
          ? TreeRestorePointsIconPath.Full
          : item.itemType === ItemTypeEnum.RestorePointTLog
          ? TreeRestorePointsIconPath.File
          : TreeRestorePointsIconPath.Diff;
        data.leftTopIcon = this.getLeftTopIcon(item);
        data.rightBottomIcon = this.getRightBottomIcon(item);
        break;
      case ItemTypeEnum.Generation:
        data.src = TreeRestorePointsIconPath.Chain;
        data.leftTopIcon = this.getLeftTopIcon(item);
        data.rightBottomIcon = this.getRightBottomIcon(item);
        data.label = `${moment(item.firstRestoreDate).utcOffset(this.providerTimezone).format(mediumDateWithTimeMoment)} - ${moment(
          item.lastRestoreDate
        )
          .utcOffset(this.providerTimezone)
          .format(mediumDateWithTimeMoment)}`;
        break;
      case ItemTypeEnum.Folder:
        data.src = TreeIconPath.Folder;
        break;
      case ItemTypeEnum.Bunch:
        data.src = TreeIconPath.Folder;
        data.leftTopIcon = this.getLeftTopIcon(item);
        data.rightBottomIcon = this.getBunchLastStatusIcon(item.lastBackupStatus);
        break;
    }

    return data;
  }

  getBunchLastStatusIcon(status: ArchiveRestorePointSummaryStatus): string {
    if (status === ArchiveRestorePointSummaryStatus.Fail) return TreeRestorePointsIconPath.Error;
    if (status === ArchiveRestorePointSummaryStatus.Warning) return TreeRestorePointsIconPath.WarnTriangle;
    if (status === ArchiveRestorePointSummaryStatus.Success || status === ArchiveRestorePointSummaryStatus.Running) {
      return TreeRestorePointsIconPath.Success;
    }

    return '';
  }

  private getStorageEncryptionStateByParent(isEncrypted, parentIsEncrypted: StorageEncryptionState): StorageEncryptionState {
    if (isEncrypted === true || parentIsEncrypted === StorageEncryptionState.Encrypted) return StorageEncryptionState.Encrypted;
    if (isEncrypted === false) return StorageEncryptionState.NotEncrypted;

    return isNil(parentIsEncrypted) ? StorageEncryptionState.PossiblyEncrypted : parentIsEncrypted;
  }

  private getBunchItemIcon(item): string {
    if (item.type === GetBackupContentBunchType.DiskImage) return TreeBunchesIcons.Ibb;
    if (item.type === GetBackupContentBunchType.Exchange) return TreeBunchesIcons.Exchange;
    if (item.type === GetBackupContentBunchType.Database || item.type === GetBackupContentBunchType.MsSql) return TreeBunchesIcons.Database;

    if (item.type === GetBackupContentBunchType.VMware) {
      return item.bunchName === IdsForCurrentFormats.VMWare ? TreeBunchesIcons.VmWareCurrent : TreeBunchesIcons.VmWare;
    }

    return item.type === GetBackupContentBunchType.HyperV ? TreeBunchesIcons.HyperV : TreeIconPath.Folder;
  }

  private getLeftTopIcon(item: any): string {
    return item.needDeepSync ? TreeRestorePointsIconPath.Quest : item.isEncrypted ? TreeRestorePointsIconPath.Encrypt : '';
  }

  private getRightBottomIcon(item: any): string {
    const warning = item.needDeepSync || item.backupResult === 'Warning' || item.restoreVerificationResult === 'Warning';
    const error = item.backupResult === 'Fail' || item.consistencyCheckResult === 'Fail';
    const key = item.backupResult ?? 'None';

    if (error) return TreeRestorePointsIconPath.Error;
    if (warning) return TreeRestorePointsIconPath.WarnTriangle;

    return TreeRestorePointsIconPath[key] || '';
  }

  private closeAllChildrenInTree(element: TreeElement): void {
    element.expanded = false;

    if (element.children?.length) element.children.forEach((el) => this.closeAllChildrenInTree(el));
  }

  private isCurrentBrunchClicked(item: MyTreeElements, current: MyTreeElements): boolean {
    const equalId = current && current.id === item?.id;

    return equalId || (!!current?.parent && this.isCurrentBrunchClicked(item, current.parent));
  }
  // Tree methods End

  // Support methods Start
  private getParams(item: MyTreeElements, isConnection = false, needOffset = false): GetBackupContentParams {
    const sortField = isConnection ? 'Type' : 'DisplayName';
    const notBunchOrCBDFile = item.itemType !== ItemTypeEnum.Bunch || item.id === IdsForCurrentFormats.File;

    return {
      agentType: 'backup',
      commandType: 'GetBackupContent',
      params: {
        SessionId: null,
        ConnectionId: null,
        Password: '',
        ContentFilter: 'Actual',
        path: isConnection ? item.storageId : item.path,
        offset: needOffset ? item?.children?.length || 0 : null,
        limit: 300,
        order: notBunchOrCBDFile && item.itemType !== ItemTypeEnum.Generation ? `${sortField}Asc` : `${sortField}Desc`
      }
    };
  }

  private getPathFromMyTreeElement(item: MyTreeElements): string {
    let currentPath = item.path;

    if (!item.type) return currentPath;

    if (item.type === GetBackupContentType.Volume) {
      const label = item.label as string;
      currentPath = /^([a-zA-Z]:)/.test(label) ? label.split(' ')[0] : `partition: ${label}`;
    }

    if (item.type === GetBackupContentType.Disk) currentPath = item.label as string;

    if (item.type === GetBackupContentType.Folder) {
      const newPath = item.path.split(this.joinForInput).slice(2).join(this.joinForInput);
      let current = item;

      while (current.type === GetBackupContentType.Folder) {
        current = current.parent;
      }

      const rootPath = this.getPathFromMyTreeElement(current);
      currentPath = rootPath[rootPath.length - 1] === this.joinForInput ? rootPath + newPath : rootPath + this.joinForInput + newPath;
    }

    return currentPath;
  }

  private resetRootAndSubtreeError(subtree$: BehaviorSubject<MyTreeElements[]>, root: MyTreeElements): void {
    subtree$.error(1);
    this.resetRoot(root);
  }

  private resetRoot(root: MyTreeElements): void {
    this.currentEncryptedRestorePoint = '';

    setTimeout(() => {
      root.deepSync = DeepSyncEnum.Error;
      root.deepSyncProgress = 0;
    }, 0);

    this.passwordRecoveryRoot = null;
  }
  // Support methods End

  // Deep Sync methods Start
  private runDeepSync(params, subtree$: BehaviorSubject<MyTreeElements[]>, root: MyTreeElements = null): void {
    const deepSyncParams = this.getDeepSyncParams(root, params.password || params.params.Password || params.params.password, true);

    this.stepService
      .getRemoteCommandData(deepSyncParams, this.computerData.hid)
      .pipe(first())
      .subscribe({
        next: (result) => {
          if (result?.data) {
            const syncDataFromRes = { deepSync: DeepSyncEnum[result.data as string], progress: 0 };
            return void this.getDeepSyncStatus(params, subtree$, root, syncDataFromRes);
          }

          this.resetRoot(root);
        },
        error: () => this.resetRootAndSubtreeError(subtree$, root)
      });
  }

  private getDeepSyncStatus(
    params,
    subtree$: BehaviorSubject<MyTreeElements[]>,
    root: MyTreeElements = null,
    syncDate = { progress: 0, deepSync: DeepSyncEnum.InProgress }
  ): void {
    root.deepSync = syncDate.deepSync;
    root.deepSyncProgress = syncDate.progress;

    if (syncDate.deepSync === DeepSyncEnum.InvalidPassword || syncDate.deepSync === DeepSyncEnum.NeedPassword) {
      return void this.showPasswordModal(root, subtree$, params);
    }

    if (syncDate.deepSync !== DeepSyncEnum.InProgressColdStorage && syncDate.deepSync !== DeepSyncEnum.InProgress) {
      return void this.newSubtreeByParams(params, subtree$, root);
    }

    const getDeepSyncStatusParams = this.getDeepSyncParams(root, params?.params?.Password || params?.params?.password || '');

    this.stepService
      .getRemoteCommandData(getDeepSyncStatusParams, this.computerData.hid)
      .pipe(first(), debounceTime(800))
      .subscribe({
        next: (res) => {
          if (!res?.data) return void this.resetRoot(root);

          const syncDataFromRes = { deepSync: DeepSyncEnum[res.data.state as string], progress: res.data.progress };

          if (syncDataFromRes.deepSync === DeepSyncEnum.Error) {
            this.toastService.error(res.data.error || res.data.progressMessage || unidentifiedErrorText);
            return void this.resetRootAndSubtreeError(subtree$, root);
          }

          this.getDeepSyncStatus(params, subtree$, root, syncDataFromRes);
        },
        error: () => this.resetRootAndSubtreeError(subtree$, root)
      });
  }

  private getDeepSyncParams(root: MyTreeElements, pass = '', isRun = false): ParamsForDeepSync {
    const newParams: ParamsForDeepSync = {
      agentType: 'backup',
      commandType: isRun ? 'RunDeepSync' : 'GetDeepSyncStatus',
      params: {
        connectionId: root.storageId,
        restoreSourcePrefix: this.storageHashTable[root.storageId].prefix,
        bunchId: root.bunchId,
        restorePointDateUtc: root.restorePoint
      }
    };

    if (pass) newParams.params.Password = pass;

    return newParams;
  }
  // Deep Sync methods End

  // Password methods Start
  private showPasswordModal(root: MyTreeElements, subtree$: BehaviorSubject<MyTreeElements[]>, params = null): void {
    if (!root || this.passwordModalIsOpen) return;

    this.passwordRecoveryRoot = root;
    this.currentEncryptedRestorePoint = root.label as string;

    this.modalService
      .openCustom(PasswordModalComponent, { data: this.getPasswordModalParams() })
      .then((result: { password: FormControl }) => this.processPasswordModalResponse(result, root, subtree$, params))
      .catch(() => this.processPasswordModalBadResponse(root, subtree$, params))
      .finally(() => (this.passwordModalIsOpen = false));

    this.passwordModalIsOpen = true;
  }

  private getPasswordModalParams(): PasswordModalParams {
    return {
      password: this.passwordGroup.get('password').value || '',
      hid: this.computerData.hid,
      restorePoint: this.passwordRecoveryRoot as unknown as RestorePointItem,
      currentEncryptedRestorePoint: this.currentEncryptedRestorePoint,
      backupVersionUpdated: this.backupVersionUpdated,
      passwordRecoveryEnabled: this.allowedRecovery && this.isNBF,
      passwordInvalid: false,
      showHint: false,
      collapsing: true
    };
  }

  private processPasswordModalResponse(
    result: { password: FormControl },
    root: MyTreeElements,
    subtree$: BehaviorSubject<MyTreeElements[]>,
    params = null
  ): void {
    const passControl = result?.password;

    if (!passControl.valid) return void this.processPasswordModalBadResponse(root, subtree$, params);

    this.passwordGroup.get('password').setValue(passControl.value);
    this.lastPasswordModalPath = root.path;

    if (!params) {
      const newParams = this.getParams(root);
      newParams.params.Password = this.passwordGroup.get('password').value;
      this.newSubtreeByParams(newParams, subtree$, root);
    } else {
      params.params.Password = this.passwordGroup.get('password').value;
      this.runDeepSync(params, subtree$, root);
    }

    this.passwordGroup.get('password').reset('');
    this.currentEncryptedRestorePoint = '';
  }

  private processPasswordModalBadResponse(root: MyTreeElements, subtree$: BehaviorSubject<MyTreeElements[]>, params = null): void {
    if (!params) subtree$.error(1);

    this.resetRoot(root);
  }

  private preparePasswordModalSettings(): ModalSettings {
    const title = this.i18nPipe.transform('computers.module:modals.bitlockerDrive', { format: 'title' });
    const confirm = this.i18nPipe.transform('buttons:ok', { format: 'title' });
    const group = this.bitlockerPasswordForm;

    return {
      header: { title },
      footer: {
        okButton: {
          text: confirm,
          type: 'primary',
          disabled$: group.valueChanges.pipe(
            startWith(true),
            switchMap(() => defer(() => of(!group.valid)))
          )
        },
        cancelButton: { text: this.i18nPipe.transform('buttons:cancel', { format: 'title' }) }
      }
    } as ModalSettings;
  }

  private showBitLockerPasswordModal(root: MyTreeElements, subtree$: BehaviorSubject<MyTreeElements[]>): void {
    if (!root) return;

    this.bitlockerPasswordForm.reset();
    this.bitLockerFileContent = '';
    this.bitLockerFileName = '';

    this.bitlockerPasswordTypeControl.setValue('1');

    this.modalService
      .open(this.preparePasswordModalSettings(), this.bitLockerPasswordModal)
      .then((confirm) => {
        const passwordControl = this.getBitlockerPasswordControls.find(
          (controlData) => controlData.value === this.bitlockerPasswordTypeControl.value
        )?.control;
        const passwordType = this.bitlockerPasswordTypeControl?.value;

        if (confirm && passwordControl?.valid && passwordType) {
          this.lastPasswordModalPath = root.path;

          const newParams = this.getParams(root);
          const passwordTypeInt = parseInt(passwordType, 10);

          newParams.params.BitLockerPasswordValue =
            passwordTypeInt === BitLockerPasswordType.KeyFile ? this.bitLockerFileContent : passwordControl.value;
          newParams.params.BitlockerPasswordType = passwordTypeInt;

          return void this.newSubtreeByParams(newParams, subtree$, root);
        }

        this.resetRootAndSubtreeError(subtree$, root);
      })
      .catch(() => this.resetRootAndSubtreeError(subtree$, root));
  }

  // Delete item section start
  private deleteSelectedItem(): void {
    this.deleteProcess = true;

    if (!this.ability.can('read', '2FAEnabled')) {
      this.deleteProcess = false;

      return void this.modalService
        .openCustom(TFARequireModalComponent, { size: MbsSize.sm, container: this.modalContainer })
        .finally(noop);
    }

    this.stepService
      .getRemoteCommandData(this.getParamsForDeleteBackupObject(), this.computerData.hid)
      .pipe(
        first(),
        untilDestroyed(this),
        catchError((error: HttpErrorResponse) => this.deleteSelectedItemErrorHandler(error))
      )
      .subscribe({
        next: (data: any) => {
          if (data === true) return;
          if (!data || !this.treeItemForActions) return void (this.deleteProcess = false);

          this.deleteSelectedTreeItemFromTree();

          if (this.selectedTreeItem?.id === this.treeItemForActions?.id) this.selectedTreeItem = null;

          this.treeItemForActions = null;
          this.deleteProcess = false;
        },
        error: (e) => {
          this.deleteProcess = false;
          this.treeItemForActions = null;
          this.toastService.error(
            e?.error?.title || e?.error?.message || e?.message || this.i18nPipe.transform('toast.error.title', { format: 'title' })
          );
        }
      });
  }

  private getParamsForDeleteBackupObject(): ParamsForDeleteBackupObject {
    return {
      agentType: 'backup',
      commandType: 'DeleteBackupObject',
      params: { ObjectsToDelete: [this.treeItemForActions?.path || ''] }
    };
  }

  private deleteSelectedItemErrorHandler(error: HttpErrorResponse): Observable<boolean> {
    if (error?.status !== TFAErrorStatus) return throwError(() => error);

    return from(
      this.TFAService.openApproveModal(
        { ...error.error, title: this.i18nPipe.transform('backup-storages:browseStorage.confirmBuildDeletionTitle') },
        this.modalContainer
      )
    ).pipe(
      switchMap((result) => {
        if (!result) return of(false);

        this.deleteSelectedItem();

        return of(true);
      })
    );
  }

  private deleteSelectedTreeItemFromTree(): void {
    const storageHash = this.storageHashTable[this.mySelectedStorage.ID];

    if (!this.showComputers && this.treeItemForActions.itemType === ItemTypeEnum.Bunch) {
      this.treeComponent.data = this.treeComponent.data.filter((child: MyTreeElements) => child.id !== this.treeItemForActions.id);
    } else {
      this.treeComponent.data = this.treeComponent.data.map((child: MyTreeElements) => {
        if (child.id === this.treeItemForActions.parent.id && child.children?.length) {
          const length = child.children.length;
          child.children = child.children.filter((ch) => ch.id !== this.treeItemForActions.id);
          child.totalChildren = child.totalChildren - (length - child.children.length);
        }
        return child;
      });
    }

    storageHash.data = this.treeComponent.data;
    this.rightTableData = this.rightTableData.filter((el) => el.id !== this.treeItemForActions.id);
  }
}
