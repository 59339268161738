import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RemoteConnectionEnableModalComponent } from '@components/computers-modal/remote-connection-enable-modal/remote-connection-enable-modal.component';
import { RemoteConnectionPrepareModalComponent } from '@components/computers-modal/remote-connection-prepare-modal/remote-connection-prepare-modal.component';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ConnectButtonGroupComponent } from '@root/mbs-ui/src/app/shared/components/computers-shared/connect-button-group/connect-button-group.component';
import { AbilityModule } from 'ability';
import { I18NextModule } from 'angular-i18next';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { RemoteConnectionChooseTypeModalComponent } from '../computers-modal/remote-connection-choose-type-modal/remote-connection-choose-type-modal.component';
import { RemoteConnectionDesktopLicenseSelectModalComponent } from '../computers-modal/remote-connection-desktop-license-select-modal/remote-connection-desktop-license-select-modal.component';
import { RemoteConnectionWebLicenseSelectModalComponent } from '../computers-modal/remote-connection-web-license-select-modal/remote-connection-web-license-select-modal.component';
import { PriorityPipe } from './priority.pipe';
import { SidepanelConnectSettingsComponent } from '@components/computers-shared/sidepanel-connect-settings/sidepanel-connect-settings.component';
import { UtilsPipeModule } from '@utils/utils.pipe.module';
import { ConnectSettingsComponent } from '@components/connect-settings/connect-settings.component';

const declarations = [
  RemoteConnectionChooseTypeModalComponent,
  RemoteConnectionEnableModalComponent,
  RemoteConnectionPrepareModalComponent,
  RemoteConnectionWebLicenseSelectModalComponent,
  RemoteConnectionDesktopLicenseSelectModalComponent,
  ConnectButtonGroupComponent,
  SidepanelConnectSettingsComponent,
  PriorityPipe
];

@NgModule({
  declarations: declarations,
  exports: declarations,
  imports: [
    CommonModule,
    I18NextModule,
    FormsModule,
    UtilsPipeModule,
    ReactiveFormsModule,
    MbsUiKitModule,
    AbilityModule,
    NgbDropdownModule,
    NgbTooltipModule,
    ConnectSettingsComponent
  ]
})
export class ComputersSharedModule {}
