import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import Computer from '@models/Computer';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ConnectService } from '@services/connect.service';
import { ModalComponent } from 'mbs-ui-kit/modal/modal.component';
import { MbsPopupType } from 'mbs-ui-kit/utils/enums/mbs-popup-enum';
import { ConnectSettingsScope } from '@models/connect/connect-settings.models';
import { SidepanelRouteType } from '@models/backup/sidepanel-route-type';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'mbs-remote-connection-allow-modal-component',
  templateUrl: './remote-connection-allow-modal.component.html',
  styleUrls: ['./remote-connection-allow-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RemoteConnectionAllowModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  public readonly mbsPopupType = MbsPopupType;
  public loading = true;
  public errorMessage = null;
  public hasPerComputerSettings = false;

  constructor(
    private connectService: ConnectService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getErrorMessage();
  }

  getErrorMessage(): void {
    this.connectService.getSettings(ConnectSettingsScope.Computer, this.baseModal.data.computer.hid)
      .subscribe((settings) => {
        this.loading = false;
        if (settings) {
          this.hasPerComputerSettings = true;
        } else {
          this.errorMessage = this.connectService.getRemoteAccessNotAllowedError(this.baseModal.data.computer);
        }
      })
  }

  onPerComputerButtonClick() {
    this.router.navigate([location.pathname, this.baseModal.data.computer.hid], { queryParams: { sidepanel: SidepanelRouteType.ConnectSettings } });
    this.baseModal.close();
  }

  getComputerName(): string {
    return Computer.getComputerName(this.baseModal.data.computer);
  }
}
