<span class="text-overflow"
      *ngIf="(time$ | async) as data">
  {{data.provider?.time}}
  <span *ngIf="showIcon && !data?.isEmpty"
        class="ml-2 cursor-help text-info"
        tooltipClass="tooltip-lg text-left"
        (mouseenter)="infoIconHoverHandler(true)"
        (mouseleave)="infoIconHoverHandler(false)"
        [placement]="placement"
        [container]="container"
        [ngbTooltip]="tooltipContent"
        [ngClass]="iconClass"></span>
  <ng-template #tooltipContent>
    <span *ngIf="needWaitLoadTimezone && isNullTimezone"
          class="loader loader-lg"></span>
    <ng-container *ngIf="!needWaitLoadTimezone || !isNullTimezone">
      <div *ngIf="!hideAgentTime">Agent: {{data.agent?.time}} {{ data.agent?.utc }}</div>
      <div>Provider: {{data.provider?.time}} {{ data.provider?.utc }}</div>
      <div>Local: {{data.local?.time}} {{ data.local?.utc }}</div>
    </ng-container>
  </ng-template>
</span>
