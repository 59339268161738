import { Component, Input } from '@angular/core';
import {
  getJoinSessionModeItems,
  getLoggingLevelItems,
  getProxyModeItems,
  getTimeUnits,
  getUnattendedAccessDisconnectModeOptions
} from '@utils/ra-settings';
import { ConnectSettingsForm, JoinSessionMode, PinLength, ProxyMode } from '@models/connect/connect-settings.models';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import { PasswordInputType } from '@models/Settings.enum';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { ConnectSettingsWrapper } from '@models/connect/connect-settings.wrapper';

enum ViewMode {
  Page = 'page',
  Panel = 'panel'
}

@Component({
  standalone: true,
  selector: 'mbs-connect-settings-form',
  templateUrl: './connect-settings.component.html',
  imports: [
    CommonModule,
    MbsUiKitModule,
    I18NextModule,
    ReactiveFormsModule,
    NgbTooltipModule
  ]
})
export class ConnectSettingsComponent {
  public readonly unattendedAccessDisconnectOptions = getUnattendedAccessDisconnectModeOptions(this.i18Next);
  public readonly timeUnits = getTimeUnits(this.i18Next);
  public readonly joinSessionModeSelectItems = getJoinSessionModeItems(this.i18Next);
  public readonly proxyModeSelectItems = getProxyModeItems(this.i18Next);
  public readonly pinLengthSelectItems = [PinLength.Four, PinLength.Six, PinLength.Eight, PinLength.Ten];
  public readonly loggingLevelSelectItems = getLoggingLevelItems(this.i18Next);
  public readonly viewModeEnum = ViewMode;

  public unattendedPasswordInputType = PasswordInputType.PASSWORD;
  public configPasswordInputType = PasswordInputType.PASSWORD;
  public basicAuthPasswordInputType = PasswordInputType.PASSWORD;

  @Input() form: ConnectSettingsForm;
  @Input() formState: ConnectSettingsWrapper;
  @Input({ transform: (value: string): ViewMode => value as ViewMode}) mode: ViewMode = ViewMode.Page;

  constructor(private i18Next: I18NextPipe) {
  }

  isUseBasicAuthSectionVisible(): boolean {
    const proxyMode = this.formState?.proxy?.mode;

    return proxyMode === ProxyMode.AutoDetect || proxyMode === ProxyMode.Manual
  }

  isProxyServerHostVisible(): boolean {
    const proxyMode = this.formState.proxy?.mode;

    return proxyMode === ProxyMode.Manual
  }

  isUseBasicAuthUserPasswordSectionVisible(): boolean {
    return this.formState?.proxy?.useBasicAuth;
  }

  isPinLengthVisible(): boolean {
    const joinSessionMode = this.formState?.security?.joinSessionMode;

    return joinSessionMode === JoinSessionMode.Pin || joinSessionMode === JoinSessionMode.DialogAndPin;
  }

  isUnattendedAccessPasswordVisible(): boolean {
    return this.formState?.security?.customPassword?.enabled;
  }

  isConfigPasswordVisible(): boolean {
    return this.formState?.configPassword?.enabled;
  }

  getPasswordInputType(inputType: PasswordInputType): PasswordInputType {
    return inputType === PasswordInputType.PASSWORD ? PasswordInputType.TEXT : PasswordInputType.PASSWORD;
  }
}
