<section [formGroup]="stepForm"
         class="mbs-wizard_form">
  <div class="mbs-wizard_form-content">
    <mbs-form-group>
      <mbs-tile [borderPadding]="'none'"
                [data]="null"
                [disabled]="false"
                [hoverAble]="false"
                [selectable]="false"
                [selected]="false"
                [showBody]="false"
                [headerTemplate]="headerTemplate"
                [id]="elementSelectors.id.consistencyCheckId">
        <ng-template #headerTemplate>
          <div class="px-3 py-2">
            <div class="d-flex align-items-center justify-content-between mb-1">
              <h4 class="mb-0">{{ 'wizards:consistencyCheck.consistencyLabel' | i18next : { format: 'title' } }}</h4>
              <mbs-switcher formControlName="useFullConsistencyCheck"></mbs-switcher>
            </div>
            <div class="d-flex">{{ 'wizards:consistencyCheck.consistencyLabelDescription' | i18next }}</div>
          </div>
        </ng-template>
      </mbs-tile>
    </mbs-form-group>

    <mbs-form-group *ngIf="isIBBPlan"
                    [class.mb-0]="(!isRDMode || !stepForm.get('RunRestoreVerificationOn').value) && restoreVerificationEnabled"
                    [attr.data-test-element]="elementSelectors.name.ibbBlock">
      <mbs-tile [borderPadding]="'none'"
                [data]="null"
                [disabled]="false"
                [hoverAble]="false"
                [selectable]="false"
                [selected]="false"
                [showBody]="false"
                [headerTemplate]="ibbHeaderTemplate"
                [id]="elementSelectors.id.restoreVerificationId">
        <ng-template #ibbHeaderTemplate>
          <div class="d-flex flex-column">
            <div class="px-3 py-2"
                 [class.pb-3]="isRestoreVerification">
              <div class="d-flex align-items-center justify-content-between mb-1">
                <h4 class="mb-0">{{ 'wizards:consistencyCheck.restoreVerificationTitle' | i18next : { format: 'title' } }}</h4>
                <mbs-switcher [(ngModel)]="isRestoreVerification"
                              [ngModelOptions]="{ standalone: true }"
                              [disabledSelf]="!restoreVerificationEnabled"
                              (afterUpdate)="isRestoreVerificationChangeHandler($event)"></mbs-switcher>
              </div>
              <span>
                {{ 'wizards:consistencyCheck.restoreVerificationDescription' | i18next }}
                <a *ngIf="ability.can('read', 'HelpMarketing')"
                   href="https://help.msp360.com/cloudberry-backup/backup/about-backups/restore-verification" target="_blank">
                  {{ 'wizards:learn_more_link' | i18next }}
                </a>
              </span>
            </div>

            <div [ngbCollapse]="!isRestoreVerification || !restoreVerificationEnabled">
              <hr>

              <div class="px-3 pt-2 mb-3">
                <span class="mbs-form_label d-inline-block font-weight-bold mb-2">
                  {{ 'wizards:consistencyCheck.verificationTypeLabel' | i18next : { format: 'title' } }}
                  <span class="cursor-help fa fa-info-circle text-info ml-1"
                        container="body"
                        placement="auto"
                        [ngbTooltip]="'wizards:consistencyCheck.verificationTypeTooltipText' | i18next"></span>
                </span>
                <div class="form-row">
                  <div class="col-auto">
                    <mbs-radio formControlName="RunRestoreVerificationOn"
                               name="RunRestoreVerificationOn"
                               [label]="'wizards:consistencyCheck.restoreVerificationFullLabel' | i18next"
                               (change)="needCheckChange()"
                               [id]="elementSelectors.id.restoreVerificationOn"
                               [value]="runRestoreVerification.OnFull"></mbs-radio>
                  </div>
                  <div class="col-auto">
                    <mbs-radio formControlName="RunRestoreVerificationOn"
                               name="RunRestoreVerificationOn"
                               [label]="'wizards:consistencyCheck.restoreVerificationIncrementalLabel' | i18next"
                               (change)="needCheckChange()"
                               [id]="elementSelectors.id.restoreVerificationDiff"
                               [value]="runRestoreVerification.OnDiff"></mbs-radio>
                  </div>
                  <div class="col-auto">
                    <mbs-radio formControlName="RunRestoreVerificationOn"
                               name="RunRestoreVerificationOn"
                               [label]="'wizards:consistencyCheck.restoreVerificationFullIncrementalLabel' | i18next"
                               (change)="needCheckChange()"
                               [id]="elementSelectors.id.restoreVerificationAll"
                               [value]="runRestoreVerification.OnAll"></mbs-radio>
                  </div>
                </div>
              </div>

              <hr>

              <div class="px-3 pt-2 mb-3">
                <div class="form-row">
                  <div class="col-5">
                    <span class="mbs-form_label d-inline-block font-weight-bold mb-2"
                          [attr.data-test-element]="elementSelectors.name.screenshotIntervalLabel"
                          [class.-disabled]="stepForm.get('minutes').disabled">
                      {{ 'wizards:consistencyCheck.screenshotIntervalLabel' | i18next : { format: 'title' } }}
                      <span class="cursor-help fa fa-info-circle text-info ml-1"
                            container="body"
                            placement="auto"
                            [ngbTooltip]="'wizards:consistencyCheck.screenshotIntervalTooltipText' | i18next"></span>
                    </span>

                    <div class="form-row align-items-center">
                      <div class="col-4">
                        <mbs-number formControlName="minutes"
                                    max="59"
                                    min="0"
                                    (change)="minutesChangeHandler($event)"
                                    [id]="elementSelectors.id.internalMinutes"></mbs-number>
                      </div>
                      <div class="col-auto">
                        <label class="mbs-form_label mb-0 lh-1"
                               [for]="elementSelectors.id.internalMinutes"
                               [attr.data-test-element]="elementSelectors.name.intervalMinutes"
                               [class.-disabled]="stepForm.get('minutes').disabled">
                          {{ 'wizards:consistencyCheck.intervalMinutes' | i18next }}
                        </label>
                      </div>
                      <div class="col-4">
                        <mbs-number formControlName="seconds"
                                    max="59"
                                    [min]="stepForm.get('minutes').value ? 0 : 1"
                                    [id]="elementSelectors.id.internalSeconds"></mbs-number>
                      </div>
                      <div class="col-auto">
                        <label class="mbs-form_label mb-0 lh-1"
                               [for]="elementSelectors.id.internalSeconds"
                               [attr.data-test-element]="elementSelectors.name.intervalSeconds"
                               [class.-disabled]="stepForm.get('seconds').disabled">
                          {{ 'wizards:consistencyCheck.intervalSeconds' | i18next }}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="col-3">
                    <label class="mbs-form_label d-inline-block font-weight-bold mb-2"
                           [for]="elementSelectors.id.numCPU"
                           [attr.data-test-element]="elementSelectors.name.numCpuNumberLabel"
                           [class.-disabled]="stepForm.get('numCpu').disabled">
                      {{ 'wizards:consistencyCheck.numCpuNumberLabel' | i18next : { format: 'title' } }}
                    </label>

                    <div class="form-row">
                      <div class="col-8">
                        <mbs-number formControlName="numCpu"
                                    max="4"
                                    min="1"
                                    [id]="elementSelectors.id.numCPU"></mbs-number>
                      </div>
                    </div>
                  </div>

                  <div class="col-4">
                    <label class="mbs-form_label d-inline-block font-weight-bold mb-2"
                           [for]="elementSelectors.id.memoryInMB"
                           [attr.data-test-element]="elementSelectors.name.memoryInMbSelectLabel"
                           [class.-disabled]="stepForm.get('memoryInMb').disabled">
                      {{ 'wizards:consistencyCheck.memoryInMbSelectLabel' | i18next : { format: 'title' } }}
                    </label>

                    <div class="form-row">
                      <div class="col-7">
                        <mbs-number formControlName="memoryInMb"
                                    (change)="memoryInMbChangeHandler()"
                                    [max]="maxStartupRAM"
                                    [min]="minStartupRAM"
                                    [placeholder]="'wizards:consistencyCheck.memoryInMbSelectPlaceholder' | i18next"
                                    [id]="elementSelectors.id.memoryInMB"></mbs-number>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr>

              <div formGroupName="dynamicMemory"
                   class="px-3 pt-2 mb-3">
                <div class="d-flex align-items-center justify-content-between">
                  <span class="mbs-form_label d-inline-block font-weight-bold mb-2"
                        [attr.data-test-element]="elementSelectors.name.dynamicMemoryEnableLabel"
                        [class.-disabled]="stepForm.get('dynamicMemory').disabled">
                    {{ 'wizards:consistencyCheck.dynamicMemoryEnableLabel' | i18next : { format: 'title' } }}
                  </span>

                  <mbs-switcher formControlName="enabled"
                                (afterUpdate)="dynamicMemoryChangeHandler($event)"></mbs-switcher>
                </div>

                <div class="form-row align-items-center">
                  <div class="col-auto">
                    <label class="mbs-form_label mb-0 lh-1"
                           [for]="elementSelectors.id.minRAM"
                           [attr.data-test-element]="elementSelectors.name.dynamicFromLabel"
                           [class.-disabled]="!stepForm.get('dynamicMemory')?.value?.enabled">
                      {{ 'wizards:consistencyCheck.dynamicFromLabel' | i18next }}
                    </label>
                  </div>
                  <div class="col-3">
                    <mbs-select bindLabel="name"
                                formControlName="min"
                                (change)="minMaxMemoryChangeHandler()"
                                [items]="possibleMinMaxMemory"
                                [id]="elementSelectors.id.minRAM"
                                [appendTo]="'body'"
                                [clearable]="false"></mbs-select>
                  </div>
                  <div class="col-auto">
                    <label class="mbs-form_label mb-0 lh-1"
                           [for]="elementSelectors.id.maxRAM"
                           [attr.data-test-element]="elementSelectors.name.dynamicToLabel"
                           [class.-disabled]="!stepForm.get('dynamicMemory')?.value?.enabled">
                      {{ 'wizards:consistencyCheck.dynamicToLabel' | i18next }}
                    </label>
                  </div>
                  <div class="col-3">
                    <mbs-select bindLabel="name"
                                formControlName="max"
                                (change)="minMaxMemoryChangeHandler(true)"
                                [items]="possibleMinMaxMemory"
                                [id]="elementSelectors.id.maxRAM"
                                [appendTo]="'body'"
                                [clearable]="false"></mbs-select>
                  </div>
                </div>
                <mbs-input-errors [errors]="[{ message: minMaxMemoryError }]"></mbs-input-errors>
              </div>

              <hr>

              <div class="px-3 pt-2 mb-3">
                <span class="mbs-form_label d-block font-weight-bold"
                      [attr.data-test-element]="elementSelectors.name.failureMinutesLabel"
                      [class.-disabled]="stepForm.get('failureMinutes').disabled">
                  {{ 'wizards:consistencyCheck.failureMinutesLabel' | i18next : { format: 'title' } }}
                </span>
                <span class="d-inline-block mb-2">{{ 'wizards:consistencyCheck.failureMinutesLabelDescription' | i18next }}</span>

                <div class="form-row align-items-center">
                  <div class="col-2">
                    <mbs-number formControlName="failureMinutes"
                                max="900"
                                min="1"
                                [id]="elementSelectors.id.failureMinutes"></mbs-number>
                  </div>
                  <div class="col-auto">
                    <label class="mbs-form_label mb-0 lh-1"
                           [for]="elementSelectors.id.failureMinutes"
                           [attr.data-test-element]="elementSelectors.name.failureMinutes"
                           [class.-disabled]="stepForm.get('failureMinutes').disabled">
                      {{ 'wizards:consistencyCheck.intervalMinutes' | i18next }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </mbs-tile>
    </mbs-form-group>

    <mbs-alert *ngIf="isRDMode && stepForm.get('RunRestoreVerificationOn').value"
               [attr.data-test-element]="elementSelectors.name.requiresHyperVText"
               [type]="mbsPopupType.info"
               [icon]="true"
               class="mb-0">
      {{ 'wizards:consistencyCheck.requiresHyperVText' | i18next }}
    </mbs-alert>

    <mbs-alert *ngIf="!restoreVerificationEnabled"
               [attr.data-test-element]="elementSelectors.name.restoreVerificationRequires"
               [type]="mbsPopupType.warning"
               [icon]="true"
               class="mb-0">
        <span *ngFor="let item of restoreVerificationRequiresText;let last = last">
          {{item}}<br *ngIf="!last">
        </span>
    </mbs-alert>
  </div>
</section>
