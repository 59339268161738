import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, NgControl, ValidationErrors } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import { InputBase } from '../input-base/input-base';

@UntilDestroy()
@Component({
  selector: 'mbs-input-password',
  templateUrl: './input-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputPasswordComponent extends InputBase<any> implements OnInit {
  @Input() validationWhitespace = true;
  @Input() disabledEye = false;
  /**
   * Add font-weight-bold;
   */
  @Input() public boldLabel = false;
  /**
   * Disable input with ignore FormControl warnings
   */
  @Input() public disabledSelf = false;
  /** Displaying '*' for required fields is the default behavior, but can be overridden. */
  @Input() public showRequiredMark = true;

  @Output() input = new EventEmitter<string>();

  public form = new FormGroup({ password: new FormControl('', this.passwordValidator.bind(this)) });

  @Input() passwordInputType: 'password' | 'text' = 'password';

  constructor(@Optional() @Self() ngControl: NgControl, protected cd: ChangeDetectorRef) {
    super(ngControl, cd);
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe({ next: (value) => this.handleInput(value.password) });

    if (this.ngControl.statusChanges) {
      this.ngControl.statusChanges.subscribe((status: string) => {
        this.setFormStatusAfterControlStatusChange(status);
        this.cd.markForCheck();
      });
    }
  }

  setFormStatusAfterControlStatusChange(status: string): void {
    if (status === 'DISABLED') return this.form.get('password').disable({ emitEvent: false });

    if (status === 'INVALID' && this.form.enabled && (!this.form.touched || !this.form.dirty || this.form.status !== status)) {
      this.form.get('password').markAsTouched();
      this.form.get('password').markAsDirty();
      this.form.get('password').updateValueAndValidity();
    }

    if (this.form.get('password').disabled) return this.form.get('password').enable({ emitEvent: false });

    if (status !== 'VALID' || this.form.status === status) return;

    if (!this.ngControl.dirty && !this.ngControl.touched) this.form.get('password').reset(this.form.get('password').value);
    else this.form.get('password').updateValueAndValidity();
  }

  passwordValidator(control: AbstractControl): ValidationErrors | null {
    return this.ngControl?.errors ? { ...this.ngControl.errors } : null;
  }

  changeTypePassword(): void {
    this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password';
  }

  inputPassHandler(value: any): void {
    if (!isNil(value?.target?.value) && this.form.invalid) {
      this.form.get('password').reset(value?.target?.value);
      this.form.get('password').markAsDirty();
      this.form.get('password').markAsTouched();
    }
  }

  handleInput(value: string): void {
    this.value = value;
  }

  writeValue(obj: string): void {
    super.writeValue(obj);
  }
}
