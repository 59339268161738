import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AccordionModule } from './accordion/accordion.module';
import { AdvancedSearchModule } from './advanced-search/advanced-search.module';
import { AdvancedSelectModule } from './advanced-select/advanced-select.module';
import { AlertModule } from './alert/alert.module';
import { ButtonModule } from './button/button.module';
import { CardModule } from './card/card.module';
import { SharedDirectivesModule } from './directives/directives.module';
import { TagsInputModule } from './form';
import { FormGroupModule } from './form-group/form-group.module';
import { FormSectionModule } from './form-section/form-section.module';
import { CheckboxModule } from './form/checkbox/checkbox.module';
import { DatepickerRangeModule } from './form/datepicker-range';
import { DatepickerModule } from './form/datepicker/datepicker.module';
import { DropFileModule } from './form/drop-file/drop-file.module';
import { InputBaseModule } from './form/input-base/input-base.module';
import { InputPasswordModule } from './form/input-password/input-password.module';
import { InputModule } from './form/input/input.module';
import { IPAddressModule } from './form/ip-address/ip-address.module';
import { NumberModule } from './form/number/number.module';
import { RadioModule } from './form/radio/radio.module';
import { SelectModule } from './form/select/select.module';
import { SwitcherModule } from './form/switcher/switcher.module';
import { TimepickerModule } from './form/timepicker/timepicker.module';
import { ListEditorModule } from './list-editor/list-editor.module';
import { ListSelectModule } from './list-select/list-select.module';
import { LoaderModule } from './loader/loader.module';
import { ModalModule } from './modal/modal.module';
import { PageFooterModule } from './page-footer/page-footer.module';
import { ProgressBarModule } from './progress-bar/progress-bar.module';
import { SHARED_PERSISTENT_STATE_PROVIDERS } from './services/storage/shared-persistent-state.provider';
import { SidepanelModule } from './sidepanel/sidepanel.module';
import { SmartSearchModule } from './smart-search/smart-search.module';
import { StatusModule } from './status/status.module';
import { StepsModule } from './steps/steps.module';
import { TableGridModule } from './table-grid/table-grid.module';
import { TabsetModule } from './tabset/tabset.module';
import { TagModule } from './tag/tag.module';
import { TextEllipsisModule } from './text-ellipsis/text-ellipsis.module';
import { TileModule } from './tile/tile.module';
import { ToastModule } from './toast/toast.module';
import { TreeModule } from './tree/tree.module';
import { SharedPipesModule } from './utils/Pipes/pipes.module';
import { MbsDirectivesModule } from './utils/mbs-directives.module';
import { MbsSharedUtilsModule } from './utils/mbs-shared-utils.module';
import { WizardModule } from './wizard/wizard.module';

const internalModules = [
  SharedPipesModule,
  SharedDirectivesModule,
  MbsDirectivesModule,
  LoaderModule,
  ListSelectModule,
  ListEditorModule,
  CheckboxModule,
  RadioModule,
  NumberModule,
  SwitcherModule,
  TimepickerModule,
  DatepickerModule,
  DatepickerRangeModule,
  WizardModule,
  ModalModule,
  SelectModule,
  AdvancedSelectModule,
  AdvancedSearchModule,
  TableGridModule,
  SidepanelModule,
  InputPasswordModule,
  MbsSharedUtilsModule,
  InputModule,
  DropFileModule,
  ButtonModule,
  AccordionModule,
  InputBaseModule,
  TagModule,
  CheckboxModule,
  StatusModule,
  StepsModule,
  TabsetModule,
  PageFooterModule,
  CardModule,
  ProgressBarModule,
  SmartSearchModule,
  TileModule,
  TextEllipsisModule,
  TreeModule,
  ToastModule,
  AlertModule,
  NgScrollbarModule,
  FormGroupModule,
  FormSectionModule,
  IPAddressModule,
  TagsInputModule
];

@NgModule({
  declarations: [],
  imports: [internalModules],
  exports: [internalModules],
  providers: [SHARED_PERSISTENT_STATE_PROVIDERS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MbsUiKitModule {}
