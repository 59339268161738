<div *ngIf="isConnectAvailable"
     class="d-flex mbs-connect-buttons"
     [ngClass]="isCtrl ? '' : customClass">
  <mbs-button-group
                    [disabled]="(isMacOrLinux && (isConnectDisabled || isDisabled)) || (compactViewMode && isConnectDisabled) ? true : undefined">
    <mbs-button (click)="connectAction()"
                [customClasses]="(isCtrl ? customClass : '') + ' d-inline-flex align-items-center'"
                [ngbTooltip]="defaultActionTooltip"
                [isCtrl]="isCtrl"
                [type]="isCtrl ? 'dark' : ''"
                [loading]="loading"
                [disabled]="loading || isConnectDisabled"
                icon="nav-link_ico mbs-agent mbs-agent-sm mbs-agent_connect text-md"
                [container]="container"
                tooltipClass="tooltip-lg text-nowrap text-left"
                [placement]="tooltipPlacement">
      <span [ngClass]="isCtrl ? '' : 'mbs-connect-button-title'">{{ 'app:products.ra' | i18next }}</span>
    </mbs-button>
    <mbs-button [type]="isCtrl ? 'dark' : null"
                [customClasses]="isCtrl ? 'pt-2 px-2' : 'py-0 px-2 text-dark btn-secondary d-inline-flex align-items-center'"
                [customParentClasses]="isCtrl ? null : 'dropdown-z-default'"
                [hideDropdownIcon]="true"
                [isCtrl]="isCtrl"
                [icon]="isCtrl ? 'text-md fa fa-caret-down' : 'text-lg fa fa-caret-down'"
                [disabled]="isDisabled"
                [dropdownPlacement]="dropdownPlacement">
      <ng-template mbsButtonDropdown
                   [container]="container"
                   display="dynamic">
        <span *ngIf="!isMacOrLinux"
              class="font-weight-semibold align-items-end"
              ngbDropdownItem>
          {{ 'computers.module:connect.settingButtonMenuItemOpenTitle' | i18next }}
        </span>
        <ng-container *ngFor="let menuOption of menuOptions">
          <button ngbDropdownItem
                  [disabled]="loading || isConnectDisabled"
                  (click)="connectAction(menuOption)">
            {{ menuOption.name }}
          </button>
        </ng-container>
        <ng-container *ngIf="!isMacOrLinux && isConnectSettingsVisible && !compactViewMode">
          <span class="font-weight-semibold align-items-end"
                ngbDropdownItem>
            {{ 'computers.module:connect.settingButtonMenuItemSettigsTitle' | i18next }}
          </span>
          <button ngbDropdownItem
                  (click)="openConnectSettings()">
            {{ 'computers.module:connect.settingButtonMenuItemComputerText' | i18next }}
          </button>
          <a *ngIf="connectCompanySettingsUrl !== null"
             ngbDropdownItem
             [href]="connectCompanySettingsUrl"
             target="_blank">
            {{ 'computers.module:connect.settingButtonMenuItemCompanyText' | i18next }}
          </a>
          <a ngbDropdownItem
             href="/AP/GlobalAgentOptions?active=ra"
             target="_blank">
            {{ 'computers.module:connect.settingButtonMenuItemGlobalText' | i18next }}
            <span *ngIf="isOldConnectSettingsInfoVisible()"
                  class="fa fa-info-circle text-info text-lg ml-2"
                  [ngbTooltip]="getOldConnectSettingsInfoText()"
                  [tooltipClass]="'tooltip-lg text-left -no-arrow'"></span>
          </a>
        </ng-container>
      </ng-template>
    </mbs-button>
  </mbs-button-group>
</div>
