<mbs-sidepanel [innerContentType]="'tabs'"
               [isCreate]="false"
               [headerClass]="'-light'"
               [showExpandedCross]="true"
               [hideSave]="true"
               [showDelete]="false"
               [large]="true"
               id="global_downloads">
  <div class="mbs-sidepanel_title"
       header-title>
    <div class="mbs-sidepanel_title-ico">
      <span *ngIf="!isBuilding"
            class="fa fa-download"></span>
      <span *ngIf="isBuilding"
            class="loader loader-lg loader-primary"></span>
    </div>
    <div class="mbs-sidepanel_title-name">
      {{'sidepanel-downloads:header' | i18next: { format: 'title' } }}
    </div>
  </div>
  <ng-container body>
    <ng-container *ngIf="(buildList | async)?.length; else loader">
      <div class="mbs-sidepanel_tabset -vertical"
           [ngStyle]="{ 'max-width.px': '680' }"
           id="global_downloads_body">
        <ul #activeContent="mbsTabset"
            mode="vertical"
            (beforeUpdate)="onTabUpdate($event)"
            [loadOnAppearance]="false"
            mbsTabset>
          <li *ngFor="let item of tabItems"
              [mbsTabsetItem]="item.id">
            <div mbsTabsetLink
                 role="button">
              <div class="form-row h-100">
                <div class="col-auto d-flex align-items-center">
                  <img [src]="item.image"
                       [width]="22"
                       [alt]="item.title">
                </div>
                <div class="col">
                  <div class="form-row position-relative">
                    <div class="col" [class.app-update-dot]="(newVersionsAlerts$ | async)?.[item.updateAlert]">
                      <span class="font-weight-semibold">{{item.title}}</span>
                    </div>
                  </div>
                  <div *ngIf="item.beta"
                       class="form-row">
                    <div class="col">
                      <span class="text-cyan text-xs">{{ 'app:beta' | i18next: { format: 'title' } }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ng-container [ngSwitch]="item.id">
              <ng-template *ngSwitchCase="agentType.Backup"
                           mbsTabsetContent>
                <ng-container [ngTemplateOutlet]="tabContent"
                              [ngTemplateOutletContext]="{ builds: (backupBuilds$ | async), type: agentType.Backup }"></ng-container>
              </ng-template>

              <ng-template *ngSwitchCase="agentType.RMM"
                           mbsTabsetContent>
                <ng-container [ngTemplateOutlet]="tabContent"
                              [ngTemplateOutletContext]="{ builds: (rmmBuilds$ | async), type: agentType.RMM }"></ng-container>
              </ng-template>

              <ng-template *ngSwitchCase="agentType.RA"
                           mbsTabsetContent>
                <ng-container [ngTemplateOutlet]="tabContent"
                              [ngTemplateOutletContext]="{ builds: (raBuilds$ | async), type: agentType.RA }"></ng-container>
              </ng-template>

              <ng-template *ngSwitchCase="'bootableUSB'"
                           mbsTabsetContent>
                <div class="mbs-sidepanel_content-inner">
                  <h4>
                    {{ 'sidepanel-downloads:createBootableUSBTitle' | i18next: { format: 'title' } }}
                  </h4>

                  <ng-container *ngTemplateOutlet="bootableUSBDescription"></ng-container>

                  <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                      <img [src]="'assets/images/icons/bootable-usb.svg'"
                           [width]="22">

                      <span class="ml-2">{{ 'products.bootableUSB' | i18next: { format: 'title' } }}</span>
                    </div>

                    <mbs-button type="primary"
                                (click)="onOpenQuickRestoreButtonClick()">{{ 'buttons:create' | i18next }}</mbs-button>
                  </div>
                </div>

                <ng-template #bootableUSBDescription>
                  <p class="my-3">
                    {{ 'sidepanel-downloads:createBootableUSBDescription' | i18next }}
                    <a href="/AP/Help/restore/quick-restore"
                       target="_blank"> {{ 'sidepanel-downloads:learnMore' | i18next }} </a>
                  </p>
                </ng-template>
              </ng-template>

              <ng-template *ngSwitchCase="agentType.NetworkDiscovery"
                           mbsTabsetContent>
                <ng-container [ngTemplateOutlet]="tabContent"
                              [ngTemplateOutletContext]="{ builds: (ndBuilds$ | async), type: agentType.NetworkDiscovery, beta: true }"></ng-container>
              </ng-template>
            </ng-container>
          </li>

          <li *ngIf="isSettingsButtonVisible"
              class="mt-auto"
              mbsTabsetItem="options">
            <mbs-button [customClasses]="'mbs-tabset_link py-1 pl-0'"
                        [isCtrl]="true"
                        [block]="true"
                        [align]="'left'"
                        mbsTabsetLink>
              <span class="form-row align-items-center">
                <span class="col-auto">
                   <span class="ico ico-Gear h3 m-0 p-0"></span>
                </span>
                <span class="col lh-1">{{ 'buttons:options' | i18next: { format: 'title' } }}</span>
              </span>
            </mbs-button>
            <ng-template mbsTabsetContent>
              <form [formGroup]="settingsForm"
                    class="mbs-form mbs-sidepanel_content-inner border-top">
                <div class="mbs-form-group">
                  <mbs-checkbox [disableControlOptions]="{ emitEvent: false }"
                                [disableControl]="isBuilding"
                                formControlName="isSandboxEnabled"
                                label="Enable Sandbox">
                    <ng-template checkboxDescription>
                      <div class="mbs-form-text small mt-0">
                        {{ 'sidepanel-downloads:enableSandboxDescription' | i18next }}
                      </div>
                    </ng-template>
                  </mbs-checkbox>
                </div>
                <div class="mbs-form-group">
                  <mbs-checkbox [disableControlOptions]="{ emitEvent: false }"
                                [disableControl]="isBuilding"
                                formControlName="isAutoUpdateEnabled"
                                label="Allow Automatic Update">
                    <ng-template checkboxDescription>
                      <div class="mbs-form-text small mt-0">
                        {{ 'sidepanel-downloads:allowAutomaticUpdateDescription' | i18next }}
                        <a *ngIf="('HelpMarketing' | can:'read')"
                           href="/AP/Help/automation/agent-update"
                           target="_blank">
                          Learn more
                        </a>
                      </div>
                    </ng-template>
                  </mbs-checkbox>
                </div>
              </form>
            </ng-template>
          </li>
        </ul>

        <div [mbsTabsetOutlet]="activeContent"
             [overlay]="currentCopingStatus === copyingStatus.Copying"
             class="position-relative"></div>
      </div>
    </ng-container>

    <ng-template #loader>
      <mbs-loader></mbs-loader>
    </ng-template>
  </ng-container>
</mbs-sidepanel>

<ng-template #tabContent
             [templateTyping]="tabContentToken"
             let-builds="builds"
             let-type="type"
             let-beta="beta">
  <div class="mbs-sidepanel_content-inner">
    <mbs-alert *ngIf="isBuilding"
               [type]="alertType.info"
               [icon]="true"
               [size]="alertSize.sm">
      {{'builds:generating_alert' | i18next }}
    </mbs-alert>

    <h4 class="mt-0">
      {{ 'sidepanel-downloads:downloadAndInstall' | i18next: { format: 'title' } }}
    </h4>

    <mbs-alert *ngIf="allowSpecificBuild && (type === agentType.Backup || type === agentType.RMM || type === agentType.RA)"
               [type]="alertType.info"
               [icon]="true">
      <div class="row">
        <div class="col-11 pr-0">
          {{ 'sidepanel-downloads:specificDownload:text' | i18next }}
          <a href="#" (click)="specificDownloadClick($event, type)">{{ 'sidepanel-downloads:specificDownload:link' | i18next }}</a>
        </div>
        <div class="col-1 text-right">
          <mbs-button [isCtrl]="true"
                      size="xs"
                      icon="ico ico-Close"
                      type="dark"
                      (click)="specificDownloadClose()"></mbs-button>
        </div>
      </div>
    </mbs-alert>

    <ng-container *ngIf="type !== agentType.RA; else raTemplate">
      <p *ngIf="type === agentType.NetworkDiscovery">
        {{ 'sidepanel-downloads:networkDiscoveryDescription' | i18next }}
        <a *ngIf="('HelpMarketing' | can:'read')"
           href="/AP/Help/downloads/discover-deploy-tool"
           target="_blank"> {{ 'sidepanel-downloads:learnMore' | i18next }} </a>
      </p>

      <ng-container *ngFor="let build of builds; let i = index">
        <section class="pt-4 pb-4"
                 [ngClass]="{'border-bottom': i !== builds.length - 1, 'pt-5': i !== 0 }">
          <mbs-download-build [isSandboxEnabled]="isSandboxEnabled"
                              [build]='build'
                              [openedPanelIds]='openedPanelIds'
                              [licensedBrandList]='licensedBrandList'
                              [buildsInProgress]='buildsInProgress'
                              [currentUser]='currentUser'
                              [showDeleteButton]="type !== agentType.NetworkDiscovery"
                              [index]='i'
                              [beta]="beta"
                              (buildPanelClick)='onBuildPanelClick($event)'
                              (removeBuild)='onRemoveBuildHandle()'></mbs-download-build>
        </section>
      </ng-container>
    </ng-container>

    <ng-template #raTemplate>
      <mbs-download-connect></mbs-download-connect>
    </ng-template>
  </div>
</ng-template>
