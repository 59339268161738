import { I18NextPipe } from 'angular-i18next';
import { BuildOsType } from '@models/build';

export enum InstallationMode {
  Manual = 'manualInstallation',
  Bulk = 'bulkInstallation'
}

export const getOsTypes = (i18N: I18NextPipe) => ([
  {
    name: BuildOsType.Windows,
    id: BuildOsType.Windows
  },
  {
    name: BuildOsType.Linux,
    id: BuildOsType.Linux
  },
  {
    name: i18N.transform('app:common.macOS'),
    id: BuildOsType.Mac
  },
]);
