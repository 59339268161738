<ng-container *ngIf="!loading">
  <mbs-alert [type]="alertType.danger" [icon]="true" *ngIf="totalSockets < requiredSockets && hasVMLicense">
    {{ 'computers.module:backupSidePanel.socketsRequired' | i18next : { requiredSockets: requiredSockets, additionalSockets: requiredSockets - totalSockets } }}
  </mbs-alert>
  <div class="mb-2"
       *ngFor="let license of licenses">
    <mbs-sidepanel-backup-license-tab-card class="card"
                                           [license]="license"
                                           [computer]="computer"
                                           [isAgentSupportOfflineLicenseManagement]="isAgentSupportOfflineLicenseManagement"
                                           (onReleaseLicense)="setLicenses()"
                                           (onVMLicenseChange)="setLicenses()"></mbs-sidepanel-backup-license-tab-card>
  </div>

  <div class="mb-2"
       *ngIf="!licenses?.length">
    <mbs-sidepanel-backup-license-tab-card class="card"
                                           [license]="null"
                                           [computer]="computer"
                                           [isAgentSupportOfflineLicenseManagement]="isAgentSupportOfflineLicenseManagement"
                                           (onReleaseLicense)="setLicenses()"
                                           (onVMLicenseChange)="setLicenses()"></mbs-sidepanel-backup-license-tab-card>
  </div>

</ng-container>

<mbs-loader *ngIf="loading"></mbs-loader>
