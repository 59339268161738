import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
export type FormGroupTitleSize = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
export type FormGroupTitlePosition = 'left' | 'top';

@Component({
  selector: 'mbs-form-group',
  templateUrl: 'form-group.component.html',
  host: {
    class: 'mbs-form-group'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormGroupComponent {
  @HostBinding('class') get getClass() {
    return this.titlePosition === 'left' ? 'd-flex justify-content-between' : '';
  }
  /**
   * Title text for form group
   */
  @Input() title: string;

  /**
   * Classes for title
   */
  @Input() titleClasses: string;

  /**
   * Implements one of title size<br />
   * Possible values: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'<br />
   */
  @Input() titleSize: FormGroupTitleSize = 'h5';

  @Input() titlePosition: FormGroupTitlePosition = 'top';

  @Input() contentCustomClass = null;
}
