import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { DataChangeWatcherService, SidepanelCrudBase, ToastService} from 'mbs-ui-kit';
import { AgentType } from '@models/Computer';
import {
  ConnectSettingsForm,
  ConnectSettingsScope,
  IConnectSettings
} from '@models/connect/connect-settings.models';
import { ConnectService } from '@services/connect.service';
import { RmCommandsAbstractWrapper } from '@services/rm-commands.wrapper';
import {
  FormControl
} from '@angular/forms';
import { ProxyMode } from '@models/connect/connect-settings.models';
import {
  ConnectSettingsWrapper
} from '@models/connect/connect-settings.wrapper';
import { FormsUtil, MbsPopupType } from 'mbs-ui-kit';
import Computer from '@models/Computer';
import { combineLatest, finalize, mergeMap, noop, Observable, of } from 'rxjs';
import { switchMap, map, startWith, catchError, filter, first } from 'rxjs/operators';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { AgentOptionsService } from '@services';
import { CompaniesFacade } from '@facades/companies.facade';

@UntilDestroy()
@Component({
  selector: 'mbs-sidepanel-connect-settings',
  templateUrl: './sidepanel-connect-settings.component.html'
})
export class SidepanelConnectSettingsComponent extends SidepanelCrudBase<Computer> implements OnInit, OnDestroy {
  public readonly alertType = MbsPopupType;
  public readonly agentType = AgentType;

  public computer: Computer;
  public isSaveInProgress = false;
  public isGlobalOptionsLoaded = false;
  public isOptionsLoaded = false;
  public formState: ConnectSettingsWrapper = new ConnectSettingsWrapper();
  public parentAgentSettingsFormState: ConnectSettingsWrapper = new ConnectSettingsWrapper();

  public form = this.formState.configureSettingsForm();
  public useGlobal = new FormControl(true);

  private valid: boolean;
  private globalAgentSettings: IConnectSettings;

  constructor(
    private companyFacade: CompaniesFacade,
    private i18nPipe: I18NextPipe,
    private connectService: ConnectService,
    private rmCommands: RmCommandsAbstractWrapper,
    public agentOptions: AgentOptionsService,
    private toastService: ToastService,
    cdk: DataChangeWatcherService
  ) {
    super(cdk);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  updateData(computer: Computer): void {
    this.isOptionsLoaded = false;
    this.computer = computer;

    if (!this.isGlobalOptionsLoaded) {
      this.loadGlobalOptions();
    } else {
      const hasCompany = Boolean(this.computer.company?.id);
      this.loadOptions(hasCompany);
    }
  }

  ngOnInit(): void {
    combineLatest([
      this.form.get('managedSystem.allowRemoteConnection').valueChanges.pipe(startWith(null)),
      this.form.get('disconnectIfInactive.enabled').valueChanges.pipe(startWith(null))
    ]).pipe(untilDestroyed(this)).subscribe(this.updateFormDisabledState.bind(this));

    this.useGlobal.valueChanges.pipe(untilDestroyed(this)).subscribe(this.onUseGlobalChange.bind(this));
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(this.onConnectAgentSettingsFormChange.bind(this));
  }

  public getGlobalOptionsEditUrl(): string {
    if (this.hasCompanySettings()) {
      return `/AP/Companies/${this.computer?.company?.id}?tab=connectAgentOptions`;
    }

    return '/AP/GlobalAgentOptions?active=ra';
  }

  public hasCompanySettings(): boolean {
    return this.formState.hasCompanySettings;
  }

  private loadGlobalOptions(): void {
    this.connectService.getSettings(ConnectSettingsScope.Global)
      .pipe(
        mergeMap((settings) => {
          return this.agentOptions.getDefault().pipe(map((options) => {
            if (settings) {
              const newSettings = new ConnectSettingsWrapper();
              return newSettings.migrateSettings(settings, options);
            }

            const newSettings = new ConnectSettingsWrapper();
            newSettings.updateState({
              managedSystem: options.connectValues
            });
            return newSettings.getSettings();
          }))
        }),
        untilDestroyed(this)
      )
      .subscribe((globalAgentSettings) => {
        const hasCompany = Boolean(this.computer.company?.id);
        this.globalAgentSettings = globalAgentSettings;
        this.isGlobalOptionsLoaded = true;
        this.loadOptions(hasCompany);
      });
  }

  private loadOptions(hasCompany: boolean): void {
    combineLatest([
      of(this.globalAgentSettings),
      hasCompany ? this.getCompanySettings() : of(null),
      this.connectService.getSettings(ConnectSettingsScope.Computer, this.computer.hid),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([globalAgentSettings, companyAgentSettings, computerAgentSettings]) => {
        this.formState.hasCompanySettings = !!companyAgentSettings;
        this.parentAgentSettingsFormState.updateStateFromConfig(companyAgentSettings ? companyAgentSettings : globalAgentSettings);
        this.formState.updateStateFromConfig(computerAgentSettings);
        this.form.patchValue(this.formState, { emitEvent: false });

        this.isOptionsLoaded = true;
        this.useGlobal.patchValue(!computerAgentSettings);
      });
  }

  private getCompanySettings() {
    return this.connectService.getSettings(ConnectSettingsScope.Company, this.computer.company.id).pipe(
      mergeMap((settings) => {
        return settings
          // Tricky. If we have settings need to apply migration. Else return null, means we need to use global
          ? this.companyFacade.getById$(this.computer.company?.id, true).pipe(
            filter(Boolean),
            first(),
            mergeMap((company) => {
              return company?.agentSettingsId
                ? this.agentOptions.getByKey(company.agentSettingsId).pipe(map((options) => {
                  return new ConnectSettingsWrapper().migrateSettings(settings, options);
                }))
                : of(settings);
            })
          )
          : of(null);
      }),
      untilDestroyed(this)
    )
  }

  updateFormValues(): void {
    this.useGlobal.patchValue(this.formState.useGlobal, { emitEvent: false });
    if (this.formState.useGlobal) {
      this.form.patchValue(this.parentAgentSettingsFormState, { emitEvent: false });
    } else {
      this.form.patchValue(this.formState, { emitEvent: false });
    }
  }

  handleSave(): Observable<boolean> {
    if (!this.valid) {
      this.triggerValidation();

      return of(false);
    }

    this.isSaveInProgress = true;

    this.getSaveConnectSettingsCommand(this.formState)
        .pipe(
          switchMap(() =>
            this.rmCommands.updateConnectConfig(
              {
                hid: this.computer.id,
                online: true
              },
              AgentType.RA
            )
          ),
          map(() => {
            this.toastService.success();
            this.close.emit();

            return true;
          }),
          finalize(() => (this.isSaveInProgress = false))
        )
        .subscribe(noop);

    return of(true);
  }

  private getSaveConnectSettingsCommand(state: ConnectSettingsWrapper): Observable<unknown> {
    if (state.useGlobal) {
      return this.connectService.deleteSettings(ConnectSettingsScope.Computer, this.computer.hid).pipe(
        catchError((e: HttpErrorResponse) => {
          if (e.status === HttpStatusCode.NotFound) {
            return of(true)
          } else {
            throw(e);
          }
        })
      );
    }

    return this.connectService.updateSettings(this.formState.getSettings(), ConnectSettingsScope.Computer, this.computer.hid);
  }

  handleDelete(): Observable<boolean> {
    throw Error('NotImplement');
  }

  getComputerName(): string {
    return Computer.getComputerName(this.computer);
  }

  getComputerStatus(): string {
    return Computer.getComputerStatusText(this.computer?.online, this.i18nPipe);
  }

  isProxyServerHostVisible(): boolean {
    const formState = this.getFormState();

    if (formState) {
      const proxyMode = formState.proxy.mode;

      if (proxyMode === ProxyMode.Manual) {
        return true;
      }
    }

    return false;
  }

  isUseBasicAuthUserPasswordSectionVisible(): boolean {
    const formState = this.getFormState();

    if (formState) {
      return formState.proxy.useBasicAuth;
    }

    return false;
  }

  isUnattendedAccessPasswordVisible(): boolean {
    const formState = this.getFormState();

    if (formState) {
      return formState.security.customPassword.enabled;
    }

    return false;
  }

  isConfigPasswordVisible(): boolean {
    const formState = this.getFormState();

    if (formState) {
      return formState.configPassword.enabled;
    }

    return false;
  }

  isGlobalSettingsVisible(): boolean {
    return this.formState.useGlobal;
  }

  getFormState(): ConnectSettingsWrapper {
    return this.formState.useGlobal ? this.parentAgentSettingsFormState : this.formState;
  }

  updateFormDisabledState(): void {
    this.form.enable({ emitEvent: false });
    this.useGlobal.enable({ emitEvent: false });

    if (this.formState.useGlobal) {
      this.form.disable({ emitEvent: false });
    } else {
      const remoteAccessAllowedControl = this.form.get('managedSystem.allowRemoteConnection');

      if (remoteAccessAllowedControl.value) {
        this.form.enable({ emitEvent: false });

        if (this.form.value?.disconnectIfInactive?.enabled) {
          this.form.get('disconnectIfInactive.interval').enable();
          this.form.get('disconnectIfInactive.intervalUnits').enable();
        } else {
          this.form.get('disconnectIfInactive.interval').disable();
          this.form.get('disconnectIfInactive.intervalUnits').disable();
        }
      } else {
        this.form.disable({ emitEvent: false });
        remoteAccessAllowedControl.enable({ emitEvent: false });
      }
    }
  }

  onConnectAgentSettingsFormChange(data: ConnectSettingsForm['value']): void {
    this.formState.updateState(data);

    this.updateValidState();
  }

  isLoading(): boolean {
    return !this.isGlobalOptionsLoaded || !this.isOptionsLoaded;
  }

  onUseGlobalChange(useGlobal: boolean): void {
    this.formState.useGlobal = useGlobal;

    this.updateFormValues();
    this.updateFormDisabledState();
    this.updateValidState();
  }

  triggerValidation(): void {
    if (this.isProxyServerHostVisible()) {
      FormsUtil.triggerValidation(this.form.get('proxy.host'));
    }

    if (this.isUseBasicAuthUserPasswordSectionVisible()) {
      FormsUtil.triggerValidation(this.form.get('proxy.basicAuthUserName'));
      FormsUtil.triggerValidation(this.form.get('proxy.basicAuthPassword'));
    }

    if (this.isConfigPasswordVisible()) {
      FormsUtil.triggerValidation(this.form.get('configPassword.password'));
    }

    if (this.isUnattendedAccessPasswordVisible()) {
      FormsUtil.triggerValidation(this.form.get('security.customPassword.password'));
    }
  }

  updateValidState(): void {
    this.valid =
      this.formState.useGlobal || this.formState.isValid(this.form);
  }
}
