<mbs-modal [title]="'computers.module:modals.licenseRequired' | i18next">
  <ng-container modal-body>
    <mbs-alert [type]="alertType"
               [icon]="true">
      {{ 'computers.module:modals.unableToAssignLicense' | i18next }}
    </mbs-alert>
  </ng-container>
  <ng-container modalFooter>
    <mbs-button [type]="'primary'"
                (click)="baseModal.close()">
      {{ 'buttons:close' | i18next }}
    </mbs-button>
  </ng-container>
</mbs-modal>
