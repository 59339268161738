import { Component } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';

@Component({
  selector: 'mbs-wizards-legacy-property-tooltip',
  template: `<span
    class="fa fa-info-circle ml-2 cursor-help text-info"
    tooltipClass="tooltip-lg"
    [container]="'body'"
    [ngbTooltip]="'wizards:legacy.legacyPropertyTooltip' | i18next"></span>`
})
export class LegacyPropertyTooltipComponent {
  constructor(public i18nPipe: I18NextPipe) {}
}
