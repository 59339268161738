import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MbsValidators } from 'mbs-ui-kit';

export enum ConnectSettingsScope {
  Global = 'global',
  Computer = 'computer',
  Company = 'company'
}

export type ConnectSettingsResponse = {
  body: string;
};

export enum JoinSessionMode {
  Pin = 'Pin',
  Dialog = 'Dialog',
  DialogAndPin = 'DialogAndPin'
}

export enum LoggingLevel {
  Debug = 'Debug',
  Info = 'Info',
  Warning = 'Warning',
  NoLog = 'NoLog'
}

export enum ProxyMode {
  None = 'None',
  AutoDetect = 'AutoDetect',
  Manual = 'Manual'
}

export enum PinLength {
  Four = 4,
  Six = 6,
  Eight = 8,
  Ten = 10
}

export enum UnattendedAccessDisconnectMode {
  ReturnOriginalState,
  LeaveOpen ,
  Lock,
  Logoff,
}

export enum TimeUnits {
  Minutes = 0,
  Hours = 1,
  Days = 2,
  Seconds = 3
}

export interface IConnectSettings {
  ConfigPassword: string;
  ManagedSystemConfig: {
    AllowOutgoingConnections: boolean;
    AllowGui: boolean;
    AllowRAUnattendedAccess: boolean;
    AllowRemoteConnection: boolean;
  };
  NetworkConfig: {
    ProxyServerConfigMode: ProxyMode;
    ProxyServerHost: string;
    ProxyServerPort: string;
    ProxyServerUseBasicAuth: boolean;
    ProxyServerUserName: string;
    ProxyServerPassword: string;
  };
  ServerConfig: {
    Password: {
      Length: PinLength;
    };
    CustomPasswords: {
      MainItemValue: string;
      ActsAsPin: false;
    };
    JoinSessionMode: JoinSessionMode;
    UnattendedAccessDisconnectConfig: {
      Mode: UnattendedAccessDisconnectMode,
      WithConfirmation: boolean
    },
    InputControl: {
      BlackScreen: boolean,
      LockInput: boolean
    },
    DisconnectIfInactiveConfig: {
      Enabled: boolean,
      Interval: number,
      IntervalUnits: TimeUnits
    }
  };
  AppFeaturesConfig: {
    UpdateConfig: {
      ModeAuto: boolean;
    };
  };
  LoggerConfig: {
    LoggingLevel: LoggingLevel;
  };
}

export type ConnectSettingsForm = FormGroup<{
  logger: FormGroup<{
    level: FormControl<LoggingLevel>
  }>;
  update: FormGroup<{
    auto: FormControl<boolean>
  }>;
  proxy: FormGroup<{
    mode: FormControl<ProxyMode>;
    host: FormControl<string>;
    port: FormControl<number>;
    useBasicAuth: FormControl<boolean>;
    basicAuthUserName: FormControl<string>;
    basicAuthPassword: FormControl<string>;
  }>;
  configPassword: FormGroup<{
    password: FormControl<string>;
    enabled: FormControl<boolean>;
  }>;
  managedSystem: FormGroup<{
    allowRAUnattendedAccess: FormControl<boolean>;
    allowRemoteConnection: FormControl<boolean>;
    allowOutgoingConnections: FormControl<boolean>;
    allowGui: FormControl<boolean>;
  }>;
  security: FormGroup<{
    pinLength: FormControl<PinLength>,
    customPassword: FormGroup<{
      enabled: FormControl<boolean>;
      password: FormControl<string>;
    }>,
    joinSessionMode: FormControl<JoinSessionMode>
  }>,
  unattendedAccessDisconnect: FormGroup<{
    mode: FormControl<UnattendedAccessDisconnectMode>;
  }>,
  inputControl: FormGroup<{
    blackScreen: FormControl<boolean>;
    lockInput: FormControl<boolean>;
  }>,
  disconnectIfInactive: FormGroup<{
    enabled: FormControl<boolean>;
    interval: FormControl<number>;
    intervalUnits: FormControl<TimeUnits>;
  }>
}>;
