import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AgentOptions } from '@models/AgentOptions';
import Computer, {
  Agent,
  ComputersApplications,
  ComputersResponse,
  Edition,
  GetComputersParams,
  PlanSettingsReportParams,
  UpdateAgentSettings
} from '@models/Computer';
import { ComputerAvailableVersions } from '@models/ComputersModals';
import { ConnectionType } from '@models/ConnectionType';
import { AvailableEdition, BackupAgentSettings } from '@models/edit-account-modal-models';
import { DestinationForEditAccount } from '@models/editAccountModalModels';
import HostInfo from '@models/rmm/HostInfo';
import { StorageAccountCamelCase } from '@models/StorageAccount';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export abstract class ComputersAbstractService {
  abstract getComputers(params?: GetComputersParams): Observable<ComputersResponse>;
  abstract getComputerById(id: string): Observable<Computer>;
  abstract getComputerByHid(hid: string): Observable<Computer>;
  abstract updateComputer(computer: Computer): Observable<any>;
  abstract deleteComputer(hid: string): Observable<any>;
  abstract getDestinationsByComputerHid(hid: string): Observable<StorageAccountCamelCase[]>;
  abstract getConnectionUrl(computer: Computer, payload): Observable<any>;
  abstract getTotalUnsupportedVersionComputers(): Observable<{ total: number }>;
  abstract stopShowingBannerForCurrentSession(): Observable<any>;
  abstract availableVersions(hid: string): Observable<ComputerAvailableVersions>;
  abstract getComputerAgentOptions(hid: string): Observable<AgentOptions>;
  abstract getComputerHostInfo(computer: Computer): Observable<HostInfo>;
  abstract getComputerApplications(hid: string): Observable<Agent[]>;
  abstract getComputersApplicationsCount(httpParams?: HttpParams): Observable<ComputersApplications>;
  abstract getComputerApplicationById(hid: string, appId: string): Observable<Agent>;
  abstract setComputerHidden(hid: string, hidden: boolean): Observable<any>;
  abstract setComputerDisplayName(hid: string, displayName: string): Observable<any>;
  abstract updateComputerApplicationById(hid: string, appId: string, body: object): Observable<Agent>;
  abstract getAvailableEditions(hid): Observable<Edition[]>;
  abstract updateBackupAgentSettings(hid: string, settings: BackupAgentSettings): Observable<UpdateAgentSettings>;
  abstract authorizeComputer(hid: string, userId?: string, companyId?: string): Observable<null>;
  abstract authorizeComputers(params: {
    hids?: string[];
    filter?: GetComputersParams;
    userId?: string;
    companyId?: string;
  }): Observable<null>;
  abstract updateUser(hid: string, userId: string): Observable<any>;
  abstract getAvailableEditionsById(hid: string, userId: string): Observable<AvailableEdition[]>;
  abstract getDestinationsByComputerHidAndUserId(hid: string, userId: string): Observable<DestinationForEditAccount[]>;
  abstract getDestinationsPrefixListByUserId(hid: string, destinationId: string, userId: string): Observable<string[]>;
  abstract isConnectionAllowed(hid: string, connectionType: ConnectionType, withoutBackup: boolean): Observable<{ result: boolean }>;
  abstract planSettingsReport(params?: PlanSettingsReportParams): Observable<any>;
  abstract export(params?: GetComputersParams): Observable<any>;
  abstract suggestUserName(hid: string, companyId: string, forceNew?: boolean): Observable<{ userId: string; userName: string }>;
  abstract editTags(hids: string[], tagIds: number[]): Observable<null>;
  abstract addTags(tagIds: number[], hids?: string[], filter?: GetComputersParams): Observable<null>;
}

// service
@Injectable()
export class ComputersService implements ComputersAbstractService {
  private readonly controllerPath = 'api/computers';

  constructor(private http: HttpClient) {}

  getComputers(params?: GetComputersParams): Observable<ComputersResponse> {
    return this.http.get<ComputersResponse>(this.controllerPath, { params });
  }

  getComputerById(id: string): Observable<Computer> {
    return this.http.get<Computer>(`${this.controllerPath}/id`, { params: { id } });
  }

  getComputerByHid(hid: string): Observable<Computer> {
    return this.http
      .get<ComputersResponse>(this.controllerPath, { params: { hid } })
      .pipe(map((res) => (res && res.total && res.data.length ? res.data[0] : null)));
  }

  updateComputer(computer: Computer): Observable<any> {
    return this.http.put(`${this.controllerPath}/${computer.hid}`, computer);
  }

  deleteComputer(hid: string): Observable<any> {
    return this.http.delete(`${this.controllerPath}/${hid}`);
  }

  getDestinationsByComputerHid(hid: string): Observable<StorageAccountCamelCase[]> {
    return this.http.get<StorageAccountCamelCase[]>(`${this.controllerPath}/${hid}/destinations`);
  }

  getConnectionUrl(computer: Computer, payload): Observable<any> {
    return this.http.post<any>(`${this.controllerPath}/${computer.hid}/applications/connect/create-connection-url`, payload, {
      headers: new HttpHeaders({ MANUAL_ERROR_HANDLED: 'MANUAL_ERROR_HANDLED' })
    });
  }

  isConnectionAllowed(hid: string, connectionType: ConnectionType, withoutBackup = false): Observable<{ result: boolean }> {
    return this.http.get<{ result: boolean }>(`${this.controllerPath}/${hid}/applications/connect/is-connection-allowed`, {
      headers: new HttpHeaders({ MANUAL_ERROR_HANDLED: 'MANUAL_ERROR_HANDLED' }),
      params: { connectionType, withoutBackup }
    });
  }

  getTotalUnsupportedVersionComputers(): Observable<{ total: number }> {
    return this.http.get<{ total: number }>(`${this.controllerPath}/count?t=unsupported`);
  }

  stopShowingBannerForCurrentSession(): Observable<any> {
    return this.http.get<any>('/Admin/Shared/StopShowingBannerForCurrentSession');
  }

  availableVersions(hid: string): Observable<ComputerAvailableVersions> {
    return this.http.get<ComputerAvailableVersions>(`${this.controllerPath}/${hid}/availableversions`);
  }

  getComputerAgentOptions(hid: string): Observable<AgentOptions> {
    return this.http.get<AgentOptions>(`${this.controllerPath}/${hid}/agentoptions`);
  }

  getComputerHostInfo(computer: Computer): Observable<HostInfo> {
    return this.http.get<HostInfo>(`${this.controllerPath}/${computer.hid}`);
  }

  getComputerApplications(hid: string): Observable<Agent[]> {
    return this.http.get<Agent[]>(`${this.controllerPath}/${hid}/applications`);
  }

  getComputersApplicationsCount(httpParams?: HttpParams): Observable<ComputersApplications> {
    return this.http.get<ComputersApplications>(`${this.controllerPath}/applications/count`, {
      params: httpParams
    });
  }

  getComputerApplicationById(hid: string, appId: string): Observable<Agent> {
    return this.http.get<Agent>(`${this.controllerPath}/${hid}/applications/${appId}`);
  }

  setComputerHidden(hid: string, hidden: boolean): Observable<any> {
    return this.http.put(`${this.controllerPath}/${hid}`, { hid, hidden });
  }

  setComputerDisplayName(hid: string, displayName: string): Observable<any> {
    return this.http.put(`${this.controllerPath}/${hid}`, { displayName });
  }

  updateComputerApplicationById(hid: string, appId: string, body: object): Observable<Agent> {
    return this.http.put<Agent>(`${this.controllerPath}/${hid}/applications/${appId}`, body);
  }

  getAvailableEditions(hid: string) {
    return this.http.get<Array<Edition>>(`/${this.controllerPath}/${hid}/applications/backup/available-editions`);
  }

  updateBackupAgentSettings(hid: string, settings: BackupAgentSettings): Observable<UpdateAgentSettings> {
    return this.http.post<UpdateAgentSettings>(`/${this.controllerPath}/${hid}/applications/backup/settings`, settings);
  }

  authorizeComputer(hid: string, userId?: string, companyId?: string): Observable<null> {
    return this.http.post<null>(`/${this.controllerPath}/${hid}/applications/authorize`, {
      userId,
      companyId
    });
  }

  authorizeComputers(params: { hids?: string[]; filter?: GetComputersParams; userId?: string; companyId?: string }): Observable<null> {
    return this.http.post<null>(`/${this.controllerPath}/applications/authorize`, params);
  }

  updateUser(hid: string, userId: string): Observable<any> {
    return this.http.put(`${this.controllerPath}/${hid}/user`, { userId });
  }

  getAvailableEditionsById(hid: string, userId: string): Observable<AvailableEdition[]> {
    return this.http.get<AvailableEdition[]>(`/${this.controllerPath}/${hid}/applications/backup/available-editions`, {
      params: { userId }
    });
  }

  getDestinationsByComputerHidAndUserId(hid: string, userId: string): Observable<DestinationForEditAccount[]> {
    return this.http.get<DestinationForEditAccount[]>(`${this.controllerPath}/${hid}/applications/backup/destinations`, {
      params: { userId }
    });
  }

  getDestinationsPrefixListByUserId(hid: string, destinationId: string, userId: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.controllerPath}/${hid}/applications/backup/destinations/${destinationId}/prefixlist`, {
      params: { userId }
    });
  }

  planSettingsReport(params?: PlanSettingsReportParams): Observable<any> {
    return this.http.post(`${this.controllerPath}/plan-settings-report`, { ...params });
  }

  export(params?: GetComputersParams): Observable<any> {
    return this.http.get(`${this.controllerPath}/export`, { params, responseType: 'text' });
  }

  suggestUserName(hid: string, companyId: string, forceNew = true): Observable<{ userId: string; userName: string }> {
    return this.http.get<{ userId: string; userName: string }>(`/${this.controllerPath}/${hid}/suggest-user-name`, {
      params: {
        forceNew,
        companyId
      }
    });
  }

  addTags(tagIds: number[], hids?: string[], filter?: GetComputersParams): Observable<null> {
    return this.http.post<null>(`${this.controllerPath}/tags`, {
      tags: tagIds,
      ...(hids ? { hids } : { filter })
    });
  }

  editTags(hids: string[], tagIds: number[]): Observable<null> {
    return this.http.put<null>(`${this.controllerPath}/tags`, {
      hids,
      tags: tagIds
    });
  }
}
