import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import Administrator from '@models/Administrator';
import { AgentOptions } from '@models/AgentOptions';
import Computer, { Agent, AgentType, OsType } from '@models/Computer';
import HostInfo from '@models/rmm/HostInfo';
import * as AppsActions from '@modules/computer-apps/store/computer-apps.actions';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import * as SummaryComputerActions from '@modules/summary-computers/store/summary-computer.actions';
import * as TerminalActions from '@modules/terminal-emulator/store/terminal.actions';
import { TerminalEmulatorService } from '@modules/terminal-emulator/terminal-emulator.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AppPersistentStateService, AuthService, TFARMMPermissionHelperService } from '@services';
import { RmmStateService } from '@services/rmm-state.service';
import { RmmWebsocketService } from '@services/rmm-websocket.service';
import { RmmService } from '@services/rmm.service';
import { SidepanelDownloadsComponent } from '@shared/components/sidepanel-downloads/sidepanel-downloads.component';
import { ComputersFacade } from '@shared/facades/computers.facade';
import { InitialTreeElements } from '@utils/constants/rmm-registry-constants';
import { mediumDateWithTime } from '@utils/date';
import { versionCompare } from '@utils/version-compare';
import { I18NextPipe, I18NextService } from 'angular-i18next';
import { DataChangeWatcherService, MbsPopupType, SidepanelCrudBase, SidepanelService, ToastService } from 'mbs-ui-kit';
import { BehaviorSubject, Observable, Subject, noop, of, merge as rxMerge } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mapTo,
  pluck,
  startWith,
  switchMap,
  tap
} from 'rxjs/operators';
import { RegTree } from './remote-registry-tab/reg-tree';

export const IS_MODAL_CONTENT_OPEN = new BehaviorSubject<boolean>(false);

@UntilDestroy()
@Component({
  selector: 'mbs-sidepanel-rmm-info',
  templateUrl: './sidepanel-rmm.component.html',
  viewProviders: [
    {
      provide: 'IS_MODAL_CONTENT_OPEN',
      useValue: IS_MODAL_CONTENT_OPEN
    }
  ]
})
export class SidepanelRmmComponent extends SidepanelCrudBase<Computer> implements OnInit, OnDestroy {
  @Output() openBackupPanel = new EventEmitter<Computer>();
  public readonly mediumDateWithTime = mediumDateWithTime;
  private version$: Subject<string> = new Subject();
  public hid = '';
  private downloadPanel = null;
  public onlineStatus = null;
  public computerName = null;
  public noRmmAgentFound = true;

  showJson = false;
  public isModalContent = false;
  private twoFAConfirmModalRef: NgbModalRef;

  public hostInfo$: Subject<HostInfo>;
  public lastUpdated$: Observable<string>;
  public agentVersion$: Observable<{ version: string; supported: boolean }>;
  public isOnlineAgent = false;
  public computer: Computer;
  public isOnline: boolean;
  public loaded = false;
  public regTree = new RegTree(InitialTreeElements);
  public needToShowContent = true;
  public loadingGeneral = true;
  public agentOptions: AgentOptions;
  public readonly alertType = MbsPopupType;

  public hasAgent = Computer.hasAgent;
  public AgentType = AgentType;

  public agent$: Observable<Agent>;
  public isMacOS: boolean;
  public isLinuxOS: boolean;
  public user: Administrator;
  public shouldRefreshComputerData: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public modalContainer = 'mbs-sidepanel-rmm-info .mbs-form_content';

  get showTerminalTab(): boolean {
    if (this.isCurrentUserSubadmin()) {
      return this.isPowerShellAllowed() && this.isOnlineAgent;
    }
    return this.isOnlineAgent;
  }

  get readonly(): boolean {
    if (this.isCurrentUserProvider()) {
      return false;
    }
    return this.agentOptions && this.agentOptions.rmmValues && this.agentOptions.rmmValues.readOnly;
  }
  constructor(
    private auth: AuthService,
    public rmmService: RmmService, // prettier
    public rmmStateService: RmmStateService,
    public rmmWebsocket: RmmWebsocketService,
    protected cdNew: DataChangeWatcherService,
    private sidepanelService: SidepanelService,
    private store: Store,
    private toastService: ToastService,
    public i18nextService: I18NextService,
    public i18nPipe: I18NextPipe,
    private appState: AppPersistentStateService,
    private computersFacade: ComputersFacade,
    private commandService: CommandService,
    private tfaRMMPermissionHelper: TFARMMPermissionHelperService,
    private terminalService: TerminalEmulatorService
  ) {
    super(cdNew);
    this.auth.currentUser.pipe(filter((user) => !!user)).subscribe((admin) => {
      this.downloadPanel = SidepanelDownloadsComponent;
      this.user = admin;
    });
    this.computersFacade.currentComputer$.pipe(untilDestroyed(this)).subscribe((computer) => {
      if (computer && computer.os) {
        this.isMacOS = computer.os === OsType.apple;
        this.isLinuxOS = computer.os === OsType.linux;
      }
      this.isOnlineAgent = Computer.getAgent(computer, AgentType.RMM)?.online;
      this.computer = computer;
      this.noRmmAgentFound = !Computer.hasAgent(computer, AgentType.RMM);
      this.computerName = Computer.getComputerName(computer);
      this.isOnline = computer?.online && this.isOnlineAgent;
      this.onlineStatus = Computer.getComputerStatusDurationText(computer, this.i18nPipe, ' ');

      this.shouldRefreshComputerData.next(this.noRmmAgentFound);
    });
  }

  ngOnInit(): void {
    this.loadingGeneral = true;

    this.shouldRefreshComputerData
      .pipe(
        debounceTime(2000),
        distinctUntilChanged(),
        filter((value) => value),
        untilDestroyed(this)
      )
      .subscribe(() => this.computersFacade.loadComputerByHid({ hid: this.computer.hid, quiet: true, force: true }));

    this.hostInfo$ = this.rmmService.getStat('host').pipe(
      filter(Boolean),
      pluck('data', '0', 'data', '0'),
      filter(Boolean),
      finalize(() => {
        this.loadingGeneral = false;
      }),
      untilDestroyed(this)
    ) as Subject<HostInfo>;

    this.lastUpdated$ = this.rmmService.getStat('host').pipe(
      map((data) => {
        if (data && data.data && data.data[0]) {
          return data.data[0].header.utcTime;
        }
        return null;
      })
    );

    this.agentVersion$ = rxMerge(
      this.version$,
      this.rmmService.getStat('host').pipe(
        // because default fire is null
        filter(Boolean),
        pluck('data', '0', 'header', 'version'),
        // because need ignore if cant detect version
        filter(Boolean)
      )
    ).pipe(
      map((version: string) => ({
        // if version undefined by all detect strategies
        version: version || 'old',
        supported: version ? versionCompare(version, '1.1.0.539') > 0 : Boolean(version)
      })),
      startWith({ version: 'undetected', supported: true })
    );

    this.commandService.tokenExpired
      .pipe(
        switchMap((value) =>
          this.tfaRMMPermissionHelper.is2FAPassedStream([this.hid], this.getTfaContainer()).pipe(catchError((value) => of(value)))
        ),
        tap((confirmed) => this.commandService.canGoNext.emit(confirmed)),
        untilDestroyed(this)
      )
      .subscribe(noop);
  }

  requestAgentOptions(): void {
    this.rmmService
      .fetchAgentOptions(this.hid)
      .pipe(
        finalize(() => {
          this.loadingGeneral = false;
        }),
        untilDestroyed(this)
      )
      .subscribe((options) => {
        this.agentOptions = options;
        this.appState.data.rmmSidepanel.agentOptions = options;
      });
  }

  emitVersion(computer: Computer): void {
    if (computer.Version) {
      this.version$.next(computer.Version);
      return;
    }

    this.version$.next(this.getRmmVersion(computer));
  }

  getRmmVersion(computer: Computer) {
    return Computer.getAgent(computer, AgentType.RMM)?.version;
  }

  handlePanelClose(): void {
    // switch to another tab on rmmSidePanelClose to call ngOnDestroy for the General tab fix
    queueMicrotask(() => {
      this.loadingGeneral = true;
      this.store.dispatch(TerminalActions.clearCache());
    });
  }

  handleClickDownloads(): void {
    const panel = this.sidepanelService.add(this.downloadPanel);
    this.sidepanelService.openByType(this.downloadPanel).pipe(untilDestroyed(this), mapTo(panel)).subscribe();
  }

  updateData(computer: Computer): void {
    this.store.dispatch(SummaryComputerActions.setCurrentComputer({ current: computer }));
    this.store.dispatch(AppsActions.loadComputerApp({ hid: computer.hid, appType: AgentType.RMM }));
    this.emitVersion(computer);
    this.rmmService.resetState();
    this.computer = computer;

    this.noRmmAgentFound = !Computer.hasAgent(this.computer, AgentType.RMM);
    this.shouldRefreshComputerData.next(this.noRmmAgentFound);

    this.isMacOS = computer.os === OsType.apple;
    this.isLinuxOS = computer.os === OsType.linux;
    if (computer.os) this.loaded = false;
    // close open 2FA confirm dialog
    if (this.twoFAConfirmModalRef) {
      this.twoFAConfirmModalRef.close();
      this.twoFAConfirmModalRef = null;
    }

    this.hid = computer.hid;
    this.rmmService.setHid(this.hid);

    this.computerName = Computer.getComputerName(computer);
    this.onlineStatus = Computer.getComputerStatusDurationText(computer, this.i18nPipe, ' ');
    this.isOnlineAgent = Computer.getAgent(computer, AgentType.RMM)?.online;
    this.isOnline = computer?.online && this.isOnlineAgent;

    this.requestAgentOptions();
    this.rmmService
      .fetchLastStat<HostInfo>('host', this.hid)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const isOnline = Computer.isAgentOnline(computer, AgentType.RMM);
        this.version$.next(Computer.getAgent(computer, AgentType.RMM)?.version);
        this.isOnlineAgent = isOnline;
        this.isOnline = computer?.online && this.isOnlineAgent;
        this.loadingData = false;
        this.loaded = true;
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  handleSave(): Observable<boolean> {
    return null;
  }

  handleDelete(): Observable<boolean> {
    return null;
  }

  getTfaContainer(): string {
    return IS_MODAL_CONTENT_OPEN.getValue() ? 'body .modal .modal-body' : this.modalContainer;
  }

  trackByFn(index: number, item: string): string {
    return item;
  }

  handleBeforeTabChange(event): void {
    if (event.nextId === 'powershell' && !(this.isCurrentUserSubadmin() || this.isPowerShellAllowed())) {
      this.toastService.error(this.i18nextService.t('error:rmm:agent_option_disabled', { setting: 'Allow PowerShell Execution' }));
      event.stop(); // Will prevent to change active tab
    }
  }

  private isCurrentUserProvider(): boolean {
    return this.user && this.user.IsProvider;
  }

  private isCurrentUserSubadmin(): boolean {
    return this.user && !this.user.IsProvider;
  }

  private isPowerShellAllowed(): boolean {
    return this.agentOptions && this.agentOptions.rmmValues && this.agentOptions.rmmValues.allowPowerShell;
  }

  public openBackup(computer: Computer): void {
    this.openBackupPanel.emit(computer);
  }

  // rewrite close handler
  handleCloseVoid() {
    this.store.dispatch(SummaryComputerActions.setCurrentComputer({ current: null }));
    super.handleCloseVoid();
  }
}
