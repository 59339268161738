import { I18NextPipe } from 'angular-i18next';
import {
  JoinSessionMode, LoggingLevel,
  ProxyMode,
  TimeUnits,
  UnattendedAccessDisconnectMode
} from '@models/connect/connect-settings.models';

export const getUnattendedAccessDisconnectModeOptions = (i18N: I18NextPipe) => ([
  {
    label: i18N.transform('agent-options:connectOptions.unattendedAccessDisconnectMode.returnOriginalState'),
    value: UnattendedAccessDisconnectMode.ReturnOriginalState,
  },
  {
    label: i18N.transform('agent-options:connectOptions.unattendedAccessDisconnectMode.lock'),
    value: UnattendedAccessDisconnectMode.Lock,
  },
  {
    label: i18N.transform('agent-options:connectOptions.unattendedAccessDisconnectMode.leaveOpen'),
    value: UnattendedAccessDisconnectMode.LeaveOpen,
  },
  {
    label: i18N.transform('agent-options:connectOptions.unattendedAccessDisconnectMode.logoff'),
    value: UnattendedAccessDisconnectMode.Logoff,
  }
]);

export const getTimeUnits = (i18N: I18NextPipe) => ([
  {
    label: i18N.transform('agent-options:connectOptions.timeUnits.minutes'),
    value: TimeUnits.Minutes
  },
  {
    label: i18N.transform('agent-options:connectOptions.timeUnits.hours'),
    value: TimeUnits.Hours
  },
  {
    label: i18N.transform('agent-options:connectOptions.timeUnits.days'),
    value: TimeUnits.Days
  }
]);

export const getJoinSessionModeItems = (i18N: I18NextPipe) => ([
  {
    value: JoinSessionMode.Pin,
    label: i18N.transform('agent-options:connectOptions.joinSessionMode.pin')
  },
  {
    value: JoinSessionMode.Dialog,
    label: i18N.transform('agent-options:connectOptions.joinSessionMode.dialog')
  },
  {
    value: JoinSessionMode.DialogAndPin,
    label: i18N.transform('agent-options:connectOptions.joinSessionMode.dialogAndPin')
  }
]);

export const getProxyModeItems = (i18N: I18NextPipe) => ([
  {
    value: ProxyMode.None,
    label: i18N.transform('agent-options:connectOptions.proxyMode.none')
  },
  {
    value: ProxyMode.AutoDetect,
    label: i18N.transform('agent-options:connectOptions.proxyMode.autoDetect')
  },
  {
    value: ProxyMode.Manual,
    label: i18N.transform('agent-options:connectOptions.proxyMode.manual')
  }
]);

export const getLoggingLevelItems = (i18N: I18NextPipe) => ([
  {
    value: LoggingLevel.NoLog,
    label: i18N.transform('agent-options:connectOptions.loggingLevel.noLog')
  },
  {
    value: LoggingLevel.Warning,
    label: i18N.transform('agent-options:connectOptions.loggingLevel.warning')
  },
  {
    value: LoggingLevel.Info,
    label: i18N.transform('agent-options:connectOptions.loggingLevel.info')
  },
  {
    value: LoggingLevel.Debug,
    label: i18N.transform('agent-options:connectOptions.loggingLevel.debug')
  }
]);
