import { Injectable } from '@angular/core';
import { RoutingPath } from '@mbs-ui/app/app-routing-path.enum';
import { SidepanelRouteType } from '@models/backup/sidepanel-route-type';
import { BackupSidePanelTab } from '@models/backup/sidepanel-tab';
import Computer, {
  AgentType,
  ComputersHealthFilterType,
  ComputersMode,
  ComputersResponse,
  GetComputersParams,
  PlanSettingsReportParams,
  UpdateAgentSettings
} from '@models/Computer';
import { ComputerAvailableVersions } from '@models/ComputersModals';
import { ConnectionType } from '@models/ConnectionType';
import { AvailableEdition, BackupAgentSettings } from '@models/edit-account-modal-models';
import { DestinationForEditAccount } from '@models/editAccountModalModels';
import { getNeededBuildType, InstallationAgents } from '@models/InstallAgent';
import { getPagingParams, getSortParams, SortParams } from '@models/Paging';
import { InstallBuildsType } from '@models/rm/remote-command-model';
import { Store } from '@ngrx/store';
import { ComputersAbstractService } from '@services/computers.service';
import { RmCommandsAbstractWrapper } from '@services/rm-commands.wrapper';
import { ComputersStoreActions, ComputersStoreSelectors } from '@store/computers';
import { ComputersStatisticStoreActions, ComputersStatisticStoreSelectors } from '@store/computersStatistic';
import { versionCompare } from '@utils/version-compare';
import { ComputersFiltersAbstractWrapper } from '@wrappers/computer.filters.wrapper';
import { I18NextPipe } from 'angular-i18next';
import { SmartSearchModel } from 'mbs-ui-kit/smart-search/models';
import { ToastService } from 'mbs-ui-kit/toast/toast.service';
import { combineLatest, filter, interval, map, Observable, of, scan, switchMap, take, tap } from 'rxjs';

const pollingDelay = 2000;
const pollingLimit = 6; // 0 - unlimited

@Injectable({
  providedIn: 'root'
})
export class ComputersFacade {
  computerAll$ = this.store.select(ComputersStoreSelectors.selectAll);
  computerEntities$ = this.store.select(ComputersStoreSelectors.selectEntities);
  currentComputer$ = this.store.select(ComputersStoreSelectors.selectSelectedComputer);
  computerLoading$ = this.store.select(ComputersStoreSelectors.selectLoading);

  // statistic
  statisticOneComputerExist$ = this.store.select(ComputersStatisticStoreSelectors.selectOneComputerExist);
  statisticOneComputerLoading$ = this.store.select(ComputersStatisticStoreSelectors.selectOneComputerLoading);
  statisticOneComputerLoaded$ = this.store.select(ComputersStatisticStoreSelectors.selectOneComputerLoaded);

  // applications
  applicationsCountInitiated$ = this.store.select(ComputersStoreSelectors.selectApplicationsCountInitiated);
  applicationsCount$ = this.store.select(ComputersStoreSelectors.selectApplicationsCount);
  applicationsCountAfterInit$ = this.applicationsCountInitiated$.pipe(
    filter((initiated) => initiated),
    switchMap(() => this.applicationsCount$)
  );

  // paging
  mode$ = this.store.select(ComputersStoreSelectors.selectMode);
  modeInitiated$ = this.store.select(ComputersStoreSelectors.selectModeInitiated);
  currentPageNumber$ = this.store.select(ComputersStoreSelectors.selectCurrentPageNumber);
  currentPerPageSetting$ = this.store.select(ComputersStoreSelectors.selectPerPage);
  currentPageIds$ = this.store.select(ComputersStoreSelectors.selectCurrentPage);
  currentPageData$ = combineLatest([this.currentPageIds$, this.computerEntities$]).pipe(
    map(([ids, entities]) => ids.map((id) => entities[id]))
  );
  filters$ = this.store.select(ComputersStoreSelectors.selectAllFilters);
  filterSearchModel$ = this.store.select(ComputersStoreSelectors.selectFilterSearchModel);
  filterCompany$ = this.store.select(ComputersStoreSelectors.selectFilterCompany);
  filterHealthType$ = this.store.select(ComputersStoreSelectors.selectFilterHealthType);
  filterHealth$ = this.store.select(ComputersStoreSelectors.selectFilterHealth);
  filterHidden$ = this.store.select(ComputersStoreSelectors.selectFilterHidden);
  filterInitiated$ = this.store.select(ComputersStoreSelectors.selectFilterInitiated);
  sortSettings$ = this.store.select(ComputersStoreSelectors.selectSortSettings);
  filterSortSettings$ = combineLatest([this.filters$, this.sortSettings$]);
  hasFilters$ = this.store.select(ComputersStoreSelectors.selectHasFilters);
  total$ = this.store.select(ComputersStoreSelectors.selectTotalItems);
  pagingLoading$ = this.store.select(ComputersStoreSelectors.selectPagingLoading);
  pagingLoaded$ = this.store.select(ComputersStoreSelectors.selectPagingLoaded);
  selectPreQueryPagingParams$ = this.store.select(ComputersStoreSelectors.selectPreQueryPagingParams);
  currentPageRequestParams$ = (skipSorting = false, skipPaging = false) =>
    this.store
      .select(ComputersStoreSelectors.selectRequestParams)
      .pipe(
        switchMap((params) =>
          this.filtersWrapper.getComputersHttpParams(
            params.filters,
            skipSorting ? undefined : getSortParams(params.sorting),
            skipPaging ? undefined : getPagingParams(params.paging)
          )
        )
      );

  // data
  getDataLoading$ = this.store.select(ComputersStoreSelectors.selectDataLoading);
  getDataLoaded$ = this.store.select(ComputersStoreSelectors.selectDataLoaded);
  getDataTotal$ = this.store.select(ComputersStoreSelectors.selectDataTotal);
  getData$ = (withLoad = false) => {
    withLoad && this.loadData();

    return this.store.select(ComputersStoreSelectors.selectData);
  };
  getDataCount$ = this.store.select(ComputersStoreSelectors.selectDataCount);
  getAllDataLoaded$ = this.store.select(ComputersStoreSelectors.selectAllDataLoaded);

  // combined
  getDataEntityLoading$ = this.store.select(ComputersStoreSelectors.selectDataEntityLoading);
  getPagingEntityLoading$ = this.store.select(ComputersStoreSelectors.selectPagingEntityLoading);
  getDataWhenLoaded$ = combineLatest([this.getData$(), this.getDataLoaded$]).pipe(
    filter(([, loaded]) => loaded),
    map(([data]) => data)
  );

  constructor(
    private store: Store,
    private computersService: ComputersAbstractService,
    private rmCommands: RmCommandsAbstractWrapper,
    private toast: ToastService,
    private i18nPipe: I18NextPipe,
    private filtersWrapper: ComputersFiltersAbstractWrapper
  ) {}

  getByHid(hid: string, directly = false): Observable<Computer> {
    return directly
      ? this.getComputerByHid(hid).pipe(
          tap((computer) => this.store.dispatch(ComputersStoreActions.setComputers({ computers: [computer] })))
        )
      : this.store.select(ComputersStoreSelectors.selectByHid(hid));
  }

  /**
   * Load computer data by hid in Computers store
   * @param {{hid: string, quiet: boolean, force: boolean}} params Parameters
   * @param {string} params.hid Computer Hid
   * @param {boolean} params.quiet Quiet loading of computer. Without  changes in 'loading' store field (without spinner)
   * @param {boolean} params.force Force loading data from the backend (even if the data is already in store)
   * @return {void}
   */
  loadComputerByHid(params: { hid: string; quiet?: boolean; force?: boolean }): void {
    this.store.dispatch(ComputersStoreActions.loadComputerByHid(params));
  }

  setSelected(selected: string): void {
    this.store.dispatch(ComputersStoreActions.setSelected({ selected }));
  }

  checkOneComputerExist(force = false): void {
    this.store.dispatch(ComputersStatisticStoreActions.checkOneComputerExist({ force }));
  }

  // paging
  setMode(mode: ComputersMode): void {
    this.store.dispatch(ComputersStoreActions.setMode({ mode }));
  }

  loadPage(params: { pageNumber?: number; perPage?: number; isFullRefresh?: boolean }): void {
    this.store.dispatch(
      ComputersStoreActions.loadPage({ pageNumber: params.pageNumber, perPage: params.perPage, isFullRefresh: params.isFullRefresh })
    );
  }

  clearPages(): void {
    this.store.dispatch(ComputersStoreActions.clearPages());
  }

  setFilterSearch(searchModel: SmartSearchModel): void {
    this.store.dispatch(ComputersStoreActions.setFilterSearch({ searchModel }));
  }

  setFilterCompany(company: string): void {
    this.store.dispatch(ComputersStoreActions.setFilterCompany({ company }));
  }

  setFilterHidden(hidden: boolean): void {
    this.store.dispatch(ComputersStoreActions.setFilterHidden({ hidden }));
  }

  setFilterHealth(healthType: ComputersHealthFilterType): void {
    this.store.dispatch(ComputersStoreActions.setFilterHealth({ healthType }));
  }

  setAllFilters(params: {
    searchModel?: SmartSearchModel;
    hidden?: boolean;
    healthType?: ComputersHealthFilterType;
    company?: string;
    apply?: boolean;
  }): void {
    this.store.dispatch(ComputersStoreActions.setAllFilters(params));
  }

  setSortSettings(sort: SortParams): void {
    this.store.dispatch(ComputersStoreActions.setSortSettings({ sort }));
  }

  setPageNumber(pageNumber: number): void {
    this.store.dispatch(ComputersStoreActions.setPageNumber({ pageNumber }));
  }

  setPerPageSetting(perPage: number): void {
    this.store.dispatch(ComputersStoreActions.setPerPageSetting({ perPage }));
  }

  refresh(): void {
    this.loadPage({ isFullRefresh: true });
  }

  // data
  public loadData(isFullRefresh = false, allowLoadMore = false, limit: number = null): void {
    this.store.dispatch(ComputersStoreActions.loadData({ isFullRefresh, allowLoadMore, limit }));
  }
  public refreshData(): void {
    this.loadData(true);
  }
  public clearData(): void {
    this.store.dispatch(ComputersStoreActions.clearData());
  }

  // Service commands
  getComputers(params?: GetComputersParams): Observable<ComputersResponse> {
    return this.computersService.getComputers(params);
  }

  getComputerByHid(hid: string): Observable<Computer> {
    return this.computersService.getComputerByHid(hid);
  }

  setHidden(hid: string, hidden = true): Observable<any> {
    return this.computersService.setComputerHidden(hid, hidden);
  }

  setComputerDisplayName(hid: string, displayName: string): Observable<any> {
    return this.computersService.setComputerDisplayName(hid, displayName);
  }

  update(computer: Computer): Observable<any> {
    return this.computersService.updateComputer(computer);
  }

  deleteComputer(hid: string): Observable<any> {
    return this.delete(hid);
  }

  delete(hid: string): Observable<any> {
    return this.computersService.deleteComputer(hid);
  }

  getConnectionUrl(computer: Computer, connectionType: ConnectionType): Observable<any> {
    return this.computersService.getConnectionUrl(computer, { connectionType });
  }

  isConnectionAllowed(hid: string, connectionType: ConnectionType, withoutBackup = false): Observable<{ result: boolean }> {
    return this.computersService.isConnectionAllowed(hid, connectionType, withoutBackup);
  }

  isPossibleToInstallAgent(computer: Computer, agent: AgentType): Observable<AgentType> {
    return of(!!getNeededBuildType(computer, agent)).pipe(
      map((possibleToInstall) => {
        if (!possibleToInstall) return null;
        return computer.apps.find((app) => app.online && InstallationAgents.includes(app.applicationId))?.applicationId ?? null;
      })
    );
  }

  installAgent(computer: Computer, agentType: AgentType): Observable<any> {
    const showUnableInstallToast = () => {
      const title = this.i18nPipe.transform('computers.module:modals.unableInstallCaption');
      const agent = this.i18nPipe.transform(`app:products.${agentType}`);
      const computerName = Computer.getComputerName(computer);
      const message = this.i18nPipe.transform('computers.module:modals.unableInstall', { agent, computerName });

      this.toast.error(message, title);
    };

    return this.isPossibleToInstallAgent(computer, agentType).pipe(
      tap((installedAgent) => !installedAgent && showUnableInstallToast()),
      filter(Boolean),
      switchMap((installedAgent) => this.rmCommands.installPublicAgentByComputer({ computer, appId: agentType, installedAgent }))
    );
  }

  installAgentToComputers(computers: Computer[], agentType: AgentType): Observable<any> {
    return this.rmCommands.installPublicAgentByComputers({ computers: computers.filter((computer) => computer.online), appId: agentType });
  }

  uninstallAgents(computer: Computer, appIds: string[] | AgentType[]): Observable<any> {
    return this.rmCommands.uninstallAgentsByComputer({ computer, appIds });
  }

  updateAgent(computerHid: string, agentType: AgentType, versionType: InstallBuildsType): Observable<any> {
    return this.rmCommands.updateAgent({ computerHid, agentType, versionType });
  }

  bulkInstallAgent(filter: GetComputersParams, appId: AgentType): Observable<any> {
    return this.rmCommands.bulkInstallPublicAgent(filter, appId);
  }

  regenerateClientHID(computerHid: string, appId: AgentType): Observable<unknown> {
    return this.rmCommands.regenerateClientHID({ computerHid, appId });
  }

  getAvailableVersions(hid: string): Observable<ComputerAvailableVersions> {
    return this.computersService.availableVersions(hid);
  }

  newAgentVersionAvailable(hid: string, agentType: AgentType): Observable<InstallBuildsType | null> {
    return this.getAvailableVersions(hid).pipe(
      map((computer: ComputerAvailableVersions) => {
        let result: InstallBuildsType | null = null;
        const agent = (computer?.applications || []).find((agent) => agent.applicationId.toLowerCase() === agentType);
        if (agent?.builds && agent?.currentVersion) {
          result =
            Object.values(InstallBuildsType).find((buildType) => {
              const newVersion = agent.builds[buildType.toLowerCase()]?.version;
              return newVersion && versionCompare(newVersion, agent.currentVersion) > 0;
            }) || null;
        }
        return result;
      })
    );
  }

  planSettingsReport(params: PlanSettingsReportParams): Observable<boolean> {
    return this.computersService.planSettingsReport(params).pipe(map(() => true));
  }

  export(params?: GetComputersParams): Observable<any> {
    return this.computersService.export(params);
  }

  switchWebSettings(hid: string, state: boolean): Observable<{ ok: boolean }> {
    return this.rmCommands.switchWebSettings(hid, state);
  }

  loadApplicationsCount(force?: boolean): void {
    this.store.dispatch(ComputersStoreActions.loadApplicationsCount({ force }));
  }

  suggestUserName(hid: string, companyId: string, forceNew?: boolean): Observable<{ userId: string; userName: string }> {
    return this.computersService.suggestUserName(hid, companyId, forceNew);
  }

  groupAuthorizeComputers(hids: string[], userId?: string, companyId?: string): Observable<null> {
    return this.computersService.authorizeComputers({ hids, userId, companyId });
  }

  bulkAuthorizeComputers(filter: GetComputersParams, userId?: string, companyId?: string): Observable<null> {
    return this.computersService.authorizeComputers({ filter, userId, companyId });
  }

  authorizeComputer(hid: string, userId?: string, companyId?: string): Observable<null> {
    return this.computersService.authorizeComputer(hid, userId, companyId);
  }

  getTotalUnsupportedVersionComputers(): Observable<number> {
    return this.computersService.getTotalUnsupportedVersionComputers().pipe(map(({ total }) => total));
  }

  pollingComputersAndOpenSidePanel(sidePanel: SidepanelRouteType.Backup | SidepanelRouteType.RMM, hid?: string, limit?: number): void {
    const agent: AgentType = sidePanel === SidepanelRouteType.Backup ? AgentType.Backup : AgentType.RMM;
    const getData = hid
      ? this.getComputerByHid(hid)
      : this.getComputers({ limit: 1, offset: 0, appIds: [agent] }).pipe(
          map((res) => (res && res.total && res.data.length ? res.data[0] : null))
        );
    // polling
    interval(pollingDelay)
      .pipe(
        switchMap(() => getData),
        scan((acc, computer) => ({ acc: acc.acc + 1, computer }), { acc: 0, computer: undefined }),
        map(({ acc, computer }) => ({ computer, isLimitExceeded: acc >= (limit ?? pollingLimit) })),
        filter(({ computer, isLimitExceeded }) => (computer?.online && Computer.isAgentOnline(computer, agent)) || isLimitExceeded),
        take(1)
      )
      .subscribe(({ computer }) => {
        let sidePanelParams = '';
        if (computer?.hid) {
          const activeTab = SidepanelRouteType.Backup ? `&activeTab=${BackupSidePanelTab.backupPlans}` : '';
          sidePanelParams = `/${computer.hid}?sidepanel=${sidePanel}${activeTab}`;
        }
        window.location.href = `${RoutingPath.ApComputers}${sidePanelParams}`;
      });
  }

  getAvailableEditionsById(hid: string, userId: string): Observable<AvailableEdition[]> {
    return this.computersService.getAvailableEditionsById(hid, userId);
  }

  getDestinationsByComputerHidAndUserId(hid: string, userId: string): Observable<DestinationForEditAccount[]> {
    return this.computersService.getDestinationsByComputerHidAndUserId(hid, userId);
  }

  getDestinationsPrefixListByUserId(hid: string, destinationId: string, userId: string): Observable<string[]> {
    return this.computersService.getDestinationsPrefixListByUserId(hid, destinationId, userId);
  }

  updateBackupAgentSettings(hid: string, settings: BackupAgentSettings): Observable<UpdateAgentSettings> {
    return this.computersService.updateBackupAgentSettings(hid, settings);
  }

  updateUser(hid: string, userId: string): Observable<any> {
    return this.computersService.updateUser(hid, userId);
  }

  addTags(tagIds: number[], hids?: string[], filter?: GetComputersParams): Observable<null> {
    return this.computersService.addTags(tagIds, hids, filter);
  }

  editTags(hids: string[], tagIds: number[]): Observable<null> {
    return this.computersService.editTags(hids, tagIds);
  }

  invalidate(): void {
    combineLatest([this.getDataLoaded$, this.pagingLoaded$])
      .pipe(take(1))
      .subscribe(([dataLoaded, pagingLoaded]) => {
        dataLoaded && this.loadData(true);
        pagingLoaded && this.loadPage({ isFullRefresh: true });
      });
  }
}
