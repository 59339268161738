<section [formGroup]="stepForm">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{ 'wizards:sql:sqlServerInstanceStepTitle' | i18next: { format: 'title' } }}
    </h4>
  </header>

  <div *ngIf="databaseServerInstances?.length"
       [attr.data-test-element]="elementsSelector.name.sqlInstanceBlock">
    <div class="row">
      <div class="col-6">
        <mbs-form-group>
          <mbs-select formControlName="sqlServerInstanceName"
                      (change)="resetValidStateIfChangedData()"
                      [label]="'wizards:sql:sqlServerNameLabel' | i18next"
                      [appendTo]="'.modal-content'"
                      [bindValue]="'Name'"
                      [bindLabel]="'Name'"
                      [items]="databaseServerInstances"
                      [id]="'db-instances-select'"
                      [clearable]="false"
                      [attr.data-test-element]="elementsSelector.name.sqlInstanceControl"
                      [searchable]="false"></mbs-select>
        </mbs-form-group>

        <mbs-form-group>
          <mbs-select formControlName="authenticationType"
                      (change)="resetValidStateIfChangedData()"
                      [label]="'wizards:sql:sqlAuthTypeLabel' | i18next"
                      [appendTo]="'.modal-content'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [items]="SQLServerAuthTypes"
                      [id]="'auth-type-select'"
                      [clearable]="false"
                      [attr.data-test-element]="elementsSelector.name.authTypeControl"
                      [searchable]="false"></mbs-select>
        </mbs-form-group>

        <mbs-form-group>
          <mbs-input formControlName="userName"
                     name="userName"
                     (change)="resetValidStateIfChangedData()"
                     [attr.data-test-element]="elementsSelector.name.sqlUserNameInput"
                     [id]="'user-name-input'"
                     [label]="authTypeIsWindows ? ('wizards:sql:sqlUserNameLabel' | i18next) : 'wizards:sql:sqlLoginLabel' | i18next"
                     [validationWhitespace]="true"></mbs-input>
        </mbs-form-group>

        <mbs-form-group>
          <mbs-input #sqlPassField
                     formControlName="password"
                     name="password"
                     (change)="resetValidStateIfChangedData()"
                     [attr.data-test-element]="elementsSelector.name.sqlPasswordInput"
                     [label]="'wizards:sql:sqlPasswordLabel' | i18next"
                     [id]="'sql-password-input'"
                     [autocomplete]="'off'"
                     [type]="passwordInputType"
                     (blur)="passwordBlurHandler($event)"
                     readonly
                     (focus)="passwordFocusHandler($event); sqlPassField.readonly = false"
                     (buttonClick)="changePasswordType()"
                     [appendButtons]="[
                     {
                       icon: passwordInputType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash',
                       id: 'changeTypeSQLPassword',
                       disabled: stepForm.get('password')?.value === initialPass || stepForm?.get('password')?.disabled,
                       type: 'secondary'
                     }
                   ]">
          </mbs-input>
        </mbs-form-group>
      </div>
    </div>

    <mbs-form-group class="mt-4">
      <mbs-checkbox formControlName="checkIsSysAdmin"
                    (change)="resetValidStateIfChangedData()"
                    [attr.data-test-element]="elementsSelector.name.checkIsSysAdmin"
                    [label]="'wizards:sql:sqlSysAdminLabel' | i18next"
                    [id]="'sql-check-sys-admin-checkbox'">
        <ng-container appendLabel>
        <span class="fa fa-info-circle ml-2 cursor-help text-info"
              container="body"
              placement="left-bottom auto"
              tooltipClass="tooltip-lg text-left"
              [ngbTooltip]="'wizards:sql:sqlSysAdminDescription' | i18next"></span>
        </ng-container>
      </mbs-checkbox>
    </mbs-form-group>

    <mbs-form-group>
      <mbs-checkbox formControlName="useSecureConnection"
                    [label]="'wizards:sql:sqlSecureConnectionLabel' | i18next"
                    [id]="'sql-use-secure-connection-checkbox'">
        <ng-container appendLabel>
        <span class="fa fa-info-circle ml-2 cursor-help text-info"
              container="body"
              tooltipClass="tooltip-lg text-left"
              [ngbTooltip]="'wizards:sql:sqlSecureConnectionDescription' | i18next"></span>
        </ng-container>
      </mbs-checkbox>
    </mbs-form-group>
  </div>

  <mbs-alert *ngIf="!databaseServerInstances?.length"
             [type]="mbsPopupType.warning"
             [icon]="true"
             class="mb-0">
    {{'wizards:sql:sqlNoServerInstancesAlert' | i18next}}
  </mbs-alert>
</section>

<ng-template #notSysAdminModal>
  <mbs-alert [type]="mbsPopupType.warning"
             [icon]="true"
             class="mb-0">
    <span *ngIf="authTypeIsWindows">{{'wizards:sql:sqlNotSysAdminModalText' | i18next}}</span>
    <span *ngIf="!authTypeIsWindows">
      {{ 'wizards:sql:sqlNotSysAdminModalNotWindowsText' | i18next : { userName: value.userName } }}
      <a *ngIf="canReadHelpMarketing"
         href="https://kb.msp360.com/managed-backup-service/sql-server-backup-permissions"
         target="_blank">
         {{'wizards:learn_more_link' | i18next}}
      </a>
    </span>
  </mbs-alert>
</ng-template>
