<form [formGroup]="form">
  <ng-container >
    <div class="mbs-form-group_content" formGroupName="managedSystem">
      <mbs-switcher [label]="'agent-options:allowRemoteAccess' | i18next"
                    formControlName="allowRemoteConnection"></mbs-switcher>
    </div>
  </ng-container>

  <ng-container [ngTemplateOutlet]="sectionTitle"
                [ngTemplateOutletContext]="{
                  title: 'agent-options:connectOptions.access' | i18next,
                  withoutSeparator: true
                }">
  </ng-container>

  <ng-container formGroupName="managedSystem">
    <div class="mbs-form-group_content">
      <mbs-checkbox [label]="'agent-options:connectOptions.securityAllowRaUnattendedAccessLabel' | i18next"
                    formControlName="allowRAUnattendedAccess">
        <ng-container appendLabel>
            <span class="ico ico-InfoCircle tooltip-btn text-info"
                  [ngbTooltip]="'agent-options:connectOptions.securityAllowRaUnattendedAccessInfoText' | i18next"
                  container="body">
            </span>
        </ng-container>
      </mbs-checkbox>
    </div>
  </ng-container>

  <ng-container formGroupName="security">
    <ng-container formGroupName="customPassword">
      <mbs-checkbox class="mt-2"
                    [label]="'agent-options:connectOptions.securityUnattendedAccessLabel' | i18next"
                    formControlName="enabled"></mbs-checkbox>
      <div class="row mt-2"
           *ngIf="isUnattendedAccessPasswordVisible()">
        <div class="col-9">
          <mbs-input [autocomplete]="'off'"
                     [name]="'unattendedAccessPassword'"
                     [type]="unattendedPasswordInputType"
                     [placeholder]="'agent-options:connectOptions.passwordInputPlaceholder' | i18next"
                     (buttonClick)="unattendedPasswordInputType = getPasswordInputType(unattendedPasswordInputType)"
                     [appendButtons]="[
                              {
                                icon: unattendedPasswordInputType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash',
                                id: 'changePasswordType',
                                type: 'secondary'
                              }
                           ]"
                     formControlName="password"></mbs-input>
        </div>
      </div>
    </ng-container>

    <div class="row mt-3">
      <div class="col">
        <mbs-select [items]="joinSessionModeSelectItems"
                    [boldLabel]="true"
                    [bindValue]="'value'"
                    [bindLabel]="'label'"
                    [label]="'agent-options:connectOptions.securityJoinSessionModeSelectLabel' | i18next"
                    [searchable]="false"
                    [clearable]="false"
                    formControlName="joinSessionMode"></mbs-select>
      </div>
      <div class="col-sm-3">
        <mbs-select *ngIf="isPinLengthVisible()"
                    [boldLabel]="true"
                    [items]="pinLengthSelectItems"
                    [label]="'agent-options:connectOptions.securityPinLengthSelectLabel' | i18next"
                    [clearable]="false"
                    [searchable]="false"
                    formControlName="pinLength"></mbs-select>
      </div>
    </div>
  </ng-container>

  <ng-container [ngTemplateOutlet]="sectionTitle"
                [ngTemplateOutletContext]="{
                  title: 'agent-options:connectOptions.sessionOptions' | i18next
                }">
  </ng-container>

  <h5 class="my-2 font-weight-bold"
      [class.-disabled]="form.disabled">{{'agent-options:connectOptions.unattendedAccess' | i18next }} </h5>

  <ng-container formGroupName="inputControl">
    <mbs-checkbox [label]="'agent-options:connectOptions.blackScreen' | i18next"
                  formControlName="blackScreen"></mbs-checkbox>

    <mbs-checkbox [label]="'agent-options:connectOptions.lockInput' | i18next"
                  formControlName="lockInput"></mbs-checkbox>
  </ng-container>

  <ng-container formGroupName="unattendedAccessDisconnect">
    <div class="form-row my-3">
      <div class="col-12">
        <mbs-select [items]="unattendedAccessDisconnectOptions"
                    [label]="'agent-options:connectOptions.unattendedAccessDisconnectMode.label' | i18next"
                    [boldLabel]="true"
                    [bindValue]="'value'"
                    [bindLabel]="'label'"
                    [clearable]="false"
                    [searchable]="false"
                    formControlName="mode"></mbs-select>
      </div>
    </div>
  </ng-container>

  <h5 class="my-2 font-weight-bold"
      [class.-disabled]="form.disabled">{{'agent-options:connectOptions.inactivity' | i18next }}</h5>

  <ng-container formGroupName="disconnectIfInactive">
    <mbs-checkbox [label]="'agent-options:connectOptions.disconnectIfInactive.enabled' | i18next"
                  formControlName="enabled"></mbs-checkbox>

    <div class="form-row mt-2 ml-4">
      <div class="col-4">
        <mbs-number [min]="1"
                    formControlName="interval"></mbs-number>
      </div>

      <div class="col-4">
        <mbs-select [items]="timeUnits"
                    [bindValue]="'value'"
                    [bindLabel]="'label'"
                    [clearable]="false"
                    [searchable]="false"
                    formControlName="intervalUnits"></mbs-select>
      </div>
    </div>
  </ng-container>

  <ng-container [ngTemplateOutlet]="sectionTitle"
                [ngTemplateOutletContext]="{title: 'agent-options:connectOptions.endUserOptions' | i18next}">
  </ng-container>

  <ng-container formGroupName="managedSystem">
    <mbs-checkbox [label]="'agent-options:connectOptions.securityAllowGuiLabel' | i18next"
                  formControlName="allowGui"></mbs-checkbox>
    <mbs-checkbox class="mt-2"
                  [label]="'agent-options:connectOptions.securityAllowOutgoingConnectionsLabel' | i18next"
                  formControlName="allowOutgoingConnections"></mbs-checkbox>
  </ng-container>

  <ng-container formGroupName="configPassword">
    <mbs-checkbox [label]="'agent-options:connectOptions.configPasswordLabel' | i18next"
                  formControlName="enabled">
      <ng-container appendLabel>
              <span class="ico ico-InfoCircle tooltip-btn text-info"
                    [ngbTooltip]="'agent-options:connectOptions.configPasswordInfo' | i18next"
                    container="body">
              </span>
      </ng-container>
    </mbs-checkbox>
    <div class="row mt-2"
         *ngIf="isConfigPasswordVisible()">
      <div class="col-9">
        <mbs-input [autocomplete]="'off'"
                   [name]="'configPassword'"
                   [placeholder]="'agent-options:connectOptions.passwordInputPlaceholder' | i18next"
                   [type]="configPasswordInputType"
                   (buttonClick)="configPasswordInputType = getPasswordInputType(configPasswordInputType)"
                   [appendButtons]="[
                            {
                              icon: configPasswordInputType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash',
                              id: 'changePasswordType',
                              type: 'secondary'
                            }
                         ]"
                   formControlName="password">
        </mbs-input>
      </div>
    </div>
  </ng-container>

  <ng-container [ngTemplateOutlet]="sectionTitle"
                [ngTemplateOutletContext]="{title: 'agent-options:connectOptions.updatesAndLogging' | i18next}">
  </ng-container>

  <div class="mbs-form-group"
       formGroupName="update">
    <mbs-switcher [label]="'agent-options:connectOptions.appAutoUpdateCheckbox' | i18next"
                  formControlName="auto">
      <ng-container appendLabel>
          <div class="d-flex align-items-center">
            <span class="ico ico-InfoCircle tooltip-btn text-info"
                  [ngbTooltip]="'agent-options:connectOptions.appAutoUpdateInfoText' | i18next"
                  container="body"></span>
          </div>
      </ng-container>
    </mbs-switcher>
  </div>

  <div class="mbs-form-group"
       formGroupName="logger">
    <mbs-select [items]="loggingLevelSelectItems"
                [boldLabel]="true"
                [bindLabel]="'label'"
                [bindValue]="'value'"
                [clearable]="false"
                [searchable]="false"
                [label]="'agent-options:connectOptions.loggingLevelSelectLabel' | i18next"
                formControlName="level"></mbs-select>
  </div>

  <ng-container [ngTemplateOutlet]="sectionTitle"
                [ngTemplateOutletContext]="{title: 'agent-options:connectOptions.proxySectionHeader' | i18next}">
  </ng-container>

  <div class="mbs-form-group"
       formGroupName="proxy">
    <mbs-select [items]="proxyModeSelectItems"
                [boldLabel]="true"
                [bindValue]="'value'"
                [bindLabel]="'label'"
                [clearable]="false"
                [searchable]="false"
                [label]="'agent-options:connectOptions.proxyModeSelectLabel' | i18next"
                formControlName="mode"></mbs-select>
    <div class="row mt-2"
         *ngIf="isProxyServerHostVisible()">
      <div class="col">
        <mbs-input [label]="'agent-options:connectOptions.proxyHostLabel' | i18next"
                   [autocomplete]="'off'"
                   [name]="'proxyHost'"
                   [placeholder]="'agent-options:connectOptions.proxyHostDescription' | i18next"
                   [type]="'text'"
                   formControlName="host"></mbs-input>
      </div>
      <div class="col-sm-3">
        <mbs-number [label]="'agent-options:connectOptions.proxyPortLabel' | i18next"
                    min="1"
                    max="65535"
                    formControlName="port"></mbs-number>
      </div>
    </div>
    <mbs-form-group class="mt-3"
                    *ngIf="isUseBasicAuthSectionVisible()">

      <mbs-checkbox [label]="'agent-options:connectOptions.proxyUseBasicAuthLabel' | i18next"
                    formControlName="useBasicAuth"></mbs-checkbox>

      <div class="row mt-3"
           *ngIf="isUseBasicAuthUserPasswordSectionVisible()">
        <div class="col-9">
          <mbs-input [label]="'agent-options:connectOptions.proxyUseBasicAuthUserNameLabel' | i18next"
                     [boldLabel]="true"
                     [showRequiredMark]="false"
                     [autocomplete]="'off'"
                     [placeholder]="'agent-options:connectOptions.userNameInputPlaceholder' | i18next"
                     [name]="'proxyBasicAuthUserName'"
                     type="text"
                     formControlName="basicAuthUserName"></mbs-input>
        </div>

        <div class="col-9 mt-2">
          <mbs-input [label]="'agent-options:connectOptions.proxyUseBasicAuthPasswordLabel' | i18next"
                     [boldLabel]="true"
                     [showRequiredMark]="false"
                     [autocomplete]="'off'"
                     [name]="'proxyBasicAuthPassword'"
                     [placeholder]="'agent-options:connectOptions.passwordInputPlaceholder' | i18next"
                     [type]="basicAuthPasswordInputType"
                     (buttonClick)="basicAuthPasswordInputType = getPasswordInputType(basicAuthPasswordInputType)"
                     [appendButtons]="[
                                {
                                  icon: basicAuthPasswordInputType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash',
                                  id: 'changePasswordType',
                                  type: 'secondary'
                                }
                             ]"
                     formControlName="basicAuthPassword"></mbs-input>
        </div>
      </div>
    </mbs-form-group>
  </div>
</form>

<ng-template #sectionTitle let-title="title" let-withoutSeparator="withoutSeparator">
  <ng-container *ngIf="mode === viewModeEnum.Page">
    <h2 class="my-3 font-weight-bold" [class.-disabled]="form.disabled">{{ title }}</h2>
  </ng-container>

  <ng-container *ngIf="mode === viewModeEnum.Panel">
    <hr *ngIf="!withoutSeparator" class="mt-4 mb-0" />
    <h4 class="my-3 font-weight-bold" [class.-disabled]="form.disabled">{{ title }}</h4>
  </ng-container>
</ng-template>
