import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { mediumDateWithTimeMoment } from '@utils/date';
import { DateTimeFormatterHelper } from '@utils/helpers/dateTimeFormatterHelper';
import { isNil } from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, iif, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-date-time-format',
  templateUrl: './date-time-format.component.html'
})
export class DateTimeFormatComponent implements OnInit, OnChanges {
  @Input() iconClass = 'ico ico-InfoCircle';
  @Input() container: string;
  @Input() dateTimeFormat = mediumDateWithTimeMoment;
  @Input() hid: string;
  @Input() placement = 'top-right auto';
  @Input() needWaitLoadTimezone = false;
  @Input() showIcon = false;
  @Input() isAgentTime: boolean;
  @Input() agentUtcOffsetValue: number;
  @Input() hideAgentTime = false;

  @Input()
  set time(value: string) {
    this.timeInput$.next(value);
  }
  get time() {
    return this.timeInput$.getValue();
  }

  @Input()
  set defaultText(value: string) {
    this.defaultTime = value;
  }
  get defaultText() {
    return this.defaultTime;
  }

  @Output() iconHover = new EventEmitter<boolean>();

  get isNullTimezone(): boolean {
    return isNil(this.agentUtcOffsetValue);
  }

  public time$: Observable<any>;
  public defaultTime = '-';
  protected timeInput$ = new BehaviorSubject<string>(new Date().toISOString());

  constructor(public auth: AuthService) {}

  ngOnInit(): void {
    this.setTime();
  }

  setTime(): void {
    const agentDiff$ = iif(() => Boolean(this.isAgentTime), of(this.getAgentDiff(this.time)), this.getAgentUtcOffsetValue());
    const providerDiff$ = this.auth.currentUser.pipe(untilDestroyed(this), map((user) => user?.ProviderInfo?.TimeZoneOffset));
    this.time$ = DateTimeFormatterHelper.setTime(
      this.timeInput$,
      agentDiff$,
      providerDiff$,
      this.defaultText,
      this.isAgentTime,
      this.dateTimeFormat
    );
  }

  getAgentUtcOffsetValue(): Observable<number> {
    return of(this.agentUtcOffsetValue ?? 0);
  }

  /**
   * Parse agent's time to get timeZone difference
   * @param  {any} time
   * @return {number}
   */
  protected getAgentDiff = (time: any): number => {
    const regex = /([0-9+])-([0-9]+)T([0-9]+):([0-9]+):([0-9]+)(.*):([0-9]+)/gm;
    const regex2 = /([0-9+])-([0-9]+)T([0-9]+):([0-9]+):([0-9]+).([0-9]?[0-9]?[0-9])(.*):([0-9]+)/gm;

    let result = regex.exec(time);
    if (!result || result?.length < 8) return moment(this.time).utcOffset();

    let hours = Number(result[6]);
    let minutes = Number(result[7]);

    if (hours !== 0 && !hours) {
      result = regex2.exec(time);
      hours = Number(result[7]);
      minutes = Number(result[8]);
    }

    return hours * 60 + (hours > 0 ? minutes : -minutes);
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (this.needWaitLoadTimezone && !isNil(changes?.agentUtcOffsetValue?.currentValue)) {
      this.setTime();
    }
  }

  infoIconHoverHandler(onHover: boolean): void {
    this.iconHover.next(onHover);
  }
}
