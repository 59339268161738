<section [formGroup]="stepForm">
  <header class="mb-4">
    <h4>{{ 'wizards:type_data_title' | i18next: { format: 'title' } }}</h4>
  </header>

  <mbs-form-group>
    <mbs-radio formControlName="restoreAs"
               name="restoreAsRadio"
               [label]="'wizards:physical_disk_label' | i18next"
               (change)="changeRestoreAsHandler($event)"
               [descriptionClasses]="'mt-1'"
               [descriptionOutside]="false"
               [id]="'restore-as-disk-radio-0'"
               [value]="0">
      <ng-template radioDescription>
        <span class="text-sm">{{'wizards:physical_disk_subtitle' | i18next}}</span>
      </ng-template>
    </mbs-radio>
    <mbs-radio formControlName="restoreAs"
               name="restoreAsRadio"
               [label]="'wizards:virtual_disk_label' | i18next"
               (change)="changeRestoreAsHandler($event)"
               [descriptionClasses]="'mt-1'"
               [descriptionOutside]="false"
               [id]="'restore-as-radio-1'"
               [value]="1">
      <ng-template radioDescription>
        <div class="text-sm mb-2">{{'wizards:virtual_disk_subtitle' | i18next}}</div>
        <mbs-select formControlName="selectedFileFormat"
                    class="mt-3"
                    [label]="'wizards:virtual_format_label' | i18next"
                    [clearable]="false"
                    [id]="'file-format-select'"
                    [items]="fileFormats"></mbs-select>
      </ng-template>
    </mbs-radio>
  </mbs-form-group>
</section>
