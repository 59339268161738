import { PlanTypes } from '@models/PlanTypes.enum';
import { RecurType } from '@models/RecurType.enum';
import { RecurringSchedule } from '@models/ScheduleModel';
import { RemoteDeployPlanModel } from '@models/remote-deploy/remote-deploy-plan-model';
import { multiplyNumbersInStringFormat } from '@utils/bigMath';
import { PasswordFromPlan } from '@utils/constants/misc-constants';
import {
  GetDateFromTimeString,
  getFullDateFromDateTimeObject,
  getISODateFromTimeAndDate,
  GetTimeSpanString,
  TimeGroupToTimeSpanString
} from '@utils/date';
import { ScheduleDataHelper } from '@utils/schedule-data-helper';
import { GuidEmpty, IntMaxValue, MaxTicks, TimeSpanMaxValue, TimeSpanZeroValue } from 'mbs-ui-kit/utils/constants';
import * as moment from 'moment';
import { AdvancedFilterStepValue, FilterTypes } from '../models/advanced-filters-models';
import { BaseRetentionPolicy, FromTime, RetentionPolicyStepValue } from '../models/retention-policy-models';
import { ScheduleAdvancedStepValue, ScheduleSettings } from '../models/schedule-advanced-models';
import { ScheduleStepValue, ScheduleType } from '../models/schedule-models';
import { SimpleScheduleStepValue } from '../models/simple-schedule-models';
import { VirtualDiskFileFormat } from '../models/type-data-models';
import { BackupTargetVolumesEnum } from '../models/what-backup-models';

export class SupportMethodsForPlanFromSteps {
  public static getExtentBasedId(extents: any[]): string {
    let extentBasedId = '';
    extentBasedId += extents.length.toString();
    extents.forEach(function (extent) {
      extentBasedId += extent.Length.toString();
      extentBasedId += extent.StartOffset.toString();
    });
    return extentBasedId;
  }

  public static updateNewPlanDiskInfo(newPlan): void {
    if (newPlan.DiskInfo && newPlan.DiskInfo.length > 0) {
      newPlan.DiskInfo.forEach((diskInfo) => {
        if (diskInfo && diskInfo.Volumes) {
          diskInfo.Volumes.forEach((vol) => {
            if (vol && vol.BackupOptions) vol.BackupOptions.ExcludeRules = [];
          });
        }
      });
    }
  }

  public static getDiskImageFileFormat(fileFormat): VirtualDiskFileFormat {
    const newElement = { Name: '', VariantName: '', DisplayName: fileFormat, UseBuilder: false };
    switch (fileFormat) {
      case 'Hyper-V Virtual Disk (VHD-format) fixed':
        newElement.Name = 'VHD';
        newElement.VariantName = 'fixed';
        newElement.UseBuilder = true;
        break;
      case 'Hyper-V Virtual Disk (VHD-format) dynamic':
        newElement.Name = 'VHD';
        newElement.VariantName = 'dynamic';
        newElement.UseBuilder = true;
        break;
      case 'RAW disk image raw':
        newElement.Name = 'RAW';
        newElement.VariantName = 'raw';
        newElement.UseBuilder = true;
        break;
      case 'RAW disk image rawsparse':
        newElement.Name = 'RAW';
        newElement.VariantName = 'rawsparse';
        newElement.UseBuilder = true;
        break;
      case 'RAW disk image tar':
        newElement.Name = 'RAW';
        newElement.VariantName = 'tar';
        newElement.UseBuilder = true;
        break;
      case 'RAW disk image tgz':
        newElement.Name = 'RAW';
        newElement.VariantName = 'tgz';
        newElement.UseBuilder = true;
        break;
      case 'VMware Virtual Disk vmfsfixed':
        newElement.Name = 'VMDK';
        newElement.VariantName = 'vmfsfixed';
        break;
      case 'VMware Virtual Disk vmfsdynamic':
        newElement.Name = 'VMDK';
        newElement.VariantName = 'vmfsdynamic';
        break;
      case 'Hyper-V Virtual Disk (VHDX-format) dynamic':
        newElement.Name = 'VHDX';
        newElement.VariantName = 'dynamic';
        newElement.UseBuilder = true;
        break;
      case 'VirtualBox Virtual Disk fixed':
        newElement.Name = 'VDI';
        newElement.VariantName = 'fixed';
        break;
      case 'VirtualBox Virtual Disk dynamic':
        newElement.Name = 'VDI';
        newElement.VariantName = 'dynamic';
        break;
    }
    return newElement;
  }

  public static getFiltersArray(step: AdvancedFilterStepValue): string[] {
    if (step.backupAllFiles === FilterTypes.UseExcludeMask) {
      const separatorExc = ~step.excludeMask.indexOf(',') ? ',' : ';';
      return step.excludeMask.split(separatorExc).map((s) => s.trim());
    } else if (step.backupAllFiles === FilterTypes.UseIncludeMask) {
      const separatorInc = ~step.includeMask.indexOf(',') ? ',' : ';';
      return step.includeMask.split(separatorInc).map((s) => s.trim());
    } else return [];
  }

  public static fromMBToBytes(mbs: string): string {
    const first = multiplyNumbersInStringFormat(mbs, '1024');
    return multiplyNumbersInStringFormat(first, '1024');
  }

  public static planDataFromRetentionPolicy(
    newPlan,
    step: RetentionPolicyStepValue | BaseRetentionPolicy,
    isHybrid = false,
    isLinux = false,
    isIbb = false
  ): void {
    newPlan.RetentionUseDefaultSettings = !isHybrid ? step.RetentionUseDefaultSettings : !step.RetentionUseDefaultSettings;
    if (!newPlan.RetentionUseDefaultSettings) {
      newPlan.RetentionNumberOfVersions = !step.keepNumberOfVersions ? IntMaxValue : step.RetentionNumberOfVersions;
      newPlan.SerializationSupportRetentionTime = step.deleteVersionsOlderThan
        ? TimeGroupToTimeSpanString({ value: step.deleteVersionsOlderThanCount, period: step.deleteVersionsOlderThanPeriod })
        : TimeSpanMaxValue;

      if (!isIbb) {
        if (!(step.alwaysKeepLastVersion === null || step.alwaysKeepLastVersion === undefined))
          newPlan.RetentionDeleteLastVersion = !step.alwaysKeepLastVersion;
        if (!isLinux) {
          newPlan.SerializationSupportRetentionDelay = step.delayPurgeForEnabled
            ? TimeGroupToTimeSpanString({ value: step.delayPurgeFor, period: step.delayPurgeForSelect })
            : TimeSpanZeroValue;
        }
        if (!(step.fromTimeSelected === null || step.fromTimeSelected === undefined)) {
          const isBackupDate = step.fromTimeSelected === FromTime['backup date'];
          newPlan.UseBackupDate = isBackupDate;
          if (isBackupDate) newPlan.RetentionDeleteLastVersion = false;
        }
      }
    }
  }

  public static delStopAfterTickFromPlanForRD(newPlan): void {
    if (!newPlan.isArchive) {
      if (newPlan.Schedule && newPlan.Schedule.StopAfterTicks) {
        if (!newPlan.Schedule.StopAfter && newPlan.Schedule.StopAfterTicks !== +MaxTicks) {
          newPlan.Schedule.StopAfter = GetTimeSpanString(moment.duration(newPlan.Schedule.StopAfterTicks / 10000, 'milliseconds'));
        }
        delete newPlan.Schedule.StopAfterTicks;
      }
      if (newPlan.ForceFullSchedule && newPlan.ForceFullSchedule.StopAfterTicks && !newPlan.ForceFullSchedule.StopAfter) {
        if (!newPlan.ForceFullSchedule.StopAfter && newPlan.ForceFullSchedule.StopAfterTicks !== +MaxTicks) {
          newPlan.ForceFullSchedule.StopAfter = GetTimeSpanString(
            moment.duration(newPlan.ForceFullSchedule.StopAfterTicks / 10000, 'milliseconds')
          );
        }
        delete newPlan.ForceFullSchedule.StopAfterTicks;
      }
      if (newPlan.ScheduleDiff && newPlan.ScheduleDiff.StopAfterTicks) delete newPlan.ScheduleDiff.StopAfterTicks;
      if (newPlan.ScheduleTLog && newPlan.ScheduleTLog.StopAfterTicks) delete newPlan.ScheduleTLog.StopAfterTicks;
    } else {
      if (newPlan.Schedule && newPlan.Schedule.StopAfterTicks) delete newPlan.Schedule.StopAfterTicks;
      if (newPlan.ForceFullSchedule && newPlan.ForceFullSchedule.StopAfterTicks) delete newPlan.ForceFullSchedule.StopAfterTicks;
    }
  }

  public static setStopAfterFromTick(newPlan): void {
    if (newPlan.Schedule && newPlan.Schedule.StopAfterTicks && !newPlan.Schedule.StopAfter) {
      const isStopAfter =
        (newPlan.Schedule.StopAfter && newPlan.Schedule.StopAfter !== TimeSpanMaxValue) ||
        (newPlan.Schedule.StopAfterTicks && newPlan.Schedule.StopAfterTicks.toString() !== MaxTicks);

      newPlan.Schedule.StopAfter = isStopAfter ? GetTimeSpanString(moment.duration(newPlan.Schedule.StopAfterTicks / 10000, 'milliseconds')) : TimeSpanMaxValue;
    }
    if (newPlan.ForceFullSchedule && newPlan.ForceFullSchedule.StopAfterTicks && !newPlan.ForceFullSchedule.StopAfter) {
      newPlan.ForceFullSchedule.StopAfter = GetTimeSpanString(
        moment.duration(newPlan.ForceFullSchedule.StopAfterTicks / 10000, 'milliseconds')
      );
    }
    if (newPlan.ScheduleDiff && newPlan.ScheduleDiff.StopAfterTicks && !newPlan.ScheduleDiff.StopAfter) {
      newPlan.ScheduleDiff.StopAfter = GetTimeSpanString(moment.duration(newPlan.ScheduleDiff.StopAfterTicks / 10000, 'milliseconds'));
    }
    if (newPlan.ScheduleTLog && newPlan.ScheduleTLog.StopAfterTicks && !newPlan.ScheduleTLog.StopAfter) {
      newPlan.ScheduleTLog.StopAfter = GetTimeSpanString(moment.duration(newPlan.ScheduleTLog.StopAfterTicks / 10000, 'milliseconds'));
    }
  }

  public static delStopAfterTickFromPlan(newPlan): void {
    if (newPlan.Schedule?.StopAfterTicks) delete newPlan.Schedule.StopAfterTicks;
    if (newPlan.ForceFullSchedule?.StopAfterTicks) delete newPlan.ForceFullSchedule.StopAfterTicks;
    if (newPlan.ScheduleDiff?.StopAfterTicks) delete newPlan.ScheduleDiff.StopAfterTicks;
    if (newPlan.ScheduleTLog?.StopAfterTicks) delete newPlan.ScheduleTLog.StopAfterTicks;
  }

  public static updateInvalidPlanForRd(wrapper: RemoteDeployPlanModel): void {
    if (wrapper.PlanType === PlanTypes.BackupDiskImagePlan && wrapper.plan.BackupVolumes === BackupTargetVolumesEnum.SelectedOnly) {
      wrapper.plan.BackupVolumes = BackupTargetVolumesEnum.SystemRequired;
    }
    if (wrapper.plan.IsHybridBackup) {
      wrapper.plan.IsHybridBackup = false;
      if (!(wrapper.plan.SyntheticFull === null || wrapper.plan.SyntheticFull === undefined)) wrapper.plan.SyntheticFull = true;
    }
    if (wrapper.plan.ExcludeEnabled) wrapper.plan.ExcludeEnabled = false;
    if (wrapper.plan.HybridID && wrapper.plan.HybridID !== GuidEmpty) wrapper.plan.HybridID = GuidEmpty;
    if (wrapper.plan.DiskInfo && wrapper.plan.DiskInfo.length) wrapper.plan.DiskInfo = [];
    if (wrapper.plan.HybridRetentionPolicy && !wrapper.plan.HybridRetentionPolicy.RetentionUseDefaultSettings)
      wrapper.plan.HybridRetentionPolicy.RetentionUseDefaultSettings = true;
  }

  public static updatePlanIfNoSchedule(plan, stopAfterString, isRestore = false): void {
    if (!isRestore) {
      plan.ScheduleDiff = { Enabled: false } as ScheduleSettings;
      plan.ScheduleTLog = { Enabled: false } as ScheduleSettings;
      plan.Schedule = { Enabled: false } as ScheduleSettings;
      plan.ForceFullSchedule = { Enabled: false } as ScheduleSettings;
      plan.Schedule.StopAfter = stopAfterString;
      plan.ForceFullSchedule.StopAfter = stopAfterString;
    } else {
      plan.Schedule = { Enabled: false } as ScheduleSettings;
      plan.Schedule.StopAfter = stopAfterString;
    }
  }

  public static updatePlanIfOnce(plan, schedule, OnceDate, stopAfterString, isRestore = false): void {
    schedule.OnceDate = getISODateFromTimeAndDate(OnceDate.time.toString(), OnceDate.date);
    schedule.Enabled = true;
    schedule.RecurType = RecurType.Once;
    schedule.StopAfter = stopAfterString;
    if (!isRestore) {
      plan.Schedule = { Enabled: false } as ScheduleSettings;
      plan.Schedule.StopAfter = stopAfterString;
      plan.ScheduleDiff = { Enabled: false } as ScheduleSettings;
      plan.ScheduleTLog = { Enabled: false } as ScheduleSettings;
    }
  }

  public static updatePlanIfHasAdvancedForLinux(ScheduleModelAdvanced, schedules, stopAfterString, isLinux = false): void {
    if (ScheduleModelAdvanced.blockLevel && ScheduleModelAdvanced.blockLevel.Enabled) {
      ScheduleDataHelper.SetRecurringScheduleSettings(schedules.schedule, ScheduleModelAdvanced.forceFull.FormSchedule, isLinux);
      ScheduleDataHelper.SetRecurringScheduleSettings(schedules.fullSchedule, ScheduleModelAdvanced.blockLevel.FormSchedule, isLinux);
    } else if (ScheduleModelAdvanced.forceFull.Enabled)
      ScheduleDataHelper.SetRecurringScheduleSettings(schedules.schedule, ScheduleModelAdvanced.forceFull.FormSchedule, isLinux);
    else ScheduleDataHelper.SetRecurringEmptySchedule(schedules.fullSchedule, isLinux);
    this.setStopAfterForSchedules(schedules, stopAfterString);
  }

  public static updatePlanIfHasAdvancedForRestores(ScheduleModelAdvanced, schedule, stopAfterString, isLinux = false): void {
    if (schedule) {
      if (ScheduleModelAdvanced.forceFull && ScheduleModelAdvanced.forceFull.Enabled)
        ScheduleDataHelper.SetRecurringScheduleSettings(schedule, ScheduleModelAdvanced.forceFull.FormSchedule, isLinux);
      else ScheduleDataHelper.SetRecurringEmptySchedule(schedule, isLinux);
      schedule.StopAfter = stopAfterString;
    }
  }

  public static updatePlanPassword(newPlan, password: string): void {
    newPlan.EncryptionPassword = password;

    if (newPlan.EncryptionPassword === PasswordFromPlan) {
      newPlan.EncryptionPassword = null;
    }
  }

  public static updatePlanIfHasAdvanced(ScheduleModelAdvanced, schedules, stopAfterString, isLinux = false): void {
    if (ScheduleModelAdvanced.blockLevel && ScheduleModelAdvanced.blockLevel.Enabled)
      ScheduleDataHelper.SetRecurringScheduleSettings(schedules.schedule, ScheduleModelAdvanced.blockLevel.FormSchedule, isLinux);
    else ScheduleDataHelper.SetRecurringEmptySchedule(schedules.schedule, isLinux);
    if (ScheduleModelAdvanced.forceFull && ScheduleModelAdvanced.forceFull.Enabled)
      ScheduleDataHelper.SetRecurringScheduleSettings(schedules.fullSchedule, ScheduleModelAdvanced.forceFull.FormSchedule, isLinux);
    else ScheduleDataHelper.SetRecurringEmptySchedule(schedules.fullSchedule, isLinux);

    if (!isLinux) {
      if (ScheduleModelAdvanced.scheduleDiff && ScheduleModelAdvanced.scheduleDiff.Enabled)
        ScheduleDataHelper.SetRecurringScheduleSettings(schedules.ScheduleDiff, ScheduleModelAdvanced.scheduleDiff.FormSchedule);
      else ScheduleDataHelper.SetRecurringEmptySchedule(schedules.ScheduleDiff);
      if (ScheduleModelAdvanced.scheduleTLog && ScheduleModelAdvanced.scheduleTLog.Enabled)
        ScheduleDataHelper.SetRecurringScheduleSettings(schedules.ScheduleTLog, ScheduleModelAdvanced.scheduleTLog.FormSchedule);
      else ScheduleDataHelper.SetRecurringEmptySchedule(schedules.ScheduleTLog);
    }
    this.setStopAfterForSchedules(schedules, stopAfterString);
  }

  public static setStopAfterForSchedules(schedules, stopAfterString): void {
    if (schedules.schedule) schedules.schedule.StopAfter = stopAfterString;
    if (schedules.fullSchedule) schedules.fullSchedule.StopAfter = stopAfterString;
    if (schedules.ScheduleDiff) schedules.ScheduleDiff.StopAfter = stopAfterString;
    if (schedules.ScheduleTLog) schedules.ScheduleTLog.StopAfter = stopAfterString;
  }

  public static SetPlanScheduleSettingsAdvanced(
    ScheduleModel: any,
    plan: any,
    step: ScheduleAdvancedStepValue,
    isLinux = false,
    isRestore = false,
    isSQL = false
  ): any {
    if (!plan.Schedule) plan.Schedule = { Enabled: false } as ScheduleSettings;
    if (!isRestore && !plan.ForceFullSchedule) plan.ForceFullSchedule = { Enabled: false } as ScheduleSettings;
    if (isSQL && !plan.ScheduleDiff) plan.ScheduleDiff = { Enabled: false } as ScheduleSettings;
    if (isSQL && !plan.ScheduleTLog) plan.ScheduleTLog = { Enabled: false } as ScheduleSettings;

    const schedule = isSQL ? plan.ForceFullSchedule : plan.Schedule;
    const fullSchedule = isSQL ? plan.Schedule : plan.ForceFullSchedule;
    const ScheduleDiff = plan.ScheduleDiff;
    const ScheduleTLog = plan.ScheduleTLog;
    const stopAfterDuration = moment.duration({ hours: ScheduleModel.stopAfterHours, minutes: ScheduleModel.stopAfterMinutes });
    const stopAfterString = !ScheduleModel.StopAfterEnabled ? TimeSpanMaxValue : GetTimeSpanString(stopAfterDuration);

    if (ScheduleDiff) ScheduleDiff.StopAfter = TimeSpanMaxValue;
    if (ScheduleTLog) ScheduleTLog.StopAfter = TimeSpanMaxValue;

    const scheduleType = +ScheduleType[ScheduleModel.ScheduleType];
    switch (scheduleType) {
      case ScheduleType.noschedule:
        this.updatePlanIfNoSchedule(plan, stopAfterString, isRestore);
        break;
      case ScheduleType.once:
        this.updatePlanIfOnce(
          plan,
          isRestore ? schedule : fullSchedule,
          ScheduleModel.specificDateGroup,
          stopAfterString,
          isRestore || isSQL
        );
        break;
      default:
        plan.IsPredefinedTemplatesSchedule = scheduleType == ScheduleType.predefined;
        if (step) {
          const schedules = { schedule, fullSchedule, ScheduleDiff, ScheduleTLog };
          if (!isRestore) this.updatePlanIfHasAdvanced(step, schedules, stopAfterString, isLinux);
          else this.updatePlanIfHasAdvancedForRestores(step, schedule, stopAfterString, isLinux);
        }
    }

    this.updatesAdvancedScheduleBeforeReturn(ScheduleModel, plan, step, isLinux);
    return plan;
  }

  public static updatesAdvancedScheduleBeforeReturn(ScheduleModel: any, plan: any, step: ScheduleAdvancedStepValue, isLinux = false): void {
    plan.ForceMissedSchedule = ScheduleModel.ForceMissedSchedule;
    if (step && (!step.blockLevel || !step.blockLevel.Enabled) && (!step.forceFull || !step.forceFull.Enabled)) {
      plan.ForceFullApplyDiffSizeCondition = false;
      plan.ForceFullDiffSizeCondition = 50;
    } else if (step && !isLinux) {
      if (!(step.ForceFullApplyDiffSizeCondition === null || step.ForceFullApplyDiffSizeCondition === undefined))
        plan.ForceFullApplyDiffSizeCondition = step.ForceFullApplyDiffSizeCondition;
      if (!(step.ForceFullDiffSizeCondition === null || step.ForceFullDiffSizeCondition === undefined))
        plan.ForceFullDiffSizeCondition = step.ForceFullDiffSizeCondition;
    }
    if (plan.Schedule) delete plan.Schedule.StopAfterTicks;
    if (plan.ForceFullSchedule) delete plan.ForceFullSchedule.StopAfterTicks;
    if (plan.ScheduleDiff) delete plan.ScheduleDiff.StopAfterTicks;
    if (plan.ScheduleTLog) delete plan.ScheduleTLog.StopAfterTicks;
  }

  public static SetRecurringScheduleIfNotPeriodically(Schedule: ScheduleSettings, RecurringSchedule: RecurringSchedule): void {
    Schedule.DailyRecurrence = RecurringSchedule.DailyFrequency.PeriodOption == 'OccursEvery';
    if (RecurringSchedule.DailyFrequency.OccursAtTime) {
      const occursAtTime = GetDateFromTimeString(RecurringSchedule.DailyFrequency.OccursAtTime);
      Schedule.Hour = occursAtTime.getHours();
      Schedule.Minutes = occursAtTime.getMinutes();
    }
    let dailyRecurrencePeriod = RecurringSchedule.DailyFrequency.OccursEveryCount;
    if (RecurringSchedule.DailyFrequency.OccursEveryPeriod === 'hours') dailyRecurrencePeriod = dailyRecurrencePeriod * 60;

    Schedule.DailyRecurrencePeriod = dailyRecurrencePeriod || 0;

    if (!Schedule.DailyRecurrence) {
      Schedule.DailyFromHour = 8;
      Schedule.DailyFromMinutes = 0;
      Schedule.DailyTillHour = 6;
      Schedule.DailyTillMinutes = 0;
    } else {
      const occursEveryFrom = GetDateFromTimeString(RecurringSchedule.DailyFrequency.OccursEveryFromTime);
      Schedule.DailyFromHour = occursEveryFrom.getHours();
      Schedule.DailyFromMinutes = occursEveryFrom.getMinutes();

      const occursEveryTo = GetDateFromTimeString(RecurringSchedule.DailyFrequency.OccursEveryToTime);
      Schedule.DailyTillHour = occursEveryTo.getHours();
      Schedule.DailyTillMinutes = occursEveryTo.getMinutes();
    }
  }

  public static SetSchedulePredefinedTemplates(
    ScheduleModel: ScheduleStepValue,
    plan: any,
    model: SimpleScheduleStepValue,
    isSQL = false
  ): void {
    this.SetPlanScheduleSettingsAdvanced(ScheduleModel, plan, null);
    const schedule = isSQL ? plan.ForceFullSchedule : plan.Schedule;
    const ScheduleDiff = plan.ScheduleDiff;
    const ScheduleTLog = plan.ScheduleTLog;
    const fullSchedule = model.fullScheduleEnabled ? (isSQL ? plan.Schedule : plan.ForceFullSchedule) : schedule;
    const newRecurringSchedule = new RecurringSchedule();
    newRecurringSchedule.RecurringPeriod = RecurType.Periodically;
    newRecurringSchedule.DailyFrequency.StartFromDate = getFullDateFromDateTimeObject({
      date: model.startFromDate,
      time: model.startFromTime
    });
    if (model.fullScheduleEnabled) {
      newRecurringSchedule.DailyFrequency.OccursEveryDuration = moment.duration({
        hours: model.fullBackupEveryHours,
        minutes: model.fullBackupEveryMinutes
      });
      ScheduleDataHelper.SetRecurringScheduleSettings(fullSchedule, newRecurringSchedule);
    } else fullSchedule.Enabled = false;

    if (model.blockLevelBackup) {
      newRecurringSchedule.DailyFrequency.OccursEveryDuration = moment.duration({
        hours: model.blockLevelBackupEveryHours,
        minutes: model.blockLevelBackupEveryMinutes
      });
      ScheduleDataHelper.SetRecurringScheduleSettings(schedule, newRecurringSchedule);
    } else schedule.Enabled = false;

    if (model.scheduleDiffEnabled) {
      newRecurringSchedule.DailyFrequency.OccursEveryDuration = moment.duration({
        hours: model.differentialBackupEveryHours,
        minutes: model.differentialBackupEveryMinutes
      });
      ScheduleDataHelper.SetRecurringScheduleSettings(ScheduleDiff, newRecurringSchedule);
    } else if (ScheduleDiff) ScheduleDiff.Enabled = false;

    if (model.scheduleTLogEnabled) {
      newRecurringSchedule.DailyFrequency.OccursEveryDuration = moment.duration({
        hours: model.tLogBackupEveryHours,
        minutes: model.tLogBackupEveryMinutes
      });
      ScheduleDataHelper.SetRecurringScheduleSettings(ScheduleTLog, newRecurringSchedule);
    } else if (ScheduleTLog) ScheduleTLog.Enabled = false;

    const StopAfterDuration = moment.duration({ hours: ScheduleModel.stopAfterHours, minutes: ScheduleModel.stopAfterMinutes });
    const stopAfterString = !ScheduleModel.StopAfterEnabled ? TimeSpanMaxValue : GetTimeSpanString(StopAfterDuration);
    schedule.StopAfter = stopAfterString;
    fullSchedule.StopAfter = stopAfterString;
    if (ScheduleDiff) ScheduleDiff.StopAfter = stopAfterString;
    if (ScheduleTLog) ScheduleTLog.StopAfter = stopAfterString;
  }
}
