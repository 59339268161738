export enum StoreTypes {
  // shared
  Administrators = 'Administrators',
  Application = 'Application',
  Auth = 'Authentication',
  Builds = 'Builds',
  Companies = 'Companies',
  Computers = 'Computers',
  ComputersBackup = 'Computers Backup',
  ComputersPaging = 'Computers Paging',
  ComputersStatistic = 'Computers Statistic',
  RemoteDeploy = 'Remote Deploy Import',
  Users = 'Users',
  OnlineAccessComputersPaging = `Online Access Paging`,
  GroupAction = 'Group Action Tasks',
  RmmGroupActionWizard = 'RMM Group Action Wizard',
  RmmScriptLibrary = 'RMM Script Library',
  RmmCustomAlertSettings = 'RMM Custom Alerting Settings',
  // domains
  Snmp = 'SNMP Devices',
  SnmpOId = 'SNMP Oid Devices',
  // modules
  SnmpAlerts = 'SNMP Alerting',
  AlertsHistory = 'alertsHistory',
}
