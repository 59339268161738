export enum RoutingPath {
  ApLicenses = '/AP/Licenses',
  ApLicensesAspx = '/AP/Licenses.aspx',
  ApComputers = '/AP/Computers',
  ApBackupComputers = '/AP/Backup/Computers',
  ApRMMComputers = '/AP/RMM/Computers',
  ApCompanies = '/AP/Companies',
  ApBranding = '/AP/Branding',
  ApNotifications = '/AP/Notifications',
  ApAdministrators = '/AP/Administrators',
  ApAppsDomains = '/AP/Apps/Domains',
  ApAppsGeneral = '/AP/Apps/General',
  ApNotFound = '/AP/NotFound',
  ApGettingStart = '/AP/GettingStart',
  ApUsers = '/AP/Users',
  ApHelp = '/AP/Help',
  ApSettings = '/AP/Settings',
  ApDefaultAspx = '/AP/Default.aspx',
  ApEditAccountAspx = '/AP/EditAccount.aspx',
  OnlineAccess = '/OnlineAccess',
  OnlineAccessWebConnect = '/OnlineAccess/WebConnect',
  OnlineAccessNotFound = '/NotFound',
  RMMAlertCustomisation = 'AP/RMMAlertCustomisation'
}
