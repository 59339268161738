import { ExtendedPagingState, PagingParams, QueryFiltersParams, QueryParams } from '@models/Paging';
import { EntityState } from '@ngrx/entity';
import { ActionCreator, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { StoreTypes } from '../storeTypes.enum';

// eslint-disable-next-line @typescript-eslint/ban-types
export interface EntityStateModel<T, F extends {} = Record<string, never>>
  extends EntityState<T>,
    ExtendedPagingState<QueryFiltersParams, F> {}

export enum PagingActionTypes {
  SetEntities = 'Set Entities',
  SetSelected = 'Set Selected Entity',
  SetSelectedSuccess = 'Set Selected Entity Success',
  Clear = 'Clear Entities',
  LoadEntityById = 'Load Entity',
  LoadError = 'Load Entity Error',
  SetLoading = 'Set Loading',
  // data
  LoadData = 'Load Data',
  LoadDataSuccess = 'Load Data Success',
  LoadDataError = 'Load Data Error',
  ClearData = 'Clear Data',
  // paging
  LoadPage = 'Load Page',
  LoadPageSuccess = 'Load Page Success',
  LoadPageError = 'Load Page Error',
  ClearPages = 'Clear Pages',
  SetPageNumber = 'Set Page Number',
  SetPageNumberSuccess = 'Set Page Number Success',
  SetPerPage = 'Set Per Page',
  RefreshDataAndPaging = 'Refresh Data And Paging',
  RefreshDataAndPagingSuccess = 'Refresh Data And Paging Success'
}

export type PagingActionType = `[${StoreTypes}] ${PagingActionTypes}`;

export type PagingActions<T> = {
  setEntities: ActionCreator<PagingActionType, (props: { entities: T[] }) => { entities: T[] } & TypedAction<PagingActionType>>;
  setSelected: ActionCreator<
    PagingActionType,
    (props: { selected: string; force: boolean }) => { selected: string; force: boolean } & TypedAction<PagingActionType>
  >;
  setSelectedSuccess: ActionCreator<
    PagingActionType,
    (props: { selected: string; entity?: T }) => { selected: string; entity?: T } & TypedAction<PagingActionType>
  >;
  clear: ActionCreator<PagingActionType, () => TypedAction<PagingActionType>>;
  loadEntityById: ActionCreator<
    PagingActionType,
    (props: {
      id: string;
      force?: boolean;
      quiet?: boolean;
    }) => { id: string; force?: boolean; quiet?: boolean } & TypedAction<PagingActionType>
  >;
  loadError: ActionCreator<PagingActionType, () => TypedAction<PagingActionType>>;
  setLoading: ActionCreator<PagingActionType, (props: { loading: boolean }) => { loading: boolean } & TypedAction<PagingActionType>>;

  // data
  loadData: ActionCreator<
    PagingActionType,
    (props: {
      isFullRefresh?: boolean;
      allowLoadMore?: boolean;
      limit?: number;
    }) => { isFullRefresh?: boolean; allowLoadMore?: boolean; limit?: number } & TypedAction<PagingActionType>
  >;
  loadDataSuccess: ActionCreator<
    PagingActionType,
    (props: {
      entities: T[];
      total: number;
      overwrite: boolean;
    }) => { entities: T[]; total: number; overwrite: boolean } & TypedAction<PagingActionType>
  >;
  loadDataError: ActionCreator<PagingActionType, () => TypedAction<PagingActionType>>;
  clearData: ActionCreator<PagingActionType, () => TypedAction<PagingActionType>>;

  // paging
  loadPage: ActionCreator<
    PagingActionType,
    (props: {
      filters?: QueryParams;
      pageNumber?: number;
      perPage?: number;
      isFullRefresh?: boolean;
    }) => { filters?: QueryParams; pageNumber?: number; perPage?: number; isFullRefresh?: boolean } & TypedAction<PagingActionType>
  >;
  loadPageSuccess: ActionCreator<
    PagingActionType,
    (props: {
      pageNumber: number;
      entities: T[];
      total: number;
    }) => { pageNumber: number; entities: T[]; total: number } & TypedAction<PagingActionType>
  >;
  loadPageError: ActionCreator<PagingActionType, () => TypedAction<PagingActionType>>;
  clearPages: ActionCreator<PagingActionType, () => TypedAction<PagingActionType>>;
  setPageNumber: ActionCreator<PagingActionType, (props: { pageNumber: number }) => { pageNumber: number } & TypedAction<PagingActionType>>;
  setPageNumberSuccess: ActionCreator<
    PagingActionType,
    (props: { pageNumber: number }) => { pageNumber: number } & TypedAction<PagingActionType>
  >;
  setPerPage: ActionCreator<PagingActionType, (props: { perPage: number }) => { perPage: number } & TypedAction<PagingActionType>>;

  // refresh
  refreshDataAndPaging: ActionCreator<PagingActionType, () => TypedAction<PagingActionType>>;
  refreshDataAndPagingSuccess: ActionCreator<
    PagingActionType,
    (props: { entities: T[]; total: number }) => { entities: T[]; total: number } & TypedAction<PagingActionType>
  >;
};

export type PagingSelectors<T, F> = {
  featureSelector: MemoizedSelector<object, EntityStateModel<T, F>, DefaultProjectorFn<EntityStateModel<T, F>>>;
  selectAll: MemoizedSelector<EntityStateModel<T, F>, T[]>;
  selectIds: MemoizedSelector<EntityStateModel<T, F>, string[] | number[]>;
  selectById: (id: string) => MemoizedSelector<EntityStateModel<T, F>, T>;
  selectLoadedById: (id: string) => MemoizedSelector<EntityStateModel<T, F>, T>;
  selectFirstEntity: MemoizedSelector<EntityStateModel<T, F>, T>;
  selectSelected: MemoizedSelector<EntityStateModel<T, F>, string>;
  selectSelectedEntity: MemoizedSelector<EntityStateModel<T, F>, T>;
  selectLoading: MemoizedSelector<EntityStateModel<T, F>, boolean>;
  selectDataLoading: MemoizedSelector<EntityStateModel<T, F>, boolean>;
  selectDataLoaded: MemoizedSelector<EntityStateModel<T, F>, boolean>;
  selectDataTotal: MemoizedSelector<EntityStateModel<T, F>, number>;
  selectData: MemoizedSelector<EntityStateModel<T, F>, T[]>;
  selectDataCount: MemoizedSelector<EntityStateModel<T, F>, number>;
  selectAllDataLoaded: MemoizedSelector<EntityStateModel<T, F>, boolean>;
  selectPreQueryDataParams: MemoizedSelector<EntityStateModel<T, F>, { dataCount: number; allLoaded: boolean }>;
  selectPagingLoading: MemoizedSelector<EntityStateModel<T, F>, boolean>;
  selectPagingLoaded: MemoizedSelector<EntityStateModel<T, F>, boolean>;
  selectPagingPageNumbers: MemoizedSelector<EntityStateModel<T, F>, number[]>;
  selectPagingCurrentPageNumber: MemoizedSelector<EntityStateModel<T, F>, number>;
  selectPagingPerPage: MemoizedSelector<EntityStateModel<T, F>, number>;
  selectPagingTotal: MemoizedSelector<EntityStateModel<T, F>, number>;
  selectPagingParams: MemoizedSelector<EntityStateModel<T, F>, PagingParams>;
  selectPagingFilters: MemoizedSelector<EntityStateModel<T, F>, QueryFiltersParams>;
  selectPreQueryPagingParams: MemoizedSelector<
    EntityStateModel<T, F>,
    { filters: QueryFiltersParams; pagingParams: PagingParams; pageNumbers: number[] }
  >;
  selectCurrentPage: MemoizedSelector<EntityStateModel<T, F>, T[]>;
  selectDataEntityLoading: MemoizedSelector<EntityStateModel<T, F>, boolean>;
  selectPagingEntityLoading: MemoizedSelector<EntityStateModel<T, F>, boolean>;
};

export const initialPagingEntityState = {
  paging: {
    loading: false,
    loaded: false,
    total: 0,
    currentPageNumber: 1,
    perPage: null,
    filters: {},
    pages: {},
    obsolescenceMark: null
  },
  data: {
    loading: false,
    loaded: false,
    total: 0,
    entities: [],
    obsolescenceMark: null
  },
  loading: false,
  selected: null
};

export const getPagingStoreActionType = (
  storeType: StoreTypes,
  pagingActionType: PagingActionTypes
): `[${StoreTypes}] ${PagingActionTypes}` => `[${storeType}] ${pagingActionType}`;
