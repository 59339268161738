import { Component, EventEmitter, forwardRef, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ComputerBackupFacade } from '@facades/computer.backup.facade';
import { ScheduleType } from '@models/schedule-type.enum';
import { WeekNumber } from '@models/WeekNumber.enum';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { baseDateFormat, getISODateFromTimeAndDate } from '@utils/date';
import { stopAfterMinutesValidator } from '@utils/validators';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { ShortDateParserFormatter } from 'mbs-ui-kit/form/datepicker/datepicker-formatters/short-date-parser-formatter';
import { ModalService, ModalSettings } from 'mbs-ui-kit/modal/modal.service';
import { EnumHelper } from 'mbs-ui-kit/utils/enum-helper';
import { FormsUtil } from 'mbs-ui-kit/utils/forms-util';
import { MbsValidators } from 'mbs-ui-kit/utils/mbs-validators';
import * as moment from 'moment';
import { noop } from 'rxjs';
import { BaseForPlanHelper as WizardBaseForPlanHelper } from '../../helpers/bases/wizard-base-helpers';
import { StepsHelpers } from '../../helpers/steps-helpers';
import {
  FullBackupData,
  IncrementalData,
  OverdueAfterSuccessPeriod,
  ScheduleModalData,
  ScheduleStepValue
} from '../../models/schedule-models';
import { RemoteManagementWizardsService } from '../../services/remote-management-wizards.service';
import { StepBase } from '../StepBase.class';
import { ScheduleModalComponent } from './schedule-modal/schedule-modal.component';

const ScheduleStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => ScheduleStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-schedule-step',
  templateUrl: './schedule-step.component.html',
  providers: [ScheduleStepValueAccessor, { provide: NgbDateParserFormatter, useClass: ShortDateParserFormatter }]
})
export class ScheduleStepComponent extends StepBase<ScheduleStepValue> implements OnInit {
  @Input() fastNTFS: boolean;
  @Input() isGFSMode: boolean;
  @Input() invalidFFIStorage: string;
  @Input() retentionTitle: string;
  @Input() retentionUseDefault: boolean;
  @Output() changeType = new EventEmitter();
  @Output() offFastNTFS = new EventEmitter();
  @Output() incrementalData = new EventEmitter();

  public showOverdue = true;
  public agentTimezone = 0;
  public OverduePeriod = EnumHelper.EnumToSelectIndexesArray(OverdueAfterSuccessPeriod);

  public schedulesTexts = { incrementalData: '', fullBackupData: '' };
  public invalidFFIStorageAlert = '';
  public elementSelectors = {
    id: {
      fullBackupEnabledCheckbox: 'full-backup-enabled-checkbox',
      fullBackupEnabledCheckboxEdit: 'full-backup-enabled-checkbox-edit',
      scheduleTypeRadio1: 'schedule-type-radio-1',
      scheduleTypeRadio2: 'schedule-type-radio-2',
      scheduleTypeRadio3: 'schedule-type-radio-3',
      scheduleTypeRadio3Edit: 'schedule-type-radio-3-edit',
      scheduleTypeRadio4: 'schedule-type-radio-4',
      scheduleTypeRadio5: 'schedule-type-radio-5',
      scheduleTypeRadio6: 'schedule-type-radio-6',
      scheduleTypeRadio6Edit: 'schedule-type-radio-6-edit'
    }
  };

  private validVersionForSchedule = false;
  private confirmedDate = false;
  private confirmedOffNTFS = false;
  private isFFIConfirmed: boolean = null;
  private initialScheduleType: string;
  private FFITagClass = this.isFFIEnabled ? 'background-red mt-n1' : 'bg-red-35 mt-n1';

  @ViewChild('fastNTFSModalContent', { static: true, read: TemplateRef }) fastNTFSModalContent: TemplateRef<any>;
  @ViewChild('FFIPayAttentionGFS', { static: true, read: TemplateRef }) FFIPayAttentionGFS: TemplateRef<any>;

  constructor(
    private ability: AbilityService,
    public mainService: RemoteManagementWizardsService,
    private modalService: ModalService,
    public i18nPipe: I18NextPipe,
    private facade: ComputerBackupFacade
  ) {
    super(mainService);
    this.showOverdue = ability.can('read', 'Monitoring') && !this.isRDMode;
    this.validVersionForSchedule = this.isRDMode || mainService.validVersionForSchedule;
    this.facade.currentTimezone$.pipe(untilDestroyed(this)).subscribe({ next: (timezone) => (this.agentTimezone = timezone) });
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup(
      {
        ScheduleType: new FormControl(''),
        specificDateGroup: new UntypedFormGroup({
          date: new FormControl(),
          time: new FormControl('00:00', MbsValidators.timeValidatorWithoutText)
        }),
        StopAfterEnabled: new FormControl(false),
        stopAfterHours: new FormControl(0),
        stopAfterMinutes: new FormControl(0, stopAfterMinutesValidator.bind(this, 'stopAfterHours')),
        ForceMissedSchedule: new FormControl(false),
        overdueAfterSuccessEnabled: new FormControl(false),
        overdueAfterSuccessAmount: new FormControl(1, Validators.min(1)),
        overdueAfterSuccessPeriod: new FormControl(0),
        incrementalData: new UntypedFormGroup(StepsHelpers.getDefaultIncrementalSchedule(this.validVersionForSchedule)),
        fullBackupData: new UntypedFormGroup(StepsHelpers.getDefaultFullSchedule(this.validVersionForSchedule)),
        syncBeforeRun: new FormControl(false)
      },
      this.isSelectedDaysValidator.bind(this)
    );

    this.initFormEvents();

    if (!this.showOverdue) {
      this.toggleFormControls(['overdueAfterSuccessEnabled', 'overdueAfterSuccessAmount', 'overdueAfterSuccessPeriod'], false);
    }
  }

  protected initFormEvents(): void {
    super.initFormEvents();

    this.stepForm
      .get('specificDateGroup')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => this.dateTimeChangeHandler());
  }

  onStepFormChange(value: ScheduleStepValue): void {
    const onceValid = value.ScheduleType === ScheduleType[ScheduleType.once] ? this.confirmedDate : true;
    const invalid = this.isBackupPlan && value.ScheduleType === 'instantly' && this.fastNTFS && !this.confirmedOffNTFS;
    const invalidFFI = this.invalidFFIStorage && this.stepForm.get('ScheduleType').value === 'ffi';
    const needConfirmFFI =
      !this.isLinux &&
      !this.isFFIConfirmed &&
      this.isNBF &&
      !this.isCreate &&
      this.initialScheduleType !== 'ffi' &&
      this.stepForm.get('ScheduleType').value === 'ffi';

    this.value = { ...value, valid: this.stepForm.valid && onceValid && !invalid && !needConfirmFFI && !invalidFFI };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.stepFocused && changes.stepFocused.currentValue) {
      this.dateTimeChangeHandler();
      setTimeout(() => this.stepForm.updateValueAndValidity());
    }

    if (this.stepForm && changes.fastNTFS && changes.fastNTFS.currentValue) {
      this.confirmedOffNTFS = false;
      queueMicrotask(() => this.stepForm.updateValueAndValidity());
    }

    if (changes.invalidFFIStorage) {
      this.invalidFFIStorageAlert = this.i18nPipe.transform('wizards:ffi_label_bad_storage', {
        storage: this.invalidFFIStorage
      });
      queueMicrotask(() => this.stepForm.updateValueAndValidity());
    }
  }

  isSelectedDaysValidator(formGroup: UntypedFormGroup): ValidationErrors | null {
    const isFFI = formGroup.value.ScheduleType === 'ffi';
    const isPredefined = formGroup.value.ScheduleType === 'predefined';

    if (this.isNBF && !this.isRestore && (isFFI || isPredefined)) {
      const incremental = formGroup.get('incrementalData') as UntypedFormGroup;
      const fullData = formGroup.get('fullBackupData') as UntypedFormGroup;
      const incErr = incremental.get('recurringPeriod').value === 'Daily' && !incremental.get('weeklyDayOfWeek').value.some((day) => !!day);
      const fullErr =
        fullData.get('enabled').value &&
        fullData.get('recurringPeriod').value === 'Daily' &&
        !fullData.get('weeklyDayOfWeek').value.some((day) => !!day);

      if (incErr) {
        return { needSelectDay: { message: isFFI ? 'Forever Forward Incremental' : 'recurring (incremental)', isFFI } };
      }

      if (fullErr) {
        return { needSelectDay: { message: 'Execute full backup (Synthetic full is possible)' } };
      }

      return null;
    }

    return null;
  }

  forceValid(data: any = null): void {
    const needConfirmFFI =
      !this.isLinux &&
      !this.isFFIConfirmed &&
      this.isNBF &&
      !this.isCreate &&
      this.initialScheduleType !== 'ffi' &&
      this.stepForm.get('ScheduleType').value === 'ffi';

    if (data.isNext && !this.confirmedDate && this.stepForm.get('ScheduleType').value === ScheduleType[ScheduleType.once]) {
      this.showConfirmIfNeed(this.stepForm.get('specificDateGroup').value, data.isNext, data.isSave);
    }

    const needShowValidMessage =
      this.isNBF && !this.isRestore && !this.stepForm.valid && this.stepForm.errors && this.stepForm.errors.needSelectDay;

    if (needShowValidMessage) {
      const modalSettings: ModalSettings = {
        header: {
          title: this.stepForm.errors.needSelectDay.isFFI ? 'Schedule Forever Forward Incremental Options' : 'Schedule Recurring Options'
        },
        footer: { okButton: { text: 'Ok' }, cancelButton: { show: false } }
      };
      const message = `In the settings of a <span class="text-warning">${this.stepForm.errors.needSelectDay.message}</span> schedule, at least one week day should be selected`;

      this.modalService.open(modalSettings, message).then().catch(noop);
    }

    if (this.fastNTFS && this.stepForm.get('ScheduleType').value === 'instantly' && !this.confirmedOffNTFS) {
      const modalSettings: ModalSettings = {
        header: { title: 'Disable Use Fast NTFS Scan' },
        footer: { okButton: { text: 'Yes', type: 'success' }, cancelButton: { text: 'No' } }
      };

      this.modalService
        .open(modalSettings, this.fastNTFSModalContent)
        .then((confirm) => {
          if (confirm) {
            this.confirmedOffNTFS = true;
            this.offFastNTFS.emit();
          }
        })
        .catch(noop);
    }

    if (needConfirmFFI && !needShowValidMessage) {
      this.showGFSAlert(data.isNext, data.isSave);
    }

    FormsUtil.triggerValidation(this.stepForm);
    this.resetValidStateForValidFields();
  }

  showConfirmIfNeed(specificDate, needNextStep: boolean, needSave: boolean): void {
    if (!this.confirmedDate) {
      const modalSettings: ModalSettings = {
        header: { title: 'Information' },
        footer: { okButton: { text: 'Yes' }, cancelButton: { text: 'No' } }
      };

      this.modalService
        .confirm(modalSettings, 'The scheduled date is in the past. Do you want to continue?')
        .then(() => {
          this.confirmedDate = true;
          this.stepForm.updateValueAndValidity();

          if (needNextStep || needSave) this.nextStep.emit(needSave);
        })
        .catch(() => this.stepForm.updateValueAndValidity());
    }
  }

  dateTimeChangeHandler(): void {
    if (!this.isNBF || this.isRestore) {
      const specificDate = this.stepForm.get('specificDateGroup').value;

      if (specificDate?.time && specificDate?.date) {
        const dateFromSpecific = getISODateFromTimeAndDate(specificDate.time.toString(), specificDate.date);

        if (this.isRDMode) {
          this.confirmedDate = moment().diff(moment(dateFromSpecific, baseDateFormat), 'days') > 0;
          return;
        }

        const currentDateTimeInAgent = moment.utc().utcOffset(this.agentTimezone || 0).format(baseDateFormat);
        this.confirmedDate = moment(currentDateTimeInAgent, baseDateFormat).isBefore(dateFromSpecific, 'minutes');
      }
    }
  }

  stopAfterHoursChangeHandler(event): void {
    if (typeof event === 'number' && !isNaN(event)) this.stepForm.get('stopAfterMinutes').updateValueAndValidity();
  }

  updateForm(value: ScheduleStepValue): void {
    if (this.isCreate && (!this.isNBF || this.isRestore) && !this.isRDMode) {
      const date = moment.utc().utcOffset(this.agentTimezone).add(1, 'hours').set('minute', 0);
      value.specificDateGroup = { date: date.toDate(), time: date.format('HH:mm:ss') };
    }

    this.initialScheduleType = value.ScheduleType === 'predefined' ? 'incremental' : value.ScheduleType;
    this.stepForm.reset(value);

    if (this.isNBF && !this.isRestore) {
      StepsHelpers.updateDaysOfWeek(value.incrementalData.weeklyDayOfWeek, this.stepForm.get('incrementalData') as UntypedFormGroup);
      StepsHelpers.updateDaysOfWeek(value.fullBackupData.weeklyDayOfWeek, this.stepForm.get('fullBackupData') as UntypedFormGroup);
      this.setPanelTitle(value.incrementalData, 'incrementalData');
      this.setPanelTitle(value.fullBackupData, 'fullBackupData');
    }

    this.scheduleTypeChangeHandler(value.ScheduleType, false);
    this.stopAfterEnabledChangeHandler(value.StopAfterEnabled);
    this.overdueAfterSuccessChangeHandler(value.overdueAfterSuccessEnabled);
  }

  stopAfterEnabledChangeHandler(event): void {
    this.toggleFormControls(['stopAfterHours', 'stopAfterMinutes'], event);

    if (event) this.stepForm.get('stopAfterMinutes').markAllAsTouched();
  }

  overdueAfterSuccessChangeHandler(event): void {
    this.toggleFormControls(['overdueAfterSuccessAmount', 'overdueAfterSuccessPeriod'], event);
  }

  enabledFullChangeHandler(event): void {
    if (event) this.openScheduleModal(null);
  }

  scheduleTypeChangeHandler(event: string, fromUI = true): void {
    const wasFFI = this.initialScheduleType === 'ffi';
    const finallyFn = () => {
      const specialEvent = event === 'predefined' || event === 'ffi';
      if (this.isNBF && !this.isRestore && fromUI && specialEvent) this.openScheduleModal(event === 'ffi' ? 'ffi' : 'incremental');
    };

    this.changeType.emit(event);

    if (event !== ScheduleType[ScheduleType.once]) this.confirmedDate = false;

    switch (event) {
      case 'instantly': {
        this.markRetentionRequired(false);
        this.stepForm.get('ForceMissedSchedule').disable();
        this.stepForm.get('StopAfterEnabled').disable();
        this.incrementalData.emit(null);

        return void finallyFn();
      }
      case 'noschedule': {
        this.markRetentionRequired(false);
        this.stepForm.get('ForceMissedSchedule').disable();
        this.stepForm.get('StopAfterEnabled').enable();
        if (this.isNBF && !this.isRestore) {
          this.stepForm.get('incrementalData').disable();
          this.stepForm.get('fullBackupData').disable();
        }
        this.incrementalData.emit(null);

        return void finallyFn();
      }
      case 'ffi': {
        this.markRetentionRequired(!wasFFI);
        this.stepForm.get('ForceMissedSchedule').enable();
        this.stepForm.get('StopAfterEnabled').enable();
        this.stepForm.get('fullBackupData').disable();
        this.stepForm.get('incrementalData').enable();
        return void finallyFn();
      }
      default: {
        this.markRetentionRequired(false);
        this.stepForm.get('ForceMissedSchedule').enable();
        this.stepForm.get('StopAfterEnabled').enable();
        if (this.isNBF && !this.isRestore) {
          this.stepForm.get('incrementalData').enable();
          this.stepForm.get('fullBackupData').enable();
        }
      }
    }

    return void finallyFn();
  }

  openScheduleModal(mode: null | 'incremental' | 'ffi' = 'incremental', isEdit = false): void {
    const settings = new ModalSettings();
    const isFFI = mode === 'ffi';
    const isIncremental = mode === 'incremental' || isFFI;
    const isNewSchedule = this.initialScheduleType !== mode && !isEdit;
    const formControl = this.stepForm.get(isIncremental ? 'incrementalData' : 'fullBackupData') as UntypedFormGroup;
    let value: IncrementalData | FullBackupData = formControl.value;

    if (isNewSchedule) {
      value = {
        ...new UntypedFormGroup(
          StepsHelpers[isIncremental ? 'getDefaultIncrementalSchedule' : 'getDefaultFullSchedule'](this.validVersionForSchedule)
        ).value,
        recurringPeriod: 'Daily',
        weeklyDayOfWeek: [...Array(7)].map((_) => true)
      };
    }

    settings.data = {
      isIncremental,
      isLinux: this.isLinux,
      isFFI,
      validVersionForSchedule: this.validVersionForSchedule,
      value
    };

    if (!isIncremental) {
      const incData = this.stepForm.get('incrementalData').value;

      if (!this.isLinux) {
        settings.data.value.occursAtTime = incData.dailyFreqPeriodOption === 'OccursAt' ? incData.occursAtTime : '00:00';
      } else if (isNewSchedule) {
        settings.data.value = { ...incData };
      }
    }

    if (isNewSchedule || !isIncremental) {
      formControl.get('recurringPeriod').setValue(settings.data.value.recurringPeriod);
      StepsHelpers.updateDaysOfWeek(settings.data.value.weeklyDayOfWeek, formControl);
      this.setPanelTitle(formControl.value, isIncremental ? 'incrementalData' : 'fullBackupData');
    }

    this.modalService
      .openCustom(ScheduleModalComponent, settings)
      .then((result: ScheduleModalData) => {
        if (result) {
          if (result.fullBackupData?.enabled === false) {
            result.fullBackupData.enabled = true;
          }

          const formData = this.stepForm.get(result.incrementalData ? 'incrementalData' : 'fullBackupData') as UntypedFormGroup;
          const resultData = result.incrementalData ? result.incrementalData : result.fullBackupData;

          this.initialScheduleType = mode;
          formData.reset(resultData);
          this.updateFullIfOccursAt(!!result.incrementalData, resultData?.occursAtTime);
          StepsHelpers.updateDaysOfWeek(resultData.weeklyDayOfWeek, formData);
          this.setPanelTitle(formData.value, result.incrementalData ? 'incrementalData' : 'fullBackupData');

          if (isFFI) {
            this.incrementalData.emit(resultData);
          }
        }
      })
      .catch(noop);
  }

  updateFullIfOccursAt(isIncremental: boolean, time: string): void {
    if (isIncremental) {
      const full = this.stepForm.get('fullBackupData');

      full.get('occursAtTime').setValue(time || full.get('occursAtTime').value);
      this.setPanelTitle(full.value, 'fullBackupData');

      return;
    }

    if (this.isLinux) {
      const incrementalData = this.stepForm.get('incrementalData');

      incrementalData.get('occursAtTime').setValue(time);
      this.setPanelTitle(incrementalData.value, 'incrementalData');
    }
  }

  setPanelTitle(schedule, name: string): void {
    schedule.weeklyDayOfWeek = schedule.weeklyDayOfWeek.map((d) => !!d);
    const newSchedule = WizardBaseForPlanHelper.ToPaskalCase(schedule);
    if (newSchedule.RecurringPeriod === 'Daily') newSchedule.RecurringPeriod = 'Weekly';
    else if (newSchedule.Occurrence === WeekNumber['Day Of Month'] && !newSchedule.WeeklyDayOfWeek.some((d) => d))
      newSchedule.RecurringPeriod = 'DayOfMonth';
    newSchedule.RepeatEveryCount = schedule.repeatEveryCount || 1;
    this.schedulesTexts[name] = StepsHelpers.getStringByScheduleData(newSchedule, false);
  }

  private showGFSAlert(needNextStep: boolean, needSave: boolean): void {
    const modalSettings: ModalSettings = {
      header: { title: this.i18nPipe.transform('wizards:ffi_warning_title', { format: 'title' }) },
      footer: {
        okButton: { text: this.i18nPipe.transform('app:buttons:enable') },
        cancelButton: { text: this.i18nPipe.transform('app:buttons:cancel') }
      }
    };

    this.modalService
      .open(modalSettings, this.FFIPayAttentionGFS)
      .then((result) => {
        if (result) {
          this.isFFIConfirmed = true;
          this.stepForm.updateValueAndValidity();

          if (needNextStep || needSave) this.nextStep.emit(needSave);
        }
      })
      .catch(noop);
  }

  /**
   * A step is required to visit if it is invalid,
   * or we are creating a new plan and
   * have not yet reached a certain step.
   *
   * I added a third condition to mark the step as required to show.
   * The retention step will be marked as required for a visit
   * if the state of the radio button is set to FFI.
   * @param {boolean} required
   * @private
   */
  private markRetentionRequired(required: boolean): void {
    this[required ? 'registerRequiredStep' : 'unregisterRequiredStep'].emit(this.retentionTitle);
  }
}
