import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import Computer from '@models/Computer';
import { ComputerLicense } from '@models/License';
import { LicensesService } from '@services';
import { MbsPopupType } from 'mbs-ui-kit';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'mbs-sidepanel-backup-license-tab',
  templateUrl: './sidepanel-backup-license-tab.component.html',
  styleUrls: ['./sidepanel-backup-license-tab.component.css']
})
export class SidepanelBackupLicenseTabComponent implements OnChanges {
  @Input() computer: Computer;
  @Input() isAgentSupportOfflineLicenseManagement: boolean
  public licenses: Array<ComputerLicense>;
  public loading = true;

  public readonly alertType = MbsPopupType;

  public get requiredSockets(): number {
    return this.computer?.socketsNumber;
  }

  public get totalSockets(): number {
    return this.licenses?.reduce((acc, license) => acc + license.socketsCount, 0);
  }

  public get hasVMLicense(): boolean {
    const VMLicenseId = 10;

    return this.licenses?.some((license) => license.licenseType.id === VMLicenseId);
  }

  constructor(private licensesService: LicensesService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.computer?.hid && this.setLicenses();
  }

  setLicenses() {
    this.loading = true;
    this.licensesService
      .getComputerLicenses(this.computer.hid)
      .pipe(
        catchError(() => of([])),
        tap(() => (this.loading = false))
      )
      .subscribe((licenses) => (this.licenses = licenses));
  }
}
