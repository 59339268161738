import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SelectLicenseParams } from '@components/licenses/models/license.model';
import Computer from '@models/Computer';
import { AvailableLicenses, AvailablePaidLicense } from '@models/License';
import { LicenseType } from '@models/LicenseType.enum';
import { PaymentSystem } from '@models/PaymentSystem';
import { AuthService } from '@services';
import { LicensesService } from '@services/licenses.service';
import { LicensesHelper } from '@utils/helpers/licenses-helper';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { isNil } from 'lodash';
import { MbsPopupType, ModalComponent, ModalService } from 'mbs-ui-kit';
import { Observable, Subscription, forkJoin, noop } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { POST_PAYMENT_TOKEN } from '../../../licenses/tokens/post-payment.token';
import { BuyLicenseModalComponent } from '../buy-license-modal/buy-license-modal.component';
import { AvailableLicenseForSelect } from './types';
@Component({
  selector: 'msp-select-license-modal',
  templateUrl: './select-license-modal.component.html',
  styleUrls: ['./select-license-modal.component.scss']
})
export class SelectLicenseModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public isShowAll = false;
  public hasAllowForCompanyLicense = false;
  public readonly isNil = isNil;

  public licenseTypes: Array<{ id: number; name: string }>;

  public selectedLicenseType: LicenseType;
  public selectedLicense: number = null;

  public availableLicenses: Array<AvailableLicenseForSelect>;
  public availablePaidLicenses: Array<AvailableLicenseForSelect>;

  public loading = false;
  public setLicenseLoading = false;
  public setLicenseTypesLoading = true;

  public licensesSubscription: Subscription;

  private get computer(): Computer {
    return this.baseModal.data.computer;
  }

  public get isLinux(): boolean {
    return Computer.isLinux(this.computer);
  }

  public get isMac(): boolean {
    return Computer.isMac(this.computer);
  }

  public get isDesktop(): boolean {
    return !this.isServer;
  }

  public get isServer(): boolean {
    return Computer.isServer(this.computer);
  }

  public readonly mbsPopupType = MbsPopupType;
  private readonly buyLicenseId = -1;

  public get isSelectBuyLicense() {
    return this.selectedLicense === this.buyLicenseId;
  }

  public get hid() {
    return this.baseModal?.data?.computer?.hid;
  }

  public get kind() {
    return this.baseModal?.data?.kind;
  }

  public isAssign() {
    return this.baseModal?.data?.isAssign;
  }

  get isShareIT(): boolean {
    return this.authService.currentUserValue?.ProviderInfo?.PaymentSystem === PaymentSystem.ShareIT;
  }

  constructor(
    @Inject(POST_PAYMENT_TOKEN) public isPostPayment: Observable<boolean>,
    private licensesService: LicensesService,
    private datePipe: DatePipe,
    private modalService: ModalService,
    private i18n: I18NextPipe,
    private ability: AbilityService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.setLicenseTypes();

    this.isPostPayment.pipe(take(1)).subscribe((isPostPayment) => {
      if (!isPostPayment) this.setLicenses();
    });
  }

  public setLicenseTypes(): void {
    this.licensesService
      .getSupportedLicenseTypes(this.hid, this.kind)
      .pipe(finalize(() => (this.setLicenseTypesLoading = false)))
      .subscribe((types) => {
        this.hasAllowForCompanyLicense = types?.some((type) => type.isAllowForCompany);
        this.licenseTypes = types?.map((type) => {
          return {
            ...type,
            name: `${type.isAllowForCompany ? '*' : ''} ${type.displayName}`
          };
        });

        this.selectedLicenseType = types?.find((type) => type.id === this.baseModal?.data?.licenseTypeId)?.id;

        if (!this.selectedLicenseType) {
          this.licensesSubscription?.unsubscribe();
        }
      });
  }

  public setLicenses(): void {
    const selectedLicenseType = !isNil(this.selectedLicenseType) ? this.selectedLicenseType : this.baseModal?.data?.licenseTypeId;

    if (isNil(selectedLicenseType) || !this.hid) {
      return;
    }

    this.loading = true;
    this.selectedLicense = null;

    this.licensesSubscription = forkJoin({
      availablePaidLicenses: this.licensesService.getAvailablePaidLicenses({
        hid: this.hid,
        licenseTypeIds: [selectedLicenseType] as LicenseType[]
      }),
      availableLicenses: this.licensesService.getAvailableLicenses(this.hid, selectedLicenseType)
    })
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((result) => {
        this.setAvailablePaidLicenses(result.availablePaidLicenses);
        this.setAvailableLicenses(result.availableLicenses);
      });
  }

  public handleChangeLicenseType() {
    this.isShowAll = false;
    this.isPostPayment.pipe(take(1)).subscribe((isPostPayment) => {
      if (!isPostPayment) this.setLicenses();
    });
  }

  private setAvailablePaidLicenses(licenses: Array<AvailablePaidLicense>): void {
    this.availablePaidLicenses = licenses?.map((license) => {
      return {
        name: this.i18n.transform('backup:selectLicenseModalComponent:paid'),
        hint: this.datePipe.transform(license.validUntil),
        description: `${license.poolName} (count: ${license.count})`,
        id: license.licenseId
      };
    });
  }

  private setAvailableLicenses(licenses: AvailableLicenses): void {
    const hasAvailablePaidLicenses = licenses?.paidLicense;
    const validUntil = licenses?.paidLicense?.validUntil;

    this.availableLicenses = [
      {
        name: this.i18n.transform('backup:selectLicenseModalComponent:paid'),
        description: hasAvailablePaidLicenses
          ? licenses?.paidLicense?.poolName
          : this.i18n.transform('backup:selectLicenseModalComponent:noAvailableLicenses'),
        hint: validUntil
          ? this.i18n.transform('backup:selectLicenseModalComponent:validUntil', {
              date: this.datePipe.transform(validUntil)
            })
          : null,
        class: hasAvailablePaidLicenses ? null : 'text-danger',
        button:
          hasAvailablePaidLicenses || !this.availablePaidLicenses?.length
            ? null
            : this.i18n.transform('backup:selectLicenseModalComponent:seeAllLicenses'),
        id: licenses?.paidLicense?.licenseId,
        disabled: !hasAvailablePaidLicenses
      }
    ];

    if (this.ability.can('read', 'LicenseBuy')) {
      this.availableLicenses.push({
        name: this.i18n.transform('backup:selectLicenseModalComponent:buyNewLicense'),
        hint: licenses?.newLicensePrice,
        id: this.buyLicenseId
      });
    }

    if (licenses?.trialLicense) {
      this.availableLicenses.unshift({
        name: this.i18n.transform('backup:selectLicenseModalComponent:trial'),
        hint: this.i18n.transform('backup:selectLicenseModalComponent:freeForDays', { count: licenses.trialLicense.days }),
        id: licenses?.trialLicense.licenseId
      });
    }
  }

  setLicense(): void {
    this.setLicenseLoading = true;
    const params: SelectLicenseParams = {
      licenseTypeId: this.selectedLicenseType,
      hid: this.hid,
      vmSockets: 0,
      availableLicenseId: this.selectedLicense,
      isOfflineMode: !this.computer.online && this.baseModal.data?.isAgentSupportOfflineLicenseManagement
    };

    if (!this.isAssign()) {
      params.oldLicenseTypeId = this.baseModal?.data?.licenseTypeId;
    }

    const request = this.isAssign()
      ? this.licensesService.assignComputerLicense(params)
      : this.licensesService.changeComputerLicense(params);

    request.subscribe({
      next: (result) => this.baseModal.save(result),
      error: () => this.baseModal.close()
    });
  }

  public buyLicense(): void {
    const licenseTypeWillNotBeChanged = this.selectedLicenseType === this.computer?.backupLicense?.licenseType;

    if ((this.baseModal.data?.isAgentSupportOfflineLicenseManagement || licenseTypeWillNotBeChanged) && !this.isShareIT) {
      this.proceed();
    } else {
      this.modalService
        .openCustom(BuyLicenseModalComponent, {
          data: {
            licenseType: this.selectedLicenseType,
            hid: this.hid
          }
        })
        .catch(noop);
    }
  }

  public proceed(): void {
    this.router.navigate(['/AP/Licenses.aspx'], {
      queryParams: LicensesHelper.getQueryParamsForCalculator(this.selectedLicenseType, this.hid)
    });
  }

  public isApplyBtnDisabled(isPostPayment: boolean) {
    return isNil(this.selectedLicenseType) || (isNil(this.selectedLicense) && !isPostPayment);
  }
}
