import {
  ConnectSettingsForm,
  IConnectSettings,
  JoinSessionMode,
  LoggingLevel,
  PinLength,
  ProxyMode,
  TimeUnits,
  UnattendedAccessDisconnectMode
} from './connect-settings.models';
import {
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MbsValidators } from 'mbs-ui-kit';
import { isNil } from 'lodash';
import { AgentOptions } from '@models/AgentOptions';

export const DEFAULT_PROXY_PORT = 3128;

export const MIN_PROXY_PORT = 1;
export const MAX_PROXY_PORT = 65535;

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export class ConnectSettingsWrapper {
  settingsLoaded = false;
  useGlobal = true;
  needUseNewSettings = false;
  hasCompanySettings = false;
  logger = {
    level: LoggingLevel.Warning
  };
  update = {
    auto: true
  };
  proxy = {
    mode: ProxyMode.None,
    host: '',
    port: DEFAULT_PROXY_PORT,
    useBasicAuth: false,
    basicAuthUserName: '',
    basicAuthPassword: ''
  };
  configPassword = {
    password: '',
    enabled: false
  };
  managedSystem = {
    allowOutgoingConnections: true,
    allowGui: true,
    allowRAUnattendedAccess: true,
    allowRemoteConnection: true,
  };
  security = {
    pinLength: PinLength.Six,
    customPassword: {
      enabled: false,
      password: ''
    },
    joinSessionMode: JoinSessionMode.Pin
  };
  unattendedAccessDisconnect = {
    mode: UnattendedAccessDisconnectMode.ReturnOriginalState,
    withConfirmation: true
  }
  inputControl = {
    blackScreen: true,
    lockInput: true
  }
  disconnectIfInactive = {
    enabled: true,
    interval: 1,
    intervalUnits: TimeUnits.Hours
  }

  private isProxyBasicAuthAvailable() {
    return this.proxy.mode === ProxyMode.AutoDetect || this.proxy.mode === ProxyMode.Manual;
  }

  private isProxyHostAvailable() {
    return this.proxy.mode === ProxyMode.Manual;
  }

  private isPinLengthAvailable() {
    return (
      this.security.joinSessionMode === JoinSessionMode.Pin || this.security.joinSessionMode === JoinSessionMode.DialogAndPin
    );
  }

  getSettings(): IConnectSettings {
    return {
      ConfigPassword: this.configPassword.enabled ? this.configPassword.password : '',
      ManagedSystemConfig: {
        AllowOutgoingConnections: this.managedSystem.allowOutgoingConnections,
        AllowGui: this.managedSystem.allowGui,
        AllowRemoteConnection: this.managedSystem.allowRemoteConnection,
        AllowRAUnattendedAccess: this.managedSystem.allowRAUnattendedAccess
      },
      NetworkConfig: {
        ProxyServerConfigMode: this.proxy.mode,
        ProxyServerHost: this.isProxyHostAvailable() ? this.proxy.host : '',
        ProxyServerPort: this.isProxyHostAvailable() && this.proxy.host ? String(this.proxy.port) : '',
        ProxyServerUseBasicAuth: this.isProxyBasicAuthAvailable() ? this.proxy.useBasicAuth : false,
        ProxyServerUserName: this.isProxyBasicAuthAvailable() ? this.proxy.basicAuthUserName : '',
        ProxyServerPassword: this.isProxyBasicAuthAvailable() ? this.proxy.basicAuthPassword : ''
      },
      ServerConfig: {
        Password: {
          Length: this.isPinLengthAvailable() ? this.security.pinLength : PinLength.Six
        },
        CustomPasswords: {
          MainItemValue: this.security.customPassword.enabled ? this.security.customPassword.password : '',
          ActsAsPin: false
        },
        JoinSessionMode: this.security.joinSessionMode,
        UnattendedAccessDisconnectConfig: {
          Mode: this.unattendedAccessDisconnect?.mode,
          WithConfirmation: this.unattendedAccessDisconnect?.withConfirmation
        },
        InputControl: {
          BlackScreen: this.inputControl.blackScreen,
          LockInput: this.inputControl.lockInput
        },
        DisconnectIfInactiveConfig: {
          Enabled: this.disconnectIfInactive.enabled,
          Interval: this.disconnectIfInactive.interval,
          IntervalUnits: this.disconnectIfInactive.intervalUnits
        }
      },
      AppFeaturesConfig: {
        UpdateConfig: {
          ModeAuto: this.update.auto
        }
      },
      LoggerConfig: {
        LoggingLevel: this.logger.level
      }
    };
  }

  updateStateFromConfig(settings: DeepPartial<IConnectSettings>) {
    const { ConfigPassword, ManagedSystemConfig, NetworkConfig, ServerConfig, AppFeaturesConfig, LoggerConfig } = settings || {};

    this.configPassword.enabled = Boolean(ConfigPassword);
    this.configPassword.password = ConfigPassword || '';

    this.managedSystem.allowOutgoingConnections = isNil(ManagedSystemConfig?.AllowOutgoingConnections)
      ? true
      : ManagedSystemConfig.AllowOutgoingConnections;
    this.managedSystem.allowGui = isNil(ManagedSystemConfig?.AllowGui) ? true : ManagedSystemConfig.AllowGui;
    this.managedSystem.allowRAUnattendedAccess = isNil(ManagedSystemConfig?.AllowRAUnattendedAccess) ? true : ManagedSystemConfig.AllowRAUnattendedAccess;
    this.managedSystem.allowRemoteConnection = isNil(ManagedSystemConfig?.AllowRemoteConnection) ? true : ManagedSystemConfig.AllowRemoteConnection;

    const proxyMode = NetworkConfig?.ProxyServerConfigMode !== undefined ? NetworkConfig.ProxyServerConfigMode : ProxyMode.None;

    this.proxy.mode = proxyMode;

    this.proxy.host = NetworkConfig?.ProxyServerHost || '';
    this.proxy.port = Number(NetworkConfig?.ProxyServerPort) || DEFAULT_PROXY_PORT;
    this.proxy.useBasicAuth = NetworkConfig?.ProxyServerUseBasicAuth || false;
    this.proxy.basicAuthUserName = NetworkConfig?.ProxyServerUserName || '';
    this.proxy.basicAuthPassword = NetworkConfig?.ProxyServerPassword || '';
    this.security.pinLength = ServerConfig?.Password?.Length !== undefined ? ServerConfig.Password.Length : PinLength.Six;
    this.security.customPassword.enabled = Boolean(ServerConfig?.CustomPasswords?.MainItemValue);
    this.security.customPassword.password = ServerConfig?.CustomPasswords?.MainItemValue || '';

    this.security.joinSessionMode = ServerConfig?.JoinSessionMode !== undefined ? ServerConfig.JoinSessionMode : JoinSessionMode.Pin;

    this.update.auto = AppFeaturesConfig?.UpdateConfig?.ModeAuto !== undefined ? AppFeaturesConfig.UpdateConfig.ModeAuto : true;

    this.logger.level = LoggerConfig?.LoggingLevel !== undefined ? LoggerConfig.LoggingLevel : LoggingLevel.Warning;

    this.unattendedAccessDisconnect = {
      mode: isNil(ServerConfig?.UnattendedAccessDisconnectConfig?.Mode) ? UnattendedAccessDisconnectMode.ReturnOriginalState : ServerConfig?.UnattendedAccessDisconnectConfig?.Mode,
      withConfirmation: ServerConfig?.UnattendedAccessDisconnectConfig?.WithConfirmation || true
    }

    this.inputControl = {
      blackScreen: isNil(ServerConfig?.InputControl?.BlackScreen) ? true : ServerConfig.InputControl.BlackScreen,
      lockInput: isNil(ServerConfig?.InputControl?.BlackScreen) ? true : ServerConfig.InputControl.LockInput
    }

    this.disconnectIfInactive = {
      enabled: isNil(ServerConfig?.DisconnectIfInactiveConfig?.Enabled) ? true : ServerConfig.DisconnectIfInactiveConfig.Enabled,
      interval: isNil(ServerConfig?.DisconnectIfInactiveConfig?.Interval) ? 1 : ServerConfig?.DisconnectIfInactiveConfig?.Interval,
      intervalUnits: isNil(ServerConfig?.DisconnectIfInactiveConfig?.IntervalUnits) ? TimeUnits.Hours : ServerConfig?.DisconnectIfInactiveConfig?.IntervalUnits
    }
  }

  updateState(data: ConnectSettingsForm['value']) {
    if (!data?.managedSystem?.allowRemoteConnection) {
      return void (this.managedSystem.allowRemoteConnection = false);
    }

    /* All fields of form are optional by reactive forms design */
    this.logger.level = data?.logger?.level;
    this.update.auto = data?.update?.auto;
    this.proxy.mode = data?.proxy?.mode;
    this.proxy.host = data?.proxy?.host;
    this.proxy.port = data?.proxy?.port;
    this.proxy.useBasicAuth = data?.proxy?.useBasicAuth;
    this.proxy.basicAuthUserName = data?.proxy?.basicAuthUserName;
    this.proxy.basicAuthPassword = data?.proxy?.basicAuthPassword;
    this.configPassword.password = data?.configPassword?.password;
    this.configPassword.enabled = data?.configPassword?.enabled;
    this.security.pinLength = data?.security?.pinLength;
    this.security.customPassword.enabled = data?.security?.customPassword?.enabled;
    this.security.customPassword.password = data?.security?.customPassword?.password;
    this.security.joinSessionMode = data?.security?.joinSessionMode;
    this.managedSystem.allowRemoteConnection = data?.managedSystem?.allowRemoteConnection;
    this.managedSystem.allowGui = data?.managedSystem?.allowGui;
    this.managedSystem.allowRAUnattendedAccess = data?.managedSystem?.allowRAUnattendedAccess;
    this.managedSystem.allowOutgoingConnections = data?.managedSystem?.allowOutgoingConnections;
    this.unattendedAccessDisconnect.mode = data?.unattendedAccessDisconnect?.mode;
    this.unattendedAccessDisconnect.withConfirmation =
      data?.unattendedAccessDisconnect?.mode === UnattendedAccessDisconnectMode.ReturnOriginalState;
    this.inputControl.lockInput = data?.inputControl?.lockInput;
    this.inputControl.blackScreen = data?.inputControl?.blackScreen;
    this.disconnectIfInactive.enabled = data?.disconnectIfInactive?.enabled;
    this.disconnectIfInactive.intervalUnits = data?.disconnectIfInactive?.intervalUnits;
    this.disconnectIfInactive.interval = data?.disconnectIfInactive?.interval;
  }

  configureSettingsForm(): ConnectSettingsForm {
    return new FormGroup({
      logger: new UntypedFormGroup({
        level: new FormControl(LoggingLevel.NoLog)
      }),
      update: new UntypedFormGroup({
        auto: new FormControl(true)
      }),
      proxy: new UntypedFormGroup({
        mode: new FormControl(ProxyMode.None),
        host: new FormControl('', Validators.required),
        port: new FormControl(null),
        useBasicAuth: new FormControl(false),
        basicAuthUserName: new FormControl('', Validators.required),
        basicAuthPassword: new FormControl('', Validators.required)
      }),
      configPassword: new UntypedFormGroup({
        password: new FormControl('', Validators.required),
        enabled: new FormControl(false)
      }),
      managedSystem: new UntypedFormGroup({
        allowRAUnattendedAccess: new FormControl(true),
        allowRemoteConnection: new FormControl(true),
        allowOutgoingConnections: new FormControl(true),
        allowGui: new FormControl(true)
      }),
      security: new UntypedFormGroup({
        pinLength: new FormControl(PinLength.Four),
        customPassword: new UntypedFormGroup({
          enabled: new FormControl(false),
          password: new FormControl('', [
            Validators.required,
            MbsValidators.createPasswordValidator({
              minlength: 6,
              maxlength: 15
            })
          ])
        }),
        joinSessionMode: new FormControl(JoinSessionMode.Pin)
      }),
      unattendedAccessDisconnect: new UntypedFormGroup({
        mode: new FormControl(UnattendedAccessDisconnectMode.ReturnOriginalState)
      }),
      inputControl: new UntypedFormGroup({
        blackScreen: new FormControl(true),
        lockInput: new FormControl(true)
      }),
      disconnectIfInactive: new UntypedFormGroup({
        enabled: new FormControl(true),
        interval: new FormControl(1),
        intervalUnits: new FormControl(TimeUnits.Hours)
      })
    });
  }

  /* Migrate options to settings */
  migrateSettings(settings: IConnectSettings, options: AgentOptions): IConnectSettings {
    const allowRAUnattendedAccess = isNil(settings?.ManagedSystemConfig?.AllowRAUnattendedAccess)
      ? isNil(options?.connectValues?.allowRAUnattendedAccess) ? true : options.connectValues.allowRAUnattendedAccess
      : settings?.ManagedSystemConfig?.AllowRAUnattendedAccess;
    const allowRemoteConnection = isNil(settings?.ManagedSystemConfig?.AllowRemoteConnection)
      ? isNil(options?.connectValues?.allowRemoteConnection) ? true : options.connectValues.allowRemoteConnection
      : settings?.ManagedSystemConfig?.AllowRemoteConnection;

    return {
      ...settings,
      ManagedSystemConfig: {
        ...settings?.ManagedSystemConfig,
        AllowRemoteConnection: allowRemoteConnection,
        AllowRAUnattendedAccess: allowRAUnattendedAccess
      }
    }
  }

  isProxyHostValid(form: FormGroup): boolean {
    return Boolean(
      form.get('proxy.host').disabled ||
      this.proxy.mode === ProxyMode.None ||
      this.proxy.mode === ProxyMode.AutoDetect ||
      this.proxy.host
    );
  }

  isProxyBasicAuthValid(form: FormGroup): boolean {
    return Boolean(
      (form.get('proxy.basicAuthUserName').disabled && form.get('proxy.basicAuthPassword').disabled) ||
      !this.proxy.useBasicAuth ||
      (this.proxy.basicAuthUserName && this.proxy.basicAuthPassword)
    );
  }

  isCustomPasswordValid(form: FormGroup): boolean {
    const field = form.get('security.customPassword.password');
    return Boolean(
      !this.security.customPassword.enabled || field.valid || field.disabled
    );
  }

  isConfigPasswordValid(form: FormGroup): boolean {
    return Boolean(
      form.get('configPassword.password').disabled ||
      !this.configPassword.enabled ||
      this.configPassword.password
    );
  }

  isValid(form: FormGroup) {
    return this.isProxyHostValid(form) &&
      this.isProxyBasicAuthValid(form) &&
      this.isCustomPasswordValid(form) &&
      this.isConfigPasswordValid(form);
  }
}
