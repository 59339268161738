import { FindResult, RegTreeItem } from '@models/rmm/RmmRegEditTypes';
import { DefaultValueName, RegEditTreeIconPath, RegistryValueKind } from '@utils/constants/rmm-registry-constants';
import { generateUid } from '@utils/generateUid';
import { TreeElement } from 'mbs-ui-kit';

export class RegTree {
  public regTreeData: TreeElement[];

  constructor(treeElements: TreeElement[]) {
    this.regTreeData = treeElements;
  }

  addPath(path: string, data: FindResult): void {
    const pathItems = path.split('\\');
    let currentSubtree = this.regTreeData;
    let parent = null;
    let last = null;

    const setChildren = (array, parent): void => {
      for (const val of array) {
        const lowerName = val.name.toLowerCase();
        if (parent.children.some((child) => child.label.toLowerCase() === lowerName)) continue;
        const name = typeof val === 'string' ? val : val.name || DefaultValueName;
        const itemValue: RegTreeItem = { name, type: val.type, value: val.value };
        parent.children.push({
          label: name,
          path: val.fullName,
          icon: null,
          id: generateUid(),
          value: itemValue,
          treeIcon: this.getIconByType(itemValue.type),
          gotChildren: !!val.type,
          parent
        });
      }
    };
    for (const pathItem of pathItems) {
      const pathItemLower = pathItem.toLowerCase();
      const found = currentSubtree.find((p) => {
        return p.label.toString().toLowerCase() === pathItemLower;
      });
      if (found) {
        if (!found.children) {
          found.children = [];
        }
        parent = found;
        currentSubtree = found.children;
        last = found;
      } else {
        if (parent) {
          parent.expanded = false;
        }
        const itemValue: RegTreeItem = { name: pathItem, type: null };
        const newTreeElement = {
          label: pathItem,
          id: generateUid(),
          gotChildren: false,
          value: itemValue,
          icon: null,
          treeIcon: this.getIconByType(itemValue.type),
          children: [],
          expanded: false,
          parent
        };
        currentSubtree.push(newTreeElement);
        parent = newTreeElement;
        currentSubtree = newTreeElement.children;
        last = newTreeElement;
      }
    }

    if (last) {
      setChildren(data.subKeys, last);
      setChildren(data.values, last);
    }

    data.subKeys.forEach((key) => {
      this.addPath(key.fullName, key);
    });
  }

  getIconByType(type): RegEditTreeIconPath {
    if (type === null || type === undefined) return RegEditTreeIconPath.windowsFolder;
    if (typeof type === 'string') {
      type = RegistryValueKind[type];
    }
    switch (type) {
      case RegistryValueKind.String:
      case RegistryValueKind.MultiString:
      case RegistryValueKind.ExpandString:
        return RegEditTreeIconPath.listFileString;
      case RegistryValueKind.DWord:
      case RegistryValueKind.QWord:
        return RegEditTreeIconPath.listFileNumeric;
      case RegistryValueKind.Binary:
        return RegEditTreeIconPath.listFileBinary;
      default:
        return RegEditTreeIconPath.listFile;
    }
  }

  getItemByPath(path: string): TreeElement {
    const pathItems = path.split('\\');
    let currentChildren = this.regTreeData;
    let currentTreeElement;
    pathItems.forEach((pathItem) => {
      const pathItemLower = pathItem.toLowerCase();
      if (pathItem) {
        currentTreeElement = currentChildren.find((p) => {
          return p.label.toString().toLowerCase() === pathItemLower;
        });
        if (currentTreeElement) {
          currentChildren = currentTreeElement.children;
        }
      }
    });

    return currentTreeElement;
  }

  addKeyToPath(path: string, keyName: string): void {
    const item = this.getItemByPath(path);
    this.addKeyToItem(item, keyName);
  }

  addKeyToItem(item, keyName: string): void {
    const regItem: RegTreeItem = { name: keyName, type: null };
    if (!item.children && item.gotChildren) {
      item.children = [];
    }
    item.children.push({
      label: keyName,
      id: generateUid(),
      value: regItem,
      children: [],
      icon: null,
      treeIcon: this.getIconByType(null),
      gotChildren: false,
      parent: item
    });
  }

  addOrModifyValueByPath(path: string, valueName: string, type: RegistryValueKind, valueData: string) {
    const item = this.getItemByPath(path) as any;
    if (!item.children) {
      item.children = [];
    }
    const modifiedItem = item.children.find((p) => p.label === valueName);
    if (modifiedItem) {
      modifiedItem.value.value = valueData;
    } else {
      const name = valueName ? valueName : DefaultValueName;
      const regItem: RegTreeItem = { name, type, value: valueData };

      if (item.gotChildren) {
        item.children.push({
          label: name,
          id: generateUid(),
          value: regItem,
          icon: null,
          treeIcon: this.getIconByType(type),
          gotChildren: true,
          parent: item,
          children: []
        });
      }
    }
  }

  deleteSubTreeByPath(path: string, name: string) {
    let fullPath = path;
    if (name) {
      fullPath += '\\\\' + name;
    }
    const item = this.getItemByPath(fullPath);
    const index = item.parent.children.findIndex((p) => p.id === item.id);
    item.parent.children.splice(index, 1);
  }
}
