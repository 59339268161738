import { DayOfWeek } from '@models/DayOfWeek.enum';
import { RecurringSchedule } from '@models/ScheduleModel';
import { WeekNumber } from '@models/WeekNumber.enum';
import { BaseFormModels } from '@modules/wizards/models/base/base-form-models';
import * as moment from 'moment';
import { AdvancedOptionsFlags, AdvancedOptionsStepValue, BlockSizeEnum } from '../advanced-options-models';
import { ArchiveConsistencyStepValue, defaultFailureMinutes } from '../archive-consistency-step-value';
import { CompressionEncryptionStepValue } from '../compression-encription-models';
import { RetentionPolicyStepValue, getDefaultGFSPolicySettings } from '../retention-policy-models';
import { ScheduleAdvancedStepValue, ScheduleFormSettings } from '../schedule-advanced-models';
import { ScheduleStepValue } from '../schedule-models';
import { SimpleScheduleStepValue } from '../simple-schedule-models';
import { BackupTargetVolumesEnum, WhatBackupStepValue } from '../what-backup-models';

export class IBBFormModel extends BaseFormModels {
  whatBackupStep: WhatBackupStepValue = { BackupVolumes: BackupTargetVolumesEnum.SystemRequired, partitions: [], valid: false };
  compressionEncryptionStep: CompressionEncryptionStepValue = {
    UseCompression: true,
    UseEncryption: false,
    EncryptionAlgorithm: 3,
    EncryptionKeySize: 256,
    EncryptionPassword: '',
    UseServerSideEncryption: false,
    valid: true
  };
  retentionPolicyStep: RetentionPolicyStepValue;
  archiveConsistencyStep: ArchiveConsistencyStepValue;
  advancedOptionsStep: AdvancedOptionsStepValue = {
    UseS3Acceleration: false,
    StorageClass: 1,
    azureAccessTier: 0,
    ExcludeEnabled: false,
    excludeRules: [],
    SyntheticFull: true,
    IgnoreBadSectors: true,
    DisableVSS: false,
    useSystemVSSProvider: false,
    PrefetchBlockCount: 100,
    BlockSize: BlockSizeEnum['1 MB'],
    valid: true
  };
  advancedOptionsFlags: AdvancedOptionsFlags = {
    ExcludePossible: true,
    UseS3AccelerationPossible: false,
    AzureAccessTierType: false,
    StorageClassSettingPossible: false,
    SyntheticFullSettingPossible: false,
    IgnoreBadSectorsPossible: true,
    DisableVSSPossible: true,
    UseVSSPossible: true,
    PrefetchBlockCountPossible: true,
    BlockSizeSettingPossible: true
  };
  scheduleStep: ScheduleStepValue;
  simpleScheduleStep?: SimpleScheduleStepValue;
  scheduleAdvancedStep?: ScheduleAdvancedStepValue;
  AvailableMachinePlans: any[] = [];

  public date = moment(new Date()).toDate();
  public time = moment(this.date).format('HH:mm:ss');
  constructor(public isNBF = false, public isLinux = false) {
    super(isNBF, isLinux);
    if (!isNBF) {
      this.retentionPolicyStep = {
        RetentionUseDefaultSettings: true,
        keepNumberOfVersions: false,
        RetentionNumberOfVersions: 3,
        deleteVersionsOlderThan: false,
        deleteVersionsOlderThanCount: 1,
        deleteVersionsOlderThanPeriod: 2,
        valid: true
      };
      this.scheduleStep = {
        ScheduleType: 'noschedule',
        specificDateGroup: { date: this.date, time: this.time },
        StopAfterEnabled: false,
        stopAfterHours: 0,
        stopAfterMinutes: 0,
        ForceMissedSchedule: false,
        overdueAfterSuccessEnabled: false,
        overdueAfterSuccessAmount: 7,
        overdueAfterSuccessPeriod: 1,
        syncBeforeRun: false,
        valid: true
      };
      this.scheduleAdvancedStep = {
        forceFull: new ScheduleFormSettings(false, new RecurringSchedule(isLinux)),
        blockLevel: new ScheduleFormSettings(),
        scheduleDiff: new ScheduleFormSettings(null, null),
        scheduleTLog: new ScheduleFormSettings(null, null),
        valid: true
      };
      this.simpleScheduleStep = {
        startFromDate: new Date(),
        startFromTime: this.time,
        scheduleTemplate: '',
        fullScheduleEnabled: true,
        fullBackupEveryHours: 168,
        fullBackupEveryMinutes: 0,
        blockLevelBackup: true,
        blockLevelBackupEveryHours: 24,
        blockLevelBackupEveryMinutes: 0,
        scheduleDiffEnabled: false,
        differentialBackupEveryHours: 0,
        differentialBackupEveryMinutes: 0,
        scheduleTLogEnabled: false,
        tLogBackupEveryHours: 0,
        tLogBackupEveryMinutes: 0,
        valid: true
      };
    } else {
      this.compressionEncryptionStep.hint = '';
      this.retentionPolicyStep = {
        RetentionUseDefaultSettings: true,
        deleteVersionsOlderThanCount: 1,
        deleteVersionsOlderThanPeriod: 2,
        intelligentRetention: true, // like at Agent side
        GFSPolicySettings: getDefaultGFSPolicySettings(),
        valid: true
      };
      this.scheduleStep = {
        ScheduleType: 'noschedule',
        StopAfterEnabled: false,
        stopAfterHours: 0,
        stopAfterMinutes: 0,
        ForceMissedSchedule: false,
        incrementalData: {
          recurringPeriod: 'Monthly',
          weeklyDayOfWeek: [],
          occurrence: WeekNumber.First,
          dayOfWeek: DayOfWeek.Wednesday,
          dayOfMonthCount: 1,
          dailyFreqPeriodOption: 'OccursAt',
          occursAtTime: '00:00',
          occursEveryCount: 1,
          occursEveryPeriod: 'hours',
          occursEveryFromTime: '00:00',
          occursEveryToTime: '23:59'
        },
        fullBackupData: {
          enabled: false,
          recurringPeriod: 'Monthly',
          weeklyDayOfWeek: [],
          occurrence: WeekNumber.First,
          dayOfWeek: DayOfWeek.Wednesday,
          dayOfMonthCount: 1,
          dailyFreqPeriodOption: 'OccursAt',
          occursAtTime: '00:00'
        },
        syncBeforeRun: false,
        valid: true
      };
      this.archiveConsistencyStep = {
        useFullConsistencyCheck: true,
        RunRestoreVerificationOn: 0,
        minutes: 1,
        seconds: 0,
        numCpu: 1,
        memoryInMb: 1024,
        failureMinutes: defaultFailureMinutes,
        valid: true
      };

      if (!isLinux) {
        this.retentionPolicyStep.GFSPolicySettings = getDefaultGFSPolicySettings();
      }
    }

    if (!isLinux) {
      this.prePostActionsStep.enableBackupChain = false;
      this.prePostActionsStep.backupChainNextPlan = '';
      this.prePostActionsStep.executeChainPlanOnSuccess = false;
      this.prePostActionsStep.ForceFullNextPlan = false;

      this.notificationStep.addEntryToEventLogOnCompleteGroup = { flag: false, case: 'whenFails' };
    }
  }
}
