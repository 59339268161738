import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DisableControlDirective } from './directives/disable-control.directive';
import { TrimInputDirective } from './directives/trim-input.directive';

const directives = [DisableControlDirective, TrimInputDirective];

@NgModule({
  imports: [CommonModule, NgbModule],
  exports: directives,
  declarations: directives
})
export class MbsSharedUtilsModule {}
