import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalComponent } from 'mbs-ui-kit';
import {LicensesHelper} from "@utils/helpers/licenses-helper";

@Component({
  selector: 'msp-buy-license-modal',
  templateUrl: './buy-license-modal.component.html'
})
export class BuyLicenseModalComponent {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public get licenseType():number{
    return this.baseModal.data?.licenseType
  }

  constructor(private router: Router) {}

  public proceed(): void {
    this.router.navigate(['/AP/Licenses.aspx'], {
      queryParams: LicensesHelper.getQueryParamsForCalculator(this.licenseType, this.baseModal.data?.hid)
    });
  }
}
